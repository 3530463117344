import React, { useEffect, useRef, useState } from "react";
import RevenueLineChart from "../../components/Charts/RevenueLineChart";
import { Table,Spinner } from "flowbite-react";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import RevenueLineChartDashboard from "../../components/Charts/RevenueLineChartDashbaord";
import { format, parse } from "date-fns";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const { token, logout } = useAuth();
  const navigate=useNavigate();
  const [totalevents, setEvents] = useState(0);
  const [totalorders, setOrders] = useState(0);
  const [totalrevenue, setRevenue] = useState(0);
  const [upcoming, setUpcoming] = useState([]);
  const [sales, setSales] = useState([]);


  const loadedOnce = useRef(false);
  const organizeOnce = useRef(false);
  const revenueOnce = useRef(false);
  const upcomingOnce = useRef(false);
  const salesOnce = useRef(false);
  const url =process.env.REACT_APP_API_URL;
  // get_events_logged_in_user

  useEffect(() => {
    if (loadedOnce.current) return;
    loadedOnce.current = true;
    handletotalevent();
  }, []);

  useEffect(() => {
    if (organizeOnce.current) return;
    organizeOnce.current = true;
    handletotalorders();
  }, []);

  useEffect(() => {
    if (revenueOnce.current) return;
    revenueOnce.current = true;
    handletotalrevenue();
  }, []);

  useEffect(() => {
    if (upcomingOnce.current) return;
    upcomingOnce.current = true;
    handleupcoming();
  }, []);

  useEffect(() => {
    if (salesOnce.current) return;
    salesOnce.current = true;
    handlesales();
  }, []);

  const handletotalevent = async () => {
    // get_dashboard_total_event
    const additionalPath = `${url}/get_dashboard_total_event`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { headers: headers })
      .then((response) => {
        setEvents(response.data.total_no);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };
  const handletotalorders = async () => {
    // get_dashboard_total_orders
    const additionalPath = `${url}/get_dashboard_total_orders`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { headers: headers })
      .then((response) => {
        setOrders(response.data.total_orders);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };
  const handletotalrevenue = async () => {
    // get_dashboard_total_revenue
    const additionalPath = `${url}/get_dashboard_total_revenue`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { headers: headers })
      .then((response) => {
        const roundedRevenue = parseFloat(response.data.total_revenue).toFixed(2);
        setRevenue(roundedRevenue);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  //

  const handleupcoming = async () => {
    // get_dashboard_total_revenue
    const additionalPath = `${url}/get_dashboard_upcoming_events`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { headers: headers })
      .then((response) => {
        const upcomingData = response.data.upcoming_events.data;
        const firstEightUpcoming = upcomingData.length > 0 ? upcomingData.slice(0, 8) : [];
        setUpcoming(firstEightUpcoming);
        upcomingOnce.current=true
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  // get_dashboard_get_sales
  const handlesales = async () => {
    // get_dashboard_total_revenue
    const additionalPath = `${url}/get_dashboard_get_sales`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { headers: headers })
      .then((response) => {
        console.log(response.data)
        // setSales(response.data.event_orders.data);
        // salesOnce.current=true
        if (response && response.data && response.data.event_sales && response.data.event_sales.data) {
          // Assuming response.data.event_orders.data is an array
          setSales(response.data.event_sales.data);
          salesOnce.current=true
        } else {
          console.error("Invalid data structure in the API response:", response.data);
          // Handle the error or setSales to an empty array depending on your use case
          setSales(response.data.event_sales.data);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
         

        } else {
          // Handle other errors
          console.log(err);
          

          // Rethrow the error for further handling if needed
        }
      });
  };

  return (
    <div className="p-3">
      <p className="text-xl font-bold text-midnightblue">Overview</p>
      <div className="flex lg:md:flex-row xl:md:flex-row flex-col justify-between mt-2 gap-4">
        <div className="flex flex-col gap-4 basis-3/4">
          <div className="flex flex-row gap-2  w-full">
            {/* card */}
            <div className="flex flex-row bg-white rounded-md p-4 justify-between w-1/3 items-center">
              <div className="flex flex-col">
                <p className="text-midnightblue font-bold text-lg">
                  {totalevents}
                </p>
                <p className="text-xs text-darkgray">Total Events</p>
              </div>

              <svg
                class="w-6 h-6 text-normalblue"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 18"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 12v5m5-9v9m5-5v5m5-9v9M1 7l5-6 5 6 5-6"
                />
              </svg>
            </div>

            <div className="flex flex-row bg-white rounded-md p-4 justify-between w-1/3 items-center">
              <div className="flex flex-col">
                <p className="text-midnightblue font-bold text-lg">
                  {totalrevenue}
                </p>
                <p className="text-xs text-darkgray">Total Revenue</p>
              </div>

              <svg
                class="w-6 h-6 text-normalblue"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 18"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 12v5m5-9v9m5-5v5m5-9v9M1 7l5-6 5 6 5-6"
                />
              </svg>
            </div>

            <div className="flex  bg-white rounded-md p-4 justify-between w-1/3 items-center">
              <div className="flex flex-col">
                <p className="text-midnightblue font-bold text-lg">
                  {totalorders}
                </p>
                <p className="text-xs text-darkgray">Total Orders</p>
              </div>

              <svg
                class="w-6 h-6 text-normalblue"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 18"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 12v5m5-9v9m5-5v5m5-9v9M1 7l5-6 5 6 5-6"
                />
              </svg>
            </div>
          </div>

          {/* sales table */}

          <div className="flex flex-col rounded-md p-3 bg-white h-88">
            <p className="font-medium text-md">Monthly Sales</p>

            <div className="w-full">
              <RevenueLineChartDashboard />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-md basis-1/4 p-3">
          <p className="text-midnightblue font-medium">Upcoming Events</p>
          <hr></hr>
          {/* div of array getting events upcoming */}
          {upcomingOnce.current==false?(
                  <div className="flex justify-center items-center h-full">
                  <Spinner aria-label="Loading" color="warning" size="md" />
                  <p className="text-darkgray">Loading Upcoming Events.....</p>
                </div>
                ):(upcoming && upcoming.length > 0 ? (
            upcoming.map((event, index) => (
              <div className="flex flex-row  gap-2 mt-2">
                <div className="border-2 rounded-full h-12 w-12 border-normalblue p-2 flex flex-col justify-center items-center text-normalblue">
                  <p className="text-xs">
                    {format(new Date(event.date), "dd")}
                  </p>
                  <p className="text-sm">
                    {format(new Date(event.date), "MMM")}
                  </p>
                </div>
                <div className="flex flex-col justify-center">
                  <p className="text-sm">{event.name}</p>
                  <p className="text-xs">{event.location}</p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-darkgray text-sm">
              No Upcoming Events. Create more events and create meaningful
              memories
            </p>
          ))}
        </div>
      </div>

      {/* recent orders and calendat */}
      <div className="flex sm:flex-row flex-col gap-4 justify-between mt-4">
        <div className="p-3 rounded-md bg-white w-full h-72">
          {/* recent orders */}
          <p className="font-medium text-md">Recent Orders</p>
          <div className="mt-4 sm:block hidden">
            <Table>
              <Table.Head>
                <Table.HeadCell>Order Number</Table.HeadCell>
                {/* <Table.HeadCell>Date and Time</Table.HeadCell> */}
                <Table.HeadCell>Total Bill</Table.HeadCell>
                {/* <Table.HeadCell>Status</Table.HeadCell> */}
                <Table.HeadCell>Actions</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {salesOnce.current==false? (
                  <Table.Row>
                  <Table.Cell colSpan={5} className="text-center">
                  <div className="flex justify-center items-center h-full">
                  <Spinner aria-label="Loading" color="warning" size="md" />
                  <p className="text-darkgray">Loading Recent Sales .....</p>
                </div>
                  </Table.Cell>
                </Table.Row>
                  
                ):(
                sales && sales.length > 0 ? (
                  sales.map((event) => (
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {event.order_no}
                      </Table.Cell>
                      {/* <Table.Cell>{event.date}</Table.Cell> */}
                      <Table.Cell>{event.total}</Table.Cell>
                      {/* <Table.Cell>{event.status}</Table.Cell> */}
                      <Table.Cell>
                        <a
                          className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                          href="#"
                        >
                          <p>View</p>
                        </a>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={5} className="text-center">
                      No recent orders. Create events and make memories
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>

          <div className="mt-4 sm:hidden block">
            <Table>
              <Table.Head>
                <Table.HeadCell>Order Number</Table.HeadCell>

                <Table.HeadCell>Total Bill</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {salesOnce.current==false?( <Table.Row>
                  <Table.Cell colSpan={5} className="text-center">
                  <div className="flex justify-center items-center h-full">
                  <Spinner aria-label="Loading" color="warning" size="md" />
                  <p className="text-darkgray">Loading Recent Sales .....</p>
                </div>
                  </Table.Cell>
                </Table.Row>):(sales && sales.length > 0 ? (
                  sales.map((event) => (
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {event.order_no}
                      </Table.Cell>

                      <Table.Cell>{event.total}</Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={2} className="text-center">
                      No recent orders. Create events and make memories
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
