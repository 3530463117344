import React, { useEffect } from "react";
import { Pagination } from 'flowbite-react';

function MainPagination({ currentPage, totalPages, onPageChange, itemsPerPage, totalItems, }){

  return (
    <div className="flex items-center justify-center text-center mt-12">
      <Pagination
      currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} showIcons
    />
    </div>
  );
}
export default MainPagination