import React, { useState } from "react";
import { Button, Checkbox, Label, Modal, TextInput ,Spinner} from "flowbite-react";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const EventOrganizerForm = ({ onrequest }) => {
  const { token, user, logout, requestlog } = useAuth();
  const [success, setSuccess] = useState(false);
  const navigate=useNavigate();
  const [formData, setFormData] = useState({
    companyname: "",
    companylocation: "",
    companyphonenumber: "",
    companyemail: "",
    Bank_branch: "",
    ghanacard: "",
    Bank_account_name: "",
    Bank_account_number: "",
    momo_number: "",
    youtube: "",
    instagram: "",
    facebook: "",
    twitter: "",
    website: "",
    linkedin: "",
  });

  const info = {
    companyname: formData.companyname,
    companylocation: formData.companylocation,
    companyphonenumber: formData.companyphonenumber,
    companyemail: formData.companyemail,
    Bank_branch: formData.Bank_branch,
    ghanacard: formData.ghanacard,
    Bank_account_name: formData.Bank_account_name,
    Bank_account_number: formData.Bank_account_number,
    momo_number: formData.momo_number,
    social_media: [
      {
        youtube: formData.youtube,
        instagram: formData.instagram,
        facebook: formData.facebook,
        twitter: formData.twitter,
        website: formData.website,
        linkedin: formData.linkedin,
      },
    ],
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const url =process.env.REACT_APP_API_URL;
  const handleverify = () => {
    console.log(info);
    setSuccess(true);
    const additionalPath = `${url}/upgrade_to_event_organizer`;
    // const path=`get_product_by_id?id=${id}`;
    // makeApiRequest("GET", null, path, token);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(additionalPath, info, { headers: headers })
      .then((response) => {
        console.log(response.data.responsemessage);
        if (response.data.responsemessage == "successful") {
          requestlog(true);
          onrequest();
        }
        // alert
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
          setSuccess(false);

          // Rethrow the error for further handling if needed
        }
      });
    // onrequest();
  };

  return (
    <div className="space-y-4 px-6 pb-2 relative">
      <div>
        <p className="font-bold text-midnightblue text-xl">
          Organization Information
        </p>
        <p className="text-darkgray text-xs">Your media links</p>
      </div>
      <div className="flex sm:flex-row flex-col gap-2">
        <div className="basis-1/2">
          <div className="mb-2 block">
            <Label
              htmlFor="name"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Organization Name
                  </p>
                  <p className="text-darkgray text-xs">
                    The name of the organization,fellowship or group
                  </p>
                </div>
              }
            />
          </div>
          <TextInput
            id="name"
            value={formData.companyname}
            onChange={handleInputChange}
            name="companyname"
            required
          />
        </div>

        <div className="basis-1/2">
          <div className="mb-2 block">
            <Label
              htmlFor="email"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Organization Email
                  </p>
                  <p className="text-darkgray text-xs">
                    The email of the organization,fellowship or group
                  </p>
                </div>
              }
            />
          </div>
          <TextInput
            id="email"
            value={formData.companyemail}
            onChange={handleInputChange}
            name="companyemail"
            required
          />
        </div>
      </div>

      <div className=" flex sm:flex-row flex-col gap-2">
        <div className="basis-1/2">
          <div className="mb-2 block">
            <Label
              htmlFor="location"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Organization Location
                  </p>
                  <p className="text-darkgray text-xs">
                    The official location of the organization,fellowship or
                    group
                  </p>
                </div>
              }
            />
          </div>
          <TextInput
            id="location"
            value={formData.companylocation}
            onChange={handleInputChange}
            name="companylocation"
            required
          />
        </div>
        <div className="basis-1/2">
          <div className="mb-2 block">
            <Label
              htmlFor="number"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Organization Phone Number
                  </p>
                  <p className="text-darkgray text-xs">
                    The official phone number of the organization,fellowship or
                    group
                  </p>
                </div>
              }
            />
          </div>
          <TextInput
            id="number"
            value={formData.companyphonenumber}
            onChange={handleInputChange}
            name="companyphonenumber"
            maxLength={10}
            required
          />
        </div>
      </div>
      <div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="ghanacard"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    National Identification Number
                  </p>
                  <p className="text-darkgray text-xs">
                    The Ghana Card number of the head of the organization
                  </p>
                </div>
              }
            />
          </div>
          <TextInput
            id="ghanacard"
            value={formData.ghanacard}
            onChange={handleInputChange}
            name="ghanacard"
            minLength={15}
            required
          />
        </div>
      </div>

      <div className="mt-2">
        <p className="font-bold text-midnightblue text-xl">Social Media</p>
        <p className="text-darkgray text-xs">Your media links</p>

        <div className="flex sm:flex-row flex-col gap-2">
          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="youtube"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">YouTube</p>
                    <p className="text-darkgray text-xs">
                      The youtube link of your company if any
                    </p>
                  </div>
                }
              />
            </div>
            <TextInput
              id="youtube"
              value={formData.youtube}
              onChange={handleInputChange}
              name="youtube"
              required
            />
          </div>

          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="website"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">Website</p>
                    <p className="text-darkgray text-xs">
                      The wesbite link of your company if any
                    </p>
                  </div>
                }
              />
            </div>
            <TextInput
              id="website"
              value={formData.website}
              onChange={handleInputChange}
              name="website"
              required
            />
          </div>
        </div>

        <div className="flex sm:flex-row flex-col gap-2">
          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="twitter"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">Twitter</p>
                    <p className="text-darkgray text-xs">
                      The twitter link of yoru company if any
                    </p>
                  </div>
                }
              />
            </div>
            <TextInput
              id="twitter"
              value={formData.twitter}
              onChange={handleInputChange}
              name="twitter"
              required
            />
          </div>
          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="linkedin"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">LinkedIn</p>
                    <p className="text-darkgray text-xs">
                      The LinkedIn link of yoru company if any
                    </p>
                  </div>
                }
              />
            </div>
            <TextInput
              id="linkedin"
              value={formData.linkedin}
              onChange={handleInputChange}
              name="linkedin"
              required
            />
          </div>
        </div>

        <div className="flex sm:flex-row flex-col gap-2">
          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="instagram"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">Instagram</p>
                    <p className="text-darkgray text-xs">
                      The Instagram link of your company if any
                    </p>
                  </div>
                }
              />
            </div>
            <TextInput
              id="instagram"
              value={formData.instagram}
              onChange={handleInputChange}
              name="instagram"
              required
            />
          </div>

          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="facebook"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">Facebook</p>
                    <p className="text-darkgray text-xs">
                      The facebook link of your company if any
                    </p>
                  </div>
                }
              />
            </div>
            <TextInput
              id="facebook"
              value={formData.facebook}
              onChange={handleInputChange}
              name="facebook"
              required
            />
          </div>
        </div>
      </div>

      <div className="mt-2">
        <p className="font-bold text-midnightblue text-xl">Financials</p>
        <p className="text-darkgray text-xs">
          Important bank information for transacations
        </p>
        <div className="flex sm:flex-row flex-col gap-2">
          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="bank"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">Bank Name</p>
                    <p className="text-darkgray text-xs">
                      The bank of the organization,fellowship or group
                    </p>
                  </div>
                }
              />
            </div>
            <TextInput
              id="bank"
              value={formData.Bank_account_name}
              onChange={handleInputChange}
              name="Bank_account_name"
              required
            />
          </div>

          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="account"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">
                      Bank Account Number
                    </p>
                    <p className="text-darkgray text-xs">
                      The account number of the organizatio or fellowship
                      associated with the bank
                    </p>
                  </div>
                }
              />
            </div>
            <TextInput
              id="account"
              value={formData.Bank_account_number}
              onChange={handleInputChange}
              name="Bank_account_number"
              maxLength={15}
              required
            />
          </div>
        </div>{" "}
      </div>

      <div className="flex sm:flex-row flex-col gap-2">
        <div className="basis-1/2">
          <div className="mb-2 block">
            <Label
              htmlFor="branch"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Bank Branch</p>
                  <p className="text-darkgray text-xs">
                    The branch of the organization,fellowship or group
                    associated with the bank
                  </p>
                </div>
              }
            />
          </div>
          <TextInput
            id="branch"
            value={formData.Bank_branch}
            onChange={handleInputChange}
            name="Bank_branch"
            required
          />
        </div>
        <div className="basis-1/2">
          <div className="mb-2 block">
            <Label
              htmlFor="momo"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Mobile Money Number
                  </p>
                  <p className="text-darkgray text-xs">
                    The mobile money number of the organization,fellowship or
                    group
                  </p>
                </div>
              }
            />
          </div>
          <TextInput
            id="momo"
            value={formData.momo_number}
            onChange={handleInputChange}
            maxlength={10}
            name="momo_number"
            required
          />
        </div>
      </div>

      <div className="flex w-full justify-end">
        <Button
          onClick={handleverify}
          className="bg-normalblue sm:w-1/4 w-1/2 p-2"
        >
          Send Request
        </Button>
      </div>


      {success && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-30 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md">
            {/* Your spinner component */}
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default EventOrganizerForm;
