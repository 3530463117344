import React, { useState } from "react";
import { Button,Label,TextInput } from 'flowbite-react';
import { useAuth } from "../../context/AuthContext";

function OrganizerProfileForm(){
    const {organizer}=useAuth();
    
    return(
        <div className=" mt-4 space-y-8">
        <div className="grid sm:grid-cols-2 grid-rows-1 gap-4">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="company" value="Company name" />
              </div>
              <TextInput id="company"  name="company" value={organizer.companyname} readOnly  />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="location" value="Location" />
              </div>
              <TextInput id="location"  name="lastname" value={organizer.companylocation}  readOnly />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="phone" value="Phone Number" />
              </div>
              <TextInput id="phone"  name="phonenumber" value={organizer.companyphonenumber} 
             readOnly />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email" value="Email address" />
              </div>
              <TextInput id="email"  name="email" value={organizer.companyemail} 
             readonly />
            </div>
            </div>

            
     
          </div>
    )
}
export default OrganizerProfileForm;