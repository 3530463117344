import React from "react";
import { Card, Rating } from "flowbite-react";

function FeedbackCard({ rate, feedback, user }) {
  // get the rating from the product and feedback and name of user
  // get_user_ratings?
  return (
    <div className="p-2 basis-1/4">
      <Card className=" h-4/5 border border-gray-300 p-4 m-4">
      <Rating>
        {Array(5)
          .fill(0)
          .map((_, starIndex) => (
            <Rating.Star
              key={starIndex}
              filled={starIndex < rate ? true : false}
            />
          ))}
      </Rating>
      <p className="font-medium">{user != null ? user : "Anonymous"}</p>
      <p className="font-normal text-midnightblue italic">{feedback}</p>
    </Card>
    </div>
  );
}

export default FeedbackCard;
