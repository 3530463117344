import React, { useState } from "react";
import NavigationBar from "../../components/Navigation/NavigationBar";
import TopBanner from "../../components/Navigation/TopBanner";
import FixedFooter from "../../components/Footer/FixedFooter";
import Newsletter from "../../components/Footer/Newsletter";
import SearchBarCollapse from "../../components/SearchBars/SearchBarCollapse";
import AboutCarousel from "../../components/Carousels/AboutCarousel";
import logo from "../../assets/images/KRIFFEONLINELOGO2.png";
import about from "../../assets/images/aboutg.png";
import ServiceCard from "../../components/Cards/ServiceCard";
import { useAuth } from "../../context/AuthContext";

function AboutUs() {
  const { isLogged } = useAuth();

  return (
    <div>
      <section className="w-screen h-screen flex flex-col">
        <TopBanner />
        <NavigationBar
          logo={logo}
          color="text-midnightblue"
          status={isLogged}
        />
        <SearchBarCollapse />
        <AboutCarousel />
      </section>
      <section className="w-screen px-3">
        <p className="text-normalblue text-2xl font-medium -mt-2">
          OUR <span className="text-normalbrown">MISSION</span>
        </p>
        <div className="flex lg:flex-row flex-col justify-between ">
          <div className="flex flex-col sm:basis-[45%] basis-1/2 ">
            <div className="flex flex-row justify-between">
              <div className=" text-base text-darkgray">
                <p className="font-medium text-lg text-midnightblue">
                  Our journey began with a simple yet profound realization: the
                  Ghanaian Christian community lacked a dedicated platform that
                  could meet its unique needs.{" "}
                </p>
                <p>
                  Inspired by this gap, we embarked on a mission to establish a
                  dedicated e-commerce marketplace that primarily caters to the
                  unique needs of the Christian community and the general public{" "}
                </p>
                <p className="mt-2 ">
                  We aim to provide a centralized platform that offers easy
                  access to faith-based resources, products, event ticketing,
                  and a supportive community. Our goal is to foster connections,
                  spiritual growth, and a sense of belonging among believers,
                  while also welcoming the general public.
                </p>
              </div>
            </div>
          </div>
          <div className="basis-1/2">
            <ul className="list-decimal ml-4 mt-2 sm:mt-0 sm:ml-0">
              <li>
                <p className="font-medium text-lg">Centralized Marketplace</p>
                <p className="text-darkgray text-base">
                  We aim to establish a centralized marketplace for Christian
                  products and event ticketing. By curating a diverse selection
                  of faith-inspired offerings, we empower believers to access
                  resources that align with their values and beliefs
                </p>
              </li>
              <li>
                <p className="font-medium text-lg">Unique Community Platform</p>
                <p className="text-darkgray text-base">
                  KRIFFEonline is designed to cater specifically to the unique
                  needs of the Ghanaian Christian community. We understand the
                  importance of faith in daily life and seek to foster
                  connections and spiritual growth among believers through our
                  platform.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w-screen px-3">
        <div className="flex lg:flex-row flex-col justify-between py-4  ">
          <div className="flex flex-col basis-1/2 mt-10 gap-2  h-full">
            <p className="text-normalblue text-2xl font-medium">
              OUR <span className="text-normalbrown">VISION</span>
            </p>
            <div className=" text-sm text-darkgray ">
              <p className=" text-sm text-darkgray">
                Our vision for KRIFFEonline is rooted in the belief that through
                innovation, community building, and continuous improvement, we
                strive to inspire and transform lives with the power of faith,
                while also serving as a valuable resource for the general public{" "}
              </p>
              <ul className="list-decimal text-midnightblue pl-4 mt-4 ">
                <li className="mb-2">
                  <p className="font-medium text-lg">Global Christian Hub</p>
                  <p className="text-darkgray text-sm leading-relaxed">
                    Our vision is to become a leading global online destination,
                    serving the Christian community by offering a secure and
                    enriching environment where people can explore, connect, and
                    engage with a wide range of Christian products, events, and
                    digital content.
                  </p>
                </li>
                <li className="mb-2">
                  <p className="font-medium text-lg">Trusted Spiritual Platform </p>
                  <p className="text-darkgray text-sm leading-relaxed">
                    We are dedicated to enhancing spiritual journeys by curating
                    reliable faith-based resources, aspiring to be the trusted
                    source for individuals seeking meaningful content.
                  </p>
                </li>
                <li className="mb-2">
                  <p className="font-medium text-lg">Empowering Christian creators</p>
                  <p className="text-darkgray text-sm leading-relaxed">
                    We aim to support and empower Christian event organizers and
                    content creators, enabling them to reach and impact their
                    target audience effectively
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="basis-1/2 flex items-center justify-center xl:items-start  lg:h-[28rem] lg:mt-10">
            <img src={about} className="h-full" />
          </div>
        </div>
      </section>
      <section className="w-screen  px-3 py-12">
        <div className="flex flex-col ">
          <p className="text-normalblue text-3xl font-medium text-center">
            OUR <span className="text-normalbrown">SERVICES</span>
          </p>
          <p className="text-darkgray text-sm text-center">
            By offering these services, KRIFFEonline caters to the core target
            group of the Christian community while also accommodating general
            products and events. The platform provides a secure and enriching
            environment where both Christian and non-Christian users can access
            a variety of products, find and attend events, discover relevant
            advertisements, and benefit from efficient event management tools.
          </p>
          <div className="grid sm:grid-cols-3 grid-rows-1 gap-y-8 mt-8">
            <ServiceCard
              name="E-commerce Marketplace"
              description="KRIFFEonline's E-Commerce Marketplace serves the Christian community with a diverse range of products. Users can easily explore and purchase Christian items such as books and music, along with general products, in a secure and user-friendly online environment."
            />
            <ServiceCard
              name="Event Ticketing"
              description="KRIFFEonline's Event Ticketing facilitates seamless ticket sales for Christian events, including conferences and concerts. It also extends its service to general events, providing a convenient platform for organizers and attendees to connect for various faith-based and non-religious gatherings."
            />
            <ServiceCard
              name="Ad Posting"
              description="KRIFFEonline's Ad-Posting service empowers businesses to advertise Christian-specific products and services to a targeted audience. It also enables the promotion of general offerings, allowing advertisers to connect effectively with both Christian and non-Christian users through tailored ads."
            />
            <ServiceCard
              name="Event Management"
              description="KRIFFEonline's Event Management service supports organizers in planning and promoting diverse events, with a focus on Christian gatherings. The platform's tools streamline registration, communication, and feedback collection for efficient organization, benefiting both Christian and general event organizers and attendees."
            />
            <ServiceCard
              name="Robust Data Generation"
              description="Gain in-depth insights into your event attendees, helping you tailor your events to resonate with your target audience."
            />
            <ServiceCard
              name="Targeted Advertising"
              description="Advertise directly to a community of individuals who share a keen interest in Christian-related activities, maximizing event attendance."
            />
          </div>
        </div>
      </section>
      <section className="w-screen">
        <Newsletter />
        <FixedFooter />
      </section>
    </div>
  );
}

export default AboutUs;
