import React from "react";
import ApexCharts from 'apexcharts';
import Donut from "../../components/Charts/Donut";
import LineChart from "../../components/Charts/LineChart";
import PieChart from "../../components/Charts/BarChart";
import StackedBarChart from "../../components/Charts/StackedBarChart";
import BarChart from "../../components/Charts/BarChart";

function Analytics(){
    
    return(<div className="p-3 space-y-4 h-screen">
        <p className="text-xl font-bold text-midnightblue">Analytics</p>
        <div className="flex xl:flex-row flex-col gap-4">
            <div className="bg-white rounded-md p-4 basis-1/2 flex flex-col">
                {/* trending categories */}
                <p className="font-bold text-center">Trending Categories</p>
                <Donut/>
     

            </div>
         
            <div className="bg-white rounded-md p-4 basis-1/2 flex flex-col">
                {/* % attendance to events */}
                <p className="font-bold text-center">Number of registered attendees who attended the events</p>
                <StackedBarChart/>

            
                

            </div>
        </div>

        <div className="flex xl:flex-row flex-col gap-4">
            <div className="bg-white rounded-md p-4 basis-1/2 flex flex-col">
               
                {/* daily registrations to events */}
                <p className="font-bold text-center">Monthly Registration to events </p>
                <LineChart/>
                
              

            </div>
            <div className="bg-white rounded-md p-4 basis-1/2 flex flex-col">
                 {/* average rating of events */}
                 <p className="font-bold text-center">Average Rating of events</p>
                <BarChart/>
               
                

            </div>

        </div>

    </div>)
}
export default Analytics;