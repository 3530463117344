import React,{useState,useEffect} from "react";
import { Navbar } from 'flowbite-react';
import { useApi } from "../../api/APIProvider";
import { useAuth } from "../../context/AuthContext";
import {useMarketplaceNavigation} from "../../context/MarketplaceNavigation";

function MarketplaceNav({setCategoryType,setSortBy}){
  const { makeApiRequest,data,error } = useApi();
  const [categorytypes,setTypes]=useState([]);
  const {token} = useAuth();
  const {type,faqlog}=useMarketplaceNavigation();
  


  

  useEffect(() => {
    if (!data) {
      fetchFaqData();
    } else if (data && data[0]) {
      faqlog(data);
    }
  }, [type, data, faqlog]);

  const fetchFaqData = async () => {
    try {

        const additionalPath = `get_product_category_type`;
        await makeApiRequest("GET", null, additionalPath);
      
    } catch (error) {
      console.error("Error fetching Type data:", error);
    }
  };




  

 

  



  
    return(
    <div>
         <Navbar fluid className="bg-transparent text-midnightblue" >
      <Navbar.Collapse>
        <Navbar.Link href="#" onClick={()=>setSortBy('trending')}>
          Trending
        </Navbar.Link>
        <Navbar.Link  href="#"  onClick={()=>setSortBy('all')}>
          All Products
        </Navbar.Link>

        {type&&type.map((type,index)=>(
           <Navbar.Link href="#" key={index} onClick={()=>setCategoryType(type.id)} >{type.name}</Navbar.Link>

        ))}

       
      
      </Navbar.Collapse>
    </Navbar>

    </div>)

}

export default MarketplaceNav;