import React,{useState,useEffect} from "react";
import { Label, Radio,Button } from 'flowbite-react';
import { useAuth } from "../../context/AuthContext";
import axios from 'axios';
import { useMarketplaceContext } from "../../context/MarketplaceContexts";
import { useNavigate } from "react-router-dom";

function AdSpecificationForm({toggleDiv}){
  const { isLogged,token,logout } = useAuth();
  const navigate=useNavigate();
  const {temporaryAdInfo,addTemporaryAdInfo,adtype,setAdtype } = useMarketplaceContext();
  const [product,setProduct] = useState([]);
  const selectedSpec = product&&product.find((spec) => spec.name === temporaryAdInfo.ad_type_id);
    const [specification, setSpecification] = useState(selectedSpec?(selectedSpec.name):("Standard"));
    const [selectedId, setSelectedId] = useState(selectedSpec?(selectedSpec.id):(1));
    const [price, setPrice] = useState(0);

    const unchecked="flex items-center justify-between border-2 border-lightgray rounded-md p-6";
    const checked="flex items-center justify-between border-2 border-green-600 rounded-md p-6";
    const textcheck="text-lg text-green-600";
    const textuncheck="text-lg text-darkgray";
    const [ err, setErr ] = useState('');

 

    useEffect(() => {
      // Make the first API call
      if(adtype.length==0){
        handlemarketplace();
      }
    }, [adtype]);

    // get_ad_types
    const url =process.env.REACT_APP_API_URL

  const handlemarketplace =() => {
    const additionalPath = `${url}/get_ad_types`;
    // const path=`get_product_by_id?id=${id}`;
    // makeApiRequest("GET", null, path, token);
    const headers = {
      'Content-Type': 'application/json',
      // 'Authorization':  `Bearer ${token}`,

    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  

    axios.get(additionalPath)
      .then(response => {
        setProduct(response.data);
        setAdtype(response.data);

      })
      .catch(err => {
                if (err.response && err.response.status === 401) {
                    // Perform actions when 401 (Unauthorized) error occurs
                    console.log('Unauthorized: Logging user out or performing specific action...');
                    logout();
                    navigate("/")
                    // Example: Log the user out or redirect to login page
                    // You may perform the necessary actions here based on your application flow
                  } else {
                    // Handle other errors
                    console.log(err);setErr(err)
                  
                     // Rethrow the error for further handling if needed
                  }
            });
  };

    const handleStatusChange=(e)=>{
        setSpecification(e.target.value);
        const selectedSpec = adtype.find((spec) => spec.name === e.target.value);
  
  // Store the selected spec's ID in the selectedId state
  if (selectedSpec) {
    setSelectedId(selectedSpec.id);
    setPrice(selectedSpec.price)
  }

    }

    const handleChanges=()=>{
        // add to the temp array
        console.log({ad_type:selectedId})
        addTemporaryAdInfo({ad_type_id:selectedId});
        addTemporaryAdInfo({ad_price:price});
        toggleDiv("review");
    }

    return(
        <div className="flex justify-center items-center  h-full">
      <form className="flex w-full flex-col gap-4 ">
      <fieldset
      className="flex w-full flex-col gap-4"
      id="radio"
    >
      <legend className="mb-4 font-bold">
        Confirm your Ad Choice
      </legend>
      {adtype.map((spec,index)=>(<p>
       
        <div className={specification==spec.name?(checked):(unchecked)}>
      <Label htmlFor="standard" className={specification==spec.name?(textcheck):(textuncheck)}>
      {spec.name} Ad
        </Label>
 
            {specification === spec.name? (
              <svg class="w-6 h-6 text-green-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
            ):(<Radio
                
                id="standard"
                name={spec.name}
                value={spec.name}
                checked={specification === spec.name}
                onChange={handleStatusChange}
      
              />)}
       
       
      </div>
        </p>))}
      
      
      
      
    </fieldset>
        
    <div className="flex justify-end ">
    <Button type="button" className=" bg-normalblue sm:p-4 p-2 rounded-md" onClick={handleChanges}>Save Changes & Next</Button>
    </div>

        
      </form>
    </div>

    )
}
export default AdSpecificationForm;