import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";

function FAQNewModal({ open, handleModal }) {
  const { token, adminlogout } = useAuth();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/add_faq`;
  const [formData, setFormData] = useState({
    category_id: null,
    answer:"",
    question: ""
  });

  const [category, setCategory] = useState([]);
  const loadedOnce = useRef(false);

  useEffect(() => {
    if (loadedOnce.current) return;
    loadedOnce.current = true;
    closeAds();
  }, []);

  const additionalPaths = `${url}/faq_categories`;
  const closeAds = () => {
    // update the ad

    // api to close and then navigaet that id
    if (!token) {
      // If token is not present in the context, try to get it from local storage
      const storedToken = localStorage.getItem("token"); // Replace 'yourTokenKey' with the actual key you used for storing the token
      if (storedToken) {
        handlecategory(JSON.parse(storedToken));
      }
    } else {
      handlecategory(token);
    }
  };

  const handlecategory = async (token) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    await axios
      .get(additionalPaths, { headers: headers })
      .then((response) => {
        console.log(response.data);
        setCategory(response.data.faq_categories);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs

            adminlogout();
            navigate("/admin")
          console.error(err);
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  const closeAd = () => {
    // update the ad

    // api to close and then navigaet that id
    if (!token) {
      // If token is not present in the context, try to get it from local storage
      const storedToken = localStorage.getItem("token"); // Replace 'yourTokenKey' with the actual key you used for storing the token
      if (storedToken) {
        handleedit(JSON.parse(storedToken));
      }
    } else {
      handleedit(token);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleedit = async (tokens) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokens}`,
    };


    const bodyinfo = {
      category_id: formData.category_id,
      answer: formData.answer,
      question: formData.question,
    };

    console.log(bodyinfo);

    await axios
      .post(additionalPath, bodyinfo, {headers: headers })
      .then((response) => {
        
          console.log("Added");

            handleModal();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs

            adminlogout();
            navigate("/admin")
          console.error(err);
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });


  };
  return (
    <div>
      <Modal show={open} size="md" popup onClose={handleModal}>
        <Modal.Header>Add FAQ</Modal.Header>
        <Modal.Body>
          <div >


           
              <div className="mt-4 flex flex-col gap-2">
                <label htmlFor="question" className="font-bold">Question</label>
                <input
                  type="text"
                  name="question"
                  placeholder="Add question"
                  value={formData.question}
                  onChange={handleInputChange}
                  class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                />

                <label htmlFor="question" className="font-bold">Answer</label>
                <input
                  type="text"
                  placeholder="Add answer"
                  name="answer"
                  value={formData.answer}
                  onChange={handleInputChange}
                  class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                />
                <label htmlFor="question" className="font-bold">Category</label>
                <select
                  id="category_id"
                  name="category_id"
                  onChange={handleInputChange}
                  value={formData.category_id}
                  className="rounded-md p-2 border border-normalblue"
                >
                  <option value="">Select</option>
                  {category &&
                    category.map((detail, index) => (
                      <option key={index+1} value={index+1}>
                        {detail.category_name}
                      </option>
                    ))}
                </select>

                <div className="justify-end items-end flex">
                  <Button
                    className="bg-normalblue p-2 text-white  mt-4 w-1/2"
                    onClick={() => closeAd()}
                  >
                    Add FAQ
                  </Button>
                </div>
              </div>
     
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default FAQNewModal;
