import React,{useEffect} from "react";
import { Button } from "flowbite-react";
import { useNavigate } from 'react-router-dom';


function AdsCard({item,status}){
   
    
    
    return(
        <div className="flex flex-row border border-lightgray rounded-md text-midnightblue justify-between  items-center mb-4 ">
            <div className="flex flex-row" >
                <img className="bg-midnightblue sm:w-24 w-16 rounded-l-md rounded-bl-md"/>
                <div className="flex flex-col p-2">
                <p className="font-medium">{item.name}</p>
                <p className="text-darkgray text-xs">{item.category}</p>
                <p className="text-sm">GHC {item.price}</p>
            </div>
            </div>
            

            <div className="p-2">
                <a href={`/adspage/${item.product_no}`}><Button className="bg-normalbrown rounded-md">View</Button></a>
            </div>
        </div>
)
}

export default AdsCard;