import React, { useEffect, useState } from "react";
import { Button, Label, TextInput, FileInput } from "flowbite-react";
import { useMarketplaceContext } from "../../../context/MarketplaceContexts";
import { useAuth } from "../../../context/AuthContext";
import TagForm from "./TagForm";
import ProductForm from "./ProductForm";
import { fetchDataForFlexibility, fetchDataForAge } from "../../../api/ApiPage";
import axios from "axios";
import { useNavigate } from "react-router-dom";


function ProductDetailsForm({ toggleDiv }) {
  const { token, logout } = useAuth();
  const navigate=useNavigate();
  const {
    temporaryAdInfo,
    addTemporaryAdInfo,
    addTemporaryPayload,
    agegroup,
    setAge,
    flexibility,
    setFlexibility,
  } = useMarketplaceContext();
  const [tags, setTags] = useState(
    temporaryAdInfo.tags.length > 0 ? temporaryAdInfo.tags : []
  );
  const [selectedProductDetails, setSelectedProductDetails] = useState(
    temporaryAdInfo.product_details.length > 0
      ? temporaryAdInfo.product_details
      : []
  );

  const info = {
    product_details: selectedProductDetails,
    tags: tags,
  };

  const category = {
    categoryid: temporaryAdInfo.category,
  };

  // get tags from api
  useEffect(() => {
    if (tags.length == 0) {
      handlemarketplace();
    }
  }, [tags]);

  const url = process.env.REACT_APP_API_URL;

  const handlemarketplace = () => {
    const additionalPath = `${url}/get_tags`;

    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .get(additionalPath, { params: category, headers: headers })
      .then((response) => {
        setTags(response.data.tags);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };
  const [isFormValid, setIsFormValid] = useState(true);
  useEffect(() => {
    // Check form validity whenever tags or selectedProductDetails change
    const requiredFields = ["product_details", "tags"];

    // Additional required fields for product details
    const productDetailFields = ["product_flexibility", "age_group"];

    // Check if each product detail field is present in selectedProductDetails
    const areProductDetailsValid = productDetailFields.every((field) =>
      selectedProductDetails.some(
        (detail) => detail.attribute_name === field && detail.attribute_value
      )
    );

    // Check if any of the required fields are empty
    const isAnyFieldEmpty = requiredFields.some(
      (field) => !info[field] || info[field].length === 0
    );

    // Update the state variable to reflect the button's disabled status
    setIsFormValid(!isAnyFieldEmpty && areProductDetailsValid);
  }, [tags, selectedProductDetails]);

  const formatPayload = (formData) => {
    const formattedPayload = { ...formData };

    if (formattedPayload.categoryid.length == 0) {
      formattedPayload.categoryid = JSON.stringify(formattedPayload.category);
    } else {
      formattedPayload.categoryid = [];
    }
  };

  const handleChanges = () => {
    // set the info to the temp array
    console.log(info);
    addTemporaryAdInfo(info);
    toggleDiv("seller");
  };

  useEffect(() => {
    if (agegroup.length == 0) {
      fetchDataForAge(logout).then((data) => {
        if (data) {
          setAge(data);
        }
      });
    }
    if (flexibility.length == 0) {
      fetchDataForFlexibility(logout).then((data) => {
        if (data) {
          setFlexibility(data);
        }
      });
    }
  }, []);

  const handleProductAgeChange = (e) => {
    const { value } = e.target;
    setSelectedProductDetails((prevDetails) => [
      ...prevDetails.filter((detail) => detail.attribute_name !== "age_group"),
      {
        attribute_name: "age_group",
        attribute_value: parseInt(value),
      },
    ]);
  };

  const handleProductFlexibilityChange = (e) => {
    const { value } = e.target;
    setSelectedProductDetails((prevDetails) => [
      ...prevDetails.filter(
        (detail) => detail.attribute_name !== "product_flexibility"
      ),
      {
        attribute_name: "product_flexibility",
        attribute_value: parseInt(value),
      },
    ]);
  };


  // get details based on category
  const [details,setDetails]=useState([]);
  useEffect(() => {
    if (details.length == 0) {
      getCategoryProducts();
    }
  }, [details]);

  const getCategoryProducts = async () => {
    const additionalPath = `${url}/get_product_details_by_category`;

    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const statusinfo={
      category_id:temporaryAdInfo.category
    }

    axios
      .get(additionalPath, { params: statusinfo, headers: headers })
      .then((response) => {
        console.log(response.data)
        setDetails(response.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          
          logout();
          navigate("/")
          
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });

  }

  return (
    <div className="flex flex-col gap-2 h-screen">
      <div>
        <div className="mb-2 block">
          <Label
            htmlFor="location"
            value={
              <div>
                <p className="font-bold text-midnightblue">Product Tags</p>
                <p className="text-darkgray">
                  List related tags or key words for the product. Press Enter to
                  add a tag
                </p>
              </div>
            }
          />
        </div>
        <TagForm tags={tags} setTags={setTags} />
      </div>

      <div>
        <div className="flex flex-row justify-between">
          <div className="mb-2 block">
            <Label
              htmlFor="flexibility"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Product Flexibility
                  </p>
                  <p className="text-darkgray">
                    Choose the appropriate flexibility for this product
                  </p>
                </div>
              }
            />
          </div>
        </div>
        <select
          id="flexibility"
          value={
            selectedProductDetails.find(
              (detail) => detail.attribute_name === "product_flexibility"
            )?.attribute_value || ""
          }
          onChange={handleProductFlexibilityChange}
          className="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
        >
          <option value="">Select</option>
          {flexibility.map((detail) => (
            <option key={detail.id} value={detail.id}>
              {detail.flexibility_label}
            </option>
          ))}
        </select>
      </div>

      <div>
        <div className="flex flex-row justify-between">
          <div className="mb-2 block">
            <Label
              htmlFor="age_group"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Product Age Group
                  </p>
                  <p className="text-darkgray">
                    Choose the appropriate age group for this product
                  </p>
                </div>
              }
            />
          </div>
        </div>
        <select
          id="age_group"
          value={
            selectedProductDetails.find(
              (detail) => detail.attribute_name === "age_group"
            )?.attribute_value || ""
          }
          onChange={handleProductAgeChange}
          className="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
        >
          <option value="">Select</option>
          {agegroup.map((detail) => (
            <option key={detail.id} value={detail.id}>
              {detail.age_group_label}
            </option>
          ))}
        </select>
      </div>

      <ProductForm
        setSelectedProductDetails={setSelectedProductDetails}
        selectedProductDetails={selectedProductDetails}
        details={details}
      />

      <div className="flex justify-end">
        <Button
          type="button"
          className={`bg-normalblue sm:p-4 p-2 rounded-md ${
            !isFormValid ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={handleChanges}
          disabled={!isFormValid}
        >
          Save Changes & Next
        </Button>
      </div>
    </div>
  );
}

export default ProductDetailsForm;
