import { Button, Spinner } from "flowbite-react";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import logo from "../../../assets/images/KRIFFEONLINELOGO2.png";
import EventReceipt from "../../../components/Miscellanous/EventReceipt";
import axios from "axios";
import { useAuth } from "../../../context/AuthContext";
import TopBanner from "../../../components/Navigation/TopBanner";
import NavigationBar from "../../../components/Navigation/NavigationBar";
import SearchBarCollapse from "../../../components/SearchBars/SearchBarCollapse";

function VerifyQRCode() {
  const { isLogged, token,logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const checked = queryParams.get("check");
  const loadedOnce = useRef(false);
  const [loading, setLoading] = useState(false);
  const [updates, setUpdate] = useState(false);
  const url = process.env.REACT_APP_API_URL;
  const [codes, setCode] = useState("0");
  const check = checked.split("?")[0];

  const updatedOnce = useRef(false);

  const additionalPaths = `${url}/verify_payment`;
  const configs = {
    order_id: code,
  };

  const headers = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  // verify
  const push = async () => {
    await axios
      .post(additionalPaths, configs, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.data.status === '1') {
          setCode(response.data.data.status);
          setUpdate(true);
        } else {
          alert("transaction not found. Reload again")
          setLoading(false)
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          console.error(err);
        } else {
          console.error(err);
        }
      });
  };



  const additionalPats = `${url}/update_payment_status`;
  const config = {
    id: code,
  };

  const handleupdate = async () => {
    await axios
      .patch(additionalPats, config, {
        headers: headers,
      })
      .then((response) => {
        loadedOnce.current = true;
        updatedOnce.current = true;
        setLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          console.error(err);
        } else {
          console.error(err);
        }
      });
  };

  useEffect(() => {
    if (check) {
      const verifyTransaction = () => {
        if (codes !== '1') {
          push();
        }
      };

      if (loadedOnce.current) return;
      loadedOnce.current = true;
      verifyTransaction();

    }

    if (updates===true) {
      handleupdate();
    }
  }, [loadedOnce, push, check, updates,handleupdate]);

  useEffect(() => {
    if(updates){
      if (updatedOnce.current) return;
      updatedOnce.current = true;
      handleupdate();

    }
   
   
  }, [updatedOnce,updates]);



  return (
    <section className="h-screen">
      <TopBanner />
      <NavigationBar logo={logo} color="text-midnightblue" status={isLogged} />
      <SearchBarCollapse />

      {check ? (
        !loadedOnce.current ? (
          <div className="flex justify-center items-center h-3/4 w-full">
            <div className="h-full flex flex-col justify-center items-center p-3 w-full">
              <Spinner aria-label="Loading" color="warning" size="xl" />
            </div>
          </div>
        ) : (
          <div className="w-full px-6 mb-4">
            <EventReceipt id={code} />
            {/* <p>Testing mic</p> */}
          </div>
        )
      ) : (
        <div className="w-full px-6 mb-4">
          <EventReceipt id={code} />
        </div>
      )}

      {/* {loadedOnce.current == false ? (
        <div className="flex justify-center items-center h-3/4 w-full">
          <div className="h-full flex flex-col justify-center items-center p-3 w-full">
            <Spinner aria-label="Loading" color="warning" size="xl" />
          </div>
        </div>
      ) : details && details.length != 0 ? (
        <div className="flex justify-center items-center h-3/4 space-y-10 w-full ">
          <div className="h-full w-1/2 rounded-md flex flex-col gap-2 justify-center items-center p-3 bg-green-200 text-green-700 w-full xl:w-1/2">
            <p className="font-bold text-2xl">{details.name}</p>
            <div>
              <svg
                class="w-32 h-32 text-green-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="m7 10 2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </div>

            <p className="font-medium text-xl">
              Ticket <strong>#{code} </strong> Valid
            </p>

            <p className="font-medium">{details.fullname}</p>
            <hr className="border-1 border-t border-dashed border-green-900 w-full"></hr>

            <p className="font-bold text-left">Ticket Details</p>

            {details.ticketinfo &&
              details.ticketinfo.map((ticket, index) => (
                <div className="flex flex-row justify-between gap-4">
                  <p className="font-medium">Ticket Bought:{ticket.name}</p>
                  <p className="font-medium">
                    Quantity:{ticket.Quantity_purchased}
                  </p>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className=" flex justify-center items-center h-3/4 w-full ">
          <div className=" h-full flex flex-col justify-center items-center bg-red-200/40 rounded-md p-3 w-full xl:w-1/2">
            <svg
              class="w-32 h-32 text-red-700"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="m13 7-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            <p className="font-bold text-xl text-red-700">
              Ticket <strong>#{code} </strong>does not exist
            </p>
          </div>
        </div>
      )} */}

      {/* <div className="flex flex-col justify-center items-center  h-3/4">
                     <Spinner aria-label="Default status example" size="xl" color="warning"/>
                     <p className="font-bold text-xl text-normalblue">Ticket #{code} already checked</p>
              

                </div> */}
    </section>
  );
}
export default VerifyQRCode;
