import React,{useState,useEffect} from "react";
import OrganizerSidebar from "../../components/Navigation/OrganizerSidebar";
import { useParams,useNavigate } from "react-router-dom";
import OrganizerNav from "../../components/Navigation/OrganizerNav";
import Events from "./Events";
import Finances from "./Finances";
import Analytics from "./Analytics";
import Account from "./Account";
import Dashboard from "./Dashboard";
import EventPage from "./SubPages/EventPage";
import OrderReceipt from "./SubPages/OrderReceipt";
import NewEvent from "./SubPages/NewEvent";
import { useAuth } from "../../context/AuthContext";
import Withdrawals from "./SubPages/Withdrawals";

function Main(){
  const {organizer}=useAuth();
    const { section } = useParams();
  const [visibleDiv, setVisibleDiv] = useState(section);

  const toggleDiv = (divValue) => {
    setVisibleDiv(divValue);
  };
  const divContentMap = {
    dashboard: <Dashboard/>,
    events: <Events/>,
    finances: <Finances/>,
    analytics:<Analytics/>,
    account: <Account/>,
    event:<EventPage/>,
    receipt:<OrderReceipt/>,
    new:<NewEvent toggleDivs={toggleDiv}/>,
    withdraw:<Withdrawals/>
  };
  const content = divContentMap[visibleDiv] || "";
 
  
  const navigate = useNavigate();

  console.log(organizer);
  useEffect(()=>{
    if(organizer === null){
      setTimeout(() => {
        toggleDiv("dashboard"); // Reload the page
      }, 2000); 

    }
  },[organizer])


  // lets put it here

    return(
    <div className="w-screen flex">

       

        <OrganizerSidebar toggleDiv={toggleDiv} visibleDiv={visibleDiv}/>
        <div className="flex-1 sm:ml-[13rem] h-screen overflow-y-auto  bg-[#F5F5F5]">
            <OrganizerNav />
            {content} 
        </div>

    </div>)
}

export default Main;