// import React, { useEffect,useState} from "react";
// import { Card,Rating } from 'flowbite-react';
// import FeedbackCard from "../Cards/FeedbackCard";
// import { useAuth } from "../../context/AuthContext";
// import axios from 'axios';

// function FeedbackPage({userid}){

//     const {token } = useAuth();
//     const [feedback,setFeedback]=useState([]);
//     const [loadedOnce,setLoadedOnce]=useState(false);
//     const url =process.env.REACT_APP_API_URL
//     const info={
//         user_id:userid
//     }
//     console.log(info);

//     useEffect(()=>{
//         if(!loadedOnce){
//             const additionalPath = `${url}/get_user_ratings`;
//             const headers = {
//               'Content-Type': 'application/json',

//             };

//             axios.get(additionalPath,{params:info,headers:headers })
//               .then(response => {
//                 console.log(response.data.ratings);
//                 setFeedback(response.data.ratings)
//                 setLoadedOnce(true);
//                 // alert

//               })
//               .catch(err => {
//                 console.log(err);
//               });

//             }

//     },[loadedOnce]);

//     return(
//         <div className="flex sm:flex-row flex-col gap-2 overflow-x-auto">
//             {feedback&&feedback.length>0?( feedback.map((feed,index)=>(<FeedbackCard key={index} rate={feed.rating} feedback={feed.feedback_text} user={feed.feedback_from}/>))):(<p>Rate this user and give them feedback</p>)}

//         </div>
//     )
// }

// export default FeedbackPage;

// get_user_ratings?user_id=2
import React, { useEffect, useState } from "react";
import { Card, Rating } from "flowbite-react";
import FeedbackCard from "../Cards/FeedbackCard";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";

function FeedbackPage({ userid }) {
  const { token } = useAuth();
  const [feedback, setFeedback] = useState([]);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [visibleFeedbackCount, setVisibleFeedbackCount] = useState(3); // Number of feedback to initially show
  const [isMdScreen, setIsMdScreen] = useState(window.innerWidth >= 768);
  const url =process.env.REACT_APP_API_URL;
  const info = {
    user_id: userid,
  };

  useEffect(() => {
    if (!loadedOnce) {
      const additionalPath = `${url}/get_user_ratings`;
      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .get(additionalPath, { params: info, headers: headers })
        .then((response) => {
          setFeedback(response.data.ratings);
          setLoadedOnce(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [loadedOnce]);

  const handleLoadMore = () => {
    setVisibleFeedbackCount((prevCount) => prevCount + 3); // Increase the visible feedback count
  };

  const handleUnload = () => {
    setLoadedOnce(false);
    setVisibleFeedbackCount(3); // Reset visible feedback count
  };

  const allFeedbackShown =
    loadedOnce && feedback.length <= visibleFeedbackCount;
  return (
    <div>
      <div className={`flex sm:flex-row flex-col gap-2 overflow-x-auto md:hidden`}>
      {feedback && feedback.length > 0 ? (
        feedback
          .slice(0, visibleFeedbackCount)
          .map((feed, index) => (
            <FeedbackCard
              key={index}
              rate={feed.rating}
              feedback={feed.feedback_text}
              user={feed.feedback_from}
            />
          ))
      ) : (
        <p>Rate this user and give them feedback</p>
      )}

      {feedback && feedback.length > visibleFeedbackCount && (
        <button onClick={handleLoadMore} className="rounded-full bg-lightblue/40 text-normalblue font-medium p-2 w-1/2 mx-auto">Load More</button>
      )}

      {feedback && allFeedbackShown && <button onClick={handleUnload} className="rounded-full bg-lightbrown/40 text-normalbrown font-medium p-2 w-1/2 mx-auto">Unload</button>}
    </div>


    <div className={`md:flex flex-row  gap-2 overflow-x-auto hidden `}>
      {feedback && feedback.length > 0 ? (
        feedback
          .map((feed, index) => (
            <FeedbackCard
              key={index}
              rate={feed.rating}
              feedback={feed.feedback_text}
              user={feed.feedback_from}
            />
          ))
      ) : (
        <p>Rate this user and give them feedback</p>
      )}

      
    </div>

    </div>
    
  );
}

export default FeedbackPage;
