import React from "react";
import { Button, Modal } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";

function MessageEditModal({ id, open, handleModal }) {
  const { token, adminlogout } = useAuth();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/delete_form`;

  const closeAd = () => {
    // update the ad

    // api to close and then navigaet that id
    if (!token) {
      // If token is not present in the context, try to get it from local storage
      const storedToken = localStorage.getItem("token"); // Replace 'yourTokenKey' with the actual key you used for storing the token
      if (storedToken) {
        handleedit(JSON.parse(storedToken));
        
      }
    }else{
      handleedit(token);
    }
  };

 



  const handleedit=async(tokens)=>{
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${tokens}`,
    };
    
    const statusinfo = {
      contact_id: id,
    };
   

    await axios
      .delete(additionalPath,{ params: statusinfo, headers: headers })
      .then((response) => {
        if (response.data.responsemessage) {
          console.log("Updated");
        } else {
          console.log("Egbamiii");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
        
            adminlogout();
            navigate("/admin")
          console.error(err);
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });

    handleModal();
  }
  return (
    <div>
      <Modal show={open} size="md" popup onClose={handleModal}>
        <Modal.Header>Delete Event Status</Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="flex justify-center">
              <svg
                class="w-12 h-12 text-darkgray  "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
              </svg>
            </div>

        
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Have you carefully reviewed the information? Are
                you sure you want to delete this message now ?
              </h3>
            
            
            <div className="flex justify-center gap-4">
  
                <div className="flex gap-2">
                  <Button
                    className="border border-normalblue text-normalblue basis-1/2"
                    onClick={() => handleModal()}
                  >
                    No,Cancel
                  </Button>
                  <Button
                    className="bg-[#AD3C3C] text-white basis-1/2"
                    onClick={() => closeAd()}
                  >
                    Yes,Delete
                  </Button>
                </div>
              

             
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MessageEditModal;
