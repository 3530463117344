import React,{useEffect, useState} from "react";
import { Button, Label, TextInput } from "flowbite-react";
import { useEventContexts } from "../../../context/EventContexts";
import { useAuth } from "../../../context/AuthContext";


function EventContactForm({toggleDiv}) {
    const { addTemporaryTicketInfo,temporaryTicketInfo } = useEventContexts();
    const {isLogged,user}=useAuth();
    const [formData, setFormData] = useState({
    firstname: isLogged &&user.fullname
    ? (user.fullname.split(" ")[1])
    : (temporaryTicketInfo.firstname !== ""
    ? temporaryTicketInfo.firstname
    : ""),
    lastname:isLogged &&user.fullname
      ? (user.fullname.split(" ")[1])
      : (temporaryTicketInfo.lastname !== ""
      ? temporaryTicketInfo.lastname
      : ""),
    email: isLogged?(user.email):(temporaryTicketInfo.email !== ""
    ? temporaryTicketInfo.email
    : ""),
   phonenumber: isLogged?(user.phonenumber):(temporaryTicketInfo.phonenumber !== ""
   ? temporaryTicketInfo.phonenumber
   : ""),
  });

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    // Check form validity whenever formData changes
    const isValid = Object.values(formData).every((value) => value.trim() !== "");
    setIsFormValid(isValid);
  }, [formData]);

 

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const info={
    firstname:formData.firstname,
    lastname:formData.lastname,
    email:formData.email,
    phonenumber:formData.phonenumber,
  }

  const handleSubmit = () => {
    if(isLogged){
      toggleDiv("payment");

    }else{
      addTemporaryTicketInfo(info);
    toggleDiv("payment");

    }
    
  };
  return (
    <div className="flex justify-center items-center  h-full">
      <form className="flex w-full flex-col gap-4 " >
        <div>
        <div className="mb-2 block">
          <Label htmlFor="fname" value={<div><p className="font-bold text-midnightblue">First Name</p></div>} />
        </div>
          
          <TextInput
            id="fname"
            name="firstname"
            required
            type="text"
            value={formData.firstname}
          onChange={handleInputChange}
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-12 h-8"
          />
        </div>
        <div>
        <div className="mb-2 block">
          <Label htmlFor="lname" value={<div><p className="font-bold text-midnightblue">Last Name</p></div>} />
        </div>
          
          <TextInput
            id="lname"
            required
            type="text"
            name="lastname"
            value={formData.lastname}
          onChange={handleInputChange}
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-12 h-8"
          />
        </div>
        <div>
        <div className="mb-2 block">
          <Label htmlFor="email" value={<div><p className="font-bold text-midnightblue">Email</p></div>} />
        </div>
          
          <TextInput
            id="email"
            required
            type="email"
            name="email"
            value={formData.email}
          onChange={handleInputChange}
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-12 h-8"
          />
        </div>
        <div>
        <div className="mb-2 block">
          <Label htmlFor="number" value={<div><p className="font-bold text-midnightblue">Phone Number</p></div>} />
        </div>
          
          <TextInput
            id="number"
            required
            type="text"
            name="phonenumber"
            value={formData.phonenumber}
          onChange={handleInputChange}
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-12 h-8"
          />
        </div>

        <div className="flex justify-end">
       
        <Button
            onClick={handleSubmit}
            className={`bg-normalblue sm:p-4 p-2 rounded-md sm:w-1/2 w-3/4 ${
              !isFormValid ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={!isFormValid}
      
          >
            Continue to Review
          </Button>
        </div>
      
      
      </form>
    </div>
  );
}

export default EventContactForm;
