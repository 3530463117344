import { Button } from "flowbite-react";
import React from "react";
import green from "../../assets/images/gree.png";
import red from "../../assets/images/redguy.png";

function AboutCarousel() {
  return (
    
    

    <div className="relative  flex flex-col grow-0 bg-lightblue h-auto md:h-3/4  sm:w-[97.5%] w-[92%] ml-4 rounded-lg mt-2 sm:mt-0 items-center pt-8 mb-8 ">
     <div className="flex flex-col items-center ">
     <p className="text-white font-bold sm:text-4xl text-3xl text-center">
        We are changing the <br></br>whole scene
      </p>
      <p className="text-white">One click at a time</p>
      <Button pill className="bg-normalblue">
        Get Started
      </Button>

     </div>
    

      <div className="sm:flex sm:flex-row h-full justify-center sm:justify-between xl:items-center items-end w-full sm:absolute sm:top-0  ">
        <img
          src={red}
          className="xl:h-full object-cover sm:object-contain  w-full  md:h-3/4"
          alt="Red Image"
        />
        <img src={green} className=" h-full object-cover  w-[45%]  xl:block hidden" alt="Green Image" />
      </div>
    </div>
  );
}
export default AboutCarousel;
