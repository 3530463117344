import React from "react";

function FormStepper({ toggleDiv, visibleDiv }) {

    const circlstyle_active="flex items-center justify-center w-10 h-10 bg-white text-lightblue border-2 border-lightblue rounded-full lg:h-12 lg:w-12  text-xl font-extrabold shrink-0";
    const circlstyle="flex items-center justify-center w-10 h-10 bg-lightblue rounded-full lg:h-12 lg:w-12  shrink-0 text-white text-xl font-extrabold";
    const circlstyle_inactive="flex items-center relative justify-center w-10 h-10 bg-lightgray rounded-full lg:h-12 lg:w-12 text-white text-xl font-extrabold shrink-0";
    const borders="flex w-full items-center relative after:content-[''] sm:after:w-[16rem] after:w-full after:h-1 after:border-b after:border-lightgray after:border-4 after:inline-block";
    const border_active="flex w-full items-center relative after:content-[''] sm:after:w-[16rem] after:w-full after:h-1 after:border-b after:border-lightblue after:border-4 after:inline-block ";
    const text_active="absolute top-0 -ml-10  text-center mt-14 w-42  text-xs font-bold uppercase text-normalblue sm:block hidden";
    const text="absolute top-0 -ml-10  text-center mt-14 w-42  text-xs font-bold uppercase text-lightgray sm:block hidden";
  return (
    <div className="w-full sm:px-6  sm:mt-0 mt-5 mb-12  ">
      <ol class="flex items-center w-full mb-4 sm:mb-5 sm:pl-10 pl-5">
        <li class="flex  w-full items-center relative text-normalblue  after:content-[''] sm:after:w-[16rem] after:w-full after:h-1 after:border-b after:border-lightblue after:border-4 after:inline-block ">
          <div class="flex items-center justify-center w-10 h-10 bg-lightblue rounded-full lg:h-12 lg:w-12  shrink-0 text-white text-xl font-extrabold">
            1
          </div>
          <div class="absolute top-0 -ml-10  text-center mt-14 w-32  text-xs font-bold uppercase text-normalblue sm:block hidden">Select Event</div>
        </li>
        <li class={visibleDiv=="contact"||visibleDiv=="payment"||visibleDiv=="receipt"?(border_active):(borders)}>
          <a href="#" onClick={() => toggleDiv("ticket")} >
            <div class={visibleDiv=="ticket"?(circlstyle_active):(circlstyle)}>
              2
            </div>
            <div class="absolute top-0 -ml-10  text-center mt-14 w-32  text-xs font-bold uppercase text-normalblue sm:block hidden">Ticket Type</div>
          </a>
        </li>
        <li class={visibleDiv=="payment"||visibleDiv=="receipt"?(border_active):(borders)}>
          <a href="#" onClick={() => {
              if (visibleDiv === "contact" || visibleDiv === "payment" || visibleDiv === "receipt") {
                // Allow going back to the previous step if currently on "contact", "payment", or "receipt"
                toggleDiv("contact");
              }
            }} disabled={visibleDiv === "ticket"}>
            <div class={visibleDiv=="contact"?(circlstyle_active):(visibleDiv=="ticket"?(circlstyle_inactive):(circlstyle))}>
              3
            </div>
            <div class={visibleDiv=="contact"||visibleDiv=="payment"||visibleDiv=="receipt"?(text_active):(text)}>Contact Information</div>
          </a>
        </li>
        <li class={visibleDiv=="receipt"?(border_active):(borders)}>
          <a href="#" onClick={() => {
              if (visibleDiv === "payment" || visibleDiv === "receipt") {
                // Allow going back to the previous step if currently on "contact", "payment", or "receipt"
                toggleDiv("payment");
              }
            }} disabled={visibleDiv === "contact"||visibleDiv==="receipt"}>
            <div class={visibleDiv=="payment"?(circlstyle_active):(visibleDiv=="receipt"?(circlstyle):(circlstyle_inactive))}>
              4
            </div>
            <div class={visibleDiv=="payment"||visibleDiv=="receipt"?(text_active):(text)}>Review and Payment</div>
          </a>
        </li>
        <li class="flex w-full items-center relative after:w-[0rem] after:content-['']">
          <a href="#" onClick={() => {
              if (visibleDiv === "receipt") {
                // Allow going back to the previous step if currently on "contact", "payment", or "receipt"
                toggleDiv("receipt");
              }
            }} disabled={visibleDiv === "payment"}>
            <div class={visibleDiv=="receipt"?(circlstyle_active):(circlstyle_inactive)}>
              5
            </div>
            <div class={visibleDiv=="receipt"?(text_active):(text)}>Confirmation Receipt</div>
          </a>
        </li>
      </ol>
    </div>
  );
}

export default FormStepper;
