import React, { useRef,useEffect,useState } from 'react';
import Chart from 'react-apexcharts';
import { useAuth } from "../../context/AuthContext";
import axios from 'axios';
import { useEventOrganizerContext } from '../../context/EventOrganizerContext';
import { fetchDataForEventCategory } from "../../api/ApiPage";
import { useNavigate } from "react-router-dom";

const Donut = () => {
  const {token,logout}=useAuth();
  const navigate=useNavigate();
  const {categories,setCategories}=useEventOrganizerContext();
  const options = {};
 
  const [label,setLabel]=useState([]);
  const [value,setValue]=useState([]);
  const [trending,setTrending]=useState([]);

  const dailyOnce=useRef(false);
  const useOnce=useRef(false);
  const url =process.env.REACT_APP_API_URL;

  useEffect(()=>{
    if(categories.length==0){
      fetchDataForEventCategory(logout).then(data => {
        if (data) {
          setCategories(data);
          setLabel(data.map(item => item.name))
        }
      });
    }
  },[categories])

  useEffect(()=>{
    // get_total_order
    // 
    if (dailyOnce.current) return;
    dailyOnce.current = true;
    handletotaldaily();

  },[]);

  const handletotaldaily=async()=>{
    // get_dashboard_total_revenue
    const additionalPath = `${url}/get_trending_categories`;
  const headers = {
      'Content-Type': 'application/json',
  };

  if(token){
    headers['Authorization'] = `Bearer ${token}`;
  }

  await axios.get(additionalPath, {headers: headers })
      .then(response => {
        console.log(response.data);
        setTrending(response.data.trending);
          //  setDaily(response.data.order);
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log('Unauthorized: Logging user out or performing specific action...');
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
        // Rethrow the error for further handling if needed
        }
      })
  }


  // Sort the data based on total revenue in descending order


const labels=trending.map(item => item.name);
const views=trending.map(item => item.total_views);


  


  const chartData = {
    series: views, // Replace with your revenue data per event
    options: {
      chart: {
        width: 400,
        type: 'donut',
        height:400
      },
      labels: labels, // Event labels
      colors: ['#1C64F2', '#16BDCA', '#9061F9', '#FF4560', '#FFC85A'], // Customize colors
      legend: {
        position: 'bottom',
      },
    },
  };

  return (
    <div className="donut flex justify-center">
      <Chart options={chartData.options} series={chartData.series} type="donut"  
        width={chartData.options.chart.width}
        height={chartData.options.chart.height} />
    </div>
  );
};

export default Donut;