import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Label,
  Modal,
  TextInput,
  Alert,
  Spinner,
} from "flowbite-react";
import { useAuth } from "../../context/AuthContext";
import { useApi } from "../../api/APIProvider";
import axios from "axios";

function LoginForm({ onSwitch }) {
  const { makeApiRequest, error } = useApi();
  const { login, toggleModal, userlog, token } = useAuth();
  const [data, setData] = useState("");
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let errors = {};

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email address";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }

    return errors;
  };
  const emailRegex = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
  const isValidEmail = (email) => {
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (data.responsemessage == "Login successful.") {
      handleuser();
    }
    if (data.userdetails) {
      userlog(data.userdetails);
      setSuccess(false);
      toggleModal();
    }
  }, [data]);

  const user={
    email:formData.email,
    password:formData.password
  }

  const url=process.env.REACT_APP_API_URL
  const additionalPath = `${url}/login`;

  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };
  if (data.usertoken) {
    headers["Authorization"] = `Bearer ${data.usertoken}`;
  }

  const handlelogin = async () => {
    setErr(null)
    setSuccess(true);

    const errors = validateForm();
    const queryParams = new URLSearchParams(formData).toString();

    if (Object.keys(errors).length === 0) {
      // const additionalPath = `login`;
      // makeApiRequest("GET", null, additionalPath);
      axios
        .post(additionalPath,user,{headers: headers })
        .then((response) => {
          // Handle successful response

          setData(response.data);

          // setLoading(false);
        })
        .catch((error) => {
          // Handle error
          setErr(error.response.data.responsemessage);
          setSuccess(false);
          // console.log(error.response.data.responsemessage)
          // console.error("Error:", error);
        });
    } else {
      // Update the state with validation errors
      setFormErrors(errors);
    }
  };

  const handleuser = () => {
    login(data.usertoken);
    handledetails();
  };

  const handledetails = () => {
    const additionalPaths = `${url}/get_current_user_info`;
    axios
      .get(additionalPaths, { headers: headers })
      .then((response) => {
        // Handle successful response

        setData(response.data);

        // setLoading(false);
      })
      .catch((error) => {
        // Handle error
        setErr("Account not found");
        console.error("Error:", error);
      });
  };

  return (
    <div className="relative">
      <div className="space-y-6">
        <p className="text-darkgray text-sm">
          <h3 className="text-3xl font-medium text-midnightblue">
            {" "}
            Welcome to KriffeOnline
          </h3>
          Join the KriffeOnlinefamily today and get access to wonderful products
          and events that help your journey
        </p>
        {err && (
          <div className="bg-red-100 p-2 rounded-md border-l-4 border-red-600">
            <p className="text-red-600 font-bold text-sm">{err}</p>
          </div>
        )}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email" value="Your email" />
          </div>
          <TextInput
            id="email"
            placeholder="name@company.com"
            value={formData.email}
            onChange={handleInputChange}
            name="email"
            required
            class={
              formErrors.email
                ? "bg-red-100 border border-red-600 text-red-600  text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
                : "bg-gray-50 border border-normalblue text-midnightblue text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
            }
            helperText={
              formErrors.email && (
                <>
                  <span className="font-medium text-red-600">
                    {formErrors.password}
                  </span>
                </>
              )
            }
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="password" value="Your password" />
          </div>
          <TextInput
            id="password"
            type="password"
            value={formData.password}
            onChange={handleInputChange}
            name="password"
            required
            class={
              formErrors.password
                ? "bg-red-100 border border-red-600 text-red-600  text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
                : "bg-gray-50 border border-normalblue text-midnightblue text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
            }
            helperText={
              formErrors.password && (
                <>
                  <span className="font-medium text-red-600">
                    {formErrors.password}
                  </span>
                </>
              )
            }
          />
        </div>
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <Checkbox id="remember" />
            <Label htmlFor="remember">Remember me</Label>
          </div>
          <a
            href="/reset_password/true"
            className="text-sm text-cyan-700 hover:underline dark:text-cyan-500"
          >
            Lost Password?
          </a>
        </div>
        <div className="flex w-full justify-center">
          <Button onClick={handlelogin} className="bg-normalblue w-1/2 p-1">
            Login
          </Button>
        </div>
        <div className="flex justify-center text-sm font-medium text-midnightblue ">
          Don’t have an account?
          <a href="#" onClick={onSwitch} className="text-normalbrown">
            Sign up
          </a>
        </div>
      </div>
      {success && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-30 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md">
            {/* Your spinner component */}
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginForm;
