import React, { useState, useRef, useEffect } from "react";
import { Button, Label, TextInput, Datepicker } from "flowbite-react";
import FileUpload from "../Ads/FileUpload";
import { useEventOrganizerContext } from "../../../context/EventOrganizerContext";

function EventMediaIntegration({ toggleDiv }) {
  const [selectedCategory, setSelectedCategory] = useState("");
  // const {categories,locations,temporaryAdInfo,addTemporaryAdInfo}=useMarketplaceContext();
  const { temporaryEventInfo, addTemporaryEventInfo, categories } =
    useEventOrganizerContext();
  const [media, setMedia] = useState([]);

  const [formData, setFormData] = useState({
    facebook: temporaryEventInfo.link[0]
      ? temporaryEventInfo.link[0].facebook
      : "",
    instagram: temporaryEventInfo.link[0]
      ? temporaryEventInfo.link[0].instagram
      : "",
    twitter: temporaryEventInfo.link[0]
      ? temporaryEventInfo.link[0].twitter
      : "",
    youtube: temporaryEventInfo.link[0]
      ? temporaryEventInfo.link[0].youtube
      : "",
  });

  const info = {
    link: [formData],
  };
  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    // Check if at least one field is filled
    const isFormValid = Object.values(formData).some((value) => value !== "");
    setIsFormValid(isFormValid);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChanges = () => {
    // save into the temp array
    console.log(info);
    addTemporaryEventInfo(info);
    toggleDiv("review");
  };

  return (
    <div className="flex justify-center items-center">
      <form className="flex w-full flex-col gap-4 ">
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="facebook"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Facebook Live Link
                  </p>
                  <p className="text-darkgray">
                    Live link to your event on facebook
                  </p>
                </div>
              }
            />
          </div>

          <TextInput
            id="facebook"
            name="facebook"
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            onChange={handleInputChange}
            value={formData.facebook}
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="twitter"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Twitter Live Link
                  </p>
                  <p className="text-darkgray">
                    Live link to your event on Twitter{" "}
                  </p>
                </div>
              }
            />
          </div>

          <TextInput
            id="twitter"
            name="twitter"
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            onChange={handleInputChange}
            value={formData.twitter}
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="instagram"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Instagram Live Link
                  </p>
                  <p className="text-darkgray">
                    Live link to your event on Instagram
                  </p>
                </div>
              }
            />
          </div>

          <TextInput
            id="instagram"
            name="instagram"
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            onChange={handleInputChange}
            value={formData.instagram}
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="youtube"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Youtube Live Link
                  </p>
                  <p className="text-darkgray">
                    Live link to your event on Instagram
                  </p>
                </div>
              }
            />
          </div>

          <TextInput
            id="youtube"
            name="youtube"
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            onChange={handleInputChange}
            value={formData.youtube}
          />
        </div>

        <div className="flex">
          <Button
            type="button"
            className={`justify-end bg-normalblue sm:p-4 p-2 rounded-md ${
              !isFormValid ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleChanges}
          >
            Save Changes & Next
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EventMediaIntegration;
