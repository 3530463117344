import React from "react";
import { Sidebar } from "flowbite-react";

function SidebarNav({toggleDiv,visibleDiv}){
    return(
        <Sidebar aria-label="Default sidebar example"  >
            <Sidebar.Items>
              <Sidebar.ItemGroup>
                <Sidebar.Item
                  href="#"
                  onClick={() => toggleDiv("profile")}
                  className={
                    visibleDiv === "profile"
                      ? "bg-normalblue/20 text-normalblue"
                      : ""
                  }
                >
                  <p>My Profile</p>
                </Sidebar.Item>
                <Sidebar.Item
                  href="#"
                  onClick={() => toggleDiv("saved")}
                  className={
                    visibleDiv === "saved"
                      ? "bg-normalblue/20 text-normalblue"
                      : ""
                  }
                >
                  <p>Saved Items</p>
                </Sidebar.Item>
                <Sidebar.Item
                  href="#"
                  onClick={() => toggleDiv("ads")}
                  className={
                    visibleDiv === "ads"
                      ? "bg-normalblue/20 text-normalblue"
                      : ""
                  }
                >
                  <p>Ads</p>
                </Sidebar.Item>
                <Sidebar.Item
                  href="#"
                  onClick={() => toggleDiv("orders")}
                  className={
                    visibleDiv === "orders"
                      ? "bg-normalblue/20 text-normalblue"
                      : ""
                  }
                >
                  <p>Orders</p>
                </Sidebar.Item>
                <Sidebar.Item
                  href="#"
                  onClick={() => toggleDiv("inbox")}
                  className={
                    visibleDiv === "inbox"
                      ? "bg-normalblue/20 text-normalblue"
                      : ""
                  }
                >
                  <p>Inbox</p>
                </Sidebar.Item>
                <Sidebar.Item
                  href="#"
                  onClick={() => toggleDiv("events")}
                  className={
                    visibleDiv === "events"
                      ? "bg-normalblue/20 text-normalblue"
                      : ""
                  }
                >
                  <p>Events Management</p>
                </Sidebar.Item>
                <Sidebar.Item
                  href="#"
                  onClick={() => toggleDiv("settings")}
                  className={
                    visibleDiv === "settings"
                      ? "bg-normalblue/20 text-normalblue"
                      : ""
                  }
                >
                  <p>Settings</p>
                </Sidebar.Item>
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </Sidebar>
);
}
export default SidebarNav;