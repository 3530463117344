import React, { useEffect, useState } from "react";
import { useApi } from "../../api/APIProvider";
import { useFaq } from "../../context/FAQContext";

function FAQLoader() {
  const { makeApiRequest, data } = useApi();
  const { faqlog, faq } = useFaq();

  useEffect(() => {
    if (!data) {
      fetchFaqData();
    } else if (data && data.responsemessage === "List of Faqs") {
      faqlog(data.faqs);
    }
  }, [faq, data, faqlog]);

  const fetchFaqData = async () => {
    try {
      if (faq.length === 0) {
        const additionalPath = "faqs";
        await makeApiRequest("GET", null, additionalPath);
      }
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
    }
  };

  return null;
}

export default FAQLoader;
