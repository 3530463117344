import { Button } from "flowbite-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMarketplaceContext } from "../../context/MarketplaceContexts";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import ProductTag from "../Miscellanous/ProductTag";
import AdCarousel from "../Carousels/AdCarousel";

function ProductCard({
  productname,
  seller,
  saves,
  price,
  category,
  saved,
  status,
  id,
  item,
  adtype,
}) {
  const [save, setSave] = useState(saved);
  const { token } = useAuth();
  const url =process.env.REACT_APP_API_URL;

  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const handlesaved = () => {
    if (save) {
      removeSavedItems();
    } else {
      addSavedItems();
    }
  };

  const formData = {
    product_id: id,
  };

  const removeSavedItems = () => {
    const additionalPath = `${url}/remove_from_favorite`;
    axios
      .delete(additionalPath, { params: formData, headers: headers })
      .then((response) => {
        // Handle successful response
        console.log(response.responsemessage);
        setSave(false);
      })
      .catch((error) => {
        // Handle error
        console.error("Error:", error);
      });
  };
  // add_to_favorites

  const addSavedItems = () => {
    const additionalPath = `${url}/add_to_favorites`;
    axios
      .post(additionalPath, formData, { headers: headers })
      .then((response) => {
        // Handle successful response
        console.log(response);
        setSave(true);
      })
      .catch((error) => {
        // Handle error
        console.error("Error:", error);
      });
  };

  const navigate = useNavigate();
  const handleAd = () => {
    navigate(`/addetails/${id}`);
  };

  return (
    <div className="flex flex-col justify-between basis-1/4">
      <div class="relative ...">
        {/* <img className="h-40 bg-midnightblue w-full rounded-lg" src={item.p}/> */}

        <img
          className="h-40 bg-midnightblue w-full rounded-lg object-cover"
          src={item.productimages && item.productimages[0]}
        />

        {/* per the ad type, saves, something else  */}

        <ProductTag saves={saves} adType={adtype} />
        <div class="absolute bottom-2 left-2 ...">
          {/* click to save. if logged in then save else if not pop-up to register if timer is not up. */}

          <Button className="w-6 h-6">
            <a href="#" onClick={handlesaved}>
              {save ? (
                <svg
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 14 20"
                >
                  <path d="M13 20a1 1 0 0 1-.64-.231L7 15.3l-5.36 4.469A1 1 0 0 1 0 19V2a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v17a1 1 0 0 1-1 1Z" />
                </svg>
              ) : (
                <svg
                  class="w-6 h-6 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="m13 19-6-5-6 5V2a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v17Z"
                  />
                </svg>
              )}
            </a>
          </Button>
        </div>
      </div>

      <p className="text-xs text-midnightblue font-light">{category}</p>
      <p className="text-xl text-midnightblue font-medium">{productname}</p>
      {/* conditional here, if unverfiied they see sellers details or elss they see the details. pass it as parameters into the component() */}

      {/* {status ? (
        <div className="flex flex-row">
          <p className="text-sm text-darkgray">{seller}</p>
          <svg
            class="w-4 h-4 text-yellow-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 22 20"
          >
            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
          </svg>
        </div>
      ) : (
        <div className="flex flex-row">
          <p className="text-sm text-darkgray">Sellers Details</p>
          <svg
            class="w-4 h-4 text-normalblue"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 16 20"
          >
            <path d="M14 7h-1.5V4.5a4.5 4.5 0 1 0-9 0V7H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2Zm-5 8a1 1 0 1 1-2 0v-3a1 1 0 1 1 2 0v3Zm1.5-8h-5V4.5a2.5 2.5 0 1 1 5 0V7Z" />
          </svg>
        </div>
      )} */}

      {/* number of people who have saved the product */}
      <p className="text-xs text-midnightblue">
        {saves} people have viewed the product
      </p>
      <div className="flex flex-row justify-between">
        <p className="text-normalblue text-base font-medium">GHS {price}</p>
        <Button
          className="bg-normalbrown rounded-md w-8 h-8"
          onClick={handleAd}
        >
          <svg
            class="w-4 h-4 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 19 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 15a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0h8m-8 0-1-4m9 4a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm1-4H5m0 0L3 4m0 0h5.501M3 4l-.792-3H1m11 3h6m-3 3V1"
            />
          </svg>
        </Button>
      </div>
    </div>
  );
}

export default ProductCard;
