import React, { useState } from "react";
import { Button, Modal } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";

function CategoryEditModal({ id, open, handleModal, tags, name }) {
  const { token, adminlogout } = useAuth();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/add_new_tags_to_category`;
  const [tag, setTags] = useState(tags);
  const [tagInput, setTagInput] = useState("");

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && tagInput.trim() !== "") {
      setTags([...tags, tagInput]);
      setTagInput(""); // Clear the input field
    }
  };

  const handleRemoveTag = (index) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1);
    setTags(updatedTags);
  };

  const closeAd = () => {
    // update the ad

    // api to close and then navigaet that id
    if (!token) {
      // If token is not present in the context, try to get it from local storage
      const storedToken = localStorage.getItem("token"); // Replace 'yourTokenKey' with the actual key you used for storing the token
      if (storedToken) {
        handleedit(JSON.parse(storedToken));
      }
    } else {
      handleedit(token);
    }
  };

  const handleedit = async (tokens) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokens}`,
    };

    const statusinfo = {
      category_id: id,
      tags: tag,
    };

    await axios
      .post(additionalPath, statusinfo, { headers: headers })
      .then((response) => {
        if (response.data.responsestatus == 200) {
          console.log("Updated");
        } else {
          console.log("Egbamiii");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs

            adminlogout();
            navigate("/admin")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });

    handleModal();
  };
  return (
    <div>
      <Modal show={open} size="md" popup onClose={handleModal}>
        <Modal.Header>Update {name} Category</Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Add New tags to the existing ones.
            </h3>
            <div className="flex flex-wrap gap-2">
              {tag.map((tags, index) => (
                <div
                  className="bg-lightbrown/40 text-normalbrown p-2 rounded-full flex w-auto items-center justify-center"
                  key={index}
                >
                  <p className="w-full">{tags}</p>
                  <Button
                    onClick={() => handleRemoveTag(index)}
                    className="text-normalbrown text-center h-4 basis-1/4"
                  >
                    X
                  </Button>
                </div>
              ))}
            </div>

            {/* shopw tags here */}

            <div>
              <div className="mt-4 flex flex-col ">
                <input
                  type="text"
                  placeholder="Add a tag"
                  value={tagInput}
                  onChange={handleTagInputChange}
                  onKeyDown={handleInputKeyPress}
                  class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                />

                <div className="justify-end items-end flex">
                  {" "}
                  <Button className="bg-normalblue p-2 text-white  mt-4 w-1/2">
                    Add New Tag
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CategoryEditModal;
