import React,{useState,useEffect} from "react";
import OrganizerSidebar from "../../components/Navigation/OrganizerSidebar";
import { useParams,useNavigate } from "react-router-dom";
import OrganizerNav from "../../components/Navigation/OrganizerNav";
import { useAuth } from "../../context/AuthContext";
import Authentication from "./MainPages/Authentication";
import AdminSideNav from "../../components/Navigation/Admin/AdminSideNav";
import AdminMainNav from "../../components/Navigation/Admin/AdminMainNav";
import Dashboard from "./MainPages/Dashboard";
import Users from "./MainPages/Users";
import Ads from "./MainPages/Ads";
import Events from "./MainPages/Events";
import Requests from "./MainPages/Requests";
import OrganizerRequests from "./MainPages/OrganizerRequests";
import Messages from "./MainPages/Messages";
import Category from "./MainPages/Category";
import FAQ from "./MainPages/FAQ";

function MainAdmin(){
const { section } = useParams();
  const [visibleDiv, setVisibleDiv] = useState(section);

  const toggleDiv = (divValue) => {
    setVisibleDiv(divValue);
    navigate(`/admin/${divValue}`)
  };
  const divContentMap = {
    dashboard:<Dashboard/>,
    events: <Events/>,
    organizers: <OrganizerRequests/>,
    analytics:"analytics",
    users: <Users/>,
    ads:<Ads/>,
    requests:<Requests/>,
    messages:<Messages/>,
    category:<Category/>,
    faqs:<FAQ/>,
    audit:"audit",
    settings:"settings",
  };
  const content = divContentMap[visibleDiv] || "";
 
  
  const navigate = useNavigate();
  const[login,setLogin]=useState(false)
  const{isLogged}=useAuth()

 


  // lets put it here

    return(
    <div >

       
    
        

        {
            isLogged?(<div className="w-screen flex"><AdminSideNav toggleDiv={toggleDiv} visibleDiv={visibleDiv}/>
            <div className="flex-1 sm:ml-[13rem] h-screen overflow-y-auto  bg-[#F5F5F5] ">
                <AdminMainNav/>
                {content} 
            </div></div>):(<Authentication setLogin={setLogin} toggleDiv={toggleDiv}/>)
        }

    </div>)
}

export default MainAdmin;