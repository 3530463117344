import React from "react";
import { Link } from "react-router-dom";
function Saved({color}) {
  return (
    <button className="sm:block hidden">
      <Link to="/">
        <svg
          class={color}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 21 19"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M11 4C5.5-1.5-1.5 5.5 4 11l7 7 7-7c5.458-5.458-1.542-12.458-7-7Z"
          />
        </svg>
      </Link>
    </button>
  );
}

export default Saved;
