import React, { useState, useRef, useEffect } from "react";
import { Button, Label, TextInput, Datepicker } from "flowbite-react";
import { useEventOrganizerContext } from "../../../context/EventOrganizerContext";
import EventFileUpload from "./EventFileUpload";
import { useAuth } from "../../../context/AuthContext";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { fetchDataForEventCategory } from "../../../api/ApiPage";

import "react-datepicker/dist/react-datepicker.css";

function EventBasicForm({ toggleDiv, addProduct, setAdd }) {
  const { organizer,logout } = useAuth();
  const [selectedCategory, setSelectedCategory] = useState("");
  // const {categories,locations,temporaryAdInfo,addTemporaryAdInfo}=useMarketplaceContext();
  const {
    temporaryEventInfo,
    addTemporaryEventInfo,
    categories,
    setCategories,
  } = useEventOrganizerContext();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [productdetails, setDetails] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(startDate);
  const [formData, setFormData] = useState({
    name: "",
    category: [],
    location: "",
    images: [],
    description: "",
    summary: "",
    host: organizer.companyname,
    date: format(startDate, "yyyy-MM-dd"),
    time: format(startDate, "hh:mm aa"),
  });

  const info = {
    name: formData.name,
    host: formData.host,
    images: selectedFiles,
    event_category: parseInt(formData.category),
    location: formData.location,
    description: formData.description,
    summary: formData.summary,
    date: formData.date,
    time: formData.time,
  };

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [selectedLocation, setSelectedLocation] = useState("");

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [formerror,setFormerror]=useState("")
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    // Check form validity whenever formData changes
    const requiredFields = [
      "host",
      "name",
      "description",
      "summary",
      "location",
      "category",
      "date",
      "time",
    ];

    const isValid = requiredFields.every((field) => formData[field] !== "");
    setIsFormValid(isValid);
  }, [formData]);

  const handleChanges = () => {
    // save into the temp array
    if (
      formData.host === '' ||
      formData.name === '' ||
      formData.description === '' ||
      formData.summary === '' ||
      formData.location === '' ||
      formData.category.length === 0 ||
      selectedFiles.length === 0
    ) {
      setFormerror('Please fill in all required fields/upload at least one image before proceeding.');
      return; // Prevent further execution if any required field is empty
    }

    addTemporaryEventInfo(info);
    toggleDiv("event");
  };

  useEffect(() => {
    if (categories.length == 0) {
      fetchDataForEventCategory(logout).then((data) => {
        if (data) {
          setCategories(data);
        }
      });
    }
  });

  return (
    <div className="flex justify-center items-center">
      <form className="flex w-full flex-col gap-4 ">
      <div>
        <p className="font-bold text-red-600 text-lg">{formerror}</p>
      </div>
        <div className="flex lg:flex-row flex-col gap-2">
        <div className="basis-1/2">
          <div className="mb-2 block">
            <Label
              htmlFor="host"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Event Host</p>
                  <p className="text-darkgray">Write the event host in full</p>
                </div>
              }
            />
          </div>

          <TextInput
            id="host"
            name="host"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            onChange={handleInputChange}
            value={
              temporaryEventInfo.host != ""
                ? temporaryEventInfo.host
                : formData.host
            }
          />
        </div>
        <div className="basis-1/2">
          <div className="mb-2 block">
            <Label
              htmlFor="name"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Event Name</p>
                  <p className="text-darkgray">Write the event name in full</p>
                </div>
              }
            />
          </div>

          <TextInput
            id="name"
            name="name"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            onChange={handleInputChange}
            value={
              temporaryEventInfo.name != ""
                ? temporaryEventInfo.name
                : formData.name
            }
          />
        </div>

        </div>
        
      
        <div className="flex lg:flex-row flex-col gap-2">
        <div className="basis-1/2">
          <div className="mb-2 block">
            <Label
              htmlFor="description"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Event Description
                  </p>
                  <p className="text-darkgray">
                    Write the event description in full{" "}
                  </p>
                </div>
              }
            />
          </div>

          <TextInput
            id="description"
            name="description"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            onChange={handleInputChange}
            value={
              temporaryEventInfo.description != ""
                ? temporaryEventInfo.description
                : formData.description
            }
          />
        </div>
        <div className="basis-1/2">
          <div className="mb-2 block">
            <Label
              htmlFor="summary"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Event Summary</p>
                  <p className="text-darkgray">
                    Write a short summary in full{" "}
                  </p>
                </div>
              }
            />
          </div>

          <TextInput
            id="summary"
            name="summary"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            onChange={handleInputChange}
            value={
              temporaryEventInfo.summary != ""
                ? temporaryEventInfo.summary
                : formData.summary
            }
          />
        </div>

        </div>


        <div className="flex lg:flex-row flex-col gap-2">
        <div className="basis-1/2">
          <div className="mb-2 block">
            <Label
              htmlFor="location"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Event Location</p>
                  <p className="text-darkgray">
                    Write where the event will be located
                  </p>
                </div>
              }
            />
          </div>

          <TextInput
            id="location"
            name="location"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            onChange={handleInputChange}
            value={
              temporaryEventInfo.location != ""
                ? temporaryEventInfo.location
                : formData.location
            }
          />
        </div>
        <div className="basis-1/2">
          <div className="mb-2 block">
            <Label
              htmlFor="category"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Event Category</p>
                  <p className="text-darkgray">Choose the event category </p>
                </div>
              }
            />
          </div>

          <select
            name="category"
            id="category"
            value={
              temporaryEventInfo.event_category != ""
                ? temporaryEventInfo.event_category
                : formData.category
            }
            onChange={handleCategoryChange}
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        </div>
       

        

        

        <div className="flex flex-col grow-0">
          <div className="mb-2 block">
            <Label
              htmlFor="price"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Event Date and Time
                  </p>
                  <p className="text-darkgray">
                    Select the date the event will be held{" "}
                  </p>
                </div>
              }
            />
          </div>

          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="MMMM d, yyyy hh:mm aa"
            showTimeSelect
            minDate={new Date()}
            className="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md sm:h-16 h-10 w-full"
          />
          {/* sm:w-[1182px] w-[340px] */}
        </div>

        <div id="fileUpload" border>
          <div className="mb-2 block">
            <Label
              htmlFor="file"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Event Images</p>
                  <p className="text-darkgray">
                    Select up to 3 images with a maximum fil size of 3MB{" "}
                  </p>
                </div>
              }
            />
          </div>
          <EventFileUpload
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        </div>
        <div className="flex">
          <Button
            type="button"
            className={`justify-end bg-normalblue sm:p-4 p-2 rounded-md ${
              !isFormValid ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleChanges}
          >
            Save Changes & Next
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EventBasicForm;
