import React from "react";
import { Button, Modal } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";

function FinanceModal({ mode, toggleModal,order }) {
  const closeAd = () => {
    toggleModal();
  };
  return (
   <div>

      <Modal show={true} size="md" popup onClose={toggleModal}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <div className="flex justify-center">
              <svg
                class="w-12 h-12 text-darkgray  "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
              </svg>
            </div>

            <h3 className="mb-5 text-xl font-bold text-gray-500 dark:text-gray-400">
             #Order {order.order_no} Information
            </h3>
            <div className="flex justify-center gap-4 flex-col">
                <p className="font-bold">Full Name:<span className="font-light">{order.fullname}</span></p>
              <p className="font-bold">Email:<span className="font-light">{order.email}</span></p>
              <p className="font-bold">Phone Number:<span className="font-light">{order.phonenumber}</span></p>
              <p className="font-bold">Total Tickets:<span className="font-light">{order.total_ticket}</span></p>
              <p className="font-bold">Payment Status:<span className="font-light">{order.status}</span></p>
              <p className="font-bold">Total Bill:<span className="font-light">GH¢ {order.total}</span></p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </div>
   
  );
}

export default FinanceModal;
