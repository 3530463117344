import React, { useRef,useEffect,useState } from 'react';
import { useAuth } from "../../../context/AuthContext";
import axios from 'axios';
import { fetchDataForEventCategory,fetchDataForCategory } from "../../../api/ApiPage";
import { useNavigate } from "react-router-dom";
import { Progress } from 'flowbite-react';

const Category = () => {
  const {token,logout}=useAuth();
  const navigate=useNavigate();
  const [categories,setCategories]=useState(0);
  const [event,setCEvent]=useState(0);
  const options = {};
 
  const [label,setLabel]=useState([]);
  const [value,setValue]=useState([]);
  const [trending,setTrending]=useState(0);

  const dailyOnce=useRef(false);
  const useOnce=useRef(false);
  const url =process.env.REACT_APP_API_URL;

  useEffect(()=>{
    if(categories===0){
      fetchDataForCategory(logout).then(data => {
        if (data) {
          setCategories(data.length);
    
        }
      });
    }

    if(event===0){
        fetchDataForEventCategory(logout).then(data => {
          if (data) {
            setCEvent(data.length);
      
          }
        });
      }
  },[categories,event])

  useEffect(()=>{
    // get_total_order
    // 
    if (!token) {
        // If token is not present in the context, try to get it from local storage
        const storedToken = localStorage.getItem('token'); // Replace 'yourTokenKey' with the actual key you used for storing the token
        if (storedToken) {
          // If token is found in local storage, set it in the context
          // Note: You might want to validate and handle the token accordingly
          // (e.g., check if it's still valid)


          if (dailyOnce.current) return;
          dailyOnce.current = true;
          handletotaldaily(JSON.parse(storedToken));
        }
      }else{
        if (dailyOnce.current) return;
        dailyOnce.current = true;
        handletotaldaily(token);

      }

   

  },[token]);

  const handletotaldaily=async(tokens)=>{
    // get_dashboard_total_revenue
    const additionalPath = `${url}/admin/dashboard_get_total_number_of_requests`;
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokens}`,
  };

  

  await axios.get(additionalPath, {headers: headers })
      .then(response => {
        console.log(response)
        setTrending(response.data.total_requests);
          //  setDaily(response.data.order);
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log('Unauthorized: Logging user out or performing specific action...');
          logout();
          navigate("/admin")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
        // Rethrow the error for further handling if needed
        }
      })
  }


  // Sort the data based on total revenue in descending order




  




  return (
    <div className=" flex flex-col justify-center h-[23rem]">
     <div class="mb-1 text-base font-medium text-blue-700 dark:text-blue-500 flex justify-between"><p>Organizer Requests</p>
     <p>{trending}</p>
     </div>
<div class="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
  <div class="bg-blue-600 h-2.5 rounded-full" style={{width: trending}}> </div>
</div>
<div class="mb-1 text-base font-medium text-indigo-700 dark:text-indigo-500 flex justify-between"><p>Product Categories</p>
     <p>{categories}</p></div>
<div class="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
  <div class="bg-indigo-600 h-2.5 rounded-full dark:bg-indigo-500" style={{width: categories}}></div>
</div>
<div class="mb-1 text-base font-medium text-purple-700 dark:text-purple-500 flex justify-between"><p>Events</p>
     <p>{event}</p></div>
<div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
  <div class="bg-purple-600 h-2.5 rounded-full dark:bg-purple-500" style={{width: event}}></div>
</div>
   
    </div>
  );
};

export default Category;