import { Button } from "flowbite-react";
import React from "react";
import {useNavigate} from "react-router-dom";

function HomeProductCard({ productname, seller, saves, price,product_id,status,images }) {
  const navigate=useNavigate();
  const handleAd=()=>{
      navigate(`/addetails/${product_id}`);
  }
  return (
    <div className="flex flex-col justify-between basis-1/4">
      <div class="relative ...">
        <img className="h-40 bg-midnightblue w-full rounded-lg object-cover" src={images&&images[0]} />
        {/* <div class="absolute bottom-2 left-2 ...">
          {/* click to save. if logged in then save else if not pop-up to register if timer is not up. 
          <Button className="w-6 h-6">
            {" "}
            <svg
              class="w-6 h-6 text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="m13 19-6-5-6 5V2a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v17Z"
              />
            </svg>
          </Button>
        </div> */}
      </div>

      <p className="text-lg text-midnightblue font-medium">{productname?(productname):("Product")}</p>
      {/* conditional here, if unverfiied they see sellers details or elss they see the details. pass it as parameters into the component() */}
      {/* <div className="flex flex-row">
        {status?(
           <p className="text-sm text-darkgray">John Doe</p>
        ):(
          <p className="text-sm text-darkgray">Sellers Details</p>

        )}
       
        <svg
          class="w-4 h-4 text-normalblue"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 16 20"
        >
          <path d="M14 7h-1.5V4.5a4.5 4.5 0 1 0-9 0V7H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2Zm-5 8a1 1 0 1 1-2 0v-3a1 1 0 1 1 2 0v3Zm1.5-8h-5V4.5a2.5 2.5 0 1 1 5 0V7Z" />
        </svg>
      </div> */}
      {/* number of people who have saved the product */}
      <p className="text-xs text-midnightblue">
      {saves?(saves):("0")} people have viewed the product
      </p>
      <div className="flex flex-row justify-between">
        <p className="text-normalbrown font-bold text-base">GHS {price?(price):("Price")}</p>
        <Button className="bg-normalbrown rounded-full w-6 h-6" onClick={handleAd}>
          <svg
            class="w-4 h-4 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 14"
          >
            <g
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            >
              <path d="M10 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
              <path d="M10 13c4.97 0 9-2.686 9-6s-4.03-6-9-6-9 2.686-9 6 4.03 6 9 6Z" />
            </g>
          </svg>
        </Button>
      </div>
    </div>
  );
}

export default HomeProductCard;
