import React,{useState} from "react";
import EventFormStepper from "../../../components/Navigation/EventFormStepper";
import EventBasicForm from "../../../components/Forms/NewEvent/EventBasicForm";
import EventDetailsForm from "../../../components/Forms/NewEvent/EventDetailsForm";
import EventTicketPackage from "../../../components/Forms/NewEvent/EventTicketPackage";
import EventMediaIntegration from "../../../components/Forms/NewEvent/EventMediaIntegration";
import EventReviewForm from "../../../components/Forms/NewEvent/EventReviewForm";

function NewEvent({toggleDivs}){
    const [visibleDiv, setVisibleDiv] = useState("basic");
  const [addevent, setAdd] = useState([]);
  const [ticketdetails, setticketDetails] = useState([]);


 
  const toggleDiv = (divValue) => {
    setVisibleDiv(divValue);
  };
    const divContentMap = {
        basic: <EventBasicForm toggleDiv={toggleDiv}/>,
        event: <EventDetailsForm toggleDiv={toggleDiv}/>,
        ticket: <EventTicketPackage ticketdetails={ticketdetails} setticketDetails={setticketDetails} toggleDiv={toggleDiv}/>,
        media: <EventMediaIntegration toggleDiv={toggleDiv}/>,
        review:<EventReviewForm toggleDiv={toggleDivs}/>
      };
      const content = divContentMap[visibleDiv] || "";
    return(
    <div className="p-3">
        <a href="/eventorganizer/events" className="text-normalblue ">{`<`}Go Back</a>

        <p className="text-midnightblue text-center font-bold text-xl">Add New Event</p>

        <div className="w-full p-3">
            {/* form stepper */}
            <EventFormStepper toggleDiv={toggleDiv} visibleDiv={visibleDiv} />
            {content}

        </div>


    </div>)
}
export default NewEvent;