import { Button } from "flowbite-react";
import React from "react";
import {
  format
} from  'date-fns';
import {useNavigate} from "react-router-dom";

function HomeEventCard({ eventname, date, time, price, event_id,location,images }) {
  const navigate=useNavigate();

  const minprice=price&&price.reduce(
    (minPrice, currentTicket) =>
      currentTicket.price < minPrice ? currentTicket.price : minPrice,
    price[0].price // Set initial minimum price as the first ticket's price
  );
  const handledetails=()=>{
    navigate(`/eventspage/${event_id}`);
}
  return (
    <div className="flex flex-col justify-between basis-1/4">
      <img className="h-40 bg-midnightblue w-full rounded-lg" src={images&&images[0]}/>
      <p className="text-lg text-midnightblue font-medium">{eventname?eventname:"Event Name"}</p>
      {/* conditional here, if unverfiied they see sellers details or elss they see the details. pass it as parameters into the component() */}
      <div className="flex flex-row gap-1">
        <svg
          class="w-4 h-4 text-darkgray"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            fill="currentColor"
            d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"
          />
        </svg>
        <p className="text-sm text-darkgray">{date?format(new Date(date), "dd MMM yyyy"):"01-00-00"}</p>
      </div>
      {/* <div className="flex flex-row gap-1">
        <svg
          class="w-4 h-4 text-darkgray"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 21"
        >
          <g
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          >
            <path d="M8 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
            <path d="M13.8 12.938h-.01a7 7 0 1 0-11.465.144h-.016l.141.17c.1.128.2.252.3.372L8 20l5.13-6.248c.193-.209.373-.429.54-.66l.13-.154Z" />
          </g>
        </svg>
        <p className="text-xs text-darkgray">Location</p>
      </div> */}
      <div className="flex flex-row justify-between">
        <p className="text-normalblue text-base">GHS {minprice}</p>
        <Button className="bg-normalbrown rounded-md w-12 h-6" onClick={handledetails}>Buy</Button>
      </div>
    </div>
  );
}

export default HomeEventCard;
