import React, { useEffect, useState } from "react";
import { useEventContexts } from "../../../context/EventContexts";
import { Button } from "flowbite-react";

function TicketPackage({
  ticketInfo,
  ticketpackages,
  addevent,
  toggleDiv,
  id,
}) {
  const { addTemporaryTicketInfo } = useEventContexts();
  const [msg,setMsg]=useState("");
  const [Err,setErr]=useState(true);

  useEffect(() => {
    handleTicketChange();
  }, []);

  const handleTicketChange = (ticketname, quantity, unitPrice) => {
    const packageIndex =
      addevent && addevent.findIndex((pkg) => pkg.ticket === ticketname);

    if (packageIndex !== -1) {
      const updatedPackages = [...addevent];
      updatedPackages[packageIndex].quantity = quantity;
      updatedPackages[packageIndex].ticket_total = quantity * unitPrice;

      if (quantity === 0) {
        updatedPackages.splice(packageIndex, 1);
      }

      ticketpackages(updatedPackages);
    } else {
      if (quantity > 0) {
        const selectedPackage = {
          ticket: ticketname,
          quantity: quantity,
          ticket_total: unitPrice * quantity,
        };
        ticketpackages((prevSelected) => [...prevSelected, selectedPackage]);
      }
    }
    // ... rest of the code
  };

  useEffect(()=>{
    const isInvalidPurchase = addevent.length === 0 || addevent.some((pkg) => pkg.quantity === 0);

    if (!isInvalidPurchase) {
      // Display an error message or handle the invalid purchase scenario
      setErr(false)
    }else{
      setErr(true)
    }
    


  },[addevent,Err]);

  const handleErr=()=>{
    const isInvalidPurchase = addevent.length === 0 || addevent.some((pkg) => pkg.quantity === 0);

    if (!isInvalidPurchase) {
      // Display an error message or handle the invalid purchase scenario
      setErr(false)
  }
}

  const handlePurchase = () => {
    addTemporaryTicketInfo({ event_id: id });
    addTemporaryTicketInfo({ ticket_info: addevent });
    toggleDiv("contact");
  };
  return (
    <div>
      {ticketInfo &&
        ticketInfo.map((ticket, index) => (
          <div key={index}>
            {ticket.number_available == 0 ? (
              <li className="list-none flex flex-row justify-between ">
                <div>
                  <div className="line-through decoration-lightgray">
                    <p className="text-midnightblue/20 font-bold text-xl">
                      {ticket.name}{" "}
                    </p>
                    <p className="text-darkgray/20 text-sm">
                      {" "}
                      {ticket.description}
                    </p>
                    <p className="text-midnightblue/20 font-medium text-base">
                      Package Information:
                      <span className="text-darkgray font-normal">
                        {ticket.package_info}
                      </span>{" "}
                    </p>
                    <p className="text-normalbrown/20 font-bold text-2xl">
                      {parseInt(ticket.price) === 0
                        ? "Free"
                        : `GHC ${parseInt(ticket.price)}`}{" "}
                      <span className="text-darkgray font-normal text-sm">
                        per attendee
                      </span>
                    </p>
                  </div>

                  <p className="font-bold text-red-600 text-xl">Sold Out</p>
                </div>

                <div>
                  <select
                    className="border border-lightgray rounded-md "
                    disabled={true}
                    id={`quantity-${index}`}
                    name={`quantity-${index}`}
                    value={
                      (addevent &&
                        addevent.find((pkg) => pkg.ticket === ticket.id)
                          ?.quantity) ||
                      0
                    }
                    onChange={(e) =>
                      handleTicketChange(
                        ticket.name,
                        parseInt(e.target.value),
                        parseInt(ticket.price)
                      )
                    }
                  >
                    {Array.from({ length: 11 }).map((element, index) => (
                      <option key={index} value={index}>
                        {index}
                      </option>
                    ))}
                  </select>
                </div>
              </li>
            ) : ticket.number_available >= 1 && ticket.number_available <= 5 ? (
              <li className="list-none flex flex-row justify-between ">
                <div>
                  <p className="text-midnightblue font-bold text-xl">
                    {ticket.name}
                  </p>
                  <p className="text-darkgray text-sm"> {ticket.description}</p>
                  <p className="text-midnightblue font-medium text-base">
                    Package Information:
                    <span className="text-darkgray font-normal">
                      {ticket.package_info}
                    </span>{" "}
                  </p>
                  <p className="text-normalbrown font-bold text-2xl">
                    {parseInt(ticket.price) === 0
                      ? "Free"
                      : `GHC ${parseInt(ticket.price)}`}{" "}
                    <span className="text-darkgray font-normal text-sm">
                      per attendee
                    </span>
                  </p>
                  <p className="font-bold text-normalbrown text-xl">
                    Only {ticket.ticket_sold} Available!
                  </p>
                </div>

                <div>
                  <select
                    className="border border-lightgray rounded-md "
                    disabled={true}
                    id={`quantity-${index}`}
                    name={`quantity-${index}`}
                    value={
                      (addevent &&
                        addevent.find((pkg) => pkg.ticket === ticket.name)
                          ?.quantity) ||
                      0
                    }
                    onChange={(e) =>
                      handleTicketChange(
                        ticket.name,
                        parseInt(e.target.value),
                        parseInt(ticket.price)
                      )
                    }
                  >
                    {Array.from({ length: 11 }).map((element, index) => (
                      <option key={index} value={index}>
                        {index}
                      </option>
                    ))}
                  </select>
                </div>
              </li>
            ) : (
              <li className="list-none flex flex-row justify-between ">
                <div>
                  <p className="text-midnightblue font-bold text-xl">
                    {ticket.name}
                  </p>
                  <p className="text-darkgray text-sm"> {ticket.description}</p>
                  <p className="text-midnightblue font-medium text-base">
                    Package Information:
                    <span className="text-darkgray font-normal">
                      {ticket.package_info}
                    </span>{" "}
                  </p>
                  <p className="text-normalbrown font-bold text-2xl">
                    {parseInt(ticket.price) === 0
                      ? "Free"
                      : `GHC ${parseInt(ticket.price)}`}{" "}
                    <span className="text-darkgray font-normal text-sm">
                      per attendee
                    </span>
                  </p>
                </div>

                <div>
                  <select
                    className="border border-lightgray rounded-md "
                    id={`quantity-${index}`}
                    name={`quantity-${index}`}
                    value={
                      (addevent &&
                        addevent.find((pkg) => pkg.ticket === ticket.id)
                          ?.quantity) ||
                      0
                    }
                    onChange={(e) =>
                      handleTicketChange(
                        ticket.id,
                        parseInt(e.target.value),
                        parseInt(ticket.price)
                      )
                    }
                  >
                    {Array.from({ length: 11 }).map((element, index) => (
                      <option key={index} value={index}>
                        {index}
                      </option>
                    ))}
                  </select>
                </div>
              </li>
            )}

            <hr className="mt-2 mb-2"></hr>
          </div>
        ))}
      <div className="w-full mt-4">
      <Button className={`bg-normalblue w-full ${Err ? "opacity-50 cursor-not-allowed" : ""}`} onClick={handlePurchase} disabled={Err}>
  Save Changes & Continue
</Button>
      </div>
    </div>
  );
}

export default TicketPackage;
