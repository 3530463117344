import React from "react";
import { Dropdown } from "flowbite-react";

function TopBanner() {
  return (
    <div className="flex items-start justify-between p-[4px] bg-normalblue text-xs text-white">
      <div className="flex flex-row gap-2 ml-3">
        <div>Fast Responses</div>
        <div>Fair Prices</div>
      </div>
      <div className="sm:block hidden">
        Unbeatable prices right here!Join the KRIFFE family.{" "}
        <span className="text-lightbrown font-bold   text-center">
          Subscribe to our newsletter
        </span>
      </div>
      <div className="flex flex-row gap-3 mr-3">
        <div>Ghana</div>
        <div>
          <Dropdown inline label="EN">
            <Dropdown.Item>English</Dropdown.Item>
          </Dropdown>
        </div>
        <div>GHS</div>
      </div>
    </div>
  );
}

export default TopBanner;
