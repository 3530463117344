import React, { createContext, useContext, useState } from "react";


const MarketplaceNavigation = createContext();


export function MarketplaceNavigationProvider({ children }) {
 
  const [type, setType]=useState(() => {
    const storedtype = localStorage.getItem('marketnav');
    return storedtype ? JSON.parse(storedtype) : null;
  });

// const [type, setType] =useState([])
  

 


  const faqlog=(faq)=>{
    localStorage.setItem('marketnav', JSON.stringify(faq)); 
    setType(faq);
    
  }
  


  

  return (
    <MarketplaceNavigation.Provider
      value={{ type,faqlog }}
    >
      {children}
    </MarketplaceNavigation.Provider>
  );
}

// Create a custom hook to use the context
export function useMarketplaceNavigation() {
  return useContext(MarketplaceNavigation);
}
