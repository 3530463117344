import React, { useState } from "react";
import { Button, Modal } from "flowbite-react";
import RegisterForm from "../Forms/RegisterForm";
import LoginForm from "../Forms/LoginForm";
import { useAuth } from "../../context/AuthContext";

function AuthenticationModal({mode,onLogin,modem}){
    const { isModalVisible,toggleModal } = useAuth(); 
    const[modal,setModal]=useState(false);
    const[isregistered,setRegistered]=useState(false);
    const[isloggedin,setLoggedin]=useState(false);
   
    const handlemodalswitch=()=>{
        setModal(!modal);
      }
    
      const handleRegister=(data)=>{
            setRegistered(true);
            setModal(false);
            console.log("regsiter:"+data.fullname);
      }
      
    
    return(
        <Modal show={isModalVisible} size="md" popup onClose={toggleModal}>
        <Modal.Header />
        <Modal.Body>
        {
                modal?(
                <div>
                    <RegisterForm onRegister={handleRegister} onSwitch={handlemodalswitch}/>
                    
                </div>

            ):(
                <div>
                    <LoginForm onSwitch={handlemodalswitch}/>
                </div>

            )
            
        }
          
        </Modal.Body>
      </Modal>
    
    );
}
export default AuthenticationModal;