import React, { createContext, useContext, useState } from "react";

const MarketplaceContext = createContext();

export const useMarketplaceContext = () => {
  return useContext(MarketplaceContext);
};
function markedProductsSavedStatus(productData, saveditem) {
  if (!productData || productData.length === 0) {
    // Handle the case when productData is empty
    return [];
  }

  // If productData is not empty, proceed with mapping and marking saved status
  return productData.map((product) => {
    // Check if the id of the current product is present in saveditem
    const isSaved = saveditem.some((savedAd) => savedAd.id === product.id);

    // Return a new object with the existing product properties and the isSaved property
    return {
      ...product,
    };
  });
}

export const MarketplaceProvider = ({ children }) => {
  const [products, setProductData] = useState([]);
  const [saveditems, setSaved] = useState([]);
  const markedProductsData = markedProductsSavedStatus(products, saveditems);
  const [temporaryAdInfo, setTemporaryAdInfo] = useState({
    name: "",
    description: "",
    price: null,
    location: "",
    category:null,
    condition:null,
    // ad_type_id:null,
    product_details: [],
    tags: [],
    product_images: [],
    // ad_price:null
  });

  const [temporarypayload, setTemporaryPayload] = useState({
    name: "",
    description: "",
    price: null,
    location: "",
    category:null,
    condition:null,
    // ad_type_id:null,
    product_details: [],
    tags: [],
    product_images: [],
  });

  const [ads, setAdsData] = useState([])

  const getproduct=(products)=>{
    setProductData(products);

  }

  const addAds = (product) => {
    setAdsData(product);
  };

  const getProductById = (id) => {
    return markedProductsData.find((product) => product.id === id);
  };

  const getProductByCategory = (id) => {
    return markedProductsData.filter((item) => item.category === markedProductsData.find((x) => x.id === id).category && item.id !== id);;
  };

  const getAdsById = (id) => {
    return ads.find((product) => product.id === id);
  };

  const addSavedItems = (product) => {
    setSaved( product);
    // const isAlreadySaved = saveditems.some(
    //   (savedAd) => savedAd.id === product.id
    // );
    // if (!isAlreadySaved) {
    //   setSaved((prevSaved) => [...prevSaved, product]);
    // }
  };
  const removeSavedItems = (id) => {
    setSaved((prevInfo) => prevInfo.filter((saved) => saved.id !== id));
  };

  const addTemporaryAdInfo = (newData) => {
    setTemporaryAdInfo((prevData) => {
      const updatedData = {
        name: newData.name || prevData.name,
        description: newData.description || prevData.description,
        price: newData.price || prevData.price,
        category: newData.category || prevData.category,
        condition: newData.condition || prevData.condition,
        location: newData.location || prevData.location,
        product_details: newData.product_details || prevData.product_details,
        tags: newData.tags || prevData.tags,
        // ad_type_id: newData.ad_type_id || prevData.ad_type_id,
        // ad_price: newData.ad_price || prevData.ad_price,
        product_images: newData.product_images || prevData.product_images,
      };

      return updatedData;
    });
  };

  // Function to clear the temporary ticket information
  const clearTemporaryAdInfo = () => {
    setTemporaryAdInfo([]);
  };

  const addTemporaryPayload = (newData) => {
    setTemporaryPayload((prevData) => {
      const updatedData = {
        name: newData.name || prevData.name,
        description: newData.description || prevData.description,
        price: newData.price || prevData.price,
        category: newData.category || prevData.category,
        condition: newData.condition || prevData.condition,
        location: newData.location || prevData.location,
        product_details: newData.product_details || prevData.product_details,
        tags: newData.tags || prevData.tags,
        ad_type_id: newData.ad_type_id || prevData.ad_type_id,
        product_images: newData.product_images || prevData.product_images,
      };

      return updatedData;
    });
  };

  // Function to clear the temporary ticket information
  const clearTemporaryPayload = () => {
    setTemporaryPayload([]);
  };

  const [categories,setCategories]=useState([])
  const [price,setPrice]=useState([{id:1,name:"0-100",min:1,max:100},{id:2,name:"101-300",min:100,max:300},{id:3,name:"301-500",min:300,max:500},{id:4,name:"501-800",min:500,max:500},{id:5,name:"801-999",min:800,max:999},{id:6,name:"1000+",min:1000,max:null}])
  const [flexibility,setFlexibility]=useState([]);
  const [condition,setCondition]=useState([]);
  const [style,setStyle]=useState([]);
  const [format,setFormat]=useState([]);
  const [language,setLanguage]=useState([]);
  const [material,setMaterial]=useState([]);
  const [agegroup,setAge]=useState([]);
  const [location,setLocation]=useState([]);
  const [adtype,setAdtype]=useState([]);

  return (
    <MarketplaceContext.Provider
      value={{
        products,
        saveditems,
        addAds,
        ads,
        getproduct,
        getAdsById,
        getProductById,
        getProductByCategory,
        addSavedItems,
        removeSavedItems,
        markedProductsData,
        temporaryAdInfo,
        addTemporaryAdInfo,
        clearTemporaryAdInfo,
        categories,setCategories,
        price,flexibility,setFlexibility,condition,setCondition,style,setStyle,format,setFormat,language,setLanguage,material,setMaterial,agegroup,setAge,location,setLocation,temporarypayload,addTemporaryPayload,clearTemporaryPayload,adtype,setAdtype
      }}
    >
      {children}
    </MarketplaceContext.Provider>
  );
    }
