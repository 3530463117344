import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import MainPagination from "../../../components/Miscellanous/MainPagination";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";
import { Table, Badge, Spinner, Button,Tabs } from "flowbite-react";
import FAQModal from "../../../components/Modal/Admin/FAQModal";
import FAQEditModal from "../../../components/Modal/Admin/FAQEditModal";
import FAQDeleteModal from "../../../components/Modal/Admin/FAQDeleteModal";
import FAQNewModal from "../../../components/Modal/Admin/FAQNewModal";
import FAQCAtegoryModal from "../../../components/Modal/Admin/FAQCategoryModal";
import FAQEditCategoryModal from "../../../components/Modal/Admin/FAQEditCategoryModal";

function FAQ() {
  const { token, adminlogout } = useAuth();
  const [faqdata, setUsers] = useState([]);
  const [faqcat, setCategories] = useState([]);
  const navigate = useNavigate();
  const loadedOnce = useRef(false);
  const [id, setId] = useState(null);
  const [data, setData] = useState(null);

  const initialPayload = {
    currentpage: 1,
  };

  const [formData, setFormData] = useState(initialPayload);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setModal] = useState(false);

  const handleModal = (id) => {
    setId(id);
    setModal(!open);
  };

  const [edit, setEModal] = useState(false);

  const handleEditModal = (id) => {
    setId(id);
    setEModal(!edit);
  };

  const [del, setDModal] = useState(false);

  const handleDeleteModal = (id) => {
    setId(id);
    setDModal(!del);
  };

  const [add, setAModal] = useState(false);

  const handleAddModal = () => {
    setAModal(!add);
  };

  const [addc, setACModal] = useState(false);

  const handleAddCModal = () => {
    setACModal(!addc);
  };

  const [cedit, setECModal] = useState(false);

  const handleEditCModal = (data,id) => {
    setId(id);
    setData(data)
    setECModal(!cedit);
  };


  const handleParamChange = (name, value) => {
    setFormData((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
    loadedOnce.current = false;
  };
  const [per_page, setPerpage] = useState(20);
  const [total, setTotal] = useState(faqdata ? faqdata.length : 0);
  const itemsPerPage = faqdata && per_page;

  const itemsToShow = faqdata;

  const totalPages = faqdata && total > 0 && Math.ceil(total / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    handleParamChange("currentpage", newPage);
  };

  const [per_pages, setPerpages] = useState(20);
  const [totals, setTotals] = useState(faqcat ? faqcat.length : 0);

  const itemsPerPages = faqcat && per_pages;
  const [currentPages, setCurrentPages] = useState(1);

  const itemToShow = faqcat;

  const totalPage = faqcat && totals > 0 && Math.ceil(totals / itemsPerPages);

  const handlePagesChange = (newPage) => {
    setCurrentPages(newPage);
    handleParamChange("currentpage", newPage);
  };

  useEffect(() => {
    if (loadedOnce.current) return;
    loadedOnce.current = true;
    handletotalevent();
    handletotalcategories();
  }, [loadedOnce, formData, currentPage]);

  const url = process.env.REACT_APP_API_URL;

  

  const handletotalevent = async () => {
    const additionalPath = `${url}/faqs`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { headers: headers })
      .then((response) => {
        setUsers(response.data.faqs.product);
        // setPerpage(response.data.faqs.per_page);
        // setTotal(response.data.faqs.product.length);
        // setTo(response.data.faqs.to);
        // setFrom(response.data.faqs.from);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          adminlogout();
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            adminlogout();
            navigate("/admin");
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            console.log(err);

            // Rethrow the error for further handling if needed
          }
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };
  const handletotalcategories = async () => {
    const additionalPath = `${url}/faq_categories`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { headers: headers })
      .then((response) => {
        setCategories(response.data.faq_categories);
        console.log(response.data)
        loadedOnce.current=true;
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          adminlogout();
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            adminlogout();
            navigate("/admin");
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            console.log(err);

            // Rethrow the error for further handling if needed
          }
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  return (
    <div className="px-3">
      <div className="flex flex-row justify-between mt-2">
        <p className="font-bold text-xl">Events</p>

        <div className="flex flex-row gap-2">
          <Button
            className="bg-normalblue text-white rounded-md"
            onClick={() => handleAddModal()}
          >
            Add New FAQ
          </Button>
          <Button
            className="border border-normalblue text-normalblue bg-white rounded-md"
            onClick={() => handleAddCModal()}
          >
            Add New FAQ Category
          </Button>
        </div>
      </div>

      <Tabs.Group aria-label="Tabs with underline" style="underline">
          <Tabs.Item active title="FAQS">
          <div className="mt-4  xl:block hidden">
        <Table>
          <Table.Head>
            <Table.HeadCell>Question</Table.HeadCell>
            <Table.HeadCell>Answer</Table.HeadCell>
            <Table.HeadCell>Category Name</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current == true ? (
              itemsToShow && itemsToShow.length != 0 ? (
                itemsToShow.map((userData, index) => (
                  <Table.Row
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                    key={index}
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {userData.question}
                    </Table.Cell>
                    <Table.Cell>{userData.answer}</Table.Cell>
                    <Table.Cell>{userData.category_name}</Table.Cell>
                    <Table.Cell>
                      <div className="flex  gap-2">
                        <a
                          className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                          href="#"
                          onClick={() => handleModal(userData)}
                        >
                          <p>View</p>
                        </a>

                        <a
                          className="font-medium text-normalbrown hover:underline dark:text-cyan-500"
                          href="#"
                          onClick={() => handleEditModal(userData)}
                        >
                          <p>Edit</p>
                        </a>
                        <a
                          className="font-medium text-normalbrown hover:underline dark:text-cyan-500"
                          href="#"
                          onClick={() => handleDeleteModal(userData.id)}
                        >
                          <p>Delete</p>
                        </a>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={6} className="bg-white text-center">
                    No FAQs
                  </Table.Cell>
                </Table.Row>
              )
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  <div className="flex justify-center items-center h-full">
                    <Spinner aria-label="Loading" color="warning" size="md" />
                  </div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>

      <div className="mt-4  md:block  xl:hidden hidden ">
        <Table>
          <Table.Head>
            <Table.HeadCell>Question</Table.HeadCell>
            <Table.HeadCell>Answer</Table.HeadCell>
            <Table.HeadCell>Category Name</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current == true ? (
              itemsToShow && itemsToShow.length != 0 ? (
                itemsToShow.map((userData, index) => (
                  <Table.Row
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                    key={index}
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {userData.question}
                    </Table.Cell>

                    <Table.Cell>{userData.answer}</Table.Cell>

                    <Table.Cell>{userData.category_name}</Table.Cell>
                    <Table.Cell>
                      <div className="flex  gap-2">
                        <a
                          className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                          href="#"
                          onClick={() => handleModal(userData)}
                        >
                          <p>View</p>
                        </a>

                        <a
                          className="font-medium text-normalbrown hover:underline dark:text-cyan-500"
                          href="#"
                          onClick={() => handleEditModal(userData)}
                        >
                          <p>Edit</p>
                        </a>
                        <a
                          className="font-medium text-normalbrown hover:underline dark:text-cyan-500"
                          href="#"
                          onClick={() => handleDeleteModal(userData.id)}
                        >
                          <p>Delete</p>
                        </a>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={6} className="bg-white text-center">
                    No FAQs
                  </Table.Cell>
                </Table.Row>
              )
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  <div className="flex justify-center items-center h-full">
                    <Spinner aria-label="Loading" color="warning" size="md" />
                  </div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>

      <div className="mt-5  md:hidden lg:hidden xl:hidden block">
        <Table>
          <Table.Head>
            <Table.HeadCell>Question</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current == true ? (
              itemsToShow && itemsToShow.length != 0 ? (
                itemsToShow.map((userData, index) => (
                  <Table.Row
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                    key={index}
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {userData.question}
                    </Table.Cell>

                    <Table.Cell>
                      <div className="flex  gap-2">
                        <a
                          className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                          href="#"
                          onClick={() => handleModal(userData)}
                        >
                          <p>View</p>
                        </a>

                        <a
                          className="font-medium text-normalbrown hover:underline dark:text-cyan-500"
                          href="#"
                          onClick={() => handleEditModal(userData)}
                        >
                          <p>Edit</p>
                        </a>
                        <a
                          className="font-medium text-normalbrown hover:underline dark:text-cyan-500"
                          href="#"
                          onClick={() => handleDeleteModal(userData.id)}
                        >
                          <p>Delete</p>
                        </a>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={6} className="bg-white text-center">
                    No FAQs
                  </Table.Cell>
                </Table.Row>
              )
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  <div className="flex justify-center items-center h-full">
                    <Spinner aria-label="Loading" color="warning" size="md" />
                  </div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>

      <MainPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
            </Tabs.Item>

            <Tabs.Item  title="Categories">
            <div className="mt-4 ">
        <Table>
          <Table.Head>
            <Table.HeadCell>Name</Table.HeadCell>

            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current == true ? (
              itemToShow && itemToShow.length != 0 ? (
                itemToShow.map((userData, index) => (
                  <Table.Row
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                    key={index}
                  >
                
                    <Table.Cell>{userData.category_name}</Table.Cell>
                    <Table.Cell>
                      <div className="flex  gap-2">
                       
                        <a
                          className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                          href="#"
                          onClick={() => handleEditCModal(userData,index+1)}
                        >
                          <p>Edit</p>
                        </a>
                      
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={6} className="bg-white text-center">
                    No Categories
                  </Table.Cell>
                </Table.Row>
              )
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  <div className="flex justify-center items-center h-full">
                    <Spinner aria-label="Loading" color="warning" size="md" />
                  </div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>

      

     

      <MainPagination
        currentPage={currentPages}
        totalPages={totalPage}
        onPageChange={handlePagesChange}
      />
            </Tabs.Item>
            </Tabs.Group>

      {/* table */}
      

      {open && <FAQModal id={id} open={open} handleModal={handleModal} />}
      {edit && (
        <FAQEditModal id={id} open={edit} handleModal={handleEditModal} />
      )}
      {del && (
        <FAQDeleteModal id={id} open={del} handleModal={handleDeleteModal} />
      )}
      {add && <FAQNewModal open={add} handleModal={handleAddModal} />}
      {addc && <FAQCAtegoryModal open={addc} handleModal={handleAddCModal} />}

      {cedit && <FAQEditCategoryModal id={id} data={data} open={cedit} handleModal={handleEditCModal} />}
    
    </div>
  );
}

export default FAQ;
