import React, { useState, useRef } from 'react';
import { FileInput,Button } from 'flowbite-react';
import { useMarketplaceContext } from '../../../context/MarketplaceContexts';


const EventImageUpload = ({selectedFiles,setSelectedFiles}) => {
  
  const fileInputRef = useRef(null);
  const maxFileCount = 3; // Maximum number of files allowed
  const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif']; // Allowed file extensions
  const maxFileSize = 1024 * 1024; // Maximum file size in bytes (1MB)
  const [error,setError]=useState("");
  const {temporaryAdInfo}=useMarketplaceContext();

  const handleFileChange = (e) => {
    const files = e.target.files;
  
    if (selectedFiles.length + files.length > maxFileCount) {
      setError(`You can only select up to ${maxFileCount} files.`);
      fileInputRef.current.value = '';
    } else {
      const newFiles = [];
  
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
  
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
          setError(`File with extension .${fileExtension} is not allowed.`);
          continue;
        }
  
        if (file.size > maxFileSize) {
          setError('File size exceeds the maximum limit of 1MB.');
          continue;
        }
  
        newFiles.push(file);
        setError("");
      }
  
      setSelectedFiles((prevSelectedFiles) => [
        ...prevSelectedFiles,
        ...newFiles.slice(0, maxFileCount - prevSelectedFiles.length)
      ]);
     
     
    }
  };

  const removeFile = (fileIndex) => {
    setSelectedFiles((prevSelectedFiles) =>
      prevSelectedFiles.filter((_, index) => index !== fileIndex)
    );

    // Check if the array is empty and clear the file input if so
    if (selectedFiles.length === 1) {
      fileInputRef.current.value = '';
    }
  };



  
  return (
    <div >
      <FileInput
        helperText={error!=""?(<p className="text-red-600 font-xl font-bold">{error}</p>):("Images allowed png,jpg,jpeg")}
        id="file"
        class=" block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full "
        type="file"
        multiple
        accept=".jpg, .jpeg, .png"
        onChange={handleFileChange}
        ref={fileInputRef}
        name="product_images"
     
        
      />
      {temporaryAdInfo.product_images&&temporaryAdInfo.product_images.length > 0?( 
        <div className="mt-4">
          <p>Selected Files:</p>
          <ul className='flex gap-2'>
            {temporaryAdInfo.product_images.map((file, index) => (
                <div key={index} className="border border-normalbrown text-normalbrown p-2 rounded-md bg-normalbrown/20 flex flex-row justify-between items-center "><p>{file.name} ({(file.size / 1024).toFixed(2)} KB)</p><Button className="text-normalbrown text-center" onClick={() => removeFile(index)} >X</Button></div>
              
            ))}


          </ul>
        </div>
      ):(selectedFiles.length > 0 && (
        <div className="mt-4">
          <p>Selected Files:</p>
          <ul className='flex lg:flex-row flex-col gap-2'>
            {selectedFiles.map((file, index) => (
                <div key={index} className="border border-normalbrown text-normalbrown p-2 rounded-md bg-normalbrown/20 flex flex-row justify-between items-center "><p>{file.name} ({(file.size / 1024).toFixed(2)} KB)</p><Button className="text-normalbrown text-center" onClick={() => removeFile(index)} >X</Button></div>
              
            ))}


          </ul>
        </div>
      ))}
      
    </div>
  );
};

export default EventImageUpload;






