 import React from "react";
import Donut from "../../../components/Charts/Admin/Donut";
import PieChart from "../../../components/Charts/Admin/PieChart";
import RadialBar from "../../../components/Charts/Admin/RadialBar";
import Category from "../../../components/Charts/Admin/Category";

 function Dashboard(){
    return(
        <div className="px-3">
            <p className="font-bold text-xl">Dashboard</p>

           
                <div className="flex lg:flex-row flex-col  justify-between mt-4 gap-4">
                    <div className="basis-1/2 bg-white h-full p-2 rounded-md">
                        <p className="font-bold text-center">Ads Statisitcs</p>
                        <Donut/>
                    </div>
                    <div className="basis-1/2 bg-white h-full p-2 rounded-md">
                    <p className="font-bold text-center">Event Statisitcs</p>
                    <PieChart/>
                    </div>

                </div>

                <div className="flex lg:flex-row flex-col  justify-between mt-4 gap-4">
                <div className="basis-1/2 bg-white h-full p-2 rounded-md">
                <p className="font-bold text-center">User Statisitcs</p>
                <RadialBar/>
                </div>
                    <div className="basis-1/2 bg-white h-full p-2 rounded-md">
                    <p className="font-bold text-center">Organizer Requests</p>
                    <Category/>
                    </div>
                    
                </div>
       
            </div>
    )
 }

 export default Dashboard