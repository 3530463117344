import React, { useEffect, useState,useRef } from "react";
import { Button, Modal,Spinner } from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../context/AuthContext";

function MessageModal({id,open,handleModal}){
    const {token,adminlogout}=useAuth();
    const [user, setUser] = useState([]);
    const [payload, setPayload] = useState({ contact_id: id.id });
    const loadedOnce = useRef(false);
    const navigate=useNavigate();

     const url =process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/read_form`;
  // const path=`get_product_by_id?id=${id}`;
  // makeApiRequest("GET", null, path, token);
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  useEffect(() => {
    // Make the first API call
    // handleevent();

    if (loadedOnce.current) return;
    loadedOnce.current = true;
    handleevent()
  }, [loadedOnce]);

  const handleevent=async()=>{
    await axios
        .patch(additionalPath, payload,{ params: payload, headers: headers })
        .then((response) => {
          console.log("Read");
          
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
           
            console.error(err)
 
            adminlogout();
            navigate("/admin");
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            console.log(err);
    

            // Rethrow the error for further handling if needed
          }
        });

  }
    
    return(
    <div>
        <Modal show={open} size="xl" popup onClose={handleModal}>
        <Modal.Header>Message Details</Modal.Header>
        <Modal.Body>
            <div className="relative">
                <div>
        <strong>Full Name:</strong> {id.name}
      </div>
      <div>
        <strong>Email:</strong> {id.email}
      </div>
      <div>
        <strong>Subject:</strong> {id.subject}
      </div>
      <div>
        <strong>Message:</strong> {id.message}
      </div>
     
     
     

             
         
           
            {/* {loadedOnce && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md">
            {/* Your spinner component <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
          </div>
        </div>
       */}
           </div>
           
            
           
        </Modal.Body>
      </Modal>

    </div>)
}

export default MessageModal;