import { Button } from 'flowbite-react';
import React, { useState } from 'react';
import { useMarketplaceContext } from '../../../context/MarketplaceContexts';

function TagForm({tags,setTags}) {

const {temporaryAdInfo}=useMarketplaceContext();
  
  const [tagInput, setTagInput] = useState('');

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  
  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter' && tagInput.trim() !== '') {
      setTags([...tags, tagInput]);
      setTagInput(''); // Clear the input field
    }
  };

  const handleRemoveTag = (index) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1);
    setTags(updatedTags);
  };

  return (
    <div>
      <div className="flex flex-row items-center ">
        <input
          type="text"
          placeholder="Add a tag"
          value={tagInput}
          onChange={handleTagInputChange}
          
          onKeyDown={handleInputKeyPress}
          class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
        />
       
      </div>
      <div>
      <ul className='flex flex-wrap gap-1'>
       
{tags&&tags.length>0?( tags.map((tag, index) => (
          <div key={index} className="border border-normalbrown text-normalbrown p-2 rounded-md bg-normalbrown/20 flex flex-row gap-2 items-center ">
            <p>{tag}</p>
            <Button onClick={() => handleRemoveTag(index)} className='text-normalbrown text-center h-4 basis-1/4'>X</Button>
          </div>
        ))):(<div>
          <p className='text-darkgray'>Loading automated tags.....
          </p>
        </div>)}
       
        
        </ul>
      </div>
    </div>
  );
}

export default TagForm;
