import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../context/AuthContext";

function OrganizerModal({ id, open, handleModal }) {
  const { token, adminlogout } = useAuth();

  const navigate = useNavigate();

  return (
    <div>
      <Modal show={open} size="xl" popup onClose={handleModal}>
        <Modal.Header>User Details</Modal.Header>
        <Modal.Body>
          <div className="relative">
            <div>
              <strong>Company Name:</strong> {id.fullname}
            </div>
            <div>
              <strong>Email:</strong> {id.companyemail}
            </div>
            <div>
              <strong>Phone Number:</strong> {id.companyphonenumber?id.companyphonenumber:"N/A"}
            </div>
            <div>
              <strong>Company Location:</strong> {id.companylocation}
            </div>
            <div>
              <strong>Bank Name:</strong> {id.Bank_name?id.Bank_name:"N/A"}
            </div>
            <div>
              <strong>Bank Account Number:</strong> {id.Bank_account_number}
            </div>
            <div>
              <strong>Bank Account Name:</strong> {id.Bank_account_name}
            </div>
            <div>
              <strong>Ghana Card:</strong> {id.ghanacard}
            </div>
            <div>
              <strong>Momo Number:</strong> {id.momo_number!=''?id.momo_number:"N/A"}
            </div>
            <div>
              <strong>Social Media:</strong>
              <ul>
                {id.social_media &&
                  id.social_media.map((item, index) => (
                    <li key={index}>
                      {Object.entries(item).map(([platform, url]) => (
                        <div key={platform}>
                          <strong>{platform}:</strong> {url}
                        </div>
                      ))}
                    </li>
                  ))}
              </ul>
            </div>

            <div>
              <strong>Status:</strong> {id.status}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default OrganizerModal;
