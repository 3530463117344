import React, { useEffect, useState } from "react";
import { Button, Modal,Spinner } from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../context/AuthContext";
import AdCarousel from "../../Carousels/AdCarousel";

function WithdrawalModal({id,open,handleModal}){
    const {token,adminlogout}=useAuth();
    const [product, setUser] = useState([]);
    const [payload, setPayload] = useState({ withdrawal_id: id });
    const [loadedOnce, setLoadedOnce] = useState(true);
    const navigate=useNavigate();

     const url =process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/admin_get_withdrawal_by_id`;
  // const path=`get_product_by_id?id=${id}`;
  // makeApiRequest("GET", null, path, token);
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  useEffect(() => {
    // Make the first API call
    // handleevent();

    if (product.length == 0) {
      axios
        .get(additionalPath, { params: payload, headers: headers })
        .then((response) => {
          console.log(response.data.withdrawal);
          setUser(response.data.withdrawal);
          setLoadedOnce(false);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            adminlogout();
            navigate("/admin");
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            console.log(err);

            // Rethrow the error for further handling if needed
          }
        });
    }
  }, [product, payload, headers]);


    return(
    <div>
        <Modal show={open} size="xl" popup onClose={handleModal}>
        <Modal.Header>{product.name}</Modal.Header>
        <Modal.Body>
            <div className="relative">
            <div className="flex gap-2 flex-col">
            
        <div>
        <strong>Requested By:</strong> {product.requested_by}
      </div>
      <div>
        <strong>Amount:</strong> {product.amount_withdrawn}
      </div>
      <div>
        <strong>Payment Method:</strong> {product.payment_method}
      </div>
      <div>
        <strong>Paid To:</strong> {product.paid_to?(product.paid_to):("N/A")}
      </div>
      <div>
        <strong>Date Paid:</strong> {product.date_paid?(product.date_paid):("N/A")}
      </div>
      <div>
        <strong>Status:</strong> {product.status}
      </div>
     
     
      
    </div>
     
     

             
         
           
            {loadedOnce && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md">
            {/* Your spinner component */}
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
          </div>
        </div>
      )}
           </div>


          
           
           
            
           
        </Modal.Body>
      </Modal>

    </div>)
}

export default WithdrawalModal;