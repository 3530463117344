import React,{useState,useEffect} from "react";
import TopBanner from "../../components/Navigation/TopBanner";
import NavigationBar from "../../components/Navigation/NavigationBar";
import Newsletter from "../../components/Footer/Newsletter";
import FixedFooter from "../../components/Footer/FixedFooter";
import SearchBarCollapse from "../../components/SearchBars/SearchBarCollapse";
import logo from "../../assets/images/KRIFFEONLINELOGOST.png";
import ContactForm from "../../components/Forms/ContactForm";
import FAQCard from "../../components/FAQs/FAQCard";
import FAQLoader from "../../components/FAQs/FaqLoader.js";
import { useAuth } from "../../context/AuthContext";
import {useFaq} from "../../context/FAQContext";

function ContactUs() {
  const { isLogged } = useAuth();
  const { faq } = useFaq();

 

  return (
    <div>
      <section className="w-screen  bg-midnightblue">
        <TopBanner />
        <NavigationBar logo={logo} color="text-white" status={isLogged}/>
        <SearchBarCollapse />
        <div className="flex lg:flex-row flex-col px-6 sm:h-[75%] h-full sm:gap-0 gap-4 ">
          <div className="flex flex-col sm:justify-center sm:basis-2/3">
            <p className="text-xs text-lightblue sm:block hidden">Contact us</p>
            <p className="text-white sm:text-5xl text-xl font-medium sm:mt-0 mt-2">
              Get in <span className="text-normalblue">Touch</span> with
              <span className="text-normalbrown"> us</span>
            </p>
            <p className="text-darkgray sm:text-base text-xs">
              We will get back to you in 2-5 days. Assisted and guided answers
              are in the FAQ section below
            </p>
            <div className="flex flex-col mt-4 gap-4">
              <div className="flex flex-row gap-2">
                <svg
                  class="sm:w-14 sm:h-14 h-6 w-6 text-lightblue "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="m19 2-8.4 7.05a1 1 0 0 1-1.2 0L1 2m18 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m18 0v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2"
                  />
                </svg>
                <p className="text-lightblue font-bold sm:text-xl text-sm">
                  Our Email
                  <div className="text-darkgray font-normal sm:text-sm text-xs">
                    info@kriffeonline.com
                  </div>
                </p>
              </div>
              <div className="flex flex-row gap-2">
                <svg
                  class="sm:w-14 sm:h-14 h-6 w-6 text-lightblue "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="m19 2-8.4 7.05a1 1 0 0 1-1.2 0L1 2m18 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m18 0v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2"
                  />
                </svg>
                <p className="text-lightblue font-bold sm:text-xl text-sm">
                  Our Phone Number
                  <div className="text-darkgray font-normal sm:text-sm text-xs">
                    +233 59 858 7073
                  </div>
                </p>
              </div>
              <div className="flex flex-row gap-2">
                <svg
                  class="sm:w-14 sm:h-14 h-6 w-6 text-lightblue "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="m19 2-8.4 7.05a1 1 0 0 1-1.2 0L1 2m18 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m18 0v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2"
                  />
                </svg>
                <p className="text-lightblue font-bold sm:text-xl text-sm">
                  Our Location
                  <div className="text-darkgray font-normal sm:text-sm text-xs">
                  188 Boundary Road,Spintex – Accra

                  </div>
                </p>
              </div>
            </div>
          </div>
          <div className="sm:basis-1/3  mb-4 lg:mb-0">
            <ContactForm/>
          </div>
        </div>
      </section>
      <section className="w-screen px-3 py-8">
        <div className="flex flex-col">
            <p className="text-midnightblue sm:text-4xl text-xl font-medium">
              Frequently <span className="text-normalblue">Asked</span>
              <span className="text-normalbrown"> Questionss</span>
            </p>
            <p className="text-sm text-darkgray">Get all your pressing issues answered and if you do not find what you are looking for, contact us on any of the lines above</p>
            <div className="grid sm:grid-cols-2 grid-rows-1 gap-y-8 mt-8 gap-x-2">
              <FAQLoader/>
              {Object.keys(faq).map((faqs)=>(
                faq[faqs].map((item,index)=>(
                  <FAQCard key={index} question={item.question} answer={item.answer}/>

                ))
               

              ))} 
                
             

            </div>
            


        </div>
      </section>
      <section className="w-screen">
        <Newsletter />
        <FixedFooter />
      </section>
    </div>
  );
}

export default ContactUs;
