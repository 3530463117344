import { Button } from "flowbite-react";
import React from "react";
import red from "../../assets/images/manandwoman.png";

function EventCarousel(){
    return(
        <div className="flex flex-row bg-lightblue h-[75%] sm:w-[97.5%] w-[92%] ml-4 rounded-lg mt-2 sm:mt-0 items-center pt-8 mb-8 sm:p-0 p-4">
            <div className="sm:basis-1/2 ">
                <img src={red} className="sm:block hidden h-full"/>
            </div>
            <div className="sm:basis-1/2">
            <p className="text-white font-bold text-4xl text-center">MADE FOR  <br></br>THOSE WHO DO</p>
            <p className="text-white text-center">Fellowship and grow with like-minded individuals</p>
            </div>
        </div>

    );
}
export default EventCarousel;