import React, { useState,useEffect } from "react";
import { Button, Checkbox , Label, Modal, TextInput } from "flowbite-react";
import { useAuth } from "../../context/AuthContext";
import { useApi } from "../../api/APIProvider";
import { useNavigate } from "react-router-dom";

function ForgotPasswordForm({ token }) {
  const { logout } = useAuth();
  const { makeApiRequest, data, error } = useApi();
  const navigate=useNavigate();
  const [checksimilarity, setCheck] = useState(false);
  const [emailcheck, setEmail] = useState(false);
  const [errors, setError] = useState(false);
  const [Msg, setMsg] = useState(false);
  const [MsgE, setMsgE] = useState(false);
  const [formData, setFormData] = useState({
    password: '',
    password1: '',
  });


  

 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if ((name === 'password' & value === formData.password1)||(name === 'password1' & value === formData.password)) {
      setCheck(true);
      console.log('Passwords match.');
      
    }

    else {
      console.log("Input values are different.");
      setError(true);
      console.log('Passwords do not match.');
    }
  };

  useEffect(() => {
    if (data &&data.usertoken&&data.repsonsecode==200) {
      setMsg(true);
      logout();
      navigate("/")
      
    }
   
    if(error){
      setMsgE(true)
    }
  }, [ data,error]);






  const handlePassword = () => {

    if(checksimilarity){

      const additionalPath="reset_password";
      const user={
        confirmationcode:token,
      password:formData.password
    };
    console.log(user);
      makeApiRequest("POST",user,additionalPath);
  
    }
    

  };

 
  return (
    <div>
      {Msg&&<div className="bg-green-100 p-2 rounded-md border-l-4 border-green-600">
        <p className="text-green-600 font-bold text-sm">Password Changed. Please login </p>
       
      </div>}
      {MsgE&&<div className="bg-red-100 p-2 rounded-md border-l-4 border-red-600">
        <p className="text-red-600 font-bold text-sm">{error}</p>
       
      </div>}

    
        <div className="space-y-6 w-[30rem]">
              <div >
                <TextInput
                  id="newpassword"
                  placeholder="New Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  name="password"
                  type="password"
                  sizing="lg"
                  class={checksimilarity?("block mb-2 text-sm font-medium text-green-600 border-green-600 bg-green-100 rounded-md w-full sm:h-14 h-10 p-1"):("block mb-2 text-sm font-medium text-darkgray border-none bg-lightgray rounded-md w-full sm:h-14 h-10 p-1")}
                  required
                />
              </div>
              <div>
                <TextInput
                  id="newpassword1"
                  placeholder="Repeat Password"
                  value={formData.password1}
                  onChange={handleInputChange}
                  name="password1"
                  type="password"
                  sizing="lg"
                  helperText={checksimilarity?<p className="text-green-600 font-medium">Passwords Match</p>:null}
                  class={checksimilarity?("block mb-2 text-sm font-medium text-green-600 border-green-600 bg-green-100 rounded-md w-full sm:h-14 h-10 p-1"):("block mb-2 text-sm font-medium text-darkgray border-none bg-lightgray rounded-md w-full sm:h-14 h-10 p-1")}
                  required
                />
     
            </div>
          
          <div className="flex w-full">
            <Button
              onClick={handlePassword}
              className="bg-normalblue w-1/2 p-1"
            >
              Change Password
            </Button>
          </div>
        </div>
     
    </div>
  );
}

export default ForgotPasswordForm;
