import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { useEventContexts } from "../../../context/EventContexts";
import logo from "../../../assets/images/KRIFFEONLINELOGO2.png";
import TopBanner from "../../../components/Navigation/TopBanner";
import NavigationBar from "../../../components/Navigation/NavigationBar";
import SearchBarCollapse from "../../../components/SearchBars/SearchBarCollapse";
import { format, parse } from "date-fns";
import axios from "axios";
import FixedFooter from "../../../components/Footer/FixedFooter";
import Newsletter from "../../../components/Footer/Newsletter";
import EventsCard from "../../../components/Cards/EventsCard";
import SimilarEvents from "../../../components/Miscellanous/SimilarEvents";
import { Spinner } from "flowbite-react";

function EventsDetails() {
  const { isLogged, token, logout } = useAuth();
  const { getEventById } = useEventContexts();
  const navigate = useNavigate;
  const { id } = useParams();
  const [err, setErr] = useState("");
  const [event, setEvent] = useState([]);
  const [similarevent, setSimilar] = useState([]);
  const [similarevents, setSimilarEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleLoadingModal = () => {
    setLoading(!loading);
  };
  const [payload, setPayload] = useState({ event_id: id });

  const url =process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/get_event_by_id`;
  // const path=`get_product_by_id?id=${id}`;
  // makeApiRequest("GET", null, path, token);
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  useEffect(() => {
    // Make the first API call
    // handleevent();

    if (event.length == 0) {
      axios
        .get(additionalPath, { params: payload, headers: headers })
        .then((response) => {
          console.log(response.data.event);
          setEvent(response.data.event);
          handleevent(response.data.event.createdby);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            logout();
            navigate("/")
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            console.log(err);
            setErr(err);

            // Rethrow the error for further handling if needed
          }
        });
    }
  }, [event, payload, headers]);

  // useEffect(() => {
  //   // Make the first API call
  //   // handleevent();

  // }, []);

  const handleevent = (createdby) => {
    const initialpayload = {
      event_id: id,
      id: createdby,
    };
    const formdata = initialpayload;

    console.log(formdata);
    const additionalPath = `${url}/get_events_for_user`;
    // const path=`get_product_by_id?id=${id}`;
    // makeApiRequest("GET", null, path, token);
    const headers = {
      "Content-Type": "application/json",
      // 'Authorization':  `Bearer ${token}`,
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .get(additionalPath, { params: formdata, headers: headers })
      .then((response) => {
        const allEvents = response.data.events.data;

      // Slice the top 5 events
      const top5Events = allEvents.slice(0, 4);

 
      setSimilar(top5Events);
      console.log(allEvents);
      setSimilarEvents(allEvents)
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
          setErr(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  const parsedTime = event.time && parse(event.time, "HH:mm:ss", new Date());

  // Format the time in 12-hour clock with AM/PM indicator
  const formattedTime = parsedTime && format(parsedTime, "h:mm a");
  const givenDateTimeString = `${event.date}T${event.time}`;

// Convert the combined string to a JavaScript Date object
const givenDateTime = new Date(givenDateTimeString);

// Get the current date and time
const currentDateTime = new Date();

// Check if the given date and time has passed
const hasPassed = givenDateTime < currentDateTime;

  return (
    <div>
      <section className="w-screen">
        <TopBanner />
        <NavigationBar
          logo={logo}
          color="text-midnightblue"
          status={isLogged}
        />
        <SearchBarCollapse />

        <a href="/events" className="text-normalblue px-3 text-sm pt-12 ">
          {`<`}Go back
        </a>
      </section>
      <section className="w-screen px-6 mt-2 space-y-6 relative">
        <div>
        <div className="flex flex-col pt-3 ">
          <div className="w-full border bg-midnightblue  rounded-md ">
            <img alt="Image" className=" h-[20rem] rounded-md" src="" />
          </div>

          <div className="flex sm:flex-row flex-col gap-4 justify-between">
            <div>
              <p className="text-sm text-normalblue">
                {" "}
                {event.date && format(new Date(event.date), "EE, MMM do")},
                {formattedTime}
              </p>
              <p className="font-bold sm:text-7xl text-5xl">{event.name}</p>
              <div className="flex justify-center rounded-full p-2 bg-lightblue/40">
                <p className="text-normalblue font-medium text-sm">
                  Organized by {event.host}
                </p>
              </div>
            </div>

            <div className="basis-1/4 border border-lightgray p-6 rounded-md flex gap-2 flex-col text-center my-2">
              <p>Get Tickets from GHS 0.00</p>
              {
                // date has passed, show a gray button that says Unavailable
                hasPassed?(<a
                  className="bg-normalbrown/50 p-3 rounded-md text-white text-center "
                  
                >
                  Unavailable
                </a>):(<a
                  className="bg-normalbrown p-3 rounded-md text-white text-center"
                  href={`/eventspurchase/${id}`}
                >
                  Buy Tickets
                </a>)
              }
              

              <p className="text-darkgray text-sm">No Refunds</p>
            </div>
          </div>
        </div>
        <div className="flex sm:flex-row flex-col gap-4 justify-between">
          <div className="basis-3/4">
            <p className="text-xl font-bold">About this event</p>
            <p className="text-midnightblue  w-full text-justify">{event.description}</p>

            <p className="text-xl font-bold mt-4">Date and Time</p>
            <p>
              {event.date && format(new Date(event.date), "EEEE, MMMM do yyyy")}
              ,{formattedTime}
            </p>

            <p className="text-xl font-bold mt-4">Location</p>
            <p>{event.location}</p>
          </div>
          <div className="border border-gray p-4 rounded-md flex flex-col gap-1 basis-1/4 my-2">
            <div className="flex flex-row gap-1">
              <img className="rounded-full bg-midnightblue h-16 w-16" />
              <div className="flex flex-col justify-center basis-3/4">
                <p className="text-xs text-darkgray">Organizing Company</p>
                <p className="font-bold text-xl">{event.companyname}</p>
              </div>
            </div>

            <div className="flex flex-col mt-2">
              <p className="text-xs text-darkgray">Phone Number</p>
              <p className="text-normalblue">{event.companyphonenumber}</p>
            </div>

            {event.email && (
              <div className="flex flex-col mt-2">
                <p className="text-xs text-darkgray">Email Address</p>
                <p className="text-normalblue">{event.companyemail}</p>
              </div>
            )}

            {event.website && (
              <div className="flex flex-col mt-2">
                <p className="text-xs text-darkgray">Website Link</p>
                <p className="text-normalblue">{event.website}</p>
              </div>
            )}
          </div>
        </div>

        <div className="flex sm:flex-row flex-col justify-between">
          <div>
            <p className="font-bold text-xl">
              How do I contact the organizers about this event?
            </p>
            <div className="flex flex-row gap-1 mb-5 mt-4">
              <svg
                class="w-5 h-5 text-lightblue "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m16.344 12.168-1.4-1.4a1.98 1.98 0 0 0-2.8 0l-.7.7a1.98 1.98 0 0 1-2.8 0l-2.1-2.1a1.98 1.98 0 0 1 0-2.8l.7-.7a1.981 1.981 0 0 0 0-2.8l-1.4-1.4a1.828 1.828 0 0 0-2.8 0C-.638 5.323 1.1 9.542 4.78 13.22c3.68 3.678 7.9 5.418 11.564 1.752a1.828 1.828 0 0 0 0-2.804Z"
                />
              </svg>
              <p>{event.number}</p>
            </div>
            <div className="flex flex-row gap-1 mb-5 mt-4">
              <svg
                class="w-5 h-5 text-lightblue  "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m16.344 12.168-1.4-1.4a1.98 1.98 0 0 0-2.8 0l-.7.7a1.98 1.98 0 0 1-2.8 0l-2.1-2.1a1.98 1.98 0 0 1 0-2.8l.7-.7a1.981 1.981 0 0 0 0-2.8l-1.4-1.4a1.828 1.828 0 0 0-2.8 0C-.638 5.323 1.1 9.542 4.78 13.22c3.68 3.678 7.9 5.418 11.564 1.752a1.828 1.828 0 0 0 0-2.804Z"
                />
              </svg>
              <p>{event.email}</p>
            </div>
          </div>

          <div className="basis-1/4">
            <p className="text-xl font-bold mb-4">Share with your contacts</p>
            <div className="flex flex-row gap-3">
              <a href={event.link && event.link[0].facebook}>
                <svg
                  class="w-5 h-5 text-lightblue "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 8 19"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
              <a href={`https://$}`} target="_blank">
                <svg
                  class="w-5 h-5 text-lightblue"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 15 15"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                    clip-rule="evenodd"
                  />
                  <path d="M3 5.012H0V15h3V5.012Z" />
                </svg>
              </a>
              <a
                href={`https://${event.link && event.link[0].twitter}`}
                target="_blank"
              >
                <svg
                  class="w-5 h-5 text-lightblue"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="currentColor"
                    d="M12.186 8.672 18.743.947h-2.927l-5.005 5.9-4.44-5.9H0l7.434 9.876-6.986 8.23h2.927l5.434-6.4 4.82 6.4H20L12.186 8.672Zm-2.267 2.671L8.544 9.515 3.2 2.42h2.2l4.312 5.719 1.375 1.828 5.731 7.613h-2.2l-4.699-6.237Z"
                  />
                </svg>
              </a>
              <a
                href={`https://${event.link && event.link[0].youtube}`}
                target="_blank"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  height="2em"
                  width="2em"
                >
                  <path d="M16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2m-5.15 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56M14.34 14H9.66c-.1-.66-.16-1.32-.16-2 0-.68.06-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2M12 19.96c-.83-1.2-1.5-2.53-1.91-3.96h3.82c-.41 1.43-1.08 2.76-1.91 3.96M8 8H5.08A7.923 7.923 0 019.4 4.44C8.8 5.55 8.35 6.75 8 8m-2.92 8H8c.35 1.25.8 2.45 1.4 3.56A8.008 8.008 0 015.08 16m-.82-2C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2M12 4.03c.83 1.2 1.5 2.54 1.91 3.97h-3.82c.41-1.43 1.08-2.77 1.91-3.97M18.92 8h-2.95a15.65 15.65 0 00-1.38-3.56c1.84.63 3.37 1.9 4.33 3.56M12 2C6.47 2 2 6.5 2 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2z" />
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div>
          {/* get an array if that array is empty then dont show  */}
   

          {similarevent && similarevent.length > 0 && (
            <div>
            <p className="text-2xl font-bold mb-2">Other Events by the Organizer</p>
            <div className="flex sm:flex-row flex-col gap-4">
              {similarevent.map((event, index) => (
                <EventsCard
                  key={index}
                  name={event.name}
                  month={format(new Date(event.date), "MMM")}
                  day={format(new Date(event.date), "dd")}
                  location={event.location}
                  id={event.event_no}
                />
              ))}
            </div>
            </div>
          )}
        </div>

        <div className="py-4">
          <p className="text-2xl font-bold">Similar Events you may like...</p>
          <SimilarEvents id={event.event_category_name} name={id} event={similarevents}/>
        </div>
        </div>
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70 z-50">
            <div className="p-4 max-w-md mx-auto rounded-md">
              {/* Your spinner component */}
              <Spinner
                aria-label="Default status example"
                color="warning"
                size="xl"
              />
            </div>
          </div>
        )}
      </section>
      <section className="w-screen">
        <FixedFooter />
      </section>
    </div>
  );
}

export default EventsDetails;
