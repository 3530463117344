import React,{useState,useEffect} from "react";
import { Button, Label, TextInput,FileInput } from "flowbite-react";
import { useAuth } from "../../../context/AuthContext";
import { useMarketplaceContext } from "../../../context/MarketplaceContexts";

function SellerForm({toggleDiv}){
    const {user}=useAuth();
    const {temporaryAdInfo,addTemporaryAdInfo}=useMarketplaceContext();
    const [formData, setFormData] = useState({
        name:user.fullname,
        email:user.email,
        number:user.phonenumber

});



const handleChanges=()=>{
    console.log(formData);
    // add to the temp array
    addTemporaryAdInfo({seller:formData.name});
    toggleDiv("review");
}
const [isFormValid, setIsFormValid] = useState(true);
useEffect(() => {
  // Check form validity whenever formData changes
  const requiredFields = ["name", "email", "number"];
  const isValid = requiredFields.every((field) => formData[field] !== "" && formData[field] !== null);
  setIsFormValid(isValid);
}, [formData]);
    return(
        <div className="flex justify-center items-center  h-full">
      <form className="flex w-full flex-col gap-4 ">
        <div>
        <div className="mb-2 block">
          <Label htmlFor="name" value={<div><p className="font-bold text-midnightblue">Seller Name</p><p className="text-darkgray">Confirm your name</p></div>} />
        </div>
          
          <TextInput
            id="name"
            name="name"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            value={formData.name}
            readOnly
            
          />
        </div>

        <div>
        <div className="mb-2 block">
          <Label htmlFor="email" value={<div><p className="font-bold text-midnightblue">Seller Email</p><p className="text-darkgray">Write the product description in full including the brand name, make and year and what it does</p></div>} />
        </div>
          
          <TextInput
            id="email"
            name="email"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            value={formData.email}
            readOnly
          />
        </div>

        
        

        <div>
        <div className="mb-2 block">
          <Label htmlFor="phoneNumber" value={<div><p className="font-bold text-midnightblue">Seller Number</p><p className="text-darkgray">The number you can be contacted on</p></div>} />
        </div>
          
          <TextInput
            id="phoneNumber"
            name="phoneNumber"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            value={formData.number}
            readOnly
          />
        </div>
        
    <div className="flex">
    <Button type="button"  className={`justify-end bg-normalblue sm:p-4 p-2 rounded-md ${
              !isFormValid ? "opacity-50 cursor-not-allowed" : ""
            }`} onClick={handleChanges} disabled={!isFormValid}>Save Changes & Next</Button>
    </div>

        
      </form>
    </div>
    )

}

export default SellerForm;