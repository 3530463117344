import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/AuthContext";
import { EventProvider } from "./context/EventContexts";
import { MarketplaceProvider } from "./context/MarketplaceContexts";
import { EventOrganizerProvider } from "./context/EventOrganizerContext";
import { ApiProvider } from "./api/APIProvider";
import { FAQProvider } from "./context/FAQContext";
import { MarketplaceNavigationProvider } from "./context/MarketplaceNavigation";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ApiProvider>
        <MarketplaceProvider>
          <MarketplaceNavigationProvider>
            <EventProvider>
              <EventOrganizerProvider>
                <FAQProvider>
                  <App />
                </FAQProvider>
              </EventOrganizerProvider>
            </EventProvider>
          </MarketplaceNavigationProvider>
        </MarketplaceProvider>
      </ApiProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
