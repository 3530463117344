import React from "react";

function Inbox(){
    return(
        <div className="flex flex-col gap-4">
        <p className="font-bold">Inbox</p>
        <div>
            <p className="text-darkgray text-center">Inbox coming soon!</p>
        </div>
    </div>
    )
}
export default Inbox;