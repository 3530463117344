import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../context/AuthContext";
import { Button, Select, Accordion, Spinner } from "flowbite-react";
import ProductCard from "../components/Cards/ProductCard";
import MarketAccordion from "./Accordion/MarketAccordion";
import { useMarketplaceContext } from "../context/MarketplaceContexts";
import { useNavigate } from "react-router-dom";
import MainPagination from "../components/Miscellanous/MainPagination";
import SpinnerModal from "./Modal/SpinnerModal";
import axios from "axios";
import {
  fetchDataForCategory,
  fetchDataForFlexibility,
  fetchDataForCondition,
  fetchDataForStyle,
  fetchDataForLanguage,
  fetchDataForFormat,
  fetchDataForMaterial,
  fetchDataForAge,
  fetchDataForLocation,
} from "../api/ApiPage";
import CategoryMarketAccordion from "./Accordion/CategoryMarketAccordion";
import PriceMarketAccordion from "./Accordion/PriceMarketAccordion";
import FlexibilityMarketAccordion from "./Accordion/FlexibilityMarketAccordion";
import ConditionMarketAccordion from "./Accordion/ConditionMarketAccordion";
import StyleMarketAccordion from "./Accordion/StyleMarketAccordion";
import MaterialMarketAccordion from "./Accordion/MaterialMarketAccordion";
import FormatMarketAccordion from "./Accordion/FormatMarketAccordion";
import AgeMarketAccordion from "./Accordion/AgeMarketAccordion";
import LanguageMarketAccordion from "./Accordion/LanguageMarketAccordion";
import LocationAccordion from "./Accordion/LocationAccordion";


function MarketplaceBody({ CategoryType, sortBy }) {
  const { isLogged, token, logout } = useAuth();
  const {
    products,
    markedProductsData,
    getproduct,
    setCategories,
    categories,
    price,
    flexibility,
    setFlexibility,
    condition,
    setCondition,
    style,
    setStyle,
    material,
    setMaterial,
    language,
    setLanguage,
    format,
    setFormat,
    agegroup,
    setAge,
    location,
    setLocation,
  } = useMarketplaceContext();
  const navigate = useNavigate();
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(true);
  const handleLoadingModal = () => {
    setLoading(!loading);
  };
  const initialPayload = {
    currentpage: 1,
    type: [],
    category: [],
    min_price: null,
    max_price: null,
    flexibility: [],
    condition: [],
    style: [],
    language: [],
    material: [],
    format: [],
    age_group: [],
    location: [],
    sortBy: null,
  };

  const [formData, setFormData] = useState(initialPayload);
  const loadedOnce = useRef(false);
  const [perpage,setPerPage]=useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [to_no, setTo] = useState(0);
  const [from_no, setFrom] = useState(0);


  // allow for people who havent logged in to see

  const url =process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/list_products`;
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  useEffect(() => {
    if (sortBy == "trending") {
      handleParamChange("sortBy", 4);
    } else {
      resetType();
    }
  }, [sortBy]);

  useEffect(() => {
    if (loadedOnce.current) return;
    handlemarket();
  }, [loadedOnce, formData,currentPage]);

  // useEffect(()=>{
  //   if(products.length>0){
  //     loadedOnce.current=true
  //   }

  // },[products])

  const handlemarket = () => {
    axios
      .get(additionalPath, {
        params: formatPayload(formData),
        headers: headers,
      })
      .then((response) => {
        // Handle successful response
        getproduct(response.data.products.data);
        setPerPage(response.data.products.per_page)
        setTotal(response.data.products.total)
        setTo(response.data.products.to);
        setFrom(response.data.products.from);
        setLoading(false);
        loadedOnce.current = true;
      })
      .catch((err) => {
        // Handle error
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
          setErr(err);
          loadedOnce.current = true;

          // Rethrow the error for further handling if needed
        }
      });
  };

  const handleArrayChange = (name, value) => {
    setFormData((prevPayload) => ({
      ...prevPayload,
      [name]: [value],
    }));
    loadedOnce.current = false;
  };

  const handleParamChange = (name, value) => {
    setFormData((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
    loadedOnce.current = false;
  };

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedFlexibilities, setSelectedFlexibilities] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState([]);
  const [selectedFormat, setSelectedFormat] = useState([]);
  const [selectedAge, setSelectedAge] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedSize, setSelectedSize] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (CategoryType) {
      handleArrayChange("type", CategoryType);
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        type: [],
      }));
      loadedOnce.current = false;
    }

    if (selectedCategories.length > 0) {
      handleParamChange("category", selectedCategories);
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        category: [],
      }));

      loadedOnce.current = false;
    }
    if (selectedFlexibilities.length > 0) {
      handleParamChange("flexibility", selectedFlexibilities);
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        flexibility: [],
      }));

      loadedOnce.current = false;
    }
    if (selectedCondition.length > 0) {
      handleParamChange("condition", selectedCondition);
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        condition: [],
      }));
      loadedOnce.current = false;
    }
    if (selectedStyle.length > 0) {
      handleParamChange("style", selectedStyle);
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        style: [],
      }));
      loadedOnce.current = false;
    }

    if (selectedLanguages.length > 0) {
      handleParamChange("language", selectedLanguages);
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        language: [],
      }));
      loadedOnce.current = false;
    }
    if (selectedMaterial.length > 0) {
      handleParamChange("material", selectedMaterial);
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        material: [],
      }));
      loadedOnce.current = false;
    }
    if (selectedFormat.length > 0) {
      handleParamChange("format", selectedFormat);
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        format: [],
      }));
      loadedOnce.current = false;
    }
    if (selectedLocation.length > 0) {
      handleParamChange("location", selectedLocation);
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        location: [],
      }));
      loadedOnce.current = false;
    }

    if (selectedAge.length > 0) {
      handleParamChange("age_group", selectedAge);
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        age_group: [],
      }));
      loadedOnce.current = false;
    }

    if (selectedPrice.length > 0) {
      console.log(selectedPrice);
      handleParamChange("min_price", selectedPrice[0].min);
      handleParamChange("max_price", selectedPrice[0].max);
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        min_price: null,
        max_price: null,
      }));
      loadedOnce.current = false;
    }

    if (selectedOption !== "") {
      let value = parseInt(selectedOption);
      handleParamChange("sortBy", value);
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        sortBy: null,
      }));
      loadedOnce.current = false;
    }
  }, [
    CategoryType,
    selectedOption,
    selectedCategories,
    selectedPrice,
    selectedFlexibilities,
    selectedCondition,
    selectedStyle,
    selectedAge,
    selectedMaterial,
    selectedFormat,
    selectedLanguages,
    selectedLocation,
  ]);

  const formatPayload = (formData) => {
    const formattedPayload = { ...formData };

    if (formattedPayload.type.length === 1) {
      formattedPayload.type = `[${formattedPayload.type[0]}]`;
    } else if (formattedPayload.category.length > 0) {
      formattedPayload.category = JSON.stringify(formattedPayload.category);
    } else if (formattedPayload.flexibility.length > 0) {
      formattedPayload.flexibility = JSON.stringify(
        formattedPayload.flexibility
      );
    } else if (formattedPayload.condition.length > 0) {
      formattedPayload.condition = JSON.stringify(formattedPayload.condition);
    } else if (formattedPayload.language.length > 0) {
      formattedPayload.language = JSON.stringify(formattedPayload.language);
    } else if (formattedPayload.material.length > 0) {
      formattedPayload.material = JSON.stringify(formattedPayload.material);
    } else if (formattedPayload.format.length > 0) {
      formattedPayload.format = JSON.stringify(formattedPayload.format);
    } else if (formattedPayload.age_group.length > 0) {
      formattedPayload.age_group = JSON.stringify(formattedPayload.age_group);
    } else if (formattedPayload.location.length > 0) {
      formattedPayload.location = JSON.stringify(formattedPayload.location);
    } else if (formattedPayload.style.length > 0) {
      formattedPayload.style = JSON.stringify(formattedPayload.style);
    } else {
      formattedPayload.type = [];
      formattedPayload.category = [];
      formattedPayload.flexibility = [];
      formattedPayload.condition = [];
      formattedPayload.style = [];
      formattedPayload.languages = [];
      formattedPayload.material = [];
      formattedPayload.format = [];
      formattedPayload.age_group = [];
      formattedPayload.location = [];
    }

    return formattedPayload;
  };

  const resetType = () => {
    setFormData(initialPayload);
  };



  // filtering

  //   get filters
  useEffect(() => {
    if (categories.length == 0) {
      fetchDataForCategory(logout).then((data) => {
        if (data) {
          setCategories(data);
        }
      });
    }

    if (flexibility.length == 0) {
      fetchDataForFlexibility(logout).then((data) => {
        if (data) {
          setFlexibility(data);
        }
      });
    }
    if (condition.length == 0) {
      fetchDataForCondition(logout).then((data) => {
        if (data) {
          setCondition(data);
        }
      });
    }
    if (style.length == 0) {
      fetchDataForStyle(logout).then((data) => {
        if (data) {
          setStyle(data);
        }
      });
    }
    if (language.length == 0) {
      fetchDataForLanguage(logout).then((data) => {
        if (data) {
          setLanguage(data);
        }
      });
    }
    if (material.length == 0) {
      fetchDataForMaterial(logout).then((data) => {
        if (data) {
          setMaterial(data);
        }
      });
    }
    if (format.length == 0) {
      fetchDataForFormat(logout).then((data) => {
        if (data) {
          setFormat(data);
        }
      });
    }
    if (agegroup.length == 0) {
      fetchDataForAge(logout).then((data) => {
        if (data) {
          setAge(data);
        }
      });
    }

    if (location.length == 0) {
      fetchDataForLocation(logout).then((data) => {
        if (data) {
          setLocation(data);
        }
      });
    }
  }, []);

  const handlepostad = () => {
    navigate("/postad");
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    handleParamChange("currentpage", newPage);
  };

  const handleCategoryChange = (e) => {
    const category = parseInt(e.target.value);
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const handlePriceChange = (e) => {
    const { value, checked } = e.target;
    const selectedPrices = price.find((p) => p.id === parseInt(value));

    if (checked) {
      setSelectedPrice((prevSelected) => [
        ...prevSelected,
        { min: selectedPrices.min, max: selectedPrices.max },
      ]);
    } else {
      setSelectedPrice((prevSelected) =>
        prevSelected.filter(
          (p) => p.min !== selectedPrices.min && p.max !== selectedPrices.max
        )
      );
    }
  };

  const handleFlexibilityChange = (e) => {
    const flexibilities = parseInt(e.target.value);
    if (selectedFlexibilities.includes(flexibilities)) {
      setSelectedFlexibilities(
        selectedFlexibilities.filter((f) => f !== flexibilities)
      );
    } else {
      setSelectedFlexibilities([...selectedFlexibilities, flexibilities]);
    }
  };

  const handleConditionChange = (e) => {
    const conditions = parseInt(e.target.value);
    if (selectedCondition.includes(conditions)) {
      setSelectedCondition(selectedCondition.filter((c) => c !== conditions));
    } else {
      setSelectedCondition([...selectedCondition, conditions]);
    }
  };

  const handleStyleChange = (e) => {
    const style = parseInt(e.target.value);
    if (selectedStyle.includes(style)) {
      setSelectedStyle(selectedStyle.filter((c) => c !== style));
    } else {
      setSelectedStyle([...selectedStyle, style]);
    }
  };

  // Function to handle flexibility filter changes
  const handleMaterialChange = (e) => {
    const materials = e.target.value;
    if (selectedMaterial.includes(materials)) {
      setSelectedMaterial(selectedMaterial.filter((f) => f !== materials));
    } else {
      setSelectedMaterial([...selectedMaterial, materials]);
    }
  };

  const handleFormatChange = (e) => {
    const formats = parseInt(e.target.value);
    if (selectedFormat.includes(formats)) {
      setSelectedFormat(selectedFormat.filter((f) => f !== formats));
    } else {
      setSelectedFormat([...selectedFormat, formats]);
    }
  };
  const handleLanguageChange = (e) => {
    const languages = parseInt(e.target.value);
    if (selectedLanguages.includes(languages)) {
      setSelectedLanguages(selectedLanguages.filter((f) => f !== languages));
    } else {
      setSelectedLanguages([...selectedLanguages, languages]);
    }
  };
  const handleAgeChange = (e) => {
    const ages = parseInt(e.target.value);
    if (selectedAge.includes(ages)) {
      setSelectedAge(selectedAge.filter((f) => f !== ages));
    } else {
      setSelectedAge([...selectedAge, ages]);
    }
  };

  //   not yet in it
  const handleLocationChange = (e) => {
    const locations = e.target.value;
    if (selectedLocation.includes(locations)) {
      setSelectedLocation(selectedLocation.filter((f) => f !== locations));
    } else {
      setSelectedLocation([...selectedLocation, locations]);
    }
  };

  const handleSizeChange = (e) => {
    const sizes = parseInt(e.target.value);
    if (selectedMaterial.includes(sizes)) {
      setSelectedSize(selectedSize.filter((f) => f !== sizes));
    } else {
      setSelectedSize([...selectedSize, sizes]);
    }
  };


  const itemsPerPage = products &&  perpage;


  // Calculate the range of items for the current page
  const startIndex = from_no;
  const endIndex = to_no;
  const itemsToShow = products ;


  const totalPages = products && total>0&& Math.ceil(total / itemsPerPage);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="flex sm:flex-row flex-col px-3 gap-12 mt-4 h-screen">
      <div className=" h-[80vh] basis-1/4">
        <div className="flex flex-row justify-between">
          <p className="text-midnightblue font-bold">FILTERS</p>
          <a className="text-darkgray" onClick={() => resetType()}>
            Reset
          </a>
        </div>

        <div className="sm:hidden block">
          <Button
            className="border border-normalblue text-normalblue w-full mt-2"
            onClick={toggleAccordion}
          >
            Show Filters
          </Button>
          {isExpanded && (
            <div>
              <CategoryMarketAccordion
                title="Category"
                children={categories}
                selectedCategories={selectedCategories}
                handleCategoryChange={handleCategoryChange}
              />
              <PriceMarketAccordion
                title="Price"
                children={price}
                selectedCategories={selectedPrice}
                handleCategoryChange={handlePriceChange}
              />
              <FlexibilityMarketAccordion
                title="Flexibility"
                children={flexibility}
                selectedCategories={selectedFlexibilities}
                handleCategoryChange={handleFlexibilityChange}
              />
              <ConditionMarketAccordion
                title="Product Condition"
                children={condition}
                selectedCategories={selectedCondition}
                handleCategoryChange={handleConditionChange}
              />
              <StyleMarketAccordion
                title="Style"
                children={style}
                selectedCategories={selectedStyle}
                handleCategoryChange={handleStyleChange}
              />
              <LocationAccordion
                title="Location"
                children={location}
                selectedCategories={selectedLocation}
                handleCategoryChange={handleLocationChange}
              />
              <MaterialMarketAccordion
                title="Material"
                children={material}
                selectedCategories={selectedMaterial}
                handleCategoryChange={handleMaterialChange}
              />
              <FormatMarketAccordion
                title="Format"
                children={format}
                selectedCategories={selectedFormat}
                handleCategoryChange={handleFormatChange}
              />
              <AgeMarketAccordion
                title="Age Group"
                children={agegroup}
                selectedCategories={selectedAge}
                handleCategoryChange={handleAgeChange}
              />
              <LanguageMarketAccordion
                title="Language"
                children={language}
                selectedCategories={selectedLanguages}
                handleCategoryChange={handleLanguageChange}
              />
            </div>
          )}
        </div>

        <div className="sm:block hidden">
          <CategoryMarketAccordion
            title="Category"
            children={categories}
            selectedCategories={selectedCategories}
            handleCategoryChange={handleCategoryChange}
          />
          <PriceMarketAccordion
            title="Price"
            children={price}
            selectedCategories={selectedPrice}
            handleCategoryChange={handlePriceChange}
          />
          <FlexibilityMarketAccordion
            title="Flexibility"
            children={flexibility}
            selectedCategories={selectedFlexibilities}
            handleCategoryChange={handleFlexibilityChange}
          />
          <ConditionMarketAccordion
            title="Product Condition"
            children={condition}
            selectedCategories={selectedCondition}
            handleCategoryChange={handleConditionChange}
          />
          <StyleMarketAccordion
            title="Style"
            children={style}
            selectedCategories={selectedStyle}
            handleCategoryChange={handleStyleChange}
          />
          <LocationAccordion
            title="Location"
            children={location}
            selectedCategories={selectedLocation}
            handleCategoryChange={handleLocationChange}
          />
          <MaterialMarketAccordion
            title="Material"
            children={material}
            selectedCategories={selectedMaterial}
            handleCategoryChange={handleMaterialChange}
          />
          <FormatMarketAccordion
            title="Format"
            children={format}
            selectedCategories={selectedFormat}
            handleCategoryChange={handleFormatChange}
          />
          <AgeMarketAccordion
            title="Age Group"
            children={agegroup}
            selectedCategories={selectedAge}
            handleCategoryChange={handleAgeChange}
          />
          <LanguageMarketAccordion
            title="Language"
            children={language}
            selectedCategories={selectedLanguages}
            handleCategoryChange={handleLanguageChange}
          />
          {/* <MarketAccordion title="Size" children={size}  selectedCategories={selectedSize} handleCategoryChange={handleSizeChange}/>  */}
        </div>
      </div>
      <div className="flex flex-col  basis-full">
        <div className="flex sm:flex-row flex-col-reverse justify-between ">
          <p className="text-darkgray sm:text-xl text-md">
            Showing{" "}
            <span className="text-midnightblue font-bold">
              {" "}
              {startIndex}-{endIndex} of {total}
            </span>{" "}
            product results
          </p>
          {isLogged ? (
            <Button
              className="bg-normalbrown sm:w-1/5 w-full p-2"
              onClick={handlepostad}
            >
              POST AD
            </Button>
          ) : null}
        </div>
        <div className="flex flex-row sm:justify-end justify-between mt-5 gap-2">
          <div className="sm:flex sm:flex-row sm:gap-2  sm:visible hidden ">
            <p className="text-md ">PRICE</p>
            <div className="flex divide-x-2 ">
              <a
                className={
                  selectedOption != "3"
                    ? "text-normalblue font-bold"
                    : "text-darkgray"
                }
              >
                High to Low
              </a>

              <a
                className={
                  selectedOption == "3"
                    ? "text-normalblue font-bold"
                    : "text-darkgray"
                }
              >
                Low to High
              </a>
            </div>
          </div>

          <div className="flex flex-row gap-2 ">
            <p className="text-md ">SORT BY</p>
            <div>
              <Select
                id="countries"
                class="bg-transparent text-normalblue border-none p-0 font-bold focus:outline-none appearance-none focus:border-none  focus:ring-0 "
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="1">Relevance</option>
                <option value="3">Price:Low to High</option>
                <option value="2">Price:High to Low</option>
              </Select>
            </div>
          </div>
        </div>

        {/* loop through prducts array , prodcut category,product name, sellers details, saves, price, id*/}

        

        {!loadedOnce.current ? (
          // Show spinner while loading
          <div className="flex flex-col pt-48 justify-center items-center">
            {/* Add your spinner component here */}
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
            <p className="text-darkgray">Loading Products....</p>
          </div>
        ) : itemsToShow && itemsToShow.length > 0 ? (
          // Display items if data is available
          <div className="grid sm:grid-cols-3 xl:grid-cols-4 mt-4 sm:gap-5 gap-y-4">
            {itemsToShow.map((item, index) => (
              <ProductCard
                key={index}
                status={isLogged}
                id={item.product_no}
                item={item}
                productname={item.name}
                seller={item.seller}
                saves={item.saves}
                price={item.price}
                category={item.category}
                saved={item.is_saved}
                adtype={item.ad_type}
              />
            ))}
          </div>
        ) : (
          // Show "No product results" section if no items
          <div>
            <p className="flex justify-center mt-6 text-darkgray font-bold p-8">
              No product results
            </p>
          </div>
        )}

        <MainPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
export default MarketplaceBody;

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}
