import React from "react";
import { useNavigate } from "react-router-dom";
import TopBanner from "../../components/Navigation/TopBanner";
import NavigationBar from "../../components/Navigation/NavigationBar";
import SearchBarCollapse from "../../components/SearchBars/SearchBarCollapse";
import logo from "../../assets/images/KRIFFEONLINELOGO2.png";
import { useAuth } from "../../context/AuthContext";
import EventOrganizerForm from "../../components/Forms/EventOrganizerForm";

const EventOrganizer=()=>{
    const { isLogged } = useAuth();
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate("/account/events");
      };
    
      const handleBack = () => {
        navigate("/account/events");
      };

    return(
        <section className="w-screen h-screen">
        <TopBanner/>
            <NavigationBar logo={logo} color="text-midnightblue" status={isLogged} />
            <SearchBarCollapse />
            <a href="#" onClick={handleBack} className="text-normalblue px-3 text-sm pt-12">
        {`<`}Go back
      </a>

      <div className="flex flex-col space-y-4 px-3">
        <p className="font-bold text-midnightblue text-center">Event Organizer Verification</p>
        <EventOrganizerForm onrequest={handleGoBack}/>

      </div>


    </section>)
}
export default EventOrganizer;