import React,{useState} from "react";
import { Label, Radio,Button } from 'flowbite-react';
import { useAuth } from "../../context/AuthContext";
import axios from "axios";

function WithdrawMoney({amount,handleWithdrawal,id}){
  const {token}=useAuth()
    const [specification, setSpecification] = useState("bank");

    const [amounts,setAmount] = useState(0.00);
  

    const unchecked="flex items-center justify-between border-2 border-lightgray rounded-md p-6";
    const checked="flex items-center justify-between border-2 border-green-600 rounded-md p-6";
    const textcheck="text-lg text-green-600";
    const textuncheck="text-lg text-darkgray";
    const url =process.env.REACT_APP_API_URL;
    const headers = {
      "Content-Type": "application/json",
      // 'Authorization':  `Bearer ${token}`,
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const handleStatusChange=(e)=>{
        setSpecification(e.target.value);

    }

    const handleAmount=(e)=>{
        setAmount(e.target.value);

    }
    const handleMax=()=>{
      setAmount(amount);
    
    }

    const info={
      amount_withdrawn:amount,
      payment_method:specification,
      event_no:id
    }

    const handleChanges=()=>{
        // add to the temp array
       
        // add_withdrawal
      
  const additionalPath = `${url}/add_withdrawal`;
  // const path=`get_product_by_id?id=${id}`;
  // makeApiRequest("GET", null, path, token);
 

  axios
        .post(additionalPath, info,{headers: headers })
        .then((response) => {
          console.log(response.data);
          handleWithdrawal();
    

        })
        .catch((err) => {
          console.log(err);
        });
      

    }

    return(
        <div className="flex justify-center items-center ">
      <form className="flex w-full flex-col gap-4 ">
      <fieldset
      className="flex w-full flex-col gap-4"
      id="radio"
    >
      <legend className="py-2">
        Confirm your Withdrawal Method
      </legend>
      <div className={specification=="bank"?(checked):(unchecked)}>
      <Label htmlFor="bank" className={specification=="bank"?(textcheck):(textuncheck)}>
          Bank Transfer 
        </Label>
 
            {specification === 'bank'? (
              <svg class="w-6 h-6 text-green-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
            ):(<Radio
                defaultChecked
                id="bank"
                name="bank"
                value="bank"
                checked={specification === 'bank'}
                onChange={handleStatusChange}
      
              />)}
       
       
      </div>
      <div className={specification=="momo"?(checked):(unchecked)}>
      <Label htmlFor="momo" className={specification=="momo"?(textcheck):(textuncheck)}>
          Mobile Money
        </Label>
        

{specification === 'momo'? (
              <svg class="w-6 h-6 text-green-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
            </svg>
            ):(<Radio
                id="momo"
                name="momo"
                value="momo"
                checked={specification === 'momo'}
                  onChange={handleStatusChange}
              />)}
        
      </div>
      
      
      
    </fieldset>


    <p>Withdraw</p>
    <div className="flex flex-row gap-2">
    <input
          type="text"
          placeholder="Amount to withdraw"
          value={amounts}
          onChange={handleAmount}
          class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md basis-3/4 p-4 h-12"
          readOnly
        />
        <Button  type="button" className=" bg-normalbrown  rounded-md basis-1/4 h-12" onClick={handleMax}>Max</Button>
    </div>
        
    <div className="flex justify-end ">
    <Button type="button" className=" bg-normalblue rounded-md p-2 w-full" onClick={handleChanges}>Send Request</Button>
    </div>

        
      </form>
    </div>

    )
}
export default WithdrawMoney;