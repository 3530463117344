import React,{useState,useEffect} from "react";
import OrderCard from "../Cards/Account/OrderCard";
import { useEventContexts } from "../../context/EventContexts";
import { useAuth } from "../../context/AuthContext";
import MainPagination from "../../components/Miscellanous/MainPagination";
import axios from 'axios';
import { Spinner } from "flowbite-react";
import { useNavigate } from "react-router-dom";

function Orders() {
  const { eventorder,addOrder } = useEventContexts();
  const navigate=useNavigate();
  const initialPayload = {
    currentpage: 1,
  };

  const [formData, setFormData] = useState(initialPayload);


  const {token,logout} = useAuth();
  const [ loadedOnce,setLoadedOnce] = useState(false);
  const [ err, setErr ] = useState('');
  const [ perpage, setPerpage ] = useState(1);
  const [total, setTotal] = useState(0);
  const [to_no, setTo] = useState(0);
  const [from_no, setFrom] = useState(0);

  const handleParamChange = (name, value) => {
    setFormData((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
    setLoadedOnce(false);
  };

  useEffect(() => {
    // Make the first API call
    if(!loadedOnce){
      handlemarketplace();
    }
  }, [loadedOnce]);

const url =process.env.REACT_APP_API_URL

const handlemarketplace =() => {
const additionalPath = `${url}/get_tickets_logged_in_user`;
// const path=`get_product_by_id?id=${id}`;
// makeApiRequest("GET", null, path, token);
const headers = {
  'Content-Type': 'application/json',
  'Authorization':  `Bearer ${token}`,

};


axios.get(additionalPath, {params: formData,headers:headers })
  .then(response => {
    console.log(response.data.sales.data)
    addOrder(response.data.sales.data);
    setPerpage(response.data.sales.per_page)
    setTo(response.data.sales.to)
    setFrom(response.data.sales.from)
    setTotal(response.data.sales.total)
    setLoadedOnce(true);

  })
  .catch(err => {
    if (err.response && err.response.status === 401) {
      // Perform actions when 401 (Unauthorized) error occurs
      console.log('Unauthorized: Logging user out or performing specific action...');
      logout();
      navigate("/");
      // Example: Log the user out or redirect to login page
      // You may perform the necessary actions here based on your application flow
    } else {
      // Handle other errors
      console.log(err);
    setErr(err);

   // Rethrow the error for further handling if needed
    }
  });
};

const itemsPerPage = eventorder&& perpage;
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the range of items for the current page
  const startIndex = from_no;
  const endIndex = to_no ;
  const itemsToShow = eventorder;
  const totalPages = Math.ceil(total / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    handleParamChange("currentpage", newPage);
  };

  return (
    <div className="flex flex-col gap-4">
      <p className="font-bold">My Orders</p>
      {loadedOnce ? (
  itemsToShow && itemsToShow.length > 0 ? (
    <div className="flex flex-col gap-2">
      {itemsToShow.map((order) => (
        <OrderCard
          key={order.ticket_id}
          id={order.ticket_id}
          event={order}
          // You can pass other props to the OrderCard component
        />
      ))}
    </div>
  ) : (
    <p className="flex text-darkgray justify-center">No Orders</p>
  )
) : (
  // Loading spinner or any other loading indicator while fetching data
  <div className="text-darkgray text-center">
    <div className="inset-0 flex items-center justify-center z-50">
      <div className="p-4 max-w-md mx-auto rounded-md">
        <Spinner aria-label="Default status example" color="warning" size="xl" />
      </div>
    </div>
    Loading orders...
  </div>
)}
<MainPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />

      <div></div>
    </div>
  );
}
export default Orders;
