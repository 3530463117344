import React, { useState, useEffect } from "react";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../../assets/images/KRIFFEONLINELOGO2.png";
import TopBanner from "../../../components/Navigation/TopBanner";
import NavigationBar from "../../../components/Navigation/NavigationBar";
import SearchBarCollapse from "../../../components/SearchBars/SearchBarCollapse";
import MarketplaceNav from "../../../components/Navigation/MarketplaceNav";
import { Carousel, Spinner } from "flowbite-react";
import SellersCard from "../../../components/Cards/SellersCard";
import SimilarProducts from "../../../components/Miscellanous/SimilarProducts";
import { useMarketplaceContext } from "../../../context/MarketplaceContexts";
import FeedbackCard from "../../../components/Cards/FeedbackCard";
import RatingCard from "../../../components/Cards/RatingCard";
import { useApi } from "../../../api/APIProvider";
import axios from "axios";
import SpinnerModal from "../../../components/Modal/SpinnerModal";
import FeedbackPage from "../../../components/Miscellanous/FeedbackPage";


function AdDetails() {
  const { isLogged, token, logout } = useAuth();
  // const { makeApiRequest,data,error } = useApi();
  const navigate = useNavigate();
  const { id } = useParams();
  const [err, setErr] = useState("");

  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data: contextData, makeApiRequest, error } = useApi();
  const [data, setData] = useState(contextData);

  const handleLoadingModal = () => {
    setLoading(!loading);
  };

  const formdata = { id: id };

  useEffect(() => {
    // Make the first API call
    if (product.length == 0) {
      handlemarketplace();
    }
  }, [product]);

  const handleGoBack = () => {
    navigate("/marketplace");
  };

  const capitalize = (text) => {
    if (typeof text !== "string") {
      return "";
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const [save, setSave] = useState(false);

  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const handlesaved = () => {
    if (save) {
      removeSavedItems();
    } else {
      addSavedItems();
    }
  };

  const formData = {
    product_id: id,
  };

  const removeSavedItems = () => {
    const additionalPath = `${url}/remove_from_favorite`;
    axios
      .delete(additionalPath, { params: formData, headers: headers })
      .then((response) => {
        setSave(false);
      })
      .catch((error) => {
        // Handle error
        console.error("Error:", error);
      });
  };
  // add_to_favorites

  const addSavedItems = () => {
    const additionalPath = `${url}/add_to_favorites`;
    axios
      .post(additionalPath, formData, { headers: headers })
      .then((response) => {
        setSave(true);
      })
      .catch((error) => {
        // Handle error
        console.error("Error:", error);
      });
  };

  const url =process.env.REACT_APP_API_URL;

  const handlemarketplace = () => {
    const additionalPath = `${url}/get_product_by_id`;
    // const path=`get_product_by_id?id=${id}`;
    // makeApiRequest("GET", null, path, token);
    const headers = {
      "Content-Type": "application/json",
      // 'Authorization':  `Bearer ${token}`,
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .get(additionalPath, { params: formdata, headers: headers })
      .then((response) => {
        setProduct(response.data.product);
        setLoading(false);
        setSave(response.data.product.is_saved);
        console.log(response.data.product)
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
          setErr(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  const displayProductDetails =
    product.product_details &&
    product.product_details.map((obj, index) => {
      const { attribute_name, attribute_value } = obj;
      let associatedName = "";
      let name = "";

      switch (attribute_name) {
        case "age_group":
          name = "Age group";
          associatedName = attribute_value;

          break;

        case "product_flexibility":
          name = "Flexibility";
          associatedName = attribute_value;

          break;

        case "material":
          name = "Material";
          associatedName = attribute_value;

          break;

        case "style":
          name = "Style";
          associatedName = attribute_value;

          break;

        case "format":
          name = "Format";
          associatedName = attribute_value;

          break;

        case "language":
          name = "Language";
          associatedName = attribute_value;

          break;

        // Add more cases for other attribute_names if needed

        default:
          break;
      }
      return (
        <div key={index}>
          <p className="text-midnightblue font-medium">{capitalize(name)}</p>

          <p className="text-darkgray">{capitalize(associatedName)}</p>
        </div>
      );
    });

  return (
    <section className="w-screen">
      <TopBanner />
      <NavigationBar logo={logo} color="text-midnightblue" status={isLogged} />
      <SearchBarCollapse />

      <a
        href="#"
        onClick={handleGoBack}
        className="text-normalblue px-3 text-sm pt-12"
      >
        {`<`}Go back
      </a>

      <div className="relative h-screen">
        <div className="flex flex-col gap-4 px-3 ">
          <div className="flex lg:flex-row flex-col gap-4 mt-4">
            <div className="lg:basis-2/5 lg:h-[40rem] h-[20rem] ">
              <Carousel slide={false} className="lg:w-[35rem] ">
                {product.productimages ? (
                  product.productimages.map((image, index) => (
                    <img
                      key={index}
                      alt="product Image"
                      src={image}
                      className="bg-midnightblue h-full object-cover"
                    />
                  ))
                ) : (
                  <img
                    alt="..."
                    src="https://flowbite.com/docs/images/carousel/carousel-2.svg"
                  />
                )}
              </Carousel>
              {/* <div className="bg-midnightblue h-full w-[35rem]">

            </div> */}
            </div>

            <div className="flex flex-col space-y-4">
              <div>
                <div className="flex flex-row gap-2">
                  <p className="text-4xl font-bold">{product.name}</p>
                  <a
                    className="flex items-center"
                    href="#"
                    onClick={handlesaved}
                  >
                    {save ? (
                      <svg
                        class="w-4 h-4 text-normalblue"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 14 20"
                      >
                        <path d="M13 20a1 1 0 0 1-.64-.231L7 15.3l-5.36 4.469A1 1 0 0 1 0 19V2a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v17a1 1 0 0 1-1 1Z" />
                      </svg>
                    ) : (
                      <svg
                        class="w-4 h-4 text-normalblue"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 20"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m13 19-6-5-6 5V2a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v17Z"
                        />
                      </svg>
                    )}
                  </a>
                </div>

                <div className="flex flex-row  divide-midnightblue">
                  <p className="p-1 border-r-2 border-r-midnightblue">
                    {product.product_details
                      ? product.product_details.find(
                          (detail) =>
                            detail.attribute_name === "product_flexibility"
                        )?.attribute_value
                      : "No Flex"}
                  </p>
                  <p className="p-1">{product.saves} saves</p>
                </div>

                <p className="font-bold text-4xl text-normalblue">
                  GHC {product.price}
                </p>
                <hr></hr>
              </div>

              <div>
                <p className="font-bold text-xl">Description</p>
                <p className="text-darkgray">{product.description}</p>
              </div>

              <div>
                <p className="font-bold text-xl">Location</p>
                <p className="text-darkgray">{product.location},Ghana</p>
              </div>

              <div>
                <p className="font-bold text-xl">Product Details</p>
                <div className="grid grid-cols-2 gap-y-2">
                  <div>
                    <p className="text-midnightblue font-medium">Category</p>

                    <p className="text-darkgray">{product.category}</p>
                  </div>

                  {product.product_details ? (
                    displayProductDetails
                  ) : (
                    <div>
                      <p className="text-midnightblue font-medium">
                        {capitalize("Product Details")}
                      </p>

                      <p className="text-darkgray">
                        {capitalize("No details")}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <p className="font-bold text-xl">Product Tags</p>

                <div className="flex flex-row flex-wrap gap-2">
                  {product.tags ? (
                    product.tags.map((detail, index) => (
                      <p key={index} className="text-normalbrown bg-lightbrown/40 rounded-full p-2">
                        {detail}
                      </p>
                    ))
                  ) : (
                    <p>No Tags</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex sm:flex-row flex-col gap-4  mb-8">
            <div className="xl:basis-2/5 basis-3/5">
              <SellersCard
                name={product.sellername}
                email={product.selleremail}
                number={product.sellerphonenumber}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2 border border-normalbrown rounded-md h-auto p-2">
                <div className="flex items-center text-normalbrown">
                  <svg
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    height="4em"
                    width="4em"
                  >
                    <path d="M449.07 399.08L278.64 82.58c-12.08-22.44-44.26-22.44-56.35 0L51.87 399.08A32 32 0 0080 446.25h340.89a32 32 0 0028.18-47.17zm-198.6-1.83a20 20 0 1120-20 20 20 0 01-20 20zm21.72-201.15l-5.74 122a16 16 0 01-32 0l-5.74-121.95a21.73 21.73 0 0121.5-22.69h.21a21.74 21.74 0 0121.73 22.7z" />
                  </svg>
                </div>

                <div className="flex flex-col ">
                  <p className="font-bold text-xl">Safety Tips</p>
                  <ul className="list-disc list-inside sm:text-sm text-xs">
                    <li>
                      Please take care before making a deal with the
                      seller.
                    </li>
                    <li>
                      Ensure that you meet at a public space such as malls .
                    </li>
                    <li>Make payment after reviewing the product in-person</li>
                  </ul>
                </div>
              </div>

              <div className="h-1/2">
                <RatingCard type="ad" userid={product.user_id}/>
              </div>
            </div>
          </div>

          <div>
            
            {/* Cards */}

            {product.user_id && <div><p className="text-midnightblue font-bold text-xl">User Feedback</p><FeedbackPage userid={product.user_id} /></div>}
          </div>
          <div className="py-4">
            <SimilarProducts name={product.category} id={id}/>
          </div>
        </div>
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70 z-50">
            <div className="p-4 max-w-md mx-auto rounded-md">
              {/* Your spinner component */}
              <Spinner
                aria-label="Default status example"
                color="warning"
                size="xl"
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default AdDetails;
