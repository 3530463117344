import React from "react";
import store from "../../assets/images/OnlineStore.png";

function ServiceCard({name,description}){
    return(
        <div className="flex flex-col gap-2">
            <img src={store} className="w-8 h-8"/>
            <p className="text-xl">{name}</p>
            <hr className="bg-normalbrown w-32 h-1 rounded-xs"></hr>
            <p className="text-darkgray sm:text-sm text-xs">{description}</p>


        </div>

    );
}

export default ServiceCard;