import React, { useEffect, useState } from "react";
import { Button, Modal,Spinner } from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../context/AuthContext";
import AdCarousel from "../../Carousels/AdCarousel";

function EventModal({id,open,handleModal}){
    const {token,adminlogout}=useAuth();
    const [event, setUser] = useState([]);
    const [payload, setPayload] = useState({ event_id: id });
    const [loadedOnce, setLoadedOnce] = useState(true);
    const navigate=useNavigate();

     const url =process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/admin_get_event_by_id`;
  // const path=`get_product_by_id?id=${id}`;
  // makeApiRequest("GET", null, path, token);
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  useEffect(() => {
    // Make the first API call
    // handleevent();

    if (event.length == 0) {
      axios
        .get(additionalPath, { params: payload, headers: headers })
        .then((response) => {
          console.log(response.data.event);
          setUser(response.data.event);
          setLoadedOnce(false);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            adminlogout();
            navigate("/admin");
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            console.log(err);

            // Rethrow the error for further handling if needed
          }
        });
    }
  }, [event, payload, headers]);
    
    return(
    <div>
        <Modal show={open} size="xl" popup onClose={handleModal}>
        <Modal.Header>{event.name}</Modal.Header>
        <Modal.Body>
            <div className="relative">
            <div className="flex gap-2 flex-col">
            <div className="w-full h-72 rounded-md bg-midnightblue">
          <AdCarousel images={event.images} />
        </div>
      <div>
        <strong>Date:</strong> {event.date}
      </div>
      <div>
        <strong>Time:</strong> {event.time}
      </div>
      <div>
        <strong>Location:</strong> {event.location}
      </div>
      <div>
        <strong>Description:</strong> {event.description}
      </div>
      <div>
        <strong>Event Category:</strong> {event.event_category_name}
      </div>
      <div>
        <strong>Event Type:</strong> {event.event_type ? event.event_type.join(', ') : 'N/A'}
      </div>
      <div>
        <strong>Host:</strong> {event.host}
      </div>
      <div>
        <strong>Email:</strong> {event.email}
      </div>
      <div>
        <strong>Phone Number:</strong> {event.number}
      </div>
      <div>
        <strong>Company Name:</strong> {event.companyname}
      </div>
      <div>
        <strong>Company Phone Number:</strong> {event.companyphonenumber}
      </div>
      <div>
        <strong>Status:</strong> {event.status}
      </div>
      <div>
        <strong>Ad Type:</strong> {event.ad_type_name}
      </div>
      <div>
        <strong>Ad Type Expiration Date:</strong> {event.ad_type_expiration_date || 'N/A'}
      </div>
      <div>
        <strong>Ticket Types</strong>
        <ul>
          {event.ticket_type&&event.ticket_type.map((ticket, index) => (
            <li key={index} className="flex flex-col gap-2" >
              <p><strong>Ticket {index+1} Name:</strong>{ticket.name}</p>
              <p><strong>Ticket Description:</strong>{ticket.description}</p>
              <div className="flex flex-col gap-2">
                <p><strong>Price:</strong> {ticket.price}</p>
                <p><strong>Ticket Sold:</strong> {ticket.ticket_sold}</p>
                <p><strong>Number Available:</strong> {ticket.number_available}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* Links */}
      <div>
        <strong>Links:</strong>
        <ul>
          {event.ticket_type&&event.link.map((link, index) => (
            <li key={index}>
              {Object.entries(link).map(([platform, url]) => (
                <div key={platform}>
                  <strong>{platform}:</strong> {url}
                </div>
              ))}
            </li>
          ))}
        </ul>
      </div>
      {/* Social Media */}
      <div>
        <strong>Social Media:</strong>
        <ul>
          {event.social_media&&event.social_media.map((item, index) => (
            <li key={index}>
              {Object.entries(item).map(([platform, url]) => (
                <div key={platform}>
                  <strong>{platform}:</strong> {url}
                </div>
              ))}
            </li>
          ))}
        </ul>
      </div>
    </div>
     
     

             
         
           
            {loadedOnce && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md">
            {/* Your spinner component */}
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
          </div>
        </div>
      )}
           </div>


          
           
           
            
           
        </Modal.Body>
      </Modal>

    </div>)
}

export default EventModal;