import React from 'react';

function ProductTag({saves,adType}){
    const adStyles = {
        silver: { backgroundColor: '#C0C0C0' },
        gold: { backgroundColor: '#DA9100' },
        diamond: { backgroundColor: '#BBC9FF' },
      };
    
      const adContent = {
        silver: 'Silver',
        gold: 'Gold',
        diamond: 'Diamond',
      };
    
      const adStyle = adType&& adStyles[adType.toLowerCase()] || {};
      const content = adType&&adContent[adType.toLowerCase()] || '';
    
      let standardContent = '';
      if (adType&&adType.toLowerCase() === 'standard' && saves > 20) {
        adStyle.backgroundColor = 'lightblue';
        standardContent = 'Trending';
      }
    
      return (
        <div className="absolute top-2 right-0  w-24  text-white rounded-l-md font-semibold pl-1 shadow" style={adStyle}>
          {adType&&adType.toLowerCase() === 'standard' ? standardContent : content}
        </div>
      );
  
       
}
export default ProductTag;