import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { Button, Modal } from "flowbite-react";

function SellersCard({ name, email, number }) {
  const { isLogged } = useAuth();
  const [isDetail, setDetail] = useState(false);

  const toggledetail = () => {
    setDetail(!isDetail);
  };
  return (
    <div className="border border-normalblue rounded-md p-6">
      {isLogged ? (
        <div className="flex flex-col">
          <div className="flex flex-row justify-between mb-6">
            <p className="flex flex-row font-bold text-xl gap-2">
              <span>
                <div className="w-6 h-6 bg-lightblue rounded-full"></div>
              </span>
              {name?(name):("Seller Name")}
              <span>
                <svg
                  class="w-6 h-6 text-green-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 21 21"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m6.072 10.072 2 2 6-4m3.586 4.314.9-.9a2 2 0 0 0 0-2.828l-.9-.9a2 2 0 0 1-.586-1.414V5.072a2 2 0 0 0-2-2H13.8a2 2 0 0 1-1.414-.586l-.9-.9a2 2 0 0 0-2.828 0l-.9.9a2 2 0 0 1-1.414.586H5.072a2 2 0 0 0-2 2v1.272a2 2 0 0 1-.586 1.414l-.9.9a2 2 0 0 0 0 2.828l.9.9a2 2 0 0 1 .586 1.414v1.272a2 2 0 0 0 2 2h1.272a2 2 0 0 1 1.414.586l.9.9a2 2 0 0 0 2.828 0l.9-.9a2 2 0 0 1 1.414-.586h1.272a2 2 0 0 0 2-2V13.8a2 2 0 0 1 .586-1.414Z"
                  />
                </svg>
              </span>
            </p>
            <div className="bg-midnightblue w-1/4 p-1 rounded-full text-center text-white h-8">
              Certified Seller
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <Button className="bg-normalblue p-3" onClick={toggledetail}>
              Show Seller's Details
            </Button>
            <Button className="border border-normalblue p-3 text-normalblue">
              Start Chat
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-row items-center justify-center">
          <svg
            viewBox="0 0 512 512"
            fill="normalbrown"
            height="3em"
            width="3em"
          >
            <path d="M449.07 399.08L278.64 82.58c-12.08-22.44-44.26-22.44-56.35 0L51.87 399.08A32 32 0 0080 446.25h340.89a32 32 0 0028.18-47.17zm-198.6-1.83a20 20 0 1120-20 20 20 0 01-20 20zm21.72-201.15l-5.74 122a16 16 0 01-32 0l-5.74-121.95a21.73 21.73 0 0121.5-22.69h.21a21.74 21.74 0 0121.73 22.7z" />
          </svg>
          <p className="text-normalblue text-center font-bold">
            Please Register/Login to get the sellers's details
          </p>
        </div>
      )}

      {isDetail && (
        <Modal show={isDetail} onClose={() => setDetail(false)}>
          <Modal.Header>{name} Details</Modal.Header>
          <Modal.Body>
            <div className="font-bold">
              Email:<span className="text-darkgray font-normal">{email}</span>
            </div>
            <div className="font-bold">
              Phone Number:
              <span className="text-darkgray font-normal">{number}</span>
            </div>
          </Modal.Body>
          {/* add calling button */}
          {/* <Modal.Footer>
          <Button color="gray" >
            Call
          </Button>
        </Modal.Footer> */}
        </Modal>
      )}
    </div>
  );
}

export default SellersCard;
