import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const RevenueLineChart = () => {
  const {token,logout}=useAuth();
  const [chartData, setChartData] = useState({
    weekly: {},
    monthly: {},
    yearly: {},
  });
  const [selectedOption, setSelectedOption] = useState('weekly');
  const navigate=useNavigate();

  const [month,setMonth]=useState([]);
  const [weekly,setWeekly]=useState([]);
  const [yearly,setYearly]=useState([]);
  
  const url =process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Simulate fetching revenue data (Replace with actual API or database call)
    const fetchRevenueData = async () => {
      // Start of current week

      // Generate data for the current week
      
      

      // get_dashboard_get_monthly_sales
      const additionalPath = `${url}/get_revenue`;
      const headers = {
          'Content-Type': 'application/json',
      };

      if(token){
        headers['Authorization'] = `Bearer ${token}`;
      }

      await axios.get(additionalPath, {headers: headers })
          .then(response => {
            console.log(response.data);
            setMonth(response.data.revenue.monthly);
            setWeekly(response.data.revenue.weekly);
            setYearly(response.data.revenue.yearly);
            
              //  setSales(response.data.event_sales);
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
                // Perform actions when 401 (Unauthorized) error occurs
                console.log('Unauthorized: Logging user out or performing specific action...');
                logout();
                navigate("/")
                // Example: Log the user out or redirect to login page
                // You may perform the necessary actions here based on your application flow
              } else {
                // Handle other errors
                console.log(err);
              
                 // Rethrow the error for further handling if needed
              }
        });

      // Generate monthly and yearly data (Simulated data)
      const monthlyData = {
        Jan: 0,
        Feb: 0,
        Mar: 0,
        Apr: 0,
        May: 0,
        Jun: 0,
        Jul: 0,
        Aug: 0,
        Sep: 0,
        Oct: 0,
        Nov: 0,
        Dec: 0,
      };
      // for (const months in month) {
      //   if (months in monthlyData) {
      //     monthlyData[months] = month[months];
      //   }
      // }
      month.forEach((item) => {
        const { month, revenue } = item;
        if (monthlyData.hasOwnProperty(month)) {
          monthlyData[month] =  parseFloat(revenue).toFixed(2); // Update value in updatedMonthlyData if the month exists
        }
      });

      const weeklyData = {
        Sun:0,Mon:0,Tue:0,Wed:0,Thurs:0,Fri:0,Sat:0
      };

      // for (const days in weekly) {
      //   if (days in weeklyData) {
      //     weeklyData[days] = weekly[days];
      //   }
      // }
      weekly.forEach((item) => {
        const { weekly, revenue } = item;
        if (weeklyData.hasOwnProperty(weekly)) {
          weeklyData[weekly] =  parseFloat(revenue).toFixed(2); // Update value in updatedMonthlyData if the month exists
        }
      });

      const yearlyData = {};
      // for (const year in yearly) {
      //     yearlyData[year] = yearly[year];
      // }

      yearly.forEach((item) => {
        const { yearly, revenue } = item;
        if (yearlyData.hasOwnProperty(yearly)) {
            yearlyData[yearly] = parseFloat(revenue).toFixed(2); // Update value in yearlyData if the property exists
        } else {
            yearlyData[yearly] = parseFloat(revenue).toFixed(2); // Add property to yearlyData if it doesn't exist
        }
    });

      setChartData({
        weekly: weeklyData,
        monthly: monthlyData,
        yearly: yearlyData,
      });
    };

    fetchRevenueData();
  }, []);

  const handleChangeOption = (e) => {
    setSelectedOption(e.target.value);
  };

  const chartOptions = {
    chart: {
      id: 'revenue-line-chart',
    },
    xaxis: {
      categories: Object.keys(chartData[selectedOption]),
    },
  };

  const chartSeries = [
    {
      name: `${selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)} Revenue`,
      data: Object.values(chartData[selectedOption]),
    },
  ];

  return (
    <div className="revenue-line-chart">
      <div>
        <label htmlFor="revenue-options">Select Data:</label>
        <select id="revenue-options" onChange={handleChangeOption} value={selectedOption} className="rounded-md p-2 border border-normalblue">
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </select>
      </div>
      <Chart options={chartOptions} series={chartSeries} type="line" height={350} />
    </div>
  );
};

export default RevenueLineChart;
