import { Button, Spinner,Checkbox,Label,Modal } from "flowbite-react";
import React, { useState, useEffect, useRef } from "react";
import { useEventContexts } from "../../context/EventContexts";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import PaystackPayment from "../PayStack/PayStackPayment";
import { useNavigate } from "react-router-dom";
import NsonoPayment from "../Nsono/NsonoPayment";

function SummaryPurchase({
  eventname,
  status,
  checkout,
  tickets,
  toggleDiv,
  events,
  addorder,
}) {
  const { isLogged, token, user,logout } = useAuth();

  const { temporaryTicketInfo } = useEventContexts();
  const navigate=useNavigate();

  const [payment, setPayment] = useState(false);
  const [updates, setUpdate] = useState(false);
  const [showPortal, setPortal] = useState(false);
  const [err, setErr] = useState("");
  const [order_id, setOrderId] = useState("");
  const [code, setCode] = useState("01");
  const [checkouturl, setCheckout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleTermsChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const handlePopupOpen = () => {
    setModalIsOpen(true);
  };

  const handlePopupClose = () => {
    setModalIsOpen(false);
  };

  const [firstname, setFirst] = useState(
    isLogged && user.fullname
      ? user.fullname.split(" ")[1]
      : temporaryTicketInfo.firstname !== ""
      ? temporaryTicketInfo.firstname
      : ""
  );
  const [lastname, setLast] = useState(
    isLogged && user.fullname
      ? user.fullname.split(" ")[1]
      : temporaryTicketInfo.lastname !== ""
      ? temporaryTicketInfo.lastname
      : ""
  );
  const [email, setEmail] = useState(
    isLogged ? user.email : temporaryTicketInfo.email
  );
  const [phonenumber, setPhone] = useState(
    isLogged ? user.phonenumber : temporaryTicketInfo.phonenumber
  );

  const displayProductDetails = tickets.map((obj, index) => {
    const { ticket, quantity, ticket_total } = obj;
    const associatedName = events.find((item) => item.id === ticket);

    return (
      <div
        className="flex flex-row justify-between text-darkgray font-medium p-2"
        key={index}
      >
        <p>{quantity}x</p>
        <p>{associatedName.name}</p>
        <p className="text-midnightblue">{ticket_total.toFixed(2)}</p>
      </div>
    );
  });

  const calculatesubTotal = () => {
    return tickets.reduce((total, tickets) => {
      return (
        total +
        (tickets.ticket_total !== undefined && tickets.ticket_total !== null
          ? tickets.ticket_total
          : 0)
      );
    }, 0);
  };

  const calculateFees = () => {
    const subTotal = calculatesubTotal();
    return subTotal * 0.15; // 15% of sub-total
  };

  const calculateTotal = () => {
    const subTotal = calculatesubTotal();
    const fees = calculateFees();
    return subTotal + fees;
  };

  const sub = calculatesubTotal();
  const fee = calculateFees();
  const tot = calculateTotal();

  const url =process.env.REACT_APP_API_URL;

  // useEffect(() => {
  //   if (updates) {
  //     // handlecheckout();
  //        setLoading(false);
  //         toggleDiv("receipt");
  //   }
  // }, [updates]);

  useEffect(() => {
    if (updates) {
      handleupdate();
      
    }
  }, [updates]);

  const [showRecallButton, setShowRecallButton] = useState(false);

 


  const handlecheckout = () => {
    // push it in api
    // return id and order number
    // call payment , if successful then push to permanent and label as paid

    setLoading(true);

    if (tot === 0 || payment) {
      let info;

      if (isLogged) {
        info = {
          event_id: temporaryTicketInfo.event_id,
          ticket_info: temporaryTicketInfo.ticket_info,
          status: "paid",
          fees: fee,
          subtotal: sub,
          total: tot,
        };
      } else {
        info = {
          event_id: temporaryTicketInfo.event_id,
          name:
            temporaryTicketInfo.firstname + " " + temporaryTicketInfo.lastname,
          email: temporaryTicketInfo.email,
          phonenumber: temporaryTicketInfo.phonenumber,
          ticket_info: temporaryTicketInfo.ticket_info,
          status: "paid",
          fees: fee,
          subtotal: sub,
          total: tot,
        };
      }

      console.log(info);

      // call api here
      const additionalPath = `${url}/add_ticket`;
      const headers = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }
      if (Object.values(info).every(value => value !== undefined && value !== null)) {
      axios
        .post(additionalPath, info, { headers: headers })
        .then((response) => {
          console.log(response.data.order_id);
          addorder(response.data.order_id);
          setLoading(false);
          toggleDiv("receipt");
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            logout();
            navigate("/")
          } else if (err.response && err.response.status === 500){
            setLoading(false);
            alert('There was an error processing your order.\nPlease try again later');
          }
          else {
            console.log(err);
            setErr(err);
          }
        });
      }else{
        setLoading(false);
      alert('Please fill in all required fields.');
      }
    } else {
      // call payment modal
    }
  };

  const handlecheckouts = () => {
    // push it in api
    // return id and order number
    // call payment , if successful then push to permanent and label as paid
    setLoading(true);
    let info;

      if (isLogged) {
        info = {
          event_id: temporaryTicketInfo.event_id,
          ticket_info: temporaryTicketInfo.ticket_info,
          status: "",
          fees: fee,
          subtotal: sub,
          total: tot,
        };
      } else {
        info = {
          event_id: temporaryTicketInfo.event_id,
          name:
            temporaryTicketInfo.firstname + " " + temporaryTicketInfo.lastname,
          email: temporaryTicketInfo.email,
          phonenumber: temporaryTicketInfo.phonenumber,
          ticket_info: temporaryTicketInfo.ticket_info,
          status: "",
          fees: fee,
          subtotal: sub,
          total: tot,
        };
      }

    

    if (tot === 0) {
      info.status="paid"

    } else {
      info.status = "not paid";
      // call payment modal
    }


    console.log(info);


      // call api here
      const additionalPath = `${url}/add_ticket`;
      const headers = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }
      if (Object.values(info).every(value => value !== undefined && value !== null)) {
      axios
        .post(additionalPath, info, { headers: headers })
        .then((response) => {
          addorder(response.data.order_id);
          setOrderId(response.data.order_id);

          if(info.total===0){
            setLoading(false);
            // toggleDiv("receipt");
            navigate(`/verifycode?code=${response.data.order_id}`)

          }else{
            if(response.data.payment!==null){
              loadedOnce.current=false;
              setCheckout(response.data.payment.data.links.checkout_url);

              // window.location.href= response.data.checkout_url;
            }

          }

          
          // call checkout here
          // setLoading(false);
          // toggleDiv("receipt");
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            logout();
            navigate("/")
          } else if (err.response && err.response.status === 500){
            setLoading(false);
            alert('There was an error processing your order.\nPlease try again later');
          }
          else {
            console.log(err);
            setErr(err);
          }
        });
      }else{
        setLoading(false);
      alert('Please fill in all required fields.');
      }
  };

  const additionalPaths = `${url}/verify_payment`;
  const configs = {
    order_id: order_id, // Unique payment reference(use event no)
  };

  const headers = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  // verify
  const handleverify = async () => {
    await axios
      .post(additionalPaths, configs, {
        headers: headers,
      })
      .then((response) => {


        if(response.code=="00"){
          setCode(response.code)
            setUpdate(true);
            verifyOnce.current=true;
            // setSuccess(true);

        }else{
            // alert("Payment not successful. Reload again")
            // setLoading(false)
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // console.log(
          //   "Unauthorized: Logging user out or performing specific action..."
          // );
          // logout();
          // navigate("/")
          console.error(err);
        } else {
          console.error(err);
        }
      });
  };

  const additionalPats = `${url}/verify_payment`;
  const config = {
    order_id: order_id, // Unique payment reference(use event no)
  };

  const handleupdate=async () => {
    setLoading(false);
    toggleDiv("receipt");
   

    // await axios
    //   .post(additionalPats, config, {
    //     headers: headers,
    //   })
    //   .then((response) => {
    //     console.log(response);
    //         setLoading(false);
    //       toggleDiv("receipt");

        
       

   
       
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.status === 401) {
    //       // console.log(
    //       //   "Unauthorized: Logging user out or performing specific action..."
    //       // );
    //       // logout();
    //       // navigate("/")
    //       console.error(err);
    //     } else {
    //       console.error(err);
    //     }
    //   });
  };

  const loadedOnce=useRef(false);
  const [timeRemaining, setTimeRemaining] = useState(120);
  const verifyOnce=useRef(false);

  useEffect(() => {
    let timer;
    
    // const verifyTransaction = () => {
    //   if (code !== "00") {
    //     // Call handleverify if code is not "00"
    //     handleverify();
    //     // Wait for a certain duration before calling verifyTransaction again
    //     timer = setTimeout(() => {
    //       setShowRecallButton(true); // Show the recall button after 2 minutes
    //       setLoading(false); // Set loading to false after 2 minutes
    //     }, 2 * 60 * 1000); // 2 minutes in milliseconds
    //   }
    // };

    // Open checkout URL in a new tab
    if (checkouturl !== null&&loadedOnce.current===false) {
      window.open(checkouturl, "_self");
      loadedOnce.current=true;

      // timer = setInterval(() => {
      //   setTimeRemaining(prevTime => {
      //     if (prevTime <= 0) {
      //       clearInterval(timer);
      //       setShowRecallButton(true); // Show the recall button after 2 minutes
      //       setLoading(false); // Set loading to false after 2 minutes
      //       return 0;
      //     }
      //     return prevTime - 1;
      //   });
      // }, 1000);
    }

    // Start verifying transaction
    // if (checkouturl !== null && code === "01"&&!verifyOnce.current) {
    //   verifyTransaction();
    //   verifyOnce.current=true
    // }

    // return () => {
    //   // Clear the timer when the component unmounts or the effect runs again
    //   clearInterval(timer);
    //   clearTimeout(timer);
    // };
  }, [checkouturl,loadedOnce]);

  // const recallHandleVerify = () => {
  //   // Hide the recall button
  //   setShowRecallButton(false);
  //   // Call handleverify again
  //   handleverify();
  //   // Reset loading state if needed
  //   setLoading(true); // Set loading to true again
  // };

  // const formatTime = (time) => {
  //   const minutes = Math.floor(time / 60);
  //   const seconds = time % 60;
  //   return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  // };
      
  
  return (
    <div className="relative">
      <div className="border border-lightgray p-6 rounded-md space-y-4">
        <p className="text-center text-midnightblue font-bold text-xl">
          {eventname}
        </p>
        <hr></hr>
        {checkout ? (
          <div>
            <p className="font-bold text-xl">User Information</p>
            <div className="flex flex-col justify-between text-darkgray font-medium p-2">
              <p className="flex justify-between">
                Full Name:<span>{firstname + " " + lastname}</span>
              </p>
              <p className="flex justify-between">
                Email Address:<span>{email}</span>
              </p>
              <p className="flex justify-between">
                Phone Number:<span>{phonenumber}</span>
              </p>
            </div>
          </div>
        ) : null}
        {status ? (
          // loop through and show the packages selected

          <div>
            <p className="font-bold text-xl">Ticket Information</p>

            {displayProductDetails}
            <hr></hr>
            <div className="flex flex-row justify-between text-darkgray font-medium p-2">
              <p>Fees</p>
              <p className="text-midnightblue">
                GHC {calculateFees().toFixed(2)}
              </p>
            </div>
            <div className="flex flex-row justify-between text-darkgray font-medium p-2">
              <p>Sub Total</p>
              <p className="text-midnightblue">
                GHC {calculatesubTotal().toFixed(2)}
              </p>
            </div>
            <hr></hr>

            <div className="flex flex-row justify-between font-bold text-xl p-2">
              <p>Total</p>
              <p>GHC {calculateTotal().toFixed(2)}</p>
            </div>
            <hr></hr>

            {checkout && !isLogged? (<div className="flex items-center gap-2 my-4">
        <Checkbox id="remember" checked={termsAccepted} onChange={handleTermsChange} />
        <Label htmlFor="remember">
        I accept the{" "}
        <a href="#" onClick={handlePopupOpen} className="text-normalblue">
          Terms and Conditions
        </a>
      </Label>

     
      </div>):null}
            
            
            { checkout?( <Button
                  onClick={handlecheckouts}
                  className={`bg-normalblue w-full ${
                    !termsAccepted&&!isLogged ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={!termsAccepted&&!isLogged }
                >
                  <p>Checkout</p>
                </Button>):null}
                {/* {checkout ? (
              tot === 0 ? (
                <Button
                  onClick={handlecheckout}
                  className={`bg-normalblue w-full ${
                    !termsAccepted&&!isLogged ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={!termsAccepted&&!isLogged }
                >
                  <p>Checkout</p>
                </Button>
              ) : (
                // <PaystackPayment
                //   setSuccess={setPayment}
                //   amount={tot}
                //   email={email}
                //   terms={termsAccepted}
                // />
                <NsonoPayment
                setSuccess={setPayment}
                  amount={tot}
                  email={email}
                  terms={termsAccepted}
                  oid={temporaryTicketInfo.event_id}
                  setLoading={setLoading}
                />
              )
            ) : null} */}
          </div>
        ) : (
          <div className="text-darkgray">
            Please choose the ticket type you want to purchase . Select the
            number of tickets you will want to purchase
          </div>
        )}
      </div>
      {modalIsOpen && (
        <Modal isOpen={modalIsOpen} onClose={handlePopupClose} title="Terms and Conditions">
          {/* Your terms and conditions content goes here */}
          <p>This is a sample content for terms and conditions.</p>
          <Button onClick={handlePopupClose}>Close Modal</Button>
        </Modal>
      )}
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md flex flex-col items-center">
            {/* Your spinner component */}
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
             {/* <p className="font-bold text-lg">Time remaining</p>
             <p className="text-normalbrown text-2xl font-bold"> {formatTime(timeRemaining)}</p> */}
          </div>
        </div>
      )}
      {/* {showRecallButton && <div className="flex items-center justify-center m-2"><button onClick={recallHandleVerify} className="bg-normalbrown w-1/2 text-white p-2 rounded-full">Recall Verify</button></div>} */}
    </div>
  );
}

export default SummaryPurchase;
