import { Label, TextInput, Button } from "flowbite-react";
import React, { useState, useEffect } from "react";
import logo from "../../../assets/images/KRIFFEONLINELOGOST.png";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";
import { Spinner } from "flowbite-react";

function Authentication({ setLogin, toggleDiv }) {
  const { adminlog, login } = useAuth();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let errors = {};

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email address";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (
      formData.password.length < 6 
    ) {
      errors.password = "Password must be at 6 characters";
    }

    return errors;
  };

  const emailRegex = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
  const isValidEmail = (email) => {
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (data.responsemessage == "Login successful.") {
      handleuser();
    }
    if (data.userdetails) {
      adminlog(data.userdetails);
      setLoading(false);
      setSuccess(true);
      toggleDiv("dashboard");
    }
  }, [data]);

  const user = {
    email: formData.email,
    password: formData.password,
  };

  const url = process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/login`;

  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };
  if (data.usertoken) {
    headers["Authorization"] = `Bearer ${data.usertoken}`;
  }

  const handlelogin = async () => {
    setLoading(true);

    

    const errors = validateForm();
    const queryParams = new URLSearchParams(formData).toString();

    if (Object.keys(errors).length === 0) {
      axios
        .post(additionalPath, user, { headers: headers })
        .then((response) => {
          // Handle successful response
          setData(response.data);
          login(response.data.usertoken);
          // setLoading(false);
        })
        .catch((error) => {
          // Handle error
          setErr(error.response.data.responsemessage);
          setLoading(false);
          // console.log(error.response.data.responsemessage)
          console.error("Error:", error);
        });
    } else {
      // Update the state with validation errors
      setErrors(errors);
      setLoading(false);

    }
  };

  const handleuser = () => {
    // login(data.usertoken);
    handledetails();
  };

  const handledetails = () => {
    const additionalPaths = `${url}/get_current_user_info`;
    axios
      .get(additionalPaths, { headers: headers })
      .then((response) => {
        // Handle successful response

        setData(response.data);
        console.log(response.data)
        // setLoading(false);
      })
      .catch((error) => {
        // Handle error
        setErr("Account not found");
        console.error("Error:", error);
      });
  };
  return (
    <div className="relative">
    <div className="flex items-center justify-center bg-midnightblue w-screen h-screen flex-col">
      <img src={logo} className="h-36" />

  



      <div className="bg-white rounded-md shadow-md p-4 xl:w-1/4 sm:w-1/2 w-4/5 flex flex-col gap-4">
        <p className="text-xl font-bold text-center">KriffeOnline Admin</p>

        {err && (
          <div className="bg-red-100 p-2 rounded-md border-l-4 border-red-600">
            <p className="text-red-600 font-bold text-sm">{err}</p>
          </div>
        )}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="usernmae" value="Your Username" />
          </div>
          <TextInput
            id="username"
            placeholder="*****"
            value={formData.email}
            onChange={handleInputChange}
            name="email"
            required
            class={
              formErrors.user
                ? "bg-red-100 border border-red-600 text-red-600  text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
                : "bg-gray-50 border border-normalblue text-midnightblue text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
            }
            helperText={
              formErrors.username && (
                <>
                  <span className="font-medium text-red-600">
                    {formErrors.user}
                  </span>
                </>
              )
            }
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="password" value="Your Password" />
          </div>
          <TextInput
            id="password"
            placeholder="*****"
            type="password"
            value={formData.password}
            onChange={handleInputChange}
            name="password"
            required
            class={
              formErrors.password
                ? "bg-red-100 border border-red-600 text-red-600  text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
                : "bg-gray-50 border border-normalblue text-midnightblue text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
            }
            helperText={
              formErrors.password && (
                <>
                  <span className="font-medium text-red-600">
                    {formErrors.password}
                  </span>
                </>
              )
            }
          />
        </div>
        <div className="flex items-center justify-center">
          <Button
            onClick={handlelogin}
            className="bg-normalblue text-white p-2 w-1/2"
          >
            Authenticate
          </Button>
        </div>
      </div>
      

    </div>
    {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-30 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md">
            {/* Your spinner component */}
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Authentication;
