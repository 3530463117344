import React, { useState ,useEffect,useRef} from "react";
import { useNavigate } from "react-router-dom";
import MainPagination from "../../../components/Miscellanous/MainPagination";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";
import { Button,Badge,Spinner,Table } from "flowbite-react";
import MessageModal from "../../../components/Modal/Admin/MessageModal";
import MessageEditModal from "../../../components/Modal/Admin/MessageEditModal";
import { fetchDataForCategory } from "../../../api/ApiPage";
import CategoryModal from "../../../components/Modal/Admin/CategoryModal";
import CategoryEditModal from "../../../components/Modal/Admin/CategoryEditModal";


function Category() {
  const { token, adminlogout } = useAuth();
  const [categoriesdata, setUsers] = useState([]);
  const [tags, setTags] = useState(null);
  const navigate = useNavigate();
  const loadedOnce = useRef(false);
  const [id,setID]=useState(null);
  const [name,setName]=useState("");
 

  const initialPayload = {
    categoryid: 1,
  };

  const [formData, setFormData] = useState(initialPayload);
  const [currentPage, setCurrentPage] = useState(1);

  const handleParamChange = (name, value) => {
    setFormData((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
    loadedOnce.current = false;
  };
  const [per_page, setPerpage] = useState(20);
  const [total, setTotal] = useState(categoriesdata.length);
  const itemsPerPage = categoriesdata && per_page;

  const itemsToShow = categoriesdata;

  const totalPages = categoriesdata && total > 0 && Math.ceil(total / itemsPerPage);

  const [open,setModal]=useState(false);

  const handleModal=(id,name)=>{
    setID(id)
    setName(name);
    setModal(!open)
  }

  const [edit,setEModal]=useState(false);

  const handleEditModal=(id,name)=>{
    setID(id);
    setName(name);
    setEModal(!edit)
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    handleParamChange("currentpage", newPage);
  };

  useEffect(() => {
    if (loadedOnce.current) return;
    loadedOnce.current = true;
    handleseq();
  }, [loadedOnce]);
  const url = process.env.REACT_APP_API_URL;

 

  const handleseq=()=>{
    if (categoriesdata.length === 0) {
        fetchDataForCategory(adminlogout).then((data) => {
          setUsers(data);
        });
      }

  }

  const handletotalevent = async (id,name) => {
    if(tags&&tags.length>0){
        setTags(null)
    }


    const additionalPath = `${url}/get_tags`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const stats={
        categoryid:id
    }



    

    await axios
      .get(additionalPath, { params: stats, headers: headers })
      .then((response) => {
        console.log(response)
        setTags(response.data.tags);
        handleModal(id,name)


       
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          adminlogout();
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            adminlogout();
            navigate("/admin");
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            loadedOnce.current = true;
            console.log(err);

            // Rethrow the error for further handling if needed
          }
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          loadedOnce.current = true;
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };


  return (
    <div className="px-3">
      <p className="font-bold text-xl">Messages</p>

      {/* table */}
      <div className="mt-4  xl:block hidden">
        <Table>
          <Table.Head>
            <Table.HeadCell>Category</Table.HeadCell>
            <Table.HeadCell>Type</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current==true? (itemsToShow && itemsToShow.length != 0 ? (
              itemsToShow.map((userData, index) => (
                <Table.Row
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  key={index}
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {userData.name}
                  </Table.Cell>
                  <Table.Cell>
                    {userData.type}
                  </Table.Cell>
              
                  <Table.Cell>
                  <div className="flex  gap-2">
                     

                     <a
                       className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handletotalevent(userData.id,userData.name)}
                     >
                       <p>View</p>
                     </a>

                     <a
                       className="font-medium text-red-900 hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handleEditModal(userData.id,userData.name)}
                     >
                       <p>Edit</p>
                     </a>
                   </div>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  No Categories
                </Table.Cell>
              </Table.Row>
            )):(
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                <div className="flex justify-center items-center h-full">
          <Spinner aria-label="Loading" color="warning" size="md" />
        </div>
                </Table.Cell>
              </Table.Row>
              
            )}
          </Table.Body>
        </Table>
      </div>

      <div className="mt-4  md:block  xl:hidden hidden ">
        <Table>
          <Table.Head>
          <Table.HeadCell>Category</Table.HeadCell>
            <Table.HeadCell>Type</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current==true?(itemsToShow && itemsToShow.length != 0 ? (
              itemsToShow.map((userData, index) => (
                <Table.Row
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  key={index}
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {userData.name}
                  </Table.Cell>

                  <Table.Cell>{userData.type}</Table.Cell>
                 
              
                  <Table.Cell>
                  <div className="flex  gap-2">
                     

                     <a
                       className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handletotalevent(userData.id,userData.name)}
                     >
                       <p>View</p>
                     </a>

                     <a
                       className="font-medium text-red-900 hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handleEditModal(userData.id,userData.name)}
                     >
                       <p>Edit</p>
                     </a>
                   </div>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  No Categories
                </Table.Cell>
              </Table.Row>
            )):(
              <Table.Row>
              <Table.Cell colSpan={6} className="bg-white text-center">
              <div className="flex justify-center items-center h-full">
        <Spinner aria-label="Loading" color="warning" size="md" />
      </div>
              </Table.Cell>
            </Table.Row>

            )}
          </Table.Body>
        </Table>
      </div>

      <div className="mt-5  md:hidden lg:hidden xl:hidden block">
        <Table>
          <Table.Head>
            <Table.HeadCell>Category</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current==true? (itemsToShow && itemsToShow.length != 0 ? (
              itemsToShow.map((userData, index) => (
                <Table.Row
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  key={index}
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {userData.name}
                  </Table.Cell>

                  <Table.Cell>
                  <div className="flex  gap-2">
                     

                     <a
                       className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handletotalevent(userData.id,userData.name)}
                     >
                       <p>View</p>
                     </a>

                     <a
                       className="font-medium text-red-900 hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handleEditModal(userData.id,userData.name)}
                     >
                       <p>Edit</p>
                     </a>
                   </div>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  No Category
                </Table.Cell>
              </Table.Row>
            )):( <Table.Row>
              <Table.Cell colSpan={6} className="bg-white text-center">
              <div className="flex justify-center items-center h-full">
        <Spinner aria-label="Loading" color="warning" size="md" />
      </div>
              </Table.Cell>
            </Table.Row>)}
          </Table.Body>
        </Table>
      </div>

      <MainPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

{open&&<CategoryModal id={id} open={open} handleModal={handleModal} tags={tags} name={name}/>}
      {edit&&<CategoryEditModal id={id} open={edit} handleModal={handleEditModal} tags={tags} name={name}/>}
    </div>
  );
}


export default Category;