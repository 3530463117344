import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Spinner, Badge,Alert } from "flowbite-react";
import { useLocation, useParams } from "react-router-dom";
import { format, parse } from "date-fns";
import MainPagination from "../../../components/Miscellanous/MainPagination";
import { useEventOrganizerContext } from "../../../context/EventOrganizerContext";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";
import WithdrawalModal from "../../../components/Modal/WithdrawalModal";
import AdModal from "../../../components/Modal/AdModal";
import AdCarousel from "../../../components/Carousels/AdCarousel";
import { useNavigate } from "react-router-dom";

function EventPage() {
  const { token, logout } = useAuth();
  const { id,status } = useParams();
  const navigate = useNavigate();

  // api to gert orders
  const [event, setEvent] = useState([]);
  const [eventorder, setOrders] = useState([]);
  const [isWithdraw, setWithdraw] = useState(false);
  const handleWithdrawal = () => {
    setWithdraw(!isWithdraw);
  };
  const [loadedOnce, setLoadedOnce] = useState(true);
  const [category, setCategory] = useState("");

  //   get orders per id
  // get_event_by_id?event_id
  const ulr= id.split("_")

  const [payload, setPayload] = useState({ event_id: ulr[0] });
  const parsedTime = event.time && parse(event.time, "HH:mm:ss", new Date());

  // Format the time in 12-hour clock with AM/PM indicator
  const formattedTime = parsedTime && format(parsedTime, "h:mm a");

  const url =process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/get_event_details_by_id`;
  // const path=`get_product_by_id?id=${id}`;
  // makeApiRequest("GET", null, path, token);
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the range of items for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToShow = eventorder.slice(startIndex, endIndex);
  const totalItems = eventorder.length;

  const totalPages = Math.ceil(eventorder.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  let statusMessage;
  let statusColor;

  switch (event.status) {
    case "active":
      statusMessage = "Active";
      statusColor = "bg-green-200 text-green-700";
      break;
    case "closed":
      statusMessage = "Closed";
      statusColor = "bg-red-200 text-red-700";
      break;
    default:
      statusMessage = "Pending";
      statusColor = "bg-lightbrown text-normalbrown";
      break;
  }

  const capitalize = (text) => {
    if (typeof text !== "string") {
      return "";
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  // ad_type_expiration_date
  const isToday = () => {
    const currentDate = new Date();
    const formattedInputDate = new Date(event.ad_type_expiration_date);

    // Set hours, minutes, seconds, and milliseconds to zero for comparison
    currentDate.setHours(0, 0, 0, 0);
    formattedInputDate.setHours(0, 0, 0, 0);

    return currentDate.getTime() === formattedInputDate.getTime();
  };

  const [isDate, setDate] = useState(false);
  useEffect(() => {
    if (event.ad_type_expiration_date != null) {
      // call api to make the payment
      setDate(isToday());
    }
  }, [event]);

  const [isBoost, setBoost] = useState(false);
  const handleBoost = () => {
    setBoost(!isBoost);
  };

  const statustext = (status) => {
    if (status === "paid") {
      return (
        <Badge color="success" className="flex justify-center">
          Paid
        </Badge>
      );
    } else {
      return (
        <Badge color="failure" className="flex justify-center">
          Not Paid
        </Badge>
      );
    }
  };

  const [isDatePassed, setIsDatePassed] = useState(false);

  useEffect(() => {
    // Assuming `date` is a string in the format "YYYY-MM-DD"
    const eventDate = new Date(event.date);
    const currentDate = new Date();

    setIsDatePassed(eventDate < currentDate);
  }, [event]);



  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("description");
  const [toast,setToast]=useState(false);
  const [fail,setFail]=useState(false);
  const [codes, setCode] = useState("0");
  const verifyOnce = useRef(false); 
  const updateOnce = useRef(false); 
  const updatedOnce = useRef(false); 
  const [updates, setUpdate] = useState(false);
  const [selectedId,setSelected]=useState(code?(parseInt(code)):(ulr[2]?(parseInt(ulr[2])):(1)));
  const order = queryParams.get("order_id");

  console.log(code)



  const additionalPaths = `${url}/verify_payment`;
  const configs = {
    order_id: order,
  };
 
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  const push = async () => {
    await axios
      .post(additionalPaths, configs, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.data.status==='1') {
          setCode(response.data.data.status);
          setUpdate(true);
        } else {
          // alert("Payment not successful. Reload again")
          setLoadedOnce(false);
          setFail(true)
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          console.error(err);
        } else {
          console.error(err);
        }
      });
  };


  
  useEffect(() => {
    if (status) {

      if(code){
        if (updateOnce.current) return;
        updateOnce.current = true;
        handleupdated();

      }else{
        const verifyTransaction = () => {
          if (codes !== "1") {
            push();
          }
        };
  
        if (verifyOnce.current) return;
        verifyOnce.current = true;
        verifyTransaction();
  
        if (updates) {
          handleupdated();
        }

      }
      
    }
  }, [verifyOnce, push, status, updates,code]);

  useEffect(() => {
    if (updates) {

  
      if (updatedOnce.current) return;
      updatedOnce.current = true;
      handleupdated();
        

      
      
    }
  }, [updatedOnce, updates]);


  // go to the first page 
  const handleupdated = () => {
    // add to the temp array



   
    const info = {
      ad_type: selectedId,
    };
  
    const param = {
      event_id: ulr[0],
    };

    // call the paystack api and pay and after success call the update expiry api
    // update_product?product_id=PRD-20231113-GrYP
    const additionalPaths = `${url}/update_event_expiry_date`;
    const headers = {
      "Content-Type": "application/json",
      // 'Authorization':  `Bearer ${token}`,
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .patch(additionalPaths,info,{ params:param,headers: headers })
      .then((response) => {
       setBoost(false);
        if (response.data.responsecode === 200) {
          setToast(true)
          setLoadedOnce(false);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
          setFail(true)

          // Rethrow the error for further handling if needed
        }
      });

    // update the product ifrst, if successful then call the paystack api an pay and if succesful call update
  };
  const updatesOnce=useRef(false)
  useEffect(() => {
    // Make the first API call
    // handleevent();
    if ((event.length === 0 || (toast || fail)) && !updatesOnce.current){
      axios
        .get(additionalPath, { params: payload, headers: headers })
        .then((response) => {
          console.log(response.data.event);
          setEvent(response.data.event);
          setOrders(response.data.event.orders);
          setCategory(response.data.event.event_category_name);
  
          if(!status){
            setLoadedOnce(false);
        }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            logout();
            navigate("/");
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            console.log(err);

            // Rethrow the error for further handling if needed
          }
        });

        updatesOnce.current = true;
    }
  }, [event, payload, headers,toast,fail]);

  return (
    <div className="relative ">
      <div className="flex flex-col gap-4  p-3">
        <a href="/eventorganizer/events" className="text-normalblue">
          {" "}
          {`<`}Go Back
        </a>

        <p className="font-bold sm:text-5xl text-3xl ">Event Details</p>


        {toast&&<div className="px-3"><Alert color="success" onDismiss={() => setToast(false)}>
      <span className="font-medium">Update successful! </span>New Ad Type!
    </Alert></div>}
    {fail&&<div className="px-3"><Alert color="failure" onDismiss={() => setFail(false)}>
      <span className="font-medium">Update unsuccessful! </span>Please restart the process
    </Alert></div>}

        <div className="w-full h-80 rounded-md bg-midnightblue">
          <AdCarousel images={event.images} />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm text-normalblue">
            {" "}
            {event.date && format(new Date(event.date), "EE, MMM dd")},
            {formattedTime}
          </p>

          <p className="font-bold sm:text-7xl text-5xl ">{event.name}</p>
        </div>
        <div className="flex justify-left flex-row gap-2">
          <Button className={statusColor} pill>
            {statusMessage}
          </Button>
          <Button className="bg-lightblue/40 text-normalblue" pill>
            {category}
          </Button>
        </div>

        <div className="bg-white rounded-md p-4">
          <p className="text-darkgray font-bold">About the Event</p>
          <p className="break-words text-lg font-light">{event.description}</p>

          <p className="font-bold text-xl">Location</p>
          <p className="font-light text-base">{event.location}</p>
          <p className="font-bold text-xl">Event Type</p>
          <p className="font-light text-base">
            {event.event_type && event.event_type.join(",")}
          </p>

          <p className="font-bold text-xl">Ticket Packages</p>
          <div className="flex flex-col gap-2">
            {event.ticket_type && event.ticket_type.length != 0 ? (
              event.ticket_type.map((type, index) => (
                <div>
                  <p className="font-medium">#Package {index + 1}</p>
                  <p className="font-medium">
                    Package name:
                    <span className="font-light">{type.name}</span>
                  </p>
                  <p className="font-medium">
                    Price:<span className="font-light">GHC{type.price}</span>
                  </p>

                  <p className="font-medium flex flex-wrap">
                    Description:
                    <span className="font-light break-words">
                      {type.description}
                    </span>
                  </p>
                </div>
              ))
            ) : (
              <p>No Packages</p>
            )}
          </div>

          <div className="mt-2">
              <p className="font-bold text-xl">Social Media</p>
              {event.social_media &&
                event.social_media.map((link, index) => (
                  <div
                    key={index}
                    className="flex flex-row gap-2 items-center mt-2"
                  >
                    {Object.keys(link).map((key) => (
                      <div key={key}>
                        {key === "facebook" && link[key] != null && (
                          <a href={link[key]}>
                            <svg
                              class="w-5 h-5 text-normalblue dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 8 19"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </a>
                        )}
                        {key === "youtube" && link[key] != null && (
                          <a href={link[key]}>
                            <svg
                              class="w-5 h-5 text-normalblue dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 14"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M19.7 3.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.84c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.84A4.225 4.225 0 0 0 .3 3.038a30.148 30.148 0 0 0-.2 3.206v1.5c.01 1.071.076 2.142.2 3.206.094.712.363 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.15 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965c.124-1.064.19-2.135.2-3.206V6.243a30.672 30.672 0 0 0-.202-3.206ZM8.008 9.59V3.97l5.4 2.819-5.4 2.8Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </a>
                        )}
                        {key === "twitter" && link[key] != null && (
                          <a href={link[key]}>
                            <svg
                              class="w-5 h-5 text-normalblue dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fill="currentColor"
                                d="M12.186 8.672 18.743.947h-2.927l-5.005 5.9-4.44-5.9H0l7.434 9.876-6.986 8.23h2.927l5.434-6.4 4.82 6.4H20L12.186 8.672Zm-2.267 2.671L8.544 9.515 3.2 2.42h2.2l4.312 5.719 1.375 1.828 5.731 7.613h-2.2l-4.699-6.237Z"
                              />
                            </svg>
                          </a>
                        )}
                        {key === "instagram" && link[key] != null && (
                          <a href={link[key]}>
                            <svg
                              viewBox="0 0 1024 1024"
                              fill="currentColor"
                              height="1.25rem"
                              width="1.25rem"
                              className="text-normalblue"
                            >
                              <path d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3 645.3 585.4 645.3 512 585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9 717.1 398.5 717.1 512 625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9 47.9 21.4 47.9 47.9a47.84 47.84 0 01-47.9 47.9z" />
                            </svg>
                          </a>
                        )}
                        {key === "linkedin" && link[key] != null && (
                          <a href={link[key]}>
                            <svg
                              class="w-5 h-5 text-normalblue dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 15 15"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                                clip-rule="evenodd"
                              />
                              <path d="M3 5.012H0V15h3V5.012Z" />
                            </svg>
                          </a>
                        )}
                        {key === "website" && link[key] != null && (
                          <a href={link[key]}>
                            <svg
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              height="1.25rem"
                              width="1.25rem"
                              className="text-normalblue"
                            >
                              <path d="M16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2m-5.15 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56M14.34 14H9.66c-.1-.66-.16-1.32-.16-2 0-.68.06-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2M12 19.96c-.83-1.2-1.5-2.53-1.91-3.96h3.82c-.41 1.43-1.08 2.76-1.91 3.96M8 8H5.08A7.923 7.923 0 019.4 4.44C8.8 5.55 8.35 6.75 8 8m-2.92 8H8c.35 1.25.8 2.45 1.4 3.56A8.008 8.008 0 015.08 16m-.82-2C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2M12 4.03c.83 1.2 1.5 2.54 1.91 3.97h-3.82c.41-1.43 1.08-2.77 1.91-3.97M18.92 8h-2.95a15.65 15.65 0 00-1.38-3.56c1.84.63 3.37 1.9 4.33 3.56M12 2C6.47 2 2 6.5 2 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2z" />
                            </svg>
                          </a>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
            </div>
        </div>

        <div className="flex sm:flex-row flex-col gap-2 justify-between">
        
        {event.status == "active" && isDatePassed && (
            
              <div className="basis-1/2 h-auto mt-2 w-full border border-normalblue rounded-md p-2 bg-white flex flex-col justify-center">
                <p className="font-bold text-xl text-center">Withdrawals</p>
                <p className="text-darkgray text-center">
                  Request to withdraw revenue from this event
                </p>{" "}
                <Button
                  className="bg-normalbrown p-2 w-full"
                  onClick={handleWithdrawal}
                >
                  Make Request
                </Button>
              </div>
           

  )}
 {event.status == "active" &&
            (event.ad_type_name == "Standard" || isDate) ? (
            
              <div className="basis-1/2  h-auto mt-2 w-full border border-normalblue rounded-md p-2 bg-white">
                <p className="font-bold text-xl text-center">Boost your Ad</p>
                <p className="text-darkgray text-center">
                  Upgrade your {event.ad_type} Ad and make it more visible on
                  the marketplace
                </p>{" "}
                <Button
                  className="bg-lightblue p-2 w-full"
                  onClick={handleBoost}
                >
                  Boost
                </Button>
              </div>
      
        
                ) : null}
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-midnightblue font-medium text-3xl">Summary</p>
          <div className="flex flex-row gap-2">
            <div className="flex flex-col bg-white rounded-md p-4 justify-between w-1/3 items-center">
              <svg
                class="w-6 h-6 text-normalblue"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 18"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 12v5m5-9v9m5-5v5m5-9v9M1 7l5-6 5 6 5-6"
                />
              </svg>
              <p className="text-xs text-darkgray">Total Orders</p>
              <p className="text-midnightblue font-bold text-xl">
                {event.total_orders}
              </p>
            </div>

            <div className="flex flex-col bg-white rounded-md p-4 justify-between w-1/3 items-center">
              <svg
                class="w-6 h-6 text-normalblue"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 18"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 12v5m5-9v9m5-5v5m5-9v9M1 7l5-6 5 6 5-6"
                />
              </svg>
              <p className="text-xs text-darkgray">Total Revenue</p>
              <p className="text-midnightblue font-bold text-xl">
                {event.revenue}
              </p>
            </div>

            <div className="flex flex-col bg-white rounded-md p-4 justify-between w-1/3 items-center">
              <svg
                class="w-6 h-6 text-normalblue"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 18"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 12v5m5-9v9m5-5v5m5-9v9M1 7l5-6 5 6 5-6"
                />
              </svg>
              <p className="text-xs text-darkgray">Total Packages</p>
              <p className="text-midnightblue font-bold text-xl">
                {event.ticket_type && event.ticket_type.length}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-midnightblue font-medium text-3xl">Orders</p>

          {/* regular screen */}
          <div className="mt-4 xl:block hidden">
            <Table>
              <Table.Head>
                <Table.HeadCell>Order Number</Table.HeadCell>
                <Table.HeadCell>Number of Tickets</Table.HeadCell>
                <Table.HeadCell>Total Price GHC</Table.HeadCell>
                <Table.HeadCell>Status</Table.HeadCell>

                <Table.HeadCell>Actions</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {itemsToShow && itemsToShow.length > 0 ? (
                  itemsToShow.map((order, index) => (
                    <Table.Row
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      key={index}
                    >
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        #{order.order_no}
                      </Table.Cell>
                      <Table.Cell>
                        {order.ticket_details.total_quantity}
                      </Table.Cell>
                      <Table.Cell>
                        {order.ticket_details.total_price}
                      </Table.Cell>

                      <Table.Cell>{statustext(order.status)}</Table.Cell>
                      <Table.Cell>
                        <a
                          className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                          href={`/eventorganizer/receipt/${id}/${order.order_no}`}
                        >
                          <p>View</p>
                        </a>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell colspan={5} className="bg-white text-center">
                      No Orders
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>

          <div className="mt-4  md:block  xl:hidden hidden">
            <Table>
              <Table.Head>
                <Table.HeadCell>Order Number</Table.HeadCell>
                <Table.HeadCell>Number of Tickets</Table.HeadCell>
                {/* <Table.HeadCell>Total Price GHC</Table.HeadCell> */}
                <Table.HeadCell>Status</Table.HeadCell>

                <Table.HeadCell>Actions</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {itemsToShow && itemsToShow.length > 0 ? (
                  itemsToShow.map((order, index) => (
                    <Table.Row
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      key={index}
                    >
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        #{order.order_no}
                      </Table.Cell>
                      <Table.Cell>
                        {order.ticket_details.total_quantity}
                      </Table.Cell>
                      {/* <Table.Cell>{order.ticket_details.total_price}</Table.Cell> */}

                      <Table.Cell>{statustext(order.status)}</Table.Cell>
                      <Table.Cell>
                        <a
                          className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                          href={`/eventorganizer/receipt/${id}/${order.order_no}`}
                        >
                          <p>View</p>
                        </a>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell colspan={5} className="bg-white text-center">
                      No Orders
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>

          {/* small screen */}
          <div className="mt-4 md:hidden lg:hidden xl:hidden block">
            <Table>
              <Table.Head>
                <Table.HeadCell>Order Number</Table.HeadCell>

                <Table.HeadCell>Actions</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {itemsToShow && itemsToShow.length != 0 ? (
                  itemsToShow.map((order, index) => (
                    <Table.Row
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      key={index}
                    >
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        #{order.order_no}
                      </Table.Cell>

                      <Table.Cell>
                        <a
                          className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                          href={`/eventorganizer/receipt/${id}/${order.order_no}`}
                        >
                          <p>View</p>
                        </a>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell colspan={3} className="bg-white text-center">
                      No Orders
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
          <MainPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
        {isWithdraw && (
          <WithdrawalModal
            handleWithdrawal={handleWithdrawal}
            isWithdraw={isWithdraw}
            id={id}
          />
        )}
        {isBoost && (
          <AdModal
            isWithdraw={isBoost}
            handleWithdrawal={handleBoost}
            adtype={event.ad_type_name}
            productid={id}
            purpose="event"
          />
        )}
      </div>

      {loadedOnce && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md">
            {/* Your spinner component */}
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default EventPage;
