import React, { useState, useRef, useEffect } from "react";
import { Button, Label, TextInput, FileInput } from "flowbite-react";
import { useMarketplaceContext } from "../../../context/MarketplaceContexts";
import EventImageUpload from "../NewEvent/EventImageUpload";
import {
  fetchDataForCategory,
  fetchDataForCondition,
  fetchDataForLocation,
} from "../../../api/ApiPage";


function BasicAdForm({ toggleDiv, addProduct, setAdd }) {
  const [selectedCategory, setSelectedCategory] = useState("");
  const {
    categories,
    setCategories,
    location,
    setLocation,
    temporaryAdInfo,
    addTemporaryAdInfo,
    condition,
    setCondition,
    addTemporaryPayload,logout
  } = useMarketplaceContext();
  const [selectedFiles, setSelectedFiles] = useState(temporaryAdInfo.product_images?temporaryAdInfo.product_images:[]);
  const [productdetails, setDetails] = useState([]);
  const [formData, setFormData] = useState({
    name: temporaryAdInfo.name?temporaryAdInfo.name:"",
    category: temporaryAdInfo.category?temporaryAdInfo.category:null,
    location: temporaryAdInfo.location?temporaryAdInfo.location:null,
    condition: temporaryAdInfo.condition?temporaryAdInfo.condition:null,
    product_images: [],
    product_details: [],
    description: temporaryAdInfo.description?temporaryAdInfo.description:"",
    price: temporaryAdInfo.price?temporaryAdInfo.price:null,
  });

  const [payload, setPayload] = useState({
    name: formData.name,
    category: null,
    location: parseInt(formData.location),
    condition: null,
    product_images: selectedFiles,
    description: formData.description,
    price: parseInt(formData.price),
  });

  const info = {
    name: formData.name,
    product_images: selectedFiles,
    category: parseInt(formData.category),
    location: parseInt(formData.location),
    condition: parseInt(formData.condition),
    description: formData.description,
    price: parseInt(formData.price),
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

   
   
  };

  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("");

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // const selectedSpec = location&&location.find((spec) => spec.id === parseInt(value));
    // setPayload({
    //   ...formData,
    //   [name]: selectedSpec.region_name,
    // });
  };

  const handleConditionChange = (event) => {
    setSelectedCondition(event.target.value);
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // const selectedSpec = condition&&condition.find((spec) => spec.id === parseInt(value));
    // setPayload({
    //   ...formData,
    //   [name]: selectedSpec.condition_label,
    // });
  };

  useEffect(() => {
    if (categories.length == 0) {
      fetchDataForCategory(logout).then((data) => {
        if (data) {
          setCategories(data);
        }
      });
    }

    if (location.length == 0) {
      fetchDataForLocation(logout).then((data) => {
        if (data) {
          setLocation(data);
        }
      });
    }
    if (condition.length == 0) {
      fetchDataForCondition(logout).then((data) => {
        if (data) {
          setCondition(data);
        }
      });
    }
  }, []);

  const [formerror,setFormerror]=useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    // Check form validity whenever formData changes
    const requiredFields = [
      "name",
      "category",
      "location",
      "condition",
      "description",
      "price",
    ];

    const isValid = requiredFields.some(
      (field) => formData[field] !== null || formData[field] !== undefined || formData[field] !== ''
    );
    setIsFormValid(isValid);
  }, [formData]);

  const handleChanges = () => {
    // save into the temp array
    if (
      formData.name === '' ||
      formData.category === null ||
      formData.location === null ||
      formData.condition === null ||
      formData.description === '' ||
      formData.price === null
    ) {
      setFormerror("Please fill in all required fields before proceeding.")
      return; // Prevent further execution if any required field is empty
    }
    console.log(info);
    setAdd(info);
    addTemporaryAdInfo(info);
    // addTemporaryPayload(payload);
    toggleDiv("productdetails");
  };

  return (
    <div className="flex justify-center items-center  h-full">
      <form className="flex w-full flex-col gap-4 ">
      <div>
        <p className="font-bold text-red-600 text-lg">{formerror}</p>
      </div>
        <div className="flex sm:flex-row flex-col gap-2 ">
          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="name"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">Product Name</p>
                    <p className="text-darkgray">
                      Write the product name in full including the brand name,
                      make and year
                    </p>
                  </div>
                }
              />
            </div>

            <TextInput
              id="name"
              name="name"
              required
              type="text"
              class="block mb-2 text-sm font-medium text-midnightblue border border-lightblue rounded-md w-full sm:h-16 h-10"
              onChange={handleInputChange}
              value={
                formData.name
              }
            />
          </div>

          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="description"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">
                      Product Description
                    </p>
                    <p className="text-darkgray">
                      Write the product description in full including the brand
                      name, make and year and what it does
                    </p>
                  </div>
                }
              />
            </div>

            <TextInput
              id="description"
              name="description"
              required
              type="text"
              class="block mb-2 text-sm font-medium text-midnightblueborder border-lightblue rounded-md w-full sm:h-16 h-10"
              onChange={handleInputChange}
              value={
                 formData.description
              }
            />
          </div>
        </div>

        <div className="flex sm:flex-row flex-col gap-2">
          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="location"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">
                      Product Location
                    </p>
                    <p className="text-darkgray">
                      Write where the product is located
                    </p>
                  </div>
                }
              />
            </div>

            <select
              name="location"
              id="location"
              value={
                formData.location
              }
              onChange={handleLocationChange}
              class="block mb-2 text-sm font-medium text-midnightblue border border-lightblue rounded-md w-full sm:h-16 h-10"
            >
              <option value="">Select a location</option>
              {location.map((locate) => (
                <option key={locate.region_name} value={locate.id}>
                  {locate.region_name}
                </option>
              ))}
            </select>
          </div>
          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="condition"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">
                      Product Condition
                    </p>
                    <p className="text-darkgray">
                      Write where the product is located
                    </p>
                  </div>
                }
              />
            </div>

            <select
              name="condition"
              id="condition"
              value={
                formData.condition
              }
              onChange={handleConditionChange}
              class="block mb-2 text-sm font-medium text-midnightblue border border-lightblue rounded-md w-full sm:h-16 h-10"
            >
              <option value="">Select a condition</option>
              {condition.map((locate) => (
                <option key={locate.id} value={locate.id}>
                  {locate.condition_label}
                </option>
              ))}
            </select>
          </div>
        </div>


        <div className="flex sm:flex-row flex-col gap-2">
        <div className="basis-1/2">
          <div className="mb-2 block">
            <Label
              htmlFor="category"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Product Category
                  </p>
                  <p className="text-darkgray">Choose the product category </p>
                </div>
              }
            />
          </div>

          <select
            name="category"
            id="category"
            value={
              formData.category
            }
            onChange={handleCategoryChange}
            class="block mb-2 text-sm font-medium text-midnightblue border border-lightblue rounded-md w-full sm:h-16 h-10"
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div className="basis-1/2">
          <div className="mb-2 block">
            <Label
              htmlFor="price"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Product Price</p>
                  <p className="text-darkgray">
                    Write the price of the product
                  </p>
                </div>
              }
            />
          </div>

          <TextInput
            id="price"
            name="price"
            required
            type="number"
            placeholder="0.00"
            class="block mb-2 text-sm font-medium text-midnightblue border border-lightblue rounded-md w-full sm:h-16 h-10"
            onChange={handleInputChange}
            value={
              formData.price
            }
          />
        </div>


        </div>

        
        <div id="fileUpload" border>
          <div className="mb-2 block">
            <Label
              htmlFor="file"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Product Images</p>
                  <p className="text-darkgray">
                    Select up to 3 images with a maximum fil size of 3MB{" "}
                  </p>
                </div>
              }
            />
          </div>
          <EventImageUpload
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        </div>
        <div className="flex">
          <Button
            type="button"
            className={`justify-end bg-normalblue sm:p-4 p-2 rounded-md ${
              !isFormValid ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleChanges}
            disabled={!isFormValid}
          >
            Save Changes & Next
          </Button>
        </div>
      </form>
    </div>
  );
}

export default BasicAdForm;
