import React from "react";
import logo from "../../assets/images/KRIFFEONLINELOGOST.png";
import { Footer } from "flowbite-react";

function FixedFooter() {
  return (
    <footer class="bg-midnightblue">
      <div className="ml-3 flex sm:flex-row flex-col justify-between py-16 gap-4 ">
        <div className="flex flex-col justify-between gap-2 ">
          <img src={logo} class="h-40 w-full" alt="KriffeOnline Logo" />
          <p className="text-white ml-3 text-center sm:text-left">
            Empowering the Christian Community
          </p>
          <div className="flex flex-row gap-4 ml-3 justify-center sm:justify-start">
            <a
              href="#"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                class="w-4 h-4 text-white dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 8 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Facebook page</span>
            </a>

            <a
              href="#"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                class="w-4 h-4 text-white dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 19"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Google </span>
            </a>

            <a
              href="#"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                class="w-4 h-4 text-white dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M12.186 8.672 18.743.947h-2.927l-5.005 5.9-4.44-5.9H0l7.434 9.876-6.986 8.23h2.927l5.434-6.4 4.82 6.4H20L12.186 8.672Zm-2.267 2.671L8.544 9.515 3.2 2.42h2.2l4.312 5.719 1.375 1.828 5.731 7.613h-2.2l-4.699-6.237Z"
                />
              </svg>

              <span class="sr-only">Twitter page</span>
            </a>

            <a
              href="#"
              class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                class="w-4 h-4 text-white dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 15 15"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                  clip-rule="evenodd"
                />
                <path d="M3 5.012H0V15h3V5.012Z" />
              </svg>

              <span class="sr-only">LinkedIn account</span>
            </a>
          </div>
        </div>
        <div className="flex flex-row basis-1/3 gap-8">
          <div className="flex flex-col gap-2 basis-1/4">
            <h2 className="text-white font-bold text-lg">Help Center</h2>
            <ul className="text-lightblue text-sm">
              <li className="mb-2"><a href="/contact">FAQs</a></li>
              <li className="mb-2"><a href="/contact">Contact Us</a></li>
              <li><a href="/contact">Support</a></li>
            </ul>
          </div>
          <div className="flex flex-col gap-2  basis-1/4">
            <h2 className="text-white font-bold text-lg">Resources</h2>
            <ul className="text-lightblue text-sm">
              <li className="mb-2"><a href="/marketplace">Marketplace</a></li>
              <li className="mb-2"><a href="/event">Events</a></li>
              <li className="mb-2"><a href="/aboutus">Management</a></li>
              {/* <li><a href="/aboutus">Management</a>Register</li> */}
            </ul>
          </div>
          <div className="flex flex-col gap-2  basis-1/4">
            <h2 className="text-white font-bold text-lg">Company</h2>
            <ul className="text-lightblue text-sm">
              <li className="mb-2"><a href="/aboutus">About us</a></li>
              <li className="mb-2"><a href="/aboutus">Services</a></li>
              <li className="mb-2"><a href="/privacypolicy">Privacy Policy</a></li>
              <li><a href="/termsandconditions">Terms&amp;Conditions</a></li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-2 basis-1/4 ">
          <h2 className="text-white font-bold text-lg">Contact Us</h2>
          <ul className="text-lightblue text-sm">
            <li className="mb-2">
              <span className="flex gap-2">
                <svg
                  class="w-5 h-5 text-lightblue"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="m19 2-8.4 7.05a1 1 0 0 1-1.2 0L1 2m18 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m18 0v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2"
                  />
                </svg>
                info@kriffeoline.com
              </span>
            </li>
            <li className="mb-2">
              <span className="flex gap-2">
                <svg
                  class="w-5 h-5 text-lightblue"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="m16.344 12.168-1.4-1.4a1.98 1.98 0 0 0-2.8 0l-.7.7a1.98 1.98 0 0 1-2.8 0l-2.1-2.1a1.98 1.98 0 0 1 0-2.8l.7-.7a1.981 1.981 0 0 0 0-2.8l-1.4-1.4a1.828 1.828 0 0 0-2.8 0C-.638 5.323 1.1 9.542 4.78 13.22c3.68 3.678 7.9 5.418 11.564 1.752a1.828 1.828 0 0 0 0-2.804Z"
                  />
                </svg>
                +233 59 858 7073
              </span>
            </li>
            <li>
              <span className="flex gap-2">
                <svg
                  class="w-5 h-5 text-lightblue"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 21"
                >
                  <g
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  >
                    <path d="M8 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    <path d="M13.8 12.938h-.01a7 7 0 1 0-11.465.144h-.016l.141.17c.1.128.2.252.3.372L8 20l5.13-6.248c.193-.209.373-.429.54-.66l.13-.154Z" />
                  </g>
                </svg>
                188 Boundary Road,Spintex – Accra
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="text-center text-white p-4 text-base ">
        <span>© 2023 KriffeOnline LTD™ . All Rights Reserved.</span>
      </div>
    </footer>
  );
}

export default FixedFooter;
