import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/Navigation/NavigationBar";
import TopBanner from "../../components/Navigation/TopBanner";
import FixedFooter from "../../components/Footer/FixedFooter";
import Newsletter from "../../components/Footer/Newsletter";
import SearchBarCollapse from "../../components/SearchBars/SearchBarCollapse";
import LandingCarousel from "../../components/Carousels/LandingCarousel";
import ReadMore from "../../components/Miscellanous/ReadMore";
import ServiceCard from "../../components/Cards/ServiceCard";
import HomeProductCard from "../../components/Cards/HomeProductCard";
import HomeEventCard from "../../components/Cards/HomeEventCard";
import card from "../../assets/images/about.png";
import logo from "../../assets/images/KRIFFEONLINELOGOST.png";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Home() {
  const { isLogged, token, logout } = useAuth();
  const initialPayload = {
    sortBy: 4,
  };
  const navigate=useNavigate();

  const [formData, setFormData] = useState(initialPayload);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [home, setHome] = useState([]);

  // allow for people who havent logged in to see

  const url =process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/list_products`;
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  useEffect(() => {
    if (!loadedOnce) {
      axios
        .get(additionalPath, { params: formData, headers: headers })
        .then((response) => {
          // Handle successful response
          // home array
          const elementsToSet = response.data.products.data.length >= 5
          ? response.data.products.data.slice(0, 5)
          : response.data.products.data;
        
        // Assign the elements to setHome
          setHome(elementsToSet);
          setLoadedOnce(true);
        })
        .catch((err) => {
          // Handle error
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            logout();
            navigate("/")
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            console.log(err);

            // Rethrow the error for further handling if needed
          }
        });
    }
  }, [formData, loadedOnce]);

  const additionalPaths = `${url}/list_events`;
  const [loadedOnces, setLoadedOnces] = useState(false);
  const [event, setEvent] = useState([]);
 
  const initialPayloads={
    current_page:1,
  }

  const [formDatas,setFormDatas]=useState(initialPayloads);




  useEffect(() => {
   
 
      const fetchData = async () => {

   
    axios.get(additionalPaths,{ params:formDatas ,headers:headers})
  .then(response => {
    // Handle successful response

    const eventsToSet = response.data.events.data.length >= 5
  ? response.data.events.data.slice(0, 5)
  : response.data.events.data;

// Assign the events to setEvent
setEvent(eventsToSet);
  
    setLoadedOnces(true);

  })
  .catch(error => {
    // Handle error
    console.error('Error:', error);
  });
}



if(!loadedOnces){
  fetchData();
}

}, [loadedOnces]);

 




  return (
    <div>
      <section className="bg-midnightblue w-screen h-screen flex flex-col">
        <TopBanner />
        <NavigationBar logo={logo} color="text-white" status={isLogged} />
        <SearchBarCollapse />
        <LandingCarousel />
      </section>

      <section className="w-screen  px-3 py-8">
        <div className="flex sm:flex-row flex-col justify-between mb-8">
          <div className="flex flex-col">
            <p className="text-midnightblue text-2xl font-medium">
              Top <span className="text-normalblue">Trending</span>{" "}
              <span className="text-normalbrown">Products</span>
            </p>
            <p className="text-darkgray sm:text-base text-sm">
              Discover the latest and hottest ads and deals{" "}
            </p>
          </div>

          <a href="/marketplace">
          <ReadMore name="View More" />
          </a>

          
        </div>
        <div>
          {home && home.length!== 0 ? (
             <div className="flex sm:flex-row flex-col gap-4">
           { home.map((product) => (
             
                <HomeProductCard status={isLogged} productname={product.name} saves={product.saves} price={product.price} product_id={product.product_no} images={product.productimages}/>
            
            )  )}</div>
          ) : (
            <div className="flex sm:flex-row flex-col gap-4">
              <HomeProductCard status={isLogged} />
              <HomeProductCard status={isLogged} />
              <HomeProductCard status={isLogged} />
              <HomeProductCard status={isLogged} />
            </div>
          )}

          {/* // <HomeProductCard status={isLogged}/> */}
          {/* <HomeProductCard status={isLogged}/>
          <HomeProductCard status={isLogged}/> */}
        </div>
      </section>
      <section className="w-screen  px-3 py-6">
        <div className="flex sm:flex-row flex-col justify-between mb-8">
          <div className="flex flex-col">
            <p className="text-midnightblue text-2xl font-medium">
              Top <span className="text-normalblue">Trending</span>{" "}
              <span className="text-normalbrown">Events</span>
            </p>
            <p className="text-darkgray sm:text-base text-sm">
              Discover and purchase your tickets for the latest events
            </p>
          </div>

          <a href="/events">
          <ReadMore name="View More" />
          </a>

         
        </div>
        <div >
        {event && event.length!== 0 ? (
             <div className="flex sm:flex-row flex-col gap-4">
           { event.map((product) => (
             
             <HomeEventCard eventname={product.name} date={product.date} price={product.ticket_type} event_id={product.event_no} images={product.images}/>
            
            )  )}</div>
          ) : (
            <div className="flex sm:flex-row flex-col gap-4">
                 
          <HomeEventCard />
           <HomeEventCard />
          <HomeEventCard />
            </div>
          )}
         
       
        </div>
      </section>
      <section className="w-screen px-3 py-8">
        <div className="flex sm:flex-row flex-col justify-between mb-8">
          <div className="flex flex-col ">
            <p className="text-xs text-darkgray">WHAT WE DO</p>
            <p className="text-midnightblue text-2xl font-medium">
              OUR <span className="text-normalblue">PROVIDED</span>{" "}
              <span className="text-normalbrown">SERVICES</span>
            </p>
            <p className="text-darkgray sm:text-base text-sm">
              At KriffeOnline we provide services tailored to our different
              categories of users
            </p>
          </div>

            <a href="/aboutus">
            <ReadMore name="Read More" />

            </a>
          
        </div>

        <div className="flex sm:flex-row flex-col gap-2">
          <ServiceCard
            name="E-commerce Marketplace"
            description="Explore our e-commerce marketplace for a diverse selection of faith-inspired products. Discover everything from Bibles to inspirational gifts, all in one secure and convenient platform"
          />
          <ServiceCard
            name="Event Ticketing"
            description="Purchase tickets for uplifting Christian events on KRIFFEonline. From worship gatherings to inspirational seminars, easily secure your spot and connect with like-minded believers"
          />
          <ServiceCard
            name="Ad Posting"
            description="Share your faith-based services or products with our community through ad posting. Reach a wider audience and enhance your connections within the Christian community"
          />
          <ServiceCard
            name="Event Management"
            description="Plan and manage your own Christian gatherings with ease. From organizing church events to community outreach, our event management tools empower you to create memorable experiences"
          />
        </div>
      </section>
      {/* <section className="w-screen px-3 pt-8 pb-6">
        <div className="flex sm:flex-row flex-col ">
          <div className="flex flex-col basis-1/2 mt-16">
            <p className="text-xs text-darkgray">WHO ARE WE</p>
            <p className="text-midnightblue text-2xl font-medium">
              ABOUT <span className="text-normalblue">KRIFFE</span>{" "}
              <span className="text-normalbrown">ONLINE</span>
            </p>
            <p className="mt-2 mb-4 text-darkgray sm:text-sm text-xs leading-relaxed sm:leading-normal">
              We are at the forefront of revolutionizing e-commerce in Ghana by
              offering a specialized platform tailored to the needs of the
              Christian community.
              <div className="mt-1">
                {" "}
                We are dedicated to providing a central marketplace for
                Christian products and event ticketing, addressing a significant
                gap in the market.
              </div>
              <div className="mt-1">
                Our mission is to empower Ghanaian Christians with convenient
                access to faith-based resources, fostering spiritual growth and
                connections among believers.
              </div>
              <div className="mt-1">
                With the increasing penetration of the internet and the rising
                demand for faith-related content, KRIFFEonline stands as a
                pioneer, ushering in a secure and enriching environment for
                believers to explore, shop, and connect in faith.
              </div>
            </p>
            <ReadMore name="Read more" />
          </div>

          <div className="basis-1/2 flex items-center justify-center">
            <img src={card} className="h-[90%]" />
          </div>
        </div>
      </section> */}
      <section className="w-screen">
        <Newsletter />
        <FixedFooter />
      </section>
    </div>
  );
}

export default Home;
