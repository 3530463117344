import React, { useState, useEffect, useRef } from "react";
import MainPagination from "../../../components/Miscellanous/MainPagination";
import { Table, Button, Badge,Spinner } from "flowbite-react";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Withdrawals() {
  const { token ,logout} = useAuth();
  const [withdraw, setWithdraws] = useState([]);
  const navigate = useNavigate();
  const withdrawOnce = useRef(false);
  const url =process.env.REACT_APP_API_URL;
  const [per_page, setPerpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [to_no, setTo] = useState(0);
  const [from_no, setFrom] = useState(0);
  const itemToShow = withdraw;
  const itemsPerPage = withdraw && per_page;
  const initialPayload = {
    currentpage: 1
  };

  const [formData, setFormData] = useState(initialPayload);

  const totalPage =
    withdraw && total > 0 && Math.ceil(total/ itemsPerPage);
  const [currentPages, setCurrentPages] = useState(1);
  const handlePagesChange = (newPage) => {
    setCurrentPages(newPage);
    handleParamChange("currentpage", newPage);
  };

  const handleParamChange = (name, value) => {
    setFormData((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
    withdrawOnce.current=false;
  };
  useEffect(() => {
    // get_total_order
    //
    if (withdrawOnce.current) return;
    withdrawOnce.current = true;
    handletotalwithdraw();
  }, [withdrawOnce,formData,currentPages]);

  const handletotalwithdraw = async () => {
    // get_dashboard_total_event
    const additionalPath = `${url}/user_withdrawals`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { params:formData,headers: headers })
      .then((response) => {
        console.log(response.data);
        setWithdraws(response.data.withdrawals.data);
        setPerpage(response.data.withdrawals.per_page)
        setTotal(response.data.withdrawals.total)
        setTo(response.data.withdrawals.to);
        setFrom(response.data.withdrawals.from);
        withdrawOnce.current=true
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  const withdrawtext = (status) => {
    if (status === "pending") {
      return (
        <Badge color="warning" className="flex justify-center">
          Pending
        </Badge>
      );
    } else {
      return (
        <Badge color="success" className="flex justify-center">
          Approved
        </Badge>
      );
    }
  };
  return (
    <div className="p-3 space-y-4">
      <div className="flex flex-row justify-between">
        <p className="text-xl font-bold text-midnightblue">Withdrawals</p>
      </div>

      <div>
        <div className="mt-4 sm:block hidden">
          <Table>
            <Table.Head>
              <Table.HeadCell>Withdrawal Number</Table.HeadCell>
              <Table.HeadCell>Amount</Table.HeadCell>
              <Table.HeadCell>Method</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {withdrawOnce.current==false?(
              <Table.Row>
              <Table.Cell colspan={4} className="bg-white text-center">
              <div className="flex justify-center items-center h-full">
                  <Spinner aria-label="Loading" color="warning" size="md" />
                  <p className="text-darkgray">Loading Withdrawals.....</p>
                </div>
              </Table.Cell>
            </Table.Row>):(itemToShow && itemToShow.length != 0 ? (
                itemToShow.map((orders, index) => (
                  <Table.Row className="bg-white">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900">
                      {orders.withdrawal_no}
                    </Table.Cell>
                    <Table.Cell>{orders.amount_withdrawn}</Table.Cell>
                    <Table.Cell>{orders.payment_method}</Table.Cell>
                    <Table.Cell>{withdrawtext(orders.status)}</Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colspan={4} className="bg-white text-center">
                    No Withdrawals
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>

        <div className="mt-4 sm:hidden block">
          <Table>
            <Table.Head>
              <Table.HeadCell>Withdrawal Number</Table.HeadCell>

              <Table.HeadCell>Status</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {withdrawOnce.current==false?(<Table.Row>
              <Table.Cell colspan={4} className="bg-white text-center">
              <div className="flex justify-center items-center h-full">
                  <Spinner aria-label="Loading" color="warning" size="md" />
                  <p className="text-darkgray">Loading Withdrawals.....</p>
                </div>
              </Table.Cell>
            </Table.Row>):(itemToShow && itemToShow.length != 0 ? (
                itemToShow.map((orders, index) => (
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {orders.withdrawal_no}
                    </Table.Cell>

                    <Table.Cell>{withdrawtext(orders.status)}</Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colspan={2} className="bg-white text-center">
                    No Withdrawals
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        <MainPagination
          currentPage={currentPages}
          totalPages={totalPage}
          onPageChange={handlePagesChange}
        />
      </div>
    </div>
  );
}

export default Withdrawals;
