import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const BarChart = () => {
  const { token, logout } = useAuth();
  const navigate = useNavigate();

  const [rate, setRate] = useState([]);
  const [rating, setRating] = useState([
    { rating: 1, count: 0 },
    { rating: 2, count: 0 },
    { rating: 3, count: 0 },
    { rating: 4, count: 0 },
    { rating: 5, count: 0 },
  ]);
  const [chartData, setChartData] = useState(rating);
  // get_all_event_ratings
  const url = process.env.REACT_APP_API_URL;

  const loadedOnce = useRef(false);

  useEffect(() => {
    // const generateMonthlyData = async () => {
    //   const additionalPath = `${url}/get_all_event_ratings`;
    //   const headers = {
    //     "Content-Type": "application/json",
    //   };

    //   if (token) {
    //     headers["Authorization"] = `Bearer ${token}`;
    //   }

    //   await axios
    //     .get(additionalPath, { headers: headers })
    //     .then((response) => {
    //       console.log(response.data);
    //       let ratings = {
    //         1: 0,
    //         2: 0,
    //         3: 0,
    //         4: 0,
    //         5: 0,
    //       };
    //       if (response.data.ratings.length == 0) {
    //         setChartData(ratings);
    //       } else {
    //         let mergedRatings = { ...ratings, ...response.data.ratings };
    //         setChartData(mergedRatings);
    //       }

    //       //  setSales(response.data.event_sales);
    //     })
    //     .catch((err) => {
    //       if (err.response && err.response.status === 401) {
    //         // Perform actions when 401 (Unauthorized) error occurs
    //         console.log(
    //           "Unauthorized: Logging user out or performing specific action..."
    //         );
    //         logout();
    //         navigate("/");
    //         // Example: Log the user out or redirect to login page
    //         // You may perform the necessary actions here based on your application flow
    //       } else {
    //         // Handle other errors
    //         console.log(err);
    //         // Rethrow the error for further handling if needed
    //       }
    //     });
    // };
    const generateMonthlyData = async () => {
      const additionalPath = `${url}/get_all_event_ratings`;
      const headers = {
        'Content-Type': 'application/json',
      };
    
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
    
      try {
        const response = await axios.get(additionalPath, { headers: headers });
        console.log(response.data);
    
        let updatedRatings = [...rating];

        // Iterate over the received ratings
        response.data.ratings.forEach((receivedRating) => {
          const ratingValue = receivedRating.rating;
    
          // Find the corresponding rating in the state
          const existingRatingIndex = updatedRatings.findIndex(
            (currentRating) => currentRating.rating === ratingValue
          );
    
          // If the rating exists, update its count with the count from the response
          if (existingRatingIndex !== -1) {
            updatedRatings[existingRatingIndex].count = receivedRating.count;
          } else {
            // If the rating doesn't exist, initialize it with the count from the response
            updatedRatings.push({ rating: ratingValue, count: receivedRating.count });
          }
        });
    
        // Update the state with the merged ratings
        setRating(updatedRatings);
    
      } catch (err) {
        if (err.response && err.response.status === 401) {
          console.log('Unauthorized: Logging user out or performing specific action...');
          logout();
          navigate('/');
        } else {
          console.log(err);
        }
      }
    };

    if (loadedOnce.current) return;
    loadedOnce.current = true;
    generateMonthlyData();
  }, []);

  const options = {
    chart: {
      id: "bar-chart",
    },
    xaxis: {
      categories: chartData.map((key) => key.rating + " Stars"),
    },
  };

  const series = [
    {
      name: "Rating Distribution",
      data: chartData.map((key) => key.count),
    },
  ];

  return (
    <div className="bar-chart">
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default BarChart;
