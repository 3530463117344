import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/KRIFFEONLINELOGO2.png";
import TopBanner from "../../../components/Navigation/TopBanner";
import NavigationBar from "../../../components/Navigation/NavigationBar";
import SearchBarCollapse from "../../../components/SearchBars/SearchBarCollapse";
import { useAuth } from "../../../context/AuthContext";
import { useMarketplaceContext } from "../../../context/MarketplaceContexts";
import AdFormStepper from "../../../components/Navigation/AdFormStepper";
import BasicAdForm from "../../../components/Forms/Ads/BasicAdForm";
import ProductDetailsForm from "../../../components/Forms/Ads/ProductDetailsForm";
import SellerForm from "../../../components/Forms/Ads/SellerForm";
import AdSpecificationForm from "../../../components/Forms/AdSpecificationForm";
import ReviewForm from "../../../components/Forms/Ads/ReviewForm";



const NewAd = () => {
  const { isLogged } = useAuth();
  const { temporaryAdInfo } = useMarketplaceContext();

  
  const [visibleDiv, setVisibleDiv] = useState("adtype");
  const [recentid, setID] = useState();

 
  const [addproduct, setAdd] = useState([]);

  

  console.log(temporaryAdInfo);

 
 
  const toggleDiv = (divValue) => {
    setVisibleDiv(divValue);
  };
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImagesSelected = (images) => {
    setSelectedImages(images);
  };

  const divContentMap = {
    adtype: <BasicAdForm toggleDiv={toggleDiv} addproduct={addproduct} setAdd={setAdd} />,
    productdetails: <ProductDetailsForm toggleDiv={toggleDiv}/>,
    seller: <SellerForm toggleDiv={toggleDiv}/>,
    review:<ReviewForm />
  };
  const content = divContentMap[visibleDiv] || "";


  
  




 

  return (
    <section className="w-screen">
      <TopBanner />
      <NavigationBar logo={logo} color="text-midnightblue" status={isLogged} />
      <SearchBarCollapse />

      <a href={`/marketplace`} className="text-normalblue px-3 text-sm pt-12 ">
        {`<`}Go back
      </a>

      <div className=" h-screen px-3 mt-1 mb-5">
        <p className="text-midnightblue font-medium text-2xl text-center mb-4">CREATE AN AD</p>
        <AdFormStepper toggleDiv={toggleDiv} visibleDiv={visibleDiv} />
        <div className="w-full sm:px-12 px-6">
          
            {content}
        
            
        </div>
      </div>
    </section>
  );
};
export default NewAd;
