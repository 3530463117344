import React, { useState,useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";
import MainPagination from "../../../components/Miscellanous/MainPagination";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";
import { Table,Badge,Spinner } from "flowbite-react";
import EventModal from "../../../components/Modal/Admin/EventModal";
import EventEditModal from "../../../components/Modal/Admin/EventEditModal";

function Events() {
  const { token, adminlogout } = useAuth();
  const [eventsdata, setUsers] = useState([]);
  const navigate = useNavigate();
  const loadedOnce = useRef(false);
  const [id,setId]=useState(null);
  const [status,setStatus]=useState(null);

  const initialPayload = {
    currentpage: 1,
  };

  const [formData, setFormData] = useState(initialPayload);
  const [currentPage, setCurrentPage] = useState(1);

  const [open,setModal]=useState(false);

  const handleModal=(id)=>{
    setId(id)
    setModal(!open)
  }


  const [edit,setEModal]=useState(false);

  const handleEditModal=(id,status)=>{
    setId(id)
    setStatus(status)
    setEModal(!edit)
  }
  const handleParamChange = (name, value) => {
    setFormData((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
    loadedOnce.current = false;
  };
  const [per_page, setPerpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [to_no, setTo] = useState(0);
  const [from_no, setFrom] = useState(0);
  const itemsPerPage = eventsdata && per_page;

  const itemsToShow = eventsdata;

  const totalPages = eventsdata && total > 0 && Math.ceil(total / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    handleParamChange("currentpage", newPage);
  };

  useEffect(() => {
    if (loadedOnce.current) return;
    loadedOnce.current = true;
    handletotalevent();
  }, [loadedOnce, formData, currentPage]);

  const url = process.env.REACT_APP_API_URL;
  const handletotalevent = async () => {
    const additionalPath = `${url}/admin_get_all_events`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { params: formData, headers: headers })
      .then((response) => {
        setUsers(response.data.events.data);
        setPerpage(response.data.events.per_page);
        setTotal(response.data.events.total);
        setTo(response.data.events.to);
        setFrom(response.data.events.from);
        loadedOnce.current = true;
        console.log(response.data.events);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          adminlogout();
          navigate("/admin")
          
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  const statustext = (status) => {
    if (status === "pending") {
      return (
        <Badge color="warning" className="flex justify-center">
          Pending
        </Badge>
      );
    } else if (status === "active") {
      return (
        <Badge color="success" className="flex justify-center">
          Active
        </Badge>
      );
    } else {
      return (
        <Badge color="failure" className="flex justify-center">
          Closed
        </Badge>
      );
    }
  };
  return (
    <div className="px-3">
      <p className="font-bold text-xl">Events</p>

      {/* table */}
      <div className="mt-4  xl:block hidden">
        <Table>
          <Table.Head>
            <Table.HeadCell>Event Name</Table.HeadCell>
            <Table.HeadCell>Category</Table.HeadCell>
            <Table.HeadCell>Event Type</Table.HeadCell>
            <Table.HeadCell>Ad Type</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current==true? (itemsToShow && itemsToShow.length != 0 ? (
              itemsToShow.map((userData, index) => (
                <Table.Row
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  key={index}
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {userData.name}
                  </Table.Cell>
                  <Table.Cell>
                    {userData.event_category_name}
                  </Table.Cell>
                  <Table.Cell>{userData.event_type.join(",")}</Table.Cell>
                  <Table.Cell>
                    {userData.ad_type}
                  </Table.Cell>
                  <Table.Cell>
                    {statustext(userData.status)}
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex  gap-2">
                     

                      <a
                        className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                        href="#"
                        onClick={()=>handleModal(userData.event_no)}
                      >
                        <p>View</p>
                      </a>

                      <a
                        className="font-medium text-normalbrown hover:underline dark:text-cyan-500"
                        href="#"
                        onClick={()=>handleEditModal(userData.event_no, userData.status)}
                      >
                        <p>Edit</p>
                      </a>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  No Events
                </Table.Cell>
              </Table.Row>
            )):(
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                <div className="flex justify-center items-center h-full">
          <Spinner aria-label="Loading" color="warning" size="md" />
        </div>
                </Table.Cell>
              </Table.Row>
              
            )}
          </Table.Body>
        </Table>
      </div>

      <div className="mt-4  md:block  xl:hidden hidden ">
        <Table>
          <Table.Head>
          <Table.HeadCell>Event Name</Table.HeadCell>
            <Table.HeadCell>Ad Type</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current==true?(itemsToShow && itemsToShow.length != 0 ? (
              itemsToShow.map((userData, index) => (
                <Table.Row
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  key={index}
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {userData.name}
                  </Table.Cell>

                  <Table.Cell>{userData.ad_type}</Table.Cell>
                 
                  <Table.Cell>
                  {statustext(userData.status)}
                  </Table.Cell>
                  <Table.Cell>
                  <div className="flex  gap-2">
                     

                     <a
                       className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handleModal(userData.event_no)}
                     >
                       <p>View</p>
                     </a>

                     <a
                       className="font-medium text-normalbrown hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handleEditModal(userData.event_no, userData.status)}
                     >
                       <p>Edit</p>
                     </a>
                   </div>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  No Events
                </Table.Cell>
              </Table.Row>
            )):(
              <Table.Row>
              <Table.Cell colSpan={6} className="bg-white text-center">
              <div className="flex justify-center items-center h-full">
        <Spinner aria-label="Loading" color="warning" size="md" />
      </div>
              </Table.Cell>
            </Table.Row>

            )}
          </Table.Body>
        </Table>
      </div>

      <div className="mt-5  md:hidden lg:hidden xl:hidden block">
        <Table>
          <Table.Head>
            <Table.HeadCell>Event Name</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current==true? (itemsToShow && itemsToShow.length != 0 ? (
              itemsToShow.map((userData, index) => (
                <Table.Row
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  key={index}
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {userData.name}
                  </Table.Cell>

                  <Table.Cell>
                  <div className="flex  gap-2">
                     

                     <a
                       className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handleModal(userData.event_no)}
                     >
                       <p>View</p>
                     </a>

                     <a
                       className="font-medium text-normalbrown hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handleEditModal(userData.event_no, userData.status)}
                     >
                       <p>Edit</p>
                     </a>
                   </div>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  No Events
                </Table.Cell>
              </Table.Row>
            )):( <Table.Row>
              <Table.Cell colSpan={6} className="bg-white text-center">
              <div className="flex justify-center items-center h-full">
        <Spinner aria-label="Loading" color="warning" size="md" />
      </div>
              </Table.Cell>
            </Table.Row>)}
          </Table.Body>
        </Table>
      </div>

      <MainPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />


{open&&<EventModal id={id} open={open} handleModal={handleModal}/>}
{edit&&<EventEditModal id={id} open={edit} handleModal={handleEditModal} status={status}/>}
    </div>
  );
}


export default Events;