import React,{useState} from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

function SearchBar() {
  const {token}=useAuth();
  const navigate=useNavigate();

  const [searchValue, setSearchValue] = useState('');

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      // Perform search or any action here with searchValue
      navigate(`/searchresult/${searchValue}`)
     
    
    }
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
   
  };
  return (
    <div className="lg:w-full">
      <form className="sm:block hidden">
        <label
          for="default-search"
          class="mb-2 text-sm font-medium text-lightgray sr-only dark:text-white"
        >
          Search
        </label>
        <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            {" "}
            <svg
              class="w-4 h-4 text-darkgray "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            class="block xl:w-[32rem] w-full h-10 p-4 pl-10 text-sm text-darkgray rounded-full bg-lightgray focus:outline-none focus:border-none focus:ring-0 border border-none "
            placeholder="Search Events, Products..."
            value={searchValue}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            required
          />
        </div>
      </form>
    </div>
  );
}

export default SearchBar;
