import React,{useState} from "react";
import { Sidebar,Dropdown } from "flowbite-react";
import logo from "../../assets/images/KRIFFEONLINELOGOST.png";

function OrganizerSidebar({ visibleDiv }) {
  const active =
    "flex items-center p-2 text-midnightblue rounded-lg  bg-white  group";
  const inactive =
    "flex items-center p-2 text-white rounded-lg  hover:bg-normalblue  group";
    const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className="flex-shrink-0 h-screen fixed sm:w-[13rem] w-20 sm:block hidden">
      <div className="h-full px-3 py-4 overflow-y-auto bg-midnightblue  text-white">
        <div className="w-full flex items-center pl-2.5 mb-5">
          <img src={logo} className="sm:h-24 sm:w-full h-12 w-full" />
        </div>

        <ul className="space-y-2 font-medium">
          <li>
            <a
              href="/eventorganizer/dashboard"
              class={visibleDiv == "dashboard" ? active : inactive}
            >
              <svg
                class="w-5 h-5  transition duration-75  group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 22 21"
              >
                <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
              </svg>
              <span class="ml-3 sm:block hidden">Dashboard</span>
            </a>
          </li>

          <li>
            <a
              href="/eventorganizer/events"
              class={visibleDiv == "events" ? active : inactive}
            >
              <svg
                class="w-5 h-5 group-hover:text-gray-900 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm14-7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4Z" />
              </svg>
              <span class="ml-3 sm:block hidden">Events</span>
            </a>
          </li>
          <li >
          
         
          <div className="flex flex-col" >
      <div className="flex flex-row justify-between" class={visibleDiv == "finances" ? `${active}  flex-row justify-between` : `${inactive}  flex-row justify-between`}>
      <a
              href="#"
              className="flex flex-row justify-between"
              // class={visibleDiv == "finances" ? active : inactive}
          
              
            >
              <svg
                class="w-5 h-5 group-hover:text-gray-900"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 16"
              >
                <path d="M18 0H6a2 2 0 0 0-2 2h10a4 4 0 0 1 4 4v6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Z" />
                <path d="M14 16H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z" />
                <path d="M8 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z" />
              </svg>
              <span class="ml-3 sm:block hidden">Finances</span>
              
            </a>
        <a href="#" onClick={toggleAccordion} className="flex text-xl">
          {isExpanded ? "-" : "+"}
        </a>
      </div>

      {isExpanded && (
        <div className="p-4 flex flex-col gap-2 font-normal ml-4">
          <ul>
          <li><a href="/eventorganizer/finances">General</a></li>
          <li><a href="/eventorganizer/withdraw">Withdrawals</a></li>
      </ul>
           </div>)}
       
           
    </div>
          </li>
         
        
          <li>
            <a
              href="/eventorganizer/analytics"
              class={visibleDiv == "analytics" ? active : inactive}
            >
              <svg
                class="w-5 h-5 group-hover:text-gray-900"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m14.556 7.799-2.43 5.482A2 2 0 0 0 14 15.98h3.114a2.001 2.001 0 0 0 1.873-2.7l-2.43-5.482v-.925c.33.07.664.107 1 .107a1 1 0 1 0 0-2 3.378 3.378 0 0 1-2.267-1.006 8.567 8.567 0 0 0-2.79-1.571 3 3 0 0 0-5.888.034c-.827.32-1.585.8-2.228 1.412a3.6 3.6 0 0 1-2.827 1.13 1 1 0 0 0 0 2 7.379 7.379 0 0 0 1-.07v.889L.127 13.28A2 2 0 0 0 2 15.98h3.114a2.001 2.001 0 0 0 1.873-2.7l-2.43-5.482v-1.57a8.355 8.355 0 0 0 1.133-.865 5.713 5.713 0 0 1 1.282-.882 2.993 2.993 0 0 0 1.585 1.316V17.98h-7a1 1 0 1 0 0 2h16a1 1 0 0 0 0-2h-7V5.797a3 3 0 0 0 1.62-1.384 7.17 7.17 0 0 1 1.89 1.143c.16.124.327.25.5.377 0 .017-.01.03-.01.048v1.818Zm-5-3.818a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
              </svg>
              <span class="ml-3 sm:block hidden">Analytics</span>
            </a>
          </li>
          <li>
            <a
              href="/eventorganizer/account"
              class={visibleDiv == "account" ? active : inactive}
            >
              <svg
                class="w-5 h-5 group-hover:text-gray-900"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 19"
              >
                <path d="M7.324 9.917A2.479 2.479 0 0 1 7.99 7.7l.71-.71a2.484 2.484 0 0 1 2.222-.688 4.538 4.538 0 1 0-3.6 3.615h.002ZM7.99 18.3a2.5 2.5 0 0 1-.6-2.564A2.5 2.5 0 0 1 6 13.5v-1c.005-.544.19-1.072.526-1.5H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h7.687l-.697-.7ZM19.5 12h-1.12a4.441 4.441 0 0 0-.579-1.387l.8-.795a.5.5 0 0 0 0-.707l-.707-.707a.5.5 0 0 0-.707 0l-.795.8A4.443 4.443 0 0 0 15 8.62V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.12c-.492.113-.96.309-1.387.579l-.795-.795a.5.5 0 0 0-.707 0l-.707.707a.5.5 0 0 0 0 .707l.8.8c-.272.424-.47.891-.584 1.382H8.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1.12c.113.492.309.96.579 1.387l-.795.795a.5.5 0 0 0 0 .707l.707.707a.5.5 0 0 0 .707 0l.8-.8c.424.272.892.47 1.382.584v1.12a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1.12c.492-.113.96-.309 1.387-.579l.795.8a.5.5 0 0 0 .707 0l.707-.707a.5.5 0 0 0 0-.707l-.8-.795c.273-.427.47-.898.584-1.392h1.12a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5ZM14 15.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z" />
              </svg>
              <span class="ml-3 sm:block hidden">Account</span>
            </a>
          </li>
        </ul>

        <div class="absolute bottom-4 sm:left-12 text-red-600  text-center ">
          {" "}
          <a href="/account/events">Leave Console</a>
          <p className="text-darkgray text-sm">v.1.0</p>
        </div>
      </div>
    </div>
  );
}

export default OrganizerSidebar;
