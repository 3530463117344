import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/KRIFFEONLINELOGO2.png";
import TopBanner from "../../../components/Navigation/TopBanner";
import NavigationBar from "../../../components/Navigation/NavigationBar";
import SearchBarCollapse from "../../../components/SearchBars/SearchBarCollapse";
import { useAuth } from "../../../context/AuthContext";
import { useEventContexts } from "../../../context/EventContexts";
import { useNavigate, useParams } from "react-router-dom";
import SummaryPurchase from "../../../components/Miscellanous/SummaryPurchase";
import FormStepper from "../../../components/Navigation/FormStepper";
import EventReceipt from "../../../components/Miscellanous/EventReceipt";
import EventContactForm from "../../../components/Forms/TicketPurchase/EventContactForm";
import TicketPackage from "../../../components/Forms/TicketPurchase/TicketPackage";
import axios from "axios";
import { Banner, Toast,Alert } from "flowbite-react";

const EventPurchase = () => {
  const { isLogged, token, logout } = useAuth();
  const {
    getEventById,
    temporaryTicketInfo,
    addOrder,
    clearTemporaryTicketInfo,
  } = useEventContexts();
  const { id,restart } = useParams();
  const navigate = useNavigate();

  const [event, setEvent] = useState([]);
  const [visibleDiv, setVisibleDiv] = useState("ticket");
  const [arraystatus, setEarray] = useState(false);
  const [checkout, setCheck] = useState(false);
  const [alert, setAlert] = useState(restart);
  const [recentid, setID] = useState("");
  const [err, setErr] = useState("");

  const [addevents, setAdd] = useState([]);
  const initialAddevent = [];
  const [payload, setPayload] = useState({ event_id: id });

  const url = process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/get_event_by_id`;
  // const path=`get_product_by_id?id=${id}`;
  // makeApiRequest("GET", null, path, token);
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  useEffect(() => {
    if (event.length == 0) {
      axios
        .get(additionalPath, { params: payload, headers: headers })
        .then((response) => {
          console.log(response.data.event);
          setEvent(response.data.event);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            logout();
            navigate("/");
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            console.log(err);
            setErr(err);

            // Rethrow the error for further handling if needed
          }
        });
    }
  }, [event, payload, headers]);
  const addevent = addevents.filter((item) => item.ticket !== undefined);

  useEffect(() => {
    if (addevent && addevent.length > 0) {
      setEarray(true);
    }
    if (visibleDiv == "payment") {
      setCheck(true);
    }
  }, [addevent, visibleDiv]);

  const toggleDiv = (divValue) => {
    setVisibleDiv(divValue);
  };

  const divContentMap = {
    ticket: (
      <TicketPackage
        ticketInfo={event.ticket_type}
        ticketpackages={setAdd}
        addevent={addevent || initialAddevent}
        toggleDiv={toggleDiv}
        id={id}
      />
    ),
    contact: <EventContactForm toggleDiv={toggleDiv} />,
    payment: "payment",
    receipt: recentid && <EventReceipt id={recentid} />,
  };
  const content = divContentMap[visibleDiv] || "";

  const text_header =
    visibleDiv == "ticket" ? "Choose your ticket type" : "Contact Information";

  const finalpurchase = () => {
    console.log(temporaryTicketInfo);
    addOrder(temporaryTicketInfo);
    setID(temporaryTicketInfo.order_id);
  };

  return (
    <section className="w-screen">
      <TopBanner />
      <NavigationBar logo={logo} color="text-midnightblue" status={isLogged} />
      <SearchBarCollapse />




     

      <a
        href={`/eventspage/${id}`}
        className="text-normalblue px-3 text-sm pt-12 "
      >
        {`<`}Go back
      </a>

      <div className=" h-screen px-3 mt-2 mb-5">
        <FormStepper toggleDiv={toggleDiv} visibleDiv={visibleDiv} />
        {alert&&<div className="px-3">
<Alert color="failure" onDismiss={() => setAlert(false)}>
      <span className="font-medium">Please restart the process! </span>There was an error
    </Alert>

</div>}
        <div className="w-full  ">
          {visibleDiv == "receipt" ? (
            <div className="w-full">{content}</div>
          ) : visibleDiv == "payment" ? (
            <div>
              <div className="sm:basis-[40%]">
                <p className="font-bold text-midnightblue text-2xl">Summary</p>
                <SummaryPurchase
                  status={true}
                  eventname={event.name}
                  checkout={checkout}
                  tickets={temporaryTicketInfo.ticket_info}
                  toggleDiv={toggleDiv}
                  addorder={setID}
                  events={event.ticket_type}
                />
              </div>
            </div>
          ) : (
            <div className="flex sm:flex-row flex-col gap-4">
              <div className="sm:basis-[60%]">
                <p className="font-bold text-midnightblue text-2xl">
                  {text_header}
                </p>
                <div className="bg-lightblue text-white p-5 text-center rounded-t-md rounded-tr-md">
                  <p className="font-medium text-xl">{event.name} Tickets</p>
                </div>
                <div className="border border-t-0 border-lightgray rounded-b-md rounded-br-md p-8">
                  {content}
                </div>
              </div>
              <div className="sm:basis-[40%]">
                <p className="font-bold text-midnightblue text-2xl">Summary</p>
                <SummaryPurchase
                  eventname={event.name}
                  status={arraystatus}
                  tickets={addevent}
                  events={event.ticket_type}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
export default EventPurchase;
