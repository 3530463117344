import React, { useEffect, useState } from "react";
import { Button, Modal,Spinner } from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../context/AuthContext";
import AdCarousel from "../../Carousels/AdCarousel";

function AdModal({id,open,handleModal}){
    const {token,adminlogout}=useAuth();
    const [product, setUser] = useState([]);
    const [payload, setPayload] = useState({ product_id: id });
    const [loadedOnce, setLoadedOnce] = useState(true);
    const navigate=useNavigate();

     const url =process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/admin_get_product_by_id`;
  // const path=`get_product_by_id?id=${id}`;
  // makeApiRequest("GET", null, path, token);
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  useEffect(() => {
    // Make the first API call
    // handleevent();

    if (product.length == 0) {
      axios
        .get(additionalPath, { params: payload, headers: headers })
        .then((response) => {
          console.log(response.data.product);
          setUser(response.data.product);
          setLoadedOnce(false);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            adminlogout();
            navigate("/admin");
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            console.log(err);

            // Rethrow the error for further handling if needed
          }
        });
    }
  }, [product, payload, headers]);

  const getHumanReadableAttributeName = (attributeName) => {
    // Add more cases as needed
    switch (attributeName) {
      case 'age_group':
        return 'Age Group';
      case 'product_flexibility':
        return 'Product Flexibility';
      // Add more cases for other attribute names
      default:
        return attributeName; // Default to the original name if not found in the mapping
    }
  };
    
    return(
    <div>
        <Modal show={open} size="xl" popup onClose={handleModal}>
        <Modal.Header>{product.name}</Modal.Header>
        <Modal.Body>
            <div className="relative">
            <div className="flex gap-2 flex-col">
            <div className="w-full h-72 rounded-md bg-midnightblue">
          <AdCarousel images={product.images} />
        </div>
        <div>
        <strong>Category:</strong> {product.category}
      </div>
      <div>
        <strong>Price:</strong> {product.price}
      </div>
      <div>
        <strong>Saves:</strong> {product.saves}
      </div>
      <div>
        <strong>Description:</strong> {product.description}
      </div>
      <div>
        <strong>Location:</strong> {product.location}
      </div>
      <div>
        <strong>Seller Name:</strong> {product.sellername}
      </div>
      <div>
        <strong>Seller Phone Number:</strong> {product.sellerphonenumber}
      </div>
      <div>
        <strong>Seller Email:</strong> {product.selleremail}
      </div>
      {/* Add more details as needed */}
      <div>
        <strong>Ad Type:</strong> {product.ad_type}
      </div>
      <div>
        <strong>Tags:</strong> {product.tags&&product.tags.join(', ')}
      </div>
      <div>
        <strong>Views:</strong> {product.views}
      </div>
      <div>
        <strong>Product Status:</strong> {product.product_status}
      </div>
      {/* Product Details */}
      <div>
        <strong>Product Details:</strong>
        <ul>
        {product.product_details &&
  product.product_details.map((detail, index) => (
    <li key={index}>
      <strong>{getHumanReadableAttributeName(detail.attribute_name)}:</strong>{' '}
      {detail.attribute_value}
    </li>
  ))}
        </ul>
      </div>
    </div>
     
     

             
         
           
            {loadedOnce && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md">
            {/* Your spinner component */}
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
          </div>
        </div>
      )}
           </div>


          
           
           
            
           
        </Modal.Body>
      </Modal>

    </div>)
}

export default AdModal;