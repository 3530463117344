import React from "react";
import {Carousel } from "flowbite-react";

function AdCarousel({images}){
    return(<div className="w-full h-full  rounded-md ">
        <Carousel
        slideInterval={5000}
        indicators={false}
        
      >
        {images&&images.map((image, index) => (
        <img key={index} src={image} alt={`Image ${index}`} className=" rounded-md object-scale-down  border border-white h-full"/>
      ))}


      </Carousel>
    </div>)
}
export default AdCarousel;