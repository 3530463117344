import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Label,
  Badge,
  TextInput,
  Spinner,Modal
} from "flowbite-react";
import { useApi } from "../../api/APIProvider";
import axios from "axios";
import ButtonGroup from "flowbite-react/lib/esm/components/Button/ButtonGroup";

function RegisterForm({ onSwitch }) {
  const { makeApiRequest, error } = useApi();
  const [reggi, setReg] = useState(false);
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errormessage, setErrMsg] = useState("");
  const [data, setData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phonenumber: "",
    password: "",
    password1: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const user = {
    fullname: formData.fullname,
    email: formData.email,
    password: formData.password,
    phonenumber: formData.phonenumber,
  };

  useEffect(() => {
    if (data && data.responsemessage) {
      console.log("Registration response:", data.responsemessage);
      setReg(true);
      setSuccess(false);
    }

    
  }, [data]);

  const validateForm = () => {
    let errors = {};

    if (!formData.fullname) {
      errors.fullname = "Full name is required";
    }

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email address";
    }

    if (!formData.phonenumber) {
      errors.phonenumber = "Phone number is required";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }

    if (formData.password1 != formData.password) {
      errors.passwordmatch = "Passwords do not match";
      setErr(true);
      setErrMsg("Passwords Do Not Match");
    }

    return errors;
  };
  const emailRegex = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
  const isValidEmail = (email) => {
    return emailRegex.test(email);
  };
  const url =process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/register`;
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };

  const handleRegistration = async () => {
    setSuccess(true);
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      // const additionalPath = "register";

      // makeApiRequest("POST", user, additionalPath);
      axios.post(additionalPath, user,{headers: headers })
        .then((response) => {
          // Handle successful response

          setData(response.data);

          // setLoading(false);
        })
        .catch((error) => {
          // Handle error
          setErr(true);
          setErrMsg("Incorrect details");
          console.error("Error:", error);
          setSuccess(false);
        });
    } else {
      // Update the state with validation errors
      setFormErrors(errors);
    }
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // Function to toggle the confirmation modal
  const toggleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const handleRegistrationConfirmation = () => {
    toggleConfirmationModal(); // Close the confirmation modal

    // Call the handleRegistration function after the confirmation
    handleRegistration();
  };
  return (
    <div className="relative">
      <div className="space-y-4">
        <p className="text-darkgray text-sm">
          <h3 className="text-3xl font-medium text-midnightblue">
            {" "}
            Welcome to KriffeOnline
          </h3>
          Join the KriffeOnlinefamily today and get access to wonderful products
          and events that help your journey
        </p>
        {reggi && (
          <div className="bg-green-100 p-2 rounded-md border-l-4 border-green-600">
            <p className="text-green-600 font-bold text-sm">
              Check your email to activate your account
            </p>
          </div>
        )}

        {err && (
          <div className="bg-red-100 p-2 rounded-md border-l-4 border-red-600">
            <p className="text-red-600 font-bold text-sm">{errormessage}</p>
          </div>
        )}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="fullname" value="Full name" />
          </div>
          <TextInput
            id="fullname"
            placeholder="eg Jane Doe"
            name="fullname"
            value={formData.fullname}
            onChange={handleInputChange}
            required
            class={
              formErrors.fullname
                ? "bg-red-100 border border-red-600 text-red-600  text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
                : "bg-gray-50 border border-normalblue text-midnightblue text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
            }
            helperText={
              formErrors.fullname && (
                <>
                  <span className="font-medium text-red-600">
                    {formErrors.fullname}
                  </span>
                </>
              )
            }
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email" value="Your email" />
          </div>
          <TextInput
            id="email"
            placeholder="name@company.com"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            class={
              formErrors.email
                ? "bg-red-100 border border-red-600 text-red-600  text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
                : "bg-gray-50 border border-normalblue text-midnightblue text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
            }
            helperText={
              formErrors.email && (
                <>
                  <span className="font-medium text-red-600">
                    {formErrors.email}
                  </span>
                </>
              )
            }
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="phone" value="Phone Number" />
          </div>
          <TextInput
            id="phone"
            placeholder="+233 24 000 000 00"
            name="phonenumber"
            value={formData.phonenumber}
            onChange={handleInputChange}
            required
            class={
              formErrors.phonenumber
                ? "bg-red-100 border border-red-600 text-red-600  text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
                : "bg-gray-50 border border-normalblue text-midnightblue text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
            }
            helperText={
              formErrors.phonenumber && (
                <>
                  <span className="font-medium text-red-600">
                    {formErrors.phonenumber}
                  </span>
                </>
              )
            }
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="password" value="Your password" />
          </div>
          <TextInput
            id="password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
            class={
              formErrors.password
                ? "bg-red-100 border border-red-600 text-red-600  text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
                : "bg-gray-50 border border-normalblue text-midnightblue text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
            }
            helperText={
              formErrors.password && (
                <>
                  <span className="font-medium text-red-600">
                    {formErrors.password}
                  </span>
                </>
              )
            }
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="password1" value="Confirm password" />
          </div>
          <TextInput
            id="password1"
            type="password"
            name="password1"
            value={formData.password1}
            onChange={handleInputChange}
            required
            class={
              formErrors.passwordmatch
                ? "bg-red-100 border border-red-600 text-red-600  text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
                : "bg-gray-50 border border-normalblue text-midnightblue text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
            }
            helperText={
              formErrors.passwordmatch && (
                <>
                  <span className="font-medium text-red-600">
                    {formErrors.passwordmatch}
                  </span>
                </>
              )
            }
          />
        </div>

        <div className="flex w-full justify-center">
          <Button
            onClick={toggleConfirmationModal}
            className="bg-normalblue w-1/2 p-1"
          >
            Register
          </Button>
        </div>
        <div className="flex justify-center text-sm font-medium text-midnightblue ">
          Already have an account?
          <a href="#" onClick={onSwitch} className="text-normalbrown">
            Sign in
          </a>
        </div>
      </div>

      {
        showConfirmationModal && (
          <div className="absolute inset-0 z-50 bg-white w-full flex flex-col gap-2">
            <p className="font-bold text-xl text-center">TERMS AND CONDITIONS</p>
            
            <div className="border border-gray-400 rounded-md h-full overflow-scroll px-3">
              <p>
                ouwqehf r;oweurho;ehir;useorihqs;doh owehriohso;flhdsf lsfhsohf;oshfo;qshfb lifdgw dsbfsohfohsflshfs;f
                abhkfsld uhwslfhgwilugdfiwhfgodfbso flhdsfsqf hqsf sifbislhfblsfbslqfs
                sfbksdqbfldsibfgildfbvfkqf
                ouwqehf r;oweurho;ehir;useorihqs;doh owehriohso;flhdsf lsfhsohf;oshfo;qshfb lifdgw dsbfsohfohsflshfs;f
                abhkfsld uhwslfhgwilugdfiwhfgodfbso flhdsfsqf hqsf sifbislhfblsfbslqfs
                sfbksdqbfldsibfgildfbvfkqf

                ouwqehf r;oweurho;ehir;useorihqs;doh owehriohso;flhdsf lsfhsohf;oshfo;qshfb lifdgw dsbfsohfohsflshfs;f
                abhkfsld uhwslfhgwilugdfiwhfgodfbso flhdsfsqf hqsf sifbislhfblsfbslqfs
                sfbksdqbfldsibfgildfbvfkqf
                ouwqehf r;oweurho;ehir;useorihqs;doh owehriohso;flhdsf lsfhsohf;oshfo;qshfb lifdgw dsbfsohfohsflshfs;f
                abhkfsld uhwslfhgwilugdfiwhfgodfbso flhdsfsqf hqsf sifbislhfblsfbslqfs
                sfbksdqbfldsibfgildfbvfkqf
                ouwqehf r;oweurho;ehir;useorihqs;doh owehriohso;flhdsf lsfhsohf;oshfo;qshfb lifdgw dsbfsohfohsflshfs;f
                abhkfsld uhwslfhgwilugdfiwhfgodfbso flhdsfsqf hqsf sifbislhfblsfbslqfs
                sfbksdqbfldsibfgildfbvfkqf
              </p>

            </div>


              <div className="flex flex-row gap-2 justify-end"> 
              <Button  className="border border-lightblue text-midnightblue" onClick={toggleConfirmationModal}>DISAGREE</Button>
              <Button className="bg-normalblue text-white" onClick={handleRegistrationConfirmation}>AGREE</Button></div>
       
             

            

          </div>
          
        )
      }
      
      {success && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-30 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md">
            {/* Your spinner component */}
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default RegisterForm;
