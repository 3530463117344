import React from "react";
import OrganizerProfileForm from "../../components/Forms/OrganizerProfileForm";
import OrganizerBankForm from "../../components/Forms/OrganizerBankForm";
import { useAuth } from "../../context/AuthContext";
function Account(){
    const {organizer}=useAuth();
    return(
    <div className="p-3">
        <p className="text-xl font-bold text-midnightblue">Account</p>
        <div className="p-3 bg-white rounded-md mt-4">
            <p>Personal Information</p>
            <div>
                <OrganizerProfileForm/>
            </div>
        
        </div>

        <div className="p-3 bg-white rounded-md mt-4">
            <div className="flex flex-row justify-between">
            <p>Bank Information</p>
        <p className="text-normalblue font-medium text-sm">Add Finance Details +</p>

            </div>
       
            <div>
                <OrganizerBankForm/>

            </div>
            

        </div>

    </div>)
}
export default Account;