import React from "react";
import { Button, Carousel } from "flowbite-react";
import smilingman from "../../assets/images/manhappy.png";
import people from "../../assets/images/manlookingatphone.png";
import reading from "../../assets/images/manreadings.png";

function LandingCarousel() {
  return (
    <div className="flex grow-0 h-full ">
      <Carousel
        slideInterval={5000}
        indicators={false}
        leftControl=" "
        rightControl=" "
      >
        <div className="flex flex-row justify-between ml-4 gap-2 w-full h-full ">
          <div className="flex flex-col justify-center">
            <p className="text-lightblue text-base">
              Inspire, shop and gather with hot deals🔥
            </p>
            <p className="text-5xl text-white font-bold mt-2">
              The Best Place Where{" "}
              <div className="text-lightblue">
                Faith <span className="text-white">Finds</span>{" "}
                <span className="text-lightbrown">Products</span>
                <span className="text-white">,and</span>
              </div>{" "}
              <span className="text-lightblue">
                Events <span className="text-white">Find</span>{" "}
                <span className="text-lightbrown">Purpose</span>{" "}
              </span>
            </p>
            <p className="text-darkgray text-xs mt-4">
              Discover and sell faith-inspired products, manage memorable
              events, and connect with our Christian community. Experience
              faith, hope, and love in every aspect of your journey with us.
            </p>
            <div className="flex flex-row mt-4 gap-2">
              <a
                className="bg-normalbrown shadow-lg shadow-normalbrown rounded-md text-white p-4"
                size="xl"
                href="/marketplace"
              >
                Shop Now
              </a>
              <a href="/events" className="flex flex-row gap-1 text-white p-4">
                <svg
                  class="w-6 h-6 text-normalblue mr-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm14-7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm-5-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4Z" />
                </svg>
                Explore Events
              </a>
            </div>
          </div>

          <div className="mr-4 w-full lg:block hidden h-full">
            <img src={smilingman} className="object-cover h-full w-full" alt="Smiling Man" />
          </div>
        </div>
        <div className="flex flex-row justify-between ml-4 gap-2 w-full h-full ">
          <div className="flex flex-col justify-center">
            <p className="text-lightblue text-base">Hustle free management🔥</p>
            <p className="sm:text-7xl text-5xl text-white font-bold mt-2">
              Take <span className="text-lightblue">Charge</span> of{" "}
              <div className="text-lightbrown">
                Your <span className="text-white">Events</span>{" "}
              </div>{" "}
            </p>
            <p className="text-darkgray text-xs mt-4">
              Curate special events for the Christian communities. Leave the
              financials and ticket handling with us
            </p>
            <div className="flex flex-row mt-4 gap-2">
              <a
                className="text-white bg-normalbrown rounded-full p-4"
                href="#"
              >
                Register Now
              </a>
            </div>
          </div>

          <div className=" mr-4 basis-1/2 lg:block hidden h-full">
            <img src={people} className="object-cover h-full  w-full" />
          </div>
        </div>
        <div className="flex flex-row justify-between  gap-2 w-full h-full ml-4">
          <div className="flex flex-col justify-center">
            <p className="sm:text-7xl text-5xl text-white font-bold mt-2">
              Looking for
              <div className="text-white">
                <span className="text-lightblue">Devotionals</span> and{" "}
                <span className="text-lightbrown">Inspirational</span> Books
              </div>{" "}
            </p>
            <p className="text-darkgray text-xs mt-4">
              You are at the right place.
            </p>
            <div className="flex flex-row mt-4 gap-2">
              <a
                className="text-white bg-normalbrown rounded-full p-4"
                href="/marketplace"
              >
                Shop Now
              </a>
              <Button
                className="text-white bg-normalblue"
                size="xl"
                pill={true}
              >
                Explore More
              </Button>
            </div>
          </div>

          <div className=" basis-1/2 lg:block hidden h-full">
            <img src={reading} className="object-cover h-full w-full" />
          </div>
        </div>
      </Carousel>
    </div>
  );
}

export default LandingCarousel;
