import React, { createContext, useContext, useState } from "react";



const FAQContext = createContext();


export function FAQProvider({ children }) {
 
//   const [faq, setFaq] =useState(() => {
//     const storedFAQS = localStorage.getItem('faqs');
//     return storedFAQS ? JSON.parse(storedFAQS) : null;
//   });

const [faq, setFaq] =useState([])
  

 


  const faqlog=(faq)=>{
    setFaq(faq);
    // localStorage.setItem('faqs', JSON.stringify(faq)); 
  }
  


  

  return (
    <FAQContext.Provider
      value={{ faq,faqlog }}
    >
      {children}
    </FAQContext.Provider>
  );
}

// Create a custom hook to use the context
export function useFaq() {
  return useContext(FAQContext);
}
