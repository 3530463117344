import React, { useState ,useEffect,useRef} from "react";
import { useNavigate } from "react-router-dom";
import MainPagination from "../../../components/Miscellanous/MainPagination";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";
import { Button,Badge,Spinner,Table } from "flowbite-react";
import MessageModal from "../../../components/Modal/Admin/MessageModal";
import MessageEditModal from "../../../components/Modal/Admin/MessageEditModal";


function Messages() {
  const { token, adminlogout } = useAuth();
  const [messagesdata, setUsers] = useState([]);
  const navigate = useNavigate();
  const loadedOnce = useRef(false);
  const [id,setId]=useState(null);
  const [status,setStatus]=useState(null);

  const initialPayload = {
    currentpage: 1,
  };

  const [formData, setFormData] = useState(initialPayload);
  const [currentPage, setCurrentPage] = useState(1);

  const handleParamChange = (name, value) => {
    setFormData((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
    loadedOnce.current = false;
  };
  const [per_page, setPerpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [to_no, setTo] = useState(0);
  const [from_no, setFrom] = useState(0);
  const itemsPerPage = messagesdata && per_page;

  const itemsToShow = messagesdata;

  const totalPages = messagesdata && total > 0 && Math.ceil(total / itemsPerPage);

  const [open,setModal]=useState(false);

  const handleModal=(id)=>{
    setId(id)
    setModal(!open)
  }

  const [edit,setEModal]=useState(false);

  const handleEditModal=(id,status)=>{
    setId(id)
    setStatus(status)
    setEModal(!edit)
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    handleParamChange("currentpage", newPage);
  };

  useEffect(() => {
    if (loadedOnce.current) return;
    loadedOnce.current = true;
    handletotalevent();
  }, [loadedOnce, formData, currentPage]);

  const url = process.env.REACT_APP_API_URL;
  const handletotalevent = async () => {
    const additionalPath = `${url}/forms`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { params: formData, headers: headers })
      .then((response) => {
        setUsers(response.data.forms.data);
        setPerpage(response.data.forms.per_page);
        setTotal(response.data.forms.total);
        setTo(response.data.forms.to);
        setFrom(response.data.forms.from);
        loadedOnce.current = true;
        console.log(response.data.forms);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          adminlogout();
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            adminlogout();
            navigate("/admin");
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            loadedOnce.current = true;
            console.log(err);

            // Rethrow the error for further handling if needed
          }
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          loadedOnce.current = true;
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  const statustext = (status) => {
    if (status === "pending") {
      return (
        <Badge color="warning" className="flex justify-center">
          Pending
        </Badge>
      );
    } else if (status === "active") {
      return (
        <Badge color="success" className="flex justify-center">
          Active
        </Badge>
      );
    } else {
      return (
        <Badge color="failure" className="flex justify-center">
          Closed
        </Badge>
      );
    }
  };
  return (
    <div className="px-3">
      <p className="font-bold text-xl">Messages</p>

      {/* table */}
      <div className="mt-4  xl:block hidden">
        <Table>
          <Table.Head>
            <Table.HeadCell>Full Name</Table.HeadCell>
            <Table.HeadCell>Email</Table.HeadCell>
            <Table.HeadCell>Subject</Table.HeadCell>
            <Table.HeadCell>Message</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current==true? (itemsToShow && itemsToShow.length != 0 ? (
              itemsToShow.map((userData, index) => (
                <Table.Row
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  key={index}
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {userData.name}
                  </Table.Cell>
                  <Table.Cell>
                    {userData.email}
                  </Table.Cell>
                  <Table.Cell>{userData.subject}</Table.Cell>
                  <Table.Cell>
                    {userData.message}
                  </Table.Cell>
                  <Table.Cell>
                    {statustext(userData.status)}
                  </Table.Cell>
                  <Table.Cell>
                  <div className="flex  gap-2">
                     

                     <a
                       className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handleModal(userData)}
                     >
                       <p>View</p>
                     </a>

                     <a
                       className="font-medium text-red-900 hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handleEditModal(userData.id)}
                     >
                       <p>Delete</p>
                     </a>
                   </div>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  No Messages
                </Table.Cell>
              </Table.Row>
            )):(
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                <div className="flex justify-center items-center h-full">
          <Spinner aria-label="Loading" color="warning" size="md" />
        </div>
                </Table.Cell>
              </Table.Row>
              
            )}
          </Table.Body>
        </Table>
      </div>

      <div className="mt-4  md:block  xl:hidden hidden ">
        <Table>
          <Table.Head>
          <Table.HeadCell>Full Name</Table.HeadCell>
            <Table.HeadCell>Subject</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current==true?(itemsToShow && itemsToShow.length != 0 ? (
              itemsToShow.map((userData, index) => (
                <Table.Row
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  key={index}
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {userData.name}
                  </Table.Cell>

                  <Table.Cell>{userData.subject}</Table.Cell>
                 
                  <Table.Cell>
                  {statustext(userData.status)}
                  </Table.Cell>
                  <Table.Cell>
                  <div className="flex  gap-2">
                     

                     <a
                       className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handleModal(userData)}
                     >
                       <p>View</p>
                     </a>

                     <a
                       className="font-medium text-red-900 hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handleEditModal(userData.id)}
                     >
                       <p>Delete</p>
                     </a>
                   </div>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  No Messages
                </Table.Cell>
              </Table.Row>
            )):(
              <Table.Row>
              <Table.Cell colSpan={6} className="bg-white text-center">
              <div className="flex justify-center items-center h-full">
        <Spinner aria-label="Loading" color="warning" size="md" />
      </div>
              </Table.Cell>
            </Table.Row>

            )}
          </Table.Body>
        </Table>
      </div>

      <div className="mt-5  md:hidden lg:hidden xl:hidden block">
        <Table>
          <Table.Head>
            <Table.HeadCell>Full Name</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current==true? (itemsToShow && itemsToShow.length != 0 ? (
              itemsToShow.map((userData, index) => (
                <Table.Row
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  key={index}
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {userData.name}
                  </Table.Cell>

                  <Table.Cell>
                  <div className="flex  gap-2">
                     

                     <a
                       className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handleModal(userData)}
                     >
                       <p>View</p>
                     </a>

                     <a
                       className="font-medium text-red-900 hover:underline dark:text-cyan-500"
                       href="#"
                       onClick={()=>handleEditModal(userData.id)}
                     >
                       <p>Delete</p>
                     </a>
                   </div>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  No Messages
                </Table.Cell>
              </Table.Row>
            )):( <Table.Row>
              <Table.Cell colSpan={6} className="bg-white text-center">
              <div className="flex justify-center items-center h-full">
        <Spinner aria-label="Loading" color="warning" size="md" />
      </div>
              </Table.Cell>
            </Table.Row>)}
          </Table.Body>
        </Table>
      </div>

      <MainPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

{open&&<MessageModal id={id} open={open} handleModal={handleModal}/>}
      {edit&&<MessageEditModal id={id} open={edit} handleModal={handleEditModal} />}
    </div>
  );
}


export default Messages;