import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import logo from "../../../assets/images/KRIFFEONLINELOGO2.png";
import TopBanner from "../../../components/Navigation/TopBanner";
import NavigationBar from "../../../components/Navigation/NavigationBar";
import SearchBarCollapse from "../../../components/SearchBars/SearchBarCollapse";
import { Alert, Button, Spinner } from "flowbite-react";
import { useMarketplaceContext } from "../../../context/MarketplaceContext";
import CloseModal from "../../../components/Modal/CloseModal";
import OpenModal from "../../../components/Modal/OpenModal";
import axios from "axios";
import AdModal from "../../../components/Modal/AdModal";
import DeleteModal from "../../../components/Modal/DeleteModal";
import AdCarousel from "../../../components/Carousels/AdCarousel";

function AdsPage() {
  const { isLogged, token, logout } = useAuth();
  const navigate = useNavigate();

  const { id, status } = useParams();
  const [item, setItem] = useState([]);
  const [close, setClose] = useState(false);
  const [open, setOpen] = useState(false);
  const ulr= id.split("_")
  const formdata = { id: ulr[0] };
  const [err, setErr] = useState("");
  const [inputValue, setInputValue] = useState([]);

  const [productid, setProductID] = useState(ulr[0]);
  const [loading, setLoading] = useState(true);
  const [statuss, setStatus] = useState(null);

  console.log(item);
 

  const url =process.env.REACT_APP_API_URL;

  const handlemarketplace = () => {
    const additionalPath = `${url}/get_product_by_id`;
    // const path=`get_product_by_id?id=${id}`;
    // makeApiRequest("GET", null, path, token);
    const headers = {
      "Content-Type": "application/json",
      // 'Authorization':  `Bearer ${token}`,
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .get(additionalPath, { params: formdata, headers: headers })
      .then((response) => {
        setItem(response.data.product);
        setStatus(response.data.product.product_status)
        if(!status){
            setLoading(false);
        }
        
        // 
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
          setErr(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  let statusMessage;
  let statusColor;

  switch (statuss) {
    case "active":
      statusMessage = "Opened";
      statusColor = "bg-green-200 text-green-700";
      break;
    case "declined":
      statusMessage = "Declined";
      statusColor = "bg-red-200 text-red-700";
      break;
    case "closed":
      statusMessage = "Closed";
      statusColor = "bg-lightblue text-normalblue";
      break;
    default:
      statusMessage = "Pending";
      statusColor = "bg-lightbrown text-normalbrown";
      break;
  }

  const handleGoBack = () => {
    navigate("/account/ads");
  };

  const capitalize = (text) => {
    if (typeof text !== "string") {
      return "";
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const handleCloseModal = () => {
    setClose(!close);
  };
  const handleOpenModal = () => {
    setOpen(!open);
  };

  const displayProductDetails =
    item.product_details &&
    item.product_details.map((obj, index) => {
      const { attribute_name, attribute_value } = obj;
      let associatedName = "";
      let name = "";

      switch (attribute_name) {
        case "age_group":
          name = "Age group";
          associatedName = attribute_value;

          break;

        case "product_flexibility":
          name = "Flexibility";
          associatedName = attribute_value;

          break;

        case "material":
          name = "Material";
          associatedName = attribute_value;

          break;

        case "style":
          name = "Style";
          associatedName = attribute_value;

          break;

        case "format":
          name = "Format";
          associatedName = attribute_value;

          break;

        case "language":
          name = "Language";
          associatedName = attribute_value;

          break;

        // Add more cases for other attribute_names if needed

        default:
          break;
      }
      return (
        <div key={index}>
          <p className="text-midnightblue font-medium">{capitalize(name)}</p>

          <p className="text-darkgray">{capitalize(associatedName)}</p>
        </div>
      );
    });

  const [isWithdraw, setWithdraw] = useState(false);
  const handleWithdrawal = () => {
    setWithdraw(!isWithdraw);
  };

  const [isDelete, setDelete] = useState(false);
  const handleDelete = () => {
    setDelete(!isDelete);
  };

  const [isDate, setDate] = useState(false);
  useEffect(() => {
    if (item.expiry_date != null) {
      // call api to make the payment
      setDate(isToday());
    }
  }, [item]);

  const isToday = () => {
    const currentDate = new Date();
    const formattedInputDate = new Date(item.expiry_date);

    // Set hours, minutes, seconds, and milliseconds to zero for comparison
    currentDate.setHours(0, 0, 0, 0);
    formattedInputDate.setHours(0, 0, 0, 0);

    return currentDate.getTime() === formattedInputDate.getTime();
  };

  useEffect(() => {
    if (
      item.expiry_date == null &&
      statuss == "active" &&
      item.ad_type != "Standard"
    ) {
      // call api to make the payment
    }
  }, [item, statuss]);

  const location = useLocation(); 
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("description");
  const [toast,setToast]=useState(false);
  const [fail,setFail]=useState(false);
  const [codes, setCode] = useState("0");
  const loadedOnce = useRef(false); 
  const updateOnce = useRef(false); 
  const updatedOnce = useRef(false); 
  const [updates, setUpdate] = useState(false);
  const [selectedId,setSelected]=useState(code?(parseInt(code)):(ulr[2]?(parseInt(ulr[2])):(1)));
  const order = queryParams.get("order_id");
 

  





  const additionalPaths = `${url}/verify_payment`;
  const configs = {
    order_id: order,
  };
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  const push = async () => {
    await axios
      .post(additionalPaths, configs, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.data.status==='1') {
          setCode(response.data.data.status);
          setUpdate(true);
        } else {
          setLoading(false)
          setFail(true)
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          console.error(err);
        } else {
          console.error(err);
        }
      });
  };

  
  
  useEffect(() => {
    if (status) {

      if(code){
        if (updateOnce.current) return;
        updateOnce.current = true;
        handleupdated();

      }else{
        const verifyTransaction = () => {
          if (codes !== "1") {
            push();
          }
        };
  
        if (loadedOnce.current) return;
        loadedOnce.current = true;
        verifyTransaction();
  
        

      }
      
    }
  }, [loadedOnce, push, status,code]);

  useEffect(() => {
    if (updates) {

  
      if (updatedOnce.current) return;
      updatedOnce.current = true;
      handleupdated();
        

      
      
    }
  }, [updatedOnce, updates]);


  // go to the first page 
  const handleupdated = () => {
    // add to the temp array

   let sid=code?(parseInt(code)):(ulr[2]?(parseInt(ulr[2])):(1))
    const info = {
      ad_type: sid,
    };
  
    const param = {
      product_id: productid,
    };

    // call the paystack api and pay and after success call the update expiry api
    // update_product?product_id=PRD-20231113-GrYP
    const additionalPath = `${url}/update_product_expiry_date`;
    const headers = {
      "Content-Type": "application/json",
      // 'Authorization':  `Bearer ${token}`,
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .patch(additionalPath,info,{ params:param,headers: headers })
      .then((response) => {
        setWithdraw(false);
        if (response.data.responsecode === 200) {
         
          setToast(true);
          setLoading(false)
        }
        
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          setFail(true)
          console.log(err);
       

          // Rethrow the error for further handling if needed
        }
      });

    // update the product ifrst, if successful then call the paystack api an pay and if succesful call update
  };
  const updatesOnce=useRef(false)
  useEffect(() => {
    if ((item.length === 0 || (toast || fail)) && !updatesOnce.current) {
        handlemarketplace();
        updatesOnce.current = true;
    }
}, [item, toast, fail]);

  return (
    <section className="w-screen mb-10 ">
      <TopBanner />
      <NavigationBar logo={logo} color="text-midnightblue" status={isLogged} />
      <SearchBarCollapse />

      <a
        href="#"
        onClick={handleGoBack}
        className="text-normalblue px-3 text-sm pt-12"
      >
        {`<`}Go back
      </a>



      {toast&&<div className="px-3"><Alert color="success" onDismiss={() => setToast(false)}>
      <span className="font-medium">Update successful! </span>New Ad Type!
    </Alert></div>}
    {fail&&<div className="px-3"><Alert color="failure" onDismiss={() => setFail(false)}>
      <span className="font-medium">Update unsuccessful! </span>Please restart the process
    </Alert></div>}
      <div className="relative">
        <div className="flex flex-col px-3 pt-3 ">
          {/* carousel of pictures */}
          <div className="h-[30rem] rounded-md bg-midnightblue">
            <AdCarousel images={item.productimages} />
            {/* {item.productimages&&item.productimages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index}`}
              className=" border border-normalblue sm:w-full w-64"
            />
          ))} */}
          </div>

          <p className="text-midnightblue text-3xl font-bold text-center">
            {item.name}
          </p>
          <div>
            <div className="flex justify-center ">
              <Button className={statusColor} pill>
                {statusMessage}
              </Button>
            </div>
          </div>
          <hr className="my-2"></hr>

          <div className="flex sm:flex-row flex-col justify-between">
            <div className="basis-3/4 flex  flex-col sm:px-12 px-6 text-midnightblue gap-4 items-start">
              <div>
                <p className="font-bold text-xl">Description</p>
                <p className="text-darkgray">{item.description}</p>
              </div>
              <div>
                <p className="font-bold text-xl">Location</p>
                <p className="text-darkgray">{item.location}</p>
              </div>

              <div>
                <p className="font-bold text-xl">Ad Type</p>
                <p className="text-darkgray">{item.ad_type}</p>
              </div>

              <p className="font-bold text-xl">Product Details</p>
              <div className="grid sm:grid-cols-4 grid-cols-2 gap-4">
                {displayProductDetails}
              </div>

              <div>
                <p className="font-medium">Product Tags</p>

                <div className="flex flex-wrap">
                  {item.tags &&
                    item.tags.map((image, index) => (
                      <p className="text-darkgray">{image}, </p>
                    ))}
                </div>
              </div>

              <div>
                <p className="font-medium">Price</p>
                <p className="text-darkgray text-3xl font-bold">
                  GHC {item.price}
                </p>
              </div>
            </div>

            {/* add the expiry date column */}

            {statuss == "active" && (item.ad_type == "Standard" || isDate) ? (
              <div className="basis-1/3 h-1/2 mt-2 w-full border border-normalblue rounded-md p-2">
                <p className="font-bold text-xl text-center">Boost your Ad</p>
                <p className="text-darkgray text-center">
                  Upgrade your {item.ad_type} Ad and make it more visible on the
                  marketplace
                </p>{" "}
                <Button
                  className="bg-lightblue p-2 w-full"
                  onClick={handleWithdrawal}
                >
                  Boost
                </Button>
              </div>
            ) : null}
          </div>

          {/* boosting only shows when it is active && 1. it is standard, 2. the expiry date for the ad is close */}

          {/* if it si pending, opened close the ad. ifit is closed show the open ad */}
          <div className="flex w-full gap-2 mt-4 justify-end">
            <Button
              className="bg-[#AD3C3C] p-2 sm:w-1/4 w-1/2"
              onClick={handleDelete}
            >
              Delete Ad
            </Button>
            {/* {status == "Pending" && (
              <Button className="bg-normalblue p-2 sm:w-1/4 w-1/2">
                Edit Ad
              </Button>
            )} */}
            {statuss == "active" ? (
              <Button
                className="bg-[#c195ce] p-2 sm:w-1/4 w-1/2"
                onClick={handleCloseModal}
              >
                Close Ad
              </Button>
            ) : statuss != "pending" && statuss != "declined" ? (
              <Button
                className="bg-normalbrown p-2 sm:w-1/4 w-1/2"
                onClick={handleOpenModal}
              >
                Open Ad
              </Button>
            ) : null}
          </div>
        </div>
        {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md">
            {/* Your spinner component */}
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
          </div>
        </div>
      )}
      </div>
      
      {close && (
        <CloseModal id={id} mode={close} toggleModal={handleCloseModal} />
      )}
      {open && <OpenModal id={id} mode={open} toggleModal={handleOpenModal} />}
      {isDelete && (
        <DeleteModal id={id} mode={isDelete} toggleModal={handleDelete} />
      )}
      {isWithdraw && (
        <AdModal
          isWithdraw={isWithdraw}
          handleWithdrawal={handleWithdrawal}
          adtype={item.ad_type}
          productid={productid}
          purpose="ad"
        />
      )}
    </section>
  );
}

export default AdsPage;
