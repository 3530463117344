import React,{useState,useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import logo from "../../../assets/images/KRIFFEONLINELOGO2.png";
import TopBanner from "../../../components/Navigation/TopBanner";
import NavigationBar from "../../../components/Navigation/NavigationBar";
import SearchBarCollapse from "../../../components/SearchBars/SearchBarCollapse";
import EventReceipt from "../../../components/Miscellanous/EventReceipt";
import RatingCard from "../../../components/Cards/RatingCard";


function OrderPage(){
    const { isLogged } = useAuth();
    const navigate = useNavigate;
    const {id} = useParams();
    const[eventid,setEventId]=useState(null);
    const [date,setDate]=useState(null);

    const [isDatePassed, setIsDatePassed] = useState(false);

  useEffect(() => {
    // Assuming `date` is a string in the format "YYYY-MM-DD"
    const eventDate = new Date(date);
    const currentDate = new Date();

    setIsDatePassed(eventDate < currentDate);
  }, [date]);

    

    const handleGoBack = () => {
        navigate("/account/orders");
    };
    return(
        <section className="w-screen mb-10 ">
      <TopBanner />
      <NavigationBar logo={logo} color="text-midnightblue" status={isLogged} />
      <SearchBarCollapse />

      <a href="/account/orders"  className="text-normalblue px-3 text-sm pt-12 ">
        {`<`}Go back
      </a>
      
      <EventReceipt id={id} setEventId={setEventId} setDate={setDate}/>
      {isDatePassed && <div className="p-3 px-6">
        <RatingCard type="event" eventid={eventid}/>
       
          </div>}
      
        
    </section>)
}

export default OrderPage;