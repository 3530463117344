import React,{useEffect} from "react";



function EventsManagementCard({name,date,location}){

    return(
        <div className="flex flex-row border border-lightgray rounded-md text-midnightblue justify-between  items-center ">
            <div className="flex flex-row" >
                <img className="bg-midnightblue sm:w-24 w-16 rounded-l-md rounded-bl-md"/>
                <div className="flex flex-col p-2">
                <p className="font-medium">{name}</p>
                <p className="text-darkgray text-xs">{date}</p>
                <p className="text-sm">{location}</p>
            </div>
            </div>
            

            
        </div>
)
}

export default EventsManagementCard;