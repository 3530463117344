import React, { createContext, useContext, useState } from "react";
import axios from 'axios';




const AuthContext = createContext();


export function AuthProvider({ children }) {
  const [isLogged, setIsLogged] = useState(() => {
    const storedIsLogged = localStorage.getItem("isLogged");
    return storedIsLogged ? JSON.parse(storedIsLogged) : false;
  });
  const [user, setUser] =useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  
  const [token, setToken] = useState(() => {
    const storedToken = localStorage.getItem('token');
    return storedToken ? JSON.parse(storedToken) : null;
  });
  // const [token, setToken] = useState();

  const [organizer, setOrganizer] =useState(() => {
    const storedOrganizer = localStorage.getItem('organizer');
    return storedOrganizer ? JSON.parse(storedOrganizer) : null;
  });

  const [admin, setAdmin] =useState(() => {
    const storedOrganizer = localStorage.getItem('admin');
    return storedOrganizer ? JSON.parse(storedOrganizer) : null;
  });
  // const [organizer, setOrganizer] = useState();

  const [request, setRequest] =useState(() => {
    const storedOrganizer = localStorage.getItem('request');
    return storedOrganizer ? JSON.parse(storedOrganizer) : false;
  });
  

  const [isModalVisible, setIsModalVisible] = useState(false);
 
 


  const userlog=(user)=>{
    setUser(user);
    setIsLogged(true);
    localStorage.setItem('isLogged', JSON.stringify(true));
    localStorage.setItem('user', JSON.stringify(user)); 
  }

  const organizerlog=(user)=>{
   localStorage.setItem('organizer', JSON.stringify(user)); 
  }

  const requestlog=(val)=>{
    setRequest(val);
    localStorage.setItem('request', JSON.stringify(val)); 
   }

   const adminlog=(user)=>{
    setIsLogged(true);
    localStorage.setItem('isLogged', JSON.stringify(true));
    setAdmin(user);
    localStorage.setItem('admin', JSON.stringify(user)); 
   }
  


  const login = (token) => {
    localStorage.setItem('token', JSON.stringify(token)); 
    
  };
  const logout = () => {
    const url =process.env.REACT_APP_API_URL
    const additionalPath = `${url}/logout`;
    const headers = {
      'Content-Type': 'application/json',
      // 'Authorization':  `Bearer ${token}`,
  
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  
  
  
  
  
    axios.get(additionalPath,{headers:headers})
      .then(response => {
        // setProduct(response.data);
        console.log(response.data);
       if(response.data.message){
        setIsLogged(false);
    setUser(null);
    setOrganizer(null);
    setToken(null);

    localStorage.setItem('isLogged', JSON.stringify(false));
    localStorage.removeItem('user');
    localStorage.removeItem('organizer');
    localStorage.removeItem('token');
        }
  
        
       
      })
      .catch(err => {
        console.log(err);
  
      });
    
  };
  const adminlogout = () => {
    const url =process.env.REACT_APP_API_URL
    const additionalPath = `${url}/logout`;
    const headers = {
      'Content-Type': 'application/json',
      // 'Authorization':  `Bearer ${token}`,
  
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  
  
  
  
  
    axios.get(additionalPath,{headers:headers})
      .then(response => {
        // setProduct(response.data);
        console.log(response.data);
       if(response.data.message){
        setIsLogged(false);
    setAdmin(null);
    setToken(null);
    localStorage.setItem('isLogged', JSON.stringify(false));
    localStorage.removeItem('admin');
    localStorage.removeItem('token');
        }
  
        
       
      })
      .catch(err => {
        console.log(err);
  
      });
    
  };
  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
    
  };

  return (
    <AuthContext.Provider
      value={{ isLogged, user,login, logout, isModalVisible, toggleModal,token,userlog,organizerlog,organizer,requestlog,request,admin,adminlog,adminlogout }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// Create a custom hook to use the context
export function useAuth() {
  return useContext(AuthContext);
}
