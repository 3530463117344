import React, { useState } from "react";
import { Navbar, Dropdown, Avatar } from "flowbite-react";
import SearchBar from "../SearchBars/SearchBar";
import NotificationBar from "./Notifications";
import Saved from "./Saved";
import UserSettings from "./UserSettings";

function NavigationBar({ logo, color, status }) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleToggleClick = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <nav class="w-full flex  justify-between gap-2 px-1">
      <div class="w-full flex flex-wrap md:flex-nowrap items-center justify-between gap-2">
        <a class="flex items-center">
          <img
            alt="KriffeOnline Logo"
            class="sm:h-16 lg:h-24 h-16"
            src={logo}
          />
        </a>
        <div class="flex md:order-2 gap-1">
          <SearchBar />
          <UserSettings color={`w-5 h-5 ${color=="text-midnightblue"?("text-midnightblue"):("text-white")}`} status={status} /> 
          <button
            data-testid="flowbite-navbar-toggle"
            onClick={handleToggleClick}
            class="inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 md:hidden"
          >
            <span class="sr-only">Open main menu</span>
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 448 512"
              aria-hidden="true"
              class="h-6 w-6 shrink-0"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
            </svg>
          </button>
        </div>
        <div
          data-testid="flowbite-navbar-collapse"
          className={`${
            isMenuOpen ? "block" : "hidden"
          } w-full md:block md:w-auto`}
        >
          <ul class="mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-base md:font-medium">
            <li>
              <a
              className={color}
               
                href="/"
              >
                <p>Home</p>
              </a>
            </li>
            <li>
              <a
              className={color}
            
                href="/aboutus"
              >
                <p>About </p>
              </a>
            </li>
            <li>
              <a
              className={color}
               
                href="/marketplace"
              >
                Marketplace
              </a>
            </li>
            <li>
              <a
              className={color}
              href="/events"
              >
                Events
              </a>
            </li>
            <li>
              <a
              className={color}
              
                href="/contactus"
              >
                Contact
              </a>
            </li>
          </ul>
     
        </div>
      </div>
    </nav>
  );
}

export default NavigationBar;
