import { Button } from "flowbite-react";
import React from "react";
import {useNavigate} from "react-router-dom";

const EventsCard=({name,month,day,location,price,id,images})=>{
    const navigate=useNavigate();
    const handledetails=()=>{
        navigate(`/eventspage/${id}`);
    }
    const minprice=price&&price.reduce(
        (minPrice, currentTicket) =>
          currentTicket.price < minPrice ? currentTicket.price : minPrice,
        price[0].price // Set initial minimum price as the first ticket's price
      );
   
    return(
    <div className="flex flex-col justify-between basis-1/4">
        <img
          className="h-40 bg-midnightblue w-full rounded-lg object-cover"
          src={images && images[0]}
        />
        <div className="flex flex-row gap-4 pt-2">
            <div><p className="text-normalblue font-medium text-xl text-center">{month}</p>
            <p className="text-midnightblue text-4xl text-center">{day}</p></div>
            
            <div className="flex flex-col gap-1 justify-center">
                <p className="text-midnightblue font-bold text-xl">{name}</p>
                <p className="text-darkgray text-sm">{location}</p>
            </div>

            
        </div>
        <div className="flex justify-end">
                <Button onClick={handledetails} className="bg-normalbrown text-white">{price?(minprice==0
                    ? "Free"
                    : `\GHC${minprice}`):('View Event')}</Button>

            </div>
        

    </div>)
}
export default EventsCard;