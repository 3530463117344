import React, { useEffect } from "react";
import { Navbar, Dropdown, Avatar } from "flowbite-react";
import { useAuth } from "../../../context/AuthContext";


function AdminMainNav() {
  const {adminlogout}=useAuth()
//  auth for admin

 

  return (
    <div className="flex justify-end p-2 sm:bg-white bg-midnightblue ">
      <Dropdown
        arrowIcon={false}
        inline
        label={
          <div className="flex flex-row items-center gap-2 mr-2">
            <svg
              class="w-6 h-6 sm:text-midnightblue text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 11 14H9a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 10 19Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
            <p className="sm:text-midnightblue text-white">
            <p>Kriffe Admin</p>
            </p>
            <svg
              class="w-3 h-3 sm:text-midnightblue text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 8"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
              />
            </svg>
          </div>
        }
      >
        <Dropdown.Header>
          {/* admin headere */}
          <p>Admin email</p>
        </Dropdown.Header>
        <Dropdown.Item>
          <a href="/admin/dashboard">Dashboard</a>
        </Dropdown.Item>
        <Dropdown.Item>
          <a href="/admin/users">Users</a>
        </Dropdown.Item>
        <Dropdown.Item>
          <a href="/admin/ads">Ads</a>
        </Dropdown.Item>
        <Dropdown.Item>      <a href="/admin/events">Events</a></Dropdown.Item>
        <Dropdown.Item>      <a href="/admin/organizers">Organizers</a></Dropdown.Item>
        <Dropdown.Item>
          <a href="/admin/requests">Requests</a>
        </Dropdown.Item>
        <Dropdown.Item>      <a href="/admin/messages">Messages</a></Dropdown.Item>
        <Dropdown.Item>      <a href="/admin/category">Categories</a></Dropdown.Item>
        <Dropdown.Item>      <a href="/admin/faqs">FAQs</a></Dropdown.Item>
        {/* <Dropdown.Item>      <a href="/admin/aduit">Audit Logs</a></Dropdown.Item>
        <Dropdown.Item>      <a href="/admin/settings">Settings</a></Dropdown.Item> */}
        <Dropdown.Divider />
        <Dropdown.Item>
          <a href="#" className="text-red-600" onClick={adminlogout}>
            Leave Console
          </a>
        </Dropdown.Item>
      </Dropdown>
    </div>
  );
}
export default AdminMainNav;
