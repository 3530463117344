import { Button, Spinner } from "flowbite-react";
import React, { useEffect,useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/images/KRIFFEONLINELOGO2.png";
import { useApi } from "../../api/APIProvider";

function SetupAccount() {
  const { makeApiRequest, data,error } = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const form = { confirmationcode: token };
  const queryParam = new URLSearchParams(form).toString();
  const additionalPath = "confirm_account";
  const [loading, setLoading] = useState(true);
  const [loadedOnce, setLoadedOnce] = useState(false);

  const handlehome = () => {
    console.log("home");
    navigate("/");
  };

  const push = async () => {
    try {
      await makeApiRequest("POST", form, additionalPath);
      setLoading(false);
      setLoadedOnce(true);
    } catch (error) {
      // Handle error (display a message, redirect, etc.)
      console.error("Error confirming account:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token && !loadedOnce) {
      push();
    }
  }, [token, loadedOnce, makeApiRequest]);

  return (
    <section className="h-screen space-y-10">
      <div className="w-48">
        <img src={logo} alt="KriffeOnline Logo" />
      </div>

      {loading && !loadedOnce ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner aria-label="Default status example" size="xl" color="warning" />
        </div>
      ) : data && data.responsemessage ? (
        <div className="flex flex-col gap-2 justify-center items-center px-3 h-[75%]">
          <div>
            <svg
              className="w-48 h-48 text-green-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
          </div>

          <p className="font-bold text-4xl">Account Verified</p>

          <p>Go back to KriffeOnline and log into your account</p>

          <div>
            <Button pill onClick={handlehome} className="bg-normalblue p-8 w-64">
              Go to Home
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          {error ? (
            <p>Error confirming account. Please try again.</p>
          ) : (
            <p>Unexpected error. Please try again later.</p>
          )}
        </div>
      )}
    </section>
  );
}
export default SetupAccount;
