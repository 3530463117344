import { Button } from "flowbite-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";


function AccountStatus({premium}){
    const navigate=useNavigate();

    const handlerequest=()=>{
        navigate("/organizerrequest");
    }
    const stand= premium=="Premium User"?(<Button ></Button>):(<Button pill  color="success">Active</Button>);
    const prem_text=premium=="Premium User"?("Premium Account"):("Subscribe to Premium and get access to host and manage events on KriffeOnline");
    const prem_button= premium=="Premium User"?(<Button pill  color="success">Active</Button>):(<Button className="bg-normalbrown" onClick={handlerequest}>Subscribe</Button>);

    return(
    <div className="flex flex-col space-y-8 mt-4">
        <div className={premium=="Premium User"?"flex flex-row justify-between border border-lightgray rounded-md p-3 items-center":"flex flex-row justify-between border border-normalblue rounded-md p-3 items-center"}>
            <p className={premium=="Premium User"?"text-darkgray text-sm":"text-normalblue font-medium"}>Standard Account</p>
            {stand}

        </div>
        <div className={premium=="Premium User"?"flex flex-row justify-between border border-normalblue rounded-md p-3 items-center":"flex flex-row justify-between border border-lightgray rounded-md p-3 items-center"}>
            <p className={premium=="Premium User"?"text-normalblue font-medium":"text-darkgray text-sm"}>{prem_text} </p>
            {prem_button}
            

        </div>


    </div>)
}
export default AccountStatus;