import { Button } from "flowbite-react";
import React, { useState ,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {useApi} from "../../api/APIProvider";
import {useAuth} from "../../context/AuthContext";

function Settings(){
    const [Subscribe,setSubscribe]=useState(false);
    const navigate=useNavigate();
    const {user,logout}=useAuth();
    const {makeApiRequest,data,error}=useApi();
    const additionalPath=`send_reset_password_link?email=${user.email}`;
    const handlechange=()=>{
        // forgotpassword api
        makeApiRequest("POST",null,additionalPath);
        // navigate('/forgotpassword/true/change');
    }

    useEffect(()=>{
        if (error&&error.response && error.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log('Unauthorized: Logging user out or performing specific action...');
            logout();
            navigate('/');
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            console.log(error);

    
      // Rethrow the error for further handling if needed
          }

    },[])

    return(
        <div className="flex flex-col gap-4 ">
        <p className="font-bold">Settings</p>
        <div>
            <p className="font-medium">User Subscriptions</p>
            <hr></hr>
            {Subscribe?(<div className="flex flex-row justify-between pt-4">     
            <p className="text-darkgray">You are currently subscribed to our newsletter. Unsubscribe now </p>  
            <Button pill color="failure">Unsubscribe</Button>     
            </div>):(<div className="flex flex-row justify-between pt-4">     
            <p className="text-darkgray">Subscribe to our newsletter. Do not miss all the details </p>  
            <Button pill color="success">Subscribe</Button>     
            </div>)}
            
            
        </div>
        
        
        {/* Form */}
        <div>
            <p className="font-medium">Account Settings</p>
            <hr></hr>
            <div className="flex flex-row justify-between pt-4">
            <p className="text-darkgray">Change your password</p>  
            <Button  className="bg-normalbrown rounded-md" onClick={handlechange}>Change Password</Button>     

            </div>
            

        </div>
    </div>
    )
}
export default Settings;