import React, { useEffect, useState,useRef,useMemo } from "react";
import ProductCard from "../Cards/ProductCard";
import { useAuth } from "../../context/AuthContext";
import { useMarketplaceContext } from "../../context/MarketplaceContexts";
import axios from "axios";
import { format, parse } from "date-fns";
import EventsCard from "../Cards/EventsCard";
import { fetchDataForEventCategory } from "../../api/ApiPage";
import { useNavigate } from "react-router-dom";

function SimilarEvents({ id,name,event}) {
  const { token, logout } = useAuth();
  const initialpayload = { event_category: null };
  const [events, setEvents] = useState([]);
  const [data, setData] = useState([]);
  const [err, setErr] = useState("");
  const [categoryId, setCategory] = useState(null);
  const loadedOnce = useRef(false);
  const [formdata, setFormData] = useState(initialpayload);
  const memoizedFormData = useMemo(() => formdata, [formdata]);
  const navigate=useNavigate();


  useEffect(() => {
    // Make the first API call

    if (data.length === 0) {
      fetchDataForEventCategory(logout).then((data) => {
        setData(data);
      });
    }





    if (categoryId === null && id ) {
      const foundItem = data.find((item) => item.name === id);
      if (foundItem) {
        console.log(foundItem)
        setCategory(foundItem.id);
        handleParamChange("event_category", foundItem.id);
      }
    }

  }, [categoryId, data,id]);

  const handleParamChange = (name, value) => {
    setFormData((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
    loadedOnce.current = false;
  };

  useEffect(() => {
    // Make the first API call
    if (categoryId !== null && event.length>0) {
      if (loadedOnce.current) return;
      loadedOnce.current = true;
      handlemarketplace();
    }
  }, [loadedOnce, categoryId,event]);

 

  const url =process.env.REACT_APP_API_URL;

  const handlemarketplace = () => {
    console.log(memoizedFormData)
    const additionalPath = `${url}/list_events`;
    const headers = {
      "Content-Type": "application/json",
      // 'Authorization':  `Bearer ${token}`,
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .get(additionalPath, { params: memoizedFormData, headers: headers })
      .then((response) => {
        // console.log(response.data.events.data)
        let filteredEvents=[];
       
        // const filteredEvents = response.data.events.data.filter(
        //   (product) => product.event_no !== name
        // );
        if(event&&event.length>0){
          // console.log(event)
          filteredEvents = response.data.events.data.filter(
            (product) => {
              // Check if the event is not already present in the events parameter
              return !event.some(event => event.event_no === product.event_no) && product.event_no !== name;
            }
          );

        }else{
          filteredEvents = response.data.events.data.filter(
              (product) => product.event_no !== name
            );
        }
        
        // console.log(filteredEvents);
        const top5Events = filteredEvents.slice(0, 4);
        setEvents(top5Events);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
          setErr(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

 

  return (
    <div className="flex sm:flex-row flex-col sm:gap-8 gap-4">
      {events && events.length > 0 ? (
        events.map((event, index) => (
          <EventsCard
            key={index}
            name={event.name}
            month={format(new Date(event.date), "MMM")}
            day={format(new Date(event.date), "dd")}
            location={event.location}
            price={event.ticket_type}
            id={event.event_no}
          />
        ))
      ) : (
        <p className="text-darkgray">No similar products</p>
      )}
    </div>
  );
}

export default SimilarEvents;
