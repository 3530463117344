import React,{useState} from "react";
import { Button } from "flowbite-react";
import { useNavigate } from 'react-router-dom';
import TopBanner from "../../components/Navigation/TopBanner";
import NavigationBar from "../../components/Navigation/NavigationBar";
import FixedFooter from "../../components/Footer/FixedFooter";
import SearchBarCollapse from "../../components/SearchBars/SearchBarCollapse";
import logo from "../../assets/images/KRIFFEONLINELOGO2.png";
import { useAuth } from "../../context/AuthContext";


function Error404(){
    const { isLogged } = useAuth();
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };
    return(
    <div>
        <section className="w-screen h-screen">
            <TopBanner/>
            <NavigationBar logo={logo} color="text-midnightblue" status={isLogged}/>
            <SearchBarCollapse />
            <div className="flex flex-col justify-center items-center gap-4 h-[75%] px-3">
                <p className="text-midnightblue font-bold text-base">OOPS! Why are you here?</p>
                <p className="text-normalblue font-bold text-[10rem]">404</p>
                <p className="text-darkgray text-sm">We are sorry for the inconvenience. It looks like you are trying to access a page that does not exist</p>
                <Button className="bg-normalbrown rounded-full mt-2 p-4 w-48" onClick={handleGoBack}>Back to Page</Button>
            </div>
  

        </section>
        <section className="w-screen">
            <FixedFooter/>

        </section>

    </div>
    );
    
}
export default Error404;