import React, { useRef,useEffect,useState } from 'react';
import Chart from 'react-apexcharts';
import { useAuth } from "../../../context/AuthContext";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const PieChart = () => {
  const {token,adminlogout}=useAuth();
  const navigate=useNavigate();

  const options = {};
 
  const [label, setLabel] = useState(['pending', 'opened', 'closed']);
  const [value, setValue] = useState([0, 0, 0]);
  const [trending,setTrending]=useState([]);

  const dailyOnce=useRef(false);
  const useOnce=useRef(false);
  const url =process.env.REACT_APP_API_URL;



  useEffect(()=>{
    // get_total_order
    // 
    if (!token) {
        // If token is not present in the context, try to get it from local storage
        const storedToken = localStorage.getItem('token'); // Replace 'yourTokenKey' with the actual key you used for storing the token
        if (storedToken) {
          // If token is found in local storage, set it in the context
          // Note: You might want to validate and handle the token accordingly
          // (e.g., check if it's still valid)


          if (dailyOnce.current) return;
          dailyOnce.current = true;
          handletotaldaily(JSON.parse(storedToken));
        }
      }else{
        if (dailyOnce.current) return;
        dailyOnce.current = true;
        handletotaldaily(token);

      }

   

  },[token]);

  const handletotaldaily=async(tokens)=>{
    // get_dashboard_total_revenue
    const additionalPath = `${url}/admin/dashboard_get_event_statistics`;
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokens}`,
  };

  

  await axios.get(additionalPath, {headers: headers })
      .then(response => {
        console.log(response.data);
        setTrending(response.data.statistics);
        //   const labels = Object.keys(response.data.statistics);
        //   const values = Object.values(response.data.statistics);
      
        //   setLabel(labels);
        const responsestats=response.data.statistics
        const values = label.map((standardLabel) => responsestats[standardLabel] || 0);
    
          setValue(values);
   
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log('Unauthorized: Logging user out or performing specific action...');
          adminlogout();
          navigate("/admin")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
        // Rethrow the error for further handling if needed
        }
      })
  }


  // Sort the data based on total revenue in descending order


// const labels=trending.map(item => item.name);
// const views=trending.map(item => item.total_views);


  


  const chartData = {
    series: value, // Replace with your revenue data per event
    options: {
      chart: {
        width: 400,
        type: 'pie',
        height:400
      },
      labels: label, // Event labels
      colors: ['#1C64F2', '#16BDCA', '#9061F9', '#FF4560', '#FFC85A'], // Customize colors
      legend: {
        position: 'bottom',
      },
    },
  };

  return (
    <div className="pie-chart flex justify-center">
      <Chart options={chartData.options} series={chartData.series} type="pie"  
        width={chartData.options.chart.width}
        height={chartData.options.chart.height} />
    </div>
  );
};

export default PieChart;