import React,{useEffect, useRef, useState} from "react";
import ProfileForm from "../Forms/ProfileForm";
import AccountStatus from "../Miscellanous/AccountStatus";
import { useAuth } from "../../context/AuthContext";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

function Profile(){
    const[premium,setPremium]=useState(false);
    const { user,token,logout } = useAuth();
    const navigate=useNavigate();

    const role=useRef(false);

    useEffect(()=>{
        if (role.current) return;
        role.current = true;
        handless();

    },[role]);

    const url =process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/get_current_user_info`;
  const headers = {
    'Content-Type': 'application/json',
    // 'Authorization':  `Bearer ${token}`,

  };
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

    const handless=()=>{
        axios.get(additionalPath,{headers:headers})
    .then(response => {

        if(response.data.userdetails.userrolename=="Premium User"){
             // Handle successful response
      const currentUser = JSON.parse(localStorage.getItem('user'));

      // Assume you want to update the 'name' property
                const updatedName = 'Premium User';
      
                // Update the 'name' property in the user object
                currentUser.userrolename = updatedName;
      
                // Store the updated user details back in local storage
                localStorage.setItem('user', JSON.stringify(currentUser));
            
                role.current=true;

        }
     
   
      // setLoading(false);
    })
    .catch(error => {

      if (error.response && error.response.status === 401) {
        // Perform actions when 401 (Unauthorized) error occurs
        console.log('Unauthorized: Logging user out or performing specific action...');
        logout();
        navigate("/");
        // Example: Log the user out or redirect to login page
        // You may perform the necessary actions here based on your application flow
      } else {
        // Handle other errors
        console.log(error);

  
     // Rethrow the error for further handling if needed
      }
    });
    }

    // call the api and check and see if the role has chnage

    return(
    <div className="flex flex-col gap-4">
        <p className="font-bold">My Details</p>
        <div>
            <p className="font-medium">Personal Information</p>
            <hr></hr>
            <ProfileForm userinfo={user}/>
        </div>
        
        
        {/* Form */}
        <div>
            <p className="font-medium">User Information</p>
            <hr></hr>
            <AccountStatus premium={user.userrolename}/>

        </div>
        
        {/* Two cards */}



    </div>)
}
export default Profile;