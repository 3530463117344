import React, { useEffect, useState, useRef } from "react";
import { Table, Button, Badge, Spinner } from "flowbite-react";
import RevenueLineChart from "../../components/Charts/RevenueLineChart";
import PieChart from "../../components/Charts/PieChart";
import WithdrawalModal from "../../components/Modal/WithdrawalModal.js";
import { useAuth } from "../../context/AuthContext";
import MainPagination from "../../components/Miscellanous/MainPagination";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FinanceModal from "../../components/Modal/FinanceModal.js";

function Finances() {
  const { token, logout } = useAuth();
  const navigate = useNavigate();
  const [isWithdraw, setWithdraw] = useState(false);
  const handleWithdrawal = () => {
    setWithdraw(!isWithdraw);
  };
  const [totalrevenue, setRevenue] = useState(0);
  const revenueOnce = useRef(false);

  const [totalorder, setOrder] = useState(0);
  const orderOnce = useRef(false);

  const [totaldaily, setDaily] = useState(0);
  const dailyOnce = useRef(false);

  const [order, setOrders] = useState([]);
  const ordersOnce = useRef(false);

  const [event, setEvents] = useState(0);
  const eventOnce = useRef(false);

  const initialPayload = {
    currentpage: 1
  };

  const [formData, setFormData] = useState(initialPayload);
  const [currentPage, setCurrentPage] = useState(1);
  const url =process.env.REACT_APP_API_URL;

  useEffect(() => {
    // get_total_order
    //
    if (revenueOnce.current) return;
    revenueOnce.current = true;
    handletotalrevenue();
  }, []);

  const handletotalrevenue = async () => {
    // get_dashboard_total_revenue
    const additionalPath = `${url}/get_total_revenue`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { headers: headers })
      .then((response) => {
        const roundedRevenue = parseFloat(response.data.Revenue).toFixed(2);
        setRevenue(roundedRevenue);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/");
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  useEffect(() => {
    // get_total_order
    //
    if (orderOnce.current) return;
    orderOnce.current = true;
    handletotalorder();
  }, []);

  const handletotalorder = async () => {
    // get_dashboard_total_revenue
    const additionalPath = `${url}/get_total_order`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { headers: headers })
      .then((response) => {
        setOrder(response.data.order);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/");
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  // get_daily_income

  useEffect(() => {
    // get_total_order
    //
    if (dailyOnce.current) return;
    dailyOnce.current = true;
    handletotaldaily();
  }, []);

  const handletotaldaily = async () => {
    // get_dashboard_total_revenue
    const additionalPath = `${url}/get_daily_income`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { headers: headers })
      .then((response) => {
        console.log(response.data);
        const roundedRevenue = parseFloat(response.data.income).toFixed(2);
        setDaily(roundedRevenue);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/");
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  const handleParamChange = (name, value) => {
    setFormData((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
    ordersOnce.current=false;
  };
  // get_all_orders
  useEffect(() => {
    // get_total_order
    //
    if (ordersOnce.current) return;
    ordersOnce.current = true;
    handletotalorders();
  }, [ordersOnce,formData,currentPage]);

  const handletotalorders = async () => {
    // get_dashboard_total_revenue
    const additionalPath = `${url}/get_all_orders`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { params:formData,headers: headers })
      .then((response) => {
        console.log(response.data.order);
        setOrders(response.data.order.data);
        setPerpage(response.data.order.per_page);
        setTotal(response.data.order.total);
        setTo(response.data.order.to);
        setFrom(response.data.order.from);
        ordersOnce.current = true;
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/");
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  useEffect(() => {
    // get_total_order
    //
    if (eventOnce.current) return;
    eventOnce.current = true;
    handletotalevent();
  }, []);

  const handletotalevent = async () => {
    // get_dashboard_total_event
    const additionalPath = `${url}/get_dashboard_total_event`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { headers: headers })
      .then((response) => {
        setEvents(response.data.total_no);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/");
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  const [per_page, setPerpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [to_no, setTo] = useState(0);
  const [from_no, setFrom] = useState(0);
  const itemsPerPage = order && per_page;





  const totalPages =
    order && total > 0 && Math.ceil(total / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    handleParamChange("currentpage", newPage);
  };

  const statustext = (status) => {
    if (status === "paid") {
      return (
        <Badge color="success" className="flex justify-center">
          Paid
        </Badge>
      );
    } else {
      return (
        <Badge color="failure" className="flex justify-center">
          Not Paid
        </Badge>
      );
    }
  };

  useEffect(() => {
    // Update filteredOrders when order array changes
    setFilteredOrders(order);
  }, [order]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter orders based on the search query
    const filtered = order.filter((order) => {
      // You can customize this condition based on your search criteria
      return order.order_no.includes(query);
    });

    setFilteredOrders(filtered);
    console.log(event.target.value)
  };
  const [mode,setMode]=useState(false);
  const handletogglemodal=()=>{
    setMode(!mode);
  }
  const renderTableRows = () => {
    if (ordersOnce.current==false) {
      return (
        <Table.Row>
          <Table.Cell colspan={5} className="bg-white text-center">
            <div className="flex justify-center items-center h-full">
              <Spinner aria-label="Loading" color="warning" size="md" />
              <p className="text-darkgray">Loading Orders.....</p>
            </div>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (filteredOrders.length !== 0) {
      return filteredOrders.map((orders, index) => (


                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {orders.order_no}
                    </Table.Cell>
                    <Table.Cell>{orders.total_ticket}</Table.Cell>
                    <Table.Cell>{orders.total}</Table.Cell>
                    <Table.Cell>{statustext(orders.status)}</Table.Cell>
                    <Table.Cell>
                      <a
                        className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                        href="/tables"
                      >
                        <p>View</p>
                      </a>
                    </Table.Cell>
                  </Table.Row>
         

      ));
    } else {
      return (
        <Table.Row>
          <Table.Cell colspan={5} className="bg-white text-center">
            No  orders found.
          </Table.Cell>
        </Table.Row>
      );
    }
  };

  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleViewOrder = (order) => {
    setSelectedOrder(order);
  };

  const renderTablesRows = (handleViewOrder) => {
    if (ordersOnce.current==false) {
      return (
        <Table.Row>
          <Table.Cell colspan={5} className="bg-white text-center">
            <div className="flex justify-center items-center h-full">
              <Spinner aria-label="Loading" color="warning" size="md" />
              <p className="text-darkgray">Loading Orders.....</p>
            </div>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (filteredOrders.length !== 0) {
      return filteredOrders.map((orders, index) => (


        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {orders.order_no}
        </Table.Cell>
        <Table.Cell>{orders.total}</Table.Cell>
        <Table.Cell>{statustext(orders.status)}</Table.Cell>
        <Table.Cell>
          <a
            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
            href="#"
            onClick={() => handleViewOrder(orders)}
          >
            <p>View</p>
          </a>
        </Table.Cell>
      </Table.Row>
         

      ));
    } else {
      return (
        <Table.Row>
          <Table.Cell colspan={5} className="bg-white text-center">
            No  orders found.
          </Table.Cell>
        </Table.Row>
      );
    }
  };

  const renderTableRow = () => {
    if (ordersOnce.current==false) {
      return (
        <Table.Row>
          <Table.Cell colspan={5} className="bg-white text-center">
            <div className="flex justify-center items-center h-full">
              <Spinner aria-label="Loading" color="warning" size="md" />
              <p className="text-darkgray">Loading Orders.....</p>
            </div>
          </Table.Cell>
        </Table.Row>
      );
    }

    if (filteredOrders.length !== 0) {
      return filteredOrders.map((orders, index) => (


        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {orders.order_no}
                    </Table.Cell>

                    <Table.Cell>{orders.total}</Table.Cell>
                  </Table.Row>
                
             
         

      ));
    } else {
      return (
        <Table.Row>
          <Table.Cell colspan={5} className="bg-white text-center">
            No  orders found.
          </Table.Cell>
        </Table.Row>
      );
    }
  };

  
  

  return (
    <div className="p-3 space-y-4">
      <div className="flex flex-row justify-between">
        <p className="text-xl font-bold text-midnightblue">Finances</p>
      </div>

      <div className="grid grid-cols-4 gap-4 w-full">
        {/* card */}
        <div className="flex flex-row bg-white rounded-md p-4 justify-between sm:col-span-1 col-span-2 items-center ">
          <div className="flex flex-col">
            <p className="text-midnightblue font-bold text-xl">
              {totalrevenue}
            </p>
            <p className="text-xs text-darkgray">Total Revenue</p>
          </div>

          <svg
            class="w-6 h-6 text-normalblue"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 18"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 12v5m5-9v9m5-5v5m5-9v9M1 7l5-6 5 6 5-6"
            />
          </svg>
        </div>
        <div className="flex flex-row bg-white rounded-md p-4 justify-between sm:col-span-1 col-span-2 items-center">
          <div className="flex flex-col">
            <p className="text-midnightblue font-bold text-xl">{totalorder}</p>
            <p className="text-xs text-darkgray">Total Orders</p>
          </div>

          <svg
            class="w-6 h-6 text-normalblue"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 18"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 12v5m5-9v9m5-5v5m5-9v9M1 7l5-6 5 6 5-6"
            />
          </svg>
        </div>
        <div className="flex flex-row bg-white rounded-md p-4 justify-between sm:col-span-1 col-span-2 items-center">
          <div className="flex flex-col">
            <p className="text-midnightblue font-bold text-xl">{totaldaily}</p>
            <p className="text-xs text-darkgray">Total Daily Income</p>
          </div>

          <svg
            class="w-6 h-6 text-normalblue"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 18"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 12v5m5-9v9m5-5v5m5-9v9M1 7l5-6 5 6 5-6"
            />
          </svg>
        </div>
        <div className="flex flex-row bg-white rounded-md p-4 justify-between sm:col-span-1 col-span-2 items-center">
          <div className="flex flex-col">
            <p className="text-midnightblue font-bold text-xl">{event}</p>
            <p className="text-xs text-darkgray">Total Events</p>
          </div>

          <svg
            class="w-6 h-6 text-normalblue"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 18"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 12v5m5-9v9m5-5v5m5-9v9M1 7l5-6 5 6 5-6"
            />
          </svg>
        </div>
      </div>

      <div>
        <p className="text-midnightblue font-bold">Sales</p>
        <div className="flex lg:flex-row flex-col gap-2 h-88">
          <div className="bg-white rounded-md p-4 basis-[60%]">
           <RevenueLineChart /> 
          </div>
          <div className="bg-white rounded-md p-4 basis-[40%] flex flex-col justify-center">
            <p className="text-center font-bold">Revenue Per Event</p>
            <PieChart />
          </div>
        </div>
      </div>

      <div>
        <p className="text-midnightblue font-bold">Financial History</p>
        <div className="mt-4 xl:block hidden">
        <input
          type="text"
          placeholder="Search Order Number"
          value={searchQuery}
          onChange={handleSearch}
          className="rounded-md p-2 border border-normalblue mb-2"
        />
          <Table>
            <Table.Head>
              <Table.HeadCell>Order Number</Table.HeadCell>
              <Table.HeadCell>Total Tickets</Table.HeadCell>
              <Table.HeadCell>Total Bill</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {renderTableRows()}
              {/* {ordersOnce.current==false ? (
                <Table.Row>
                  <Table.Cell colspan={5} className="bg-white text-center">
                    <div className="flex justify-center items-center h-full">
                      <Spinner aria-label="Loading" color="warning" size="md" />
                      <p className="text-darkgray">Loading Orders.....</p>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ) : order && order.length != 0 ? (
                order.map((orders, index) => (
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {orders.order_no}
                    </Table.Cell>
                    <Table.Cell>{orders.total_ticket}</Table.Cell>
                    <Table.Cell>{orders.total}</Table.Cell>
                    <Table.Cell>{statustext(orders.status)}</Table.Cell>
                    <Table.Cell>
                      <a
                        className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                        href="/tables"
                      >
                        <p>View</p>
                      </a>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colspan={5} className="bg-white text-center">
                    No Orders
                  </Table.Cell>
                </Table.Row>
              )} */}
            </Table.Body>
          </Table>
        </div>

        <div className="mt-4  md:block  xl:hidden hidden">
        <input
          type="text"
          placeholder="Search Order Number"
          value={searchQuery}
          onChange={handleSearch}
          className="rounded-md p-2 border border-normalblue mb-2"
        />
          <Table>
            <Table.Head>
              <Table.HeadCell>Order Number</Table.HeadCell>
              <Table.HeadCell>Total Bill</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
            {renderTablesRows(handleViewOrder)}
              {/* {ordersOnce.current == false ? (
                <Table.Row>
                  <Table.Cell colspan={5} className="bg-white text-center">
                    <div className="flex justify-center items-center h-full">
                      <Spinner aria-label="Loading" color="warning" size="md" />
                      <p className="text-darkgray">Loading Orders.....</p>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ) : order && order.length != 0 ? (
                order.map((orders, index) => (
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {orders.order_no}
                    </Table.Cell>
                    <Table.Cell>{orders.total}</Table.Cell>
                    <Table.Cell>{statustext(orders.status)}</Table.Cell>
                    <Table.Cell>
                      <a
                        className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                        href="/tables"
                      >
                        <p>View</p>
                      </a>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colspan={5} className="bg-white text-center">
                    No Orders
                  </Table.Cell>
                </Table.Row>
              )} */}
            </Table.Body>
          </Table>
        </div>

        <div className="mt-4  md:hidden lg:hidden xl:hidden block">
        <input
          type="text"
          placeholder="Search Order Number"
          value={searchQuery}
          onChange={handleSearch}
          className="rounded-md p-2 border border-normalblue mb-2"
        />
          <Table>
            <Table.Head>
              <Table.HeadCell>Order Number</Table.HeadCell>

              <Table.HeadCell>Total Bill</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
            {renderTableRow()}
              {/* {ordersOnce.current==false?(<Table.Row>
                  <Table.Cell colspan={5} className="bg-white text-center">
                  <div className="flex justify-center items-center h-full">
                  <Spinner aria-label="Loading" color="warning" size="md" />
                  <p className="text-darkgray">Loading Withdrawals.....</p>
                </div>
                  </Table.Cell>
                </Table.Row>):(order && order.length != 0 ? (
                order.map((orders, index) => (
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {orders.order_no}
                    </Table.Cell>

                    <Table.Cell>{orders.total}</Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colspan={2} className="bg-white text-center">
                    No Orders
                  </Table.Cell>
                </Table.Row>
              ))} */}
            </Table.Body>
          </Table>
        </div>
        <MainPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
      {/* {mode&&<FinanceModal mode={mode} toggleModal={handletogglemodal}/>} */}
      {selectedOrder && (
        <FinanceModal order={selectedOrder} toggleModal={() => setSelectedOrder(null)} />
      )}
    </div>
  );
}
export default Finances;
