import React from "react";
import { Button, Modal } from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import axios from 'axios';

function DeleteModal({mode,toggleModal,id}){
  const {token,logout} = useAuth();
    const navigate=useNavigate();
    const url =process.env.REACT_APP_API_URL
    const closeAd=()=>{
        // update the ad

        // api to close and then navigaet that id


  

          const additionalPath = `${url}/delete_product`;
          const headers = {
            'Content-Type': 'application/json',
            'Authorization':  `Bearer ${token}`,
        
          };
        
        
          const statusinfo={
            product_id:id
          }
      
         
        
        
        
          axios.delete(additionalPath,{params:statusinfo,headers:headers })
            .then(response => {
              console.log(response);
         
              if(response.data.responsemessage){
                console.log("Deleted")
                navigate(`/account/ads`)
              }else{
                console.log('Egbamiii');
              }
            
    
          
            })
            .catch(err => {
              if (err.response && err.response.status === 401) {
                  // Perform actions when 401 (Unauthorized) error occurs
                  console.log('Unauthorized: Logging user out or performing specific action...');
                  logout();
                  navigate("/")
                  // Example: Log the user out or redirect to login page
                  // You may perform the necessary actions here based on your application flow
                } else {
                  // Handle other errors
                  console.log(err);
                
                   // Rethrow the error for further handling if needed
                }
          });
       
 
        toggleModal();

    }
    return(
    <div>
        <Modal show={mode} size="md" popup onClose={toggleModal}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <div className="flex justify-center"><svg class="w-12 h-12 text-darkgray  " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"/>
  </svg></div>
          
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete this ad?
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={closeAd}>
                Yes, I'm sure
              </Button>
              <Button color="gray" onClick={toggleModal}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </div>)
}

export default DeleteModal;