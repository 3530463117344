import React, { useState, useEffect } from "react";
import { Button, Label, TextInput, FileInput, Spinner } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useEventOrganizerContext } from "../../../context/EventOrganizerContext";
import { format, parse } from "date-fns";
import axios from "axios";
import { useAuth } from "../../../context/AuthContext";
import AWS from "aws-sdk";

function EventReviewForm({ toggleDiv }) {
  const { token, logout } = useAuth();
  const { temporaryEventInfo, addTemporaryEventInfo, categories } =
    useEventOrganizerContext();
  const navigate = useNavigate();
  const [selectimages, setImages] = useState([]);
  const [id, setId] = useState("");
  const [success, setSuccess] = useState(false);

  const selectedSpec =
    categories &&
    categories.find((spec) => spec.id === temporaryEventInfo.event_category);
  const capitalize = (text) => {
    if (typeof text !== "string") {
      return "";
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const formatDate = (inputDate) => {
    const [year, month, day] = inputDate.split("-");
    const formattedDate = new Date(year, month - 1, day); // month is 0-indexed in JavaScript dates

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return formattedDate.toLocaleDateString("en-US", options);
  };

  const formattedDate = formatDate(temporaryEventInfo.date);

  function convertTo24HourFormat(time12h) {
    const [time, period] = time12h.split(" ");
    let [hours, minutes] = time.split(":");

    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (isNaN(hours) || isNaN(minutes)) {
      return "Invalid time format";
    }

    if (period === "PM" && hours < 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    const seconds = "00";

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds}`;
  }

  const formattedtime = convertTo24HourFormat(temporaryEventInfo.time);

  const info = {
    name: temporaryEventInfo.name,
    date: temporaryEventInfo.date,
    time: formattedtime, // Date and time in ISO format
    location: temporaryEventInfo.location,
    host: temporaryEventInfo.host,
    description: temporaryEventInfo.description,
    summary: temporaryEventInfo.summary,
    number: temporaryEventInfo.number,
    email: temporaryEventInfo.email,
    event_category: temporaryEventInfo.event_category,
    link: temporaryEventInfo.link,
    event_type: temporaryEventInfo.event_type,
    ticket_type: temporaryEventInfo.ticket_type,
  };

  const url =process.env.REACT_APP_API_URL;

  const handleChanges = () => {
    setSuccess(true);
    console.log("finish");
    console.log(info);

    // api call
    // navigate(`/eventorganizer/events`);
    const additionalPath = `${url}/add_event`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    // if (token) {
    //   headers['Authorization'] = `Bearer ${token}`;
    // }

    axios
      .post(additionalPath, info, { headers: headers })
      .then((response) => {
        // setProduct(response.data);
        console.log(response.data.event_id);
        console.log("finish");
        setId(response.data.event_id);
        uploadMultipleImages(response.data.event_id);
        // if(selectimages.length!=0){
        //   handleupdate();
        // }else{
        //   console.log("none");
        //     console.log(selectimages);
        // }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  const idinfo = {
    event_id: id,
  };

  const imageinfo = {
    event_id: id,
    images: selectimages,
  };

  // Key : assets/dev /events
  const spacesEndpoint = new AWS.Endpoint("blr1.digitaloceanspaces.com");
  const s3 = new AWS.S3({
    endpoint: spacesEndpoint,
    accessKeyId: "DO00JYWNXZG6EYV2J4JT",
    secretAccessKey: "QARFr7ME28EltUtflEWNF/2OzR92JJjL1TWjQi+A5po",
  });

  useEffect(() => {
    if (selectimages.length != 0) {
      handleupdate();
    } else {
      console.log("none");
      console.log(selectimages);
    }
  }, [selectimages]);

  const handleupdate = () => {
    const additionalPath = `${url}/update_event_image`;
    const headers = {
      "Content-Type": "application/json",
      // 'Authorization':  `Bearer ${token}`,
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    console.log(imageinfo);

    axios
      .patch(additionalPath, imageinfo, { headers: headers })
      .then((response) => {
        // setProduct(response.data);
        console.log(response.data.responsemessage);
        console.log("finishhh");
        setSuccess(false);
        toggleDiv("events");
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
          setSuccess(false);

          // Rethrow the error for further handling if needed
        }
      });
  };

  const uploadMultipleImages = async (id) => {
    try {
      AWS.config.update({
        accessKeyId: "DO00JYWNXZG6EYV2J4JT",
        secretAccessKey: "QARFr7ME28EltUtflEWNF/2OzR92JJjL1TWjQi+A5po",
        region: "blr1",
      });

      const spacesEndpoint = new AWS.Endpoint(`blr1.digitaloceanspaces.com`);

      const s3 = new AWS.S3({
        endpoint: spacesEndpoint,
      });

      for (let i = 0; i < temporaryEventInfo.images.length; i++) {
        const file = temporaryEventInfo.images[i];
        const params = {
          Bucket: "kriffeonline-assets",
          Key: `prod/events/${id}_${i + 1}.png`,
          Body: file,
          ACL: "public-read",
        };

        const uploadResult = await s3.upload(params).promise();
        setImages((prevFiles) => [...prevFiles, uploadResult.Location]);
        console.log(uploadResult.Location);
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
    // for (let i = 0; i < temporaryEventInfo.images.length; i++) {
    //   const file = temporaryEventInfo.images[i];

    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);

    //   reader.onload = async (e) => {
    //     const fileContent = e.target.result;

    //     const params = {
    //       Bucket: "kriffeonline-assets",
    //       Key: `dev/events/${id}_${i + 1}.png`,
    //       Body: fileContent,
    //       ACL: "public-read", // Set ACL for public access if needed
    //       ContentType: file.type, // Set the correct content type
    //     };

    //     try {
    //       new Promise((resolve, reject) => {
    //         s3.upload(params, (err, data) => {
    //           if (err) {
    //             console.log(err);
    //             console.error("Error uploading to S3:", err);
    //             reject(err);
    //           } else {
    //             if (data.Location) {
    //               console.log(`Image ${i + 1} uploaded successfully.`);
    //               console.log(data.Location);
    //               setImages((prevUrls) => [...prevUrls, data.Location]);
    //               resolve(data.Location);
    //             } else {
    //               console.log(`image not uploaded`);
    //             }

    //             // Return the URL
    //           }
    //         });
    //       });
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   };
    // }
  };
  return (
    <div className="flex justify-center items-center  h-full relative">
      <form className="w-full">
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="name"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Event Name</p>
                </div>
              }
            />
          </div>

          <TextInput
            id="name"
            name="name"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            value={temporaryEventInfo.name}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="host"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Event Host</p>
                </div>
              }
            />
          </div>

          <TextInput
            id="host"
            name="host"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            value={temporaryEventInfo.host}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="date"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Date and Time</p>
                </div>
              }
            />
          </div>

          <TextInput
            id="date"
            name="date"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            value={formattedDate + " " + temporaryEventInfo.time}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="description"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Event Description
                  </p>
                </div>
              }
            />
          </div>

          <TextInput
            id="description"
            name="description"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            value={temporaryEventInfo.description}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="summary"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Event Summary</p>
                </div>
              }
            />
          </div>

          <TextInput
            id="summary"
            name="summary"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            value={temporaryEventInfo.summary}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="summary"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Event Type</p>
                </div>
              }
            />
          </div>

          <TextInput
            id="summary"
            name="summary"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            value={temporaryEventInfo.event_type.join(",")}
          />
        </div>

        <div className="flex flex-row gap-2 w-full">
          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="category"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">
                      Event Category
                    </p>
                  </div>
                }
              />
            </div>

            <TextInput
              id="category"
              name="category"
              required
              type="text"
              class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
              value={selectedSpec.name}
            />
          </div>

          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="location"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">
                      Event Location
                    </p>
                  </div>
                }
              />
            </div>

            <TextInput
              id="location"
              name="location"
              required
              type="text"
              class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
              value={temporaryEventInfo.location}
            />
          </div>
        </div>

        <div className="flex flex-row gap-2 w-full">
          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="category"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">Event Email</p>
                  </div>
                }
              />
            </div>

            <TextInput
              id="category"
              name="category"
              required
              type="text"
              class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
              value={temporaryEventInfo.email}
            />
          </div>

          <div className="basis-1/2">
            <div className="mb-2 block">
              <Label
                htmlFor="location"
                value={
                  <div>
                    <p className="font-bold text-midnightblue">Event Number</p>
                  </div>
                }
              />
            </div>

            <TextInput
              id="location"
              name="location"
              required
              type="text"
              class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
              value={temporaryEventInfo.number}
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <p className="font-bold font-xl">Ticketing</p>
          {/* array */}
          {temporaryEventInfo.ticket_type &&
            temporaryEventInfo.ticket_type.length != 0 &&
            temporaryEventInfo.ticket_type.map((ticket, index) => (
              <div className="bg-white p-4">
                <div className="flex flex-row justify-between">
                  <div className="mb-2 block ">
                    <Label
                      htmlFor="detail"
                      value={
                        <div>
                          <p className="font-bold text-midnightblue">
                            Ticket Package #{index + 1}
                          </p>
                        </div>
                      }
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="name"
                        value={
                          <div>
                            <p className="font-bold text-midnightblue">
                              Ticket Package Name
                            </p>
                          </div>
                        }
                      />
                    </div>

                    <TextInput
                      id="name"
                      name="name"
                      required
                      type="text"
                      class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                      value={ticket.name}
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="description"
                        value={
                          <div>
                            <p className="font-bold text-midnightblue">
                              Ticket Package Description
                            </p>
                          </div>
                        }
                      />
                    </div>

                    <TextInput
                      id="description"
                      name="description"
                      required
                      type="text"
                      class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                      value={ticket.description}
                    />
                  </div>

                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="packageinfo"
                        value={
                          <div>
                            <p className="font-bold text-midnightblue">
                              Ticket Package Information
                            </p>
                          </div>
                        }
                      />
                    </div>

                    <TextInput
                      id="packageinfo"
                      name="package_info"
                      required
                      type="text"
                      class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                      value={ticket.package_info}
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="price"
                        value={
                          <div>
                            <p className="font-bold text-midnightblue">
                              Ticket Package Price
                            </p>
                          </div>
                        }
                      />
                    </div>

                    <TextInput
                      id="price"
                      name="price"
                      required
                      type="text"
                      class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                      value={ticket.price}
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="price"
                        value={
                          <div>
                            <p className="font-bold text-midnightblue">
                              Ticket Package Number Available
                            </p>
                          </div>
                        }
                      />
                    </div>

                    <TextInput
                      id="price"
                      name="available"
                      required
                      type="text"
                      class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                      value={ticket.number_available}
                    />
                  </div>
                  <div>
                    <div className="mb-2 block ">
                      <Label
                        htmlFor="ticketType"
                        value={
                          <div>
                            <p className="font-bold text-midnightblue">
                              Ticket Package Type
                            </p>
                          </div>
                        }
                      />
                    </div>

                    <TextInput
                      id="ticketType"
                      name="ticketType"
                      required
                      type="text"
                      class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                      value={ticket.package_type}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div>
          {/* media links */}
          {/* map through media links array */}
          {temporaryEventInfo.link.map((link, index) => (
            <div key={index} className="grid grid-cols-2 gap-2 mt-4">
              {Object.keys(link).map((key) => (
                <div key={key}>
                  <div className="mb-2 block">
                    <Label
                      htmlFor={key}
                      value={
                        <div>
                          <p className="font-bold text-midnightblue">
                            {capitalize(key)} Live Link
                          </p>
                        </div>
                      }
                    />
                  </div>

                  <TextInput
                    id={key}
                    name={key}
                    type="text"
                    class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                    value={link[key]}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="detail"
              value={
                <div>
                  <p className="font-bold text-midnightblue">Event Images</p>
                </div>
              }
            />
          </div>

          <div className=" p-2 sm:flex sm:flex-row grid grid-cols-2 gap-2">
            {temporaryEventInfo.images.map((image, index) => (
              <img
                key={index}
                src={URL.createObjectURL(image)}
                alt={`Image ${index}`}
                className="sm:h-64 h-36 sm:w-64 w-36 border border-normalblue rounded-md"
              />
            ))}
          </div>
        </div>

        {/* images in preview */}

        <div className="flex justify-end mt-4 ">
          <Button
            type="button"
            className=" bg-normalblue sm:p-4 p-2 rounded-md"
            onClick={handleChanges}
          >
            Post event
          </Button>
        </div>
      </form>
      {success && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-30 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md">
            {/* Your spinner component */}
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default EventReviewForm;
