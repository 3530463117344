import React,{useState,useEffect} from "react";
import ProductCard from "../Cards/ProductCard";
import { useAuth } from "../../context/AuthContext";
import { useMarketplaceContext } from "../../context/MarketplaceContexts";
import axios from 'axios';
import { Spinner } from "flowbite-react";
import MainPagination from "../../components/Miscellanous/MainPagination";
import { useNavigate } from "react-router-dom";

function SavedItems(){
    const { isLogged,token,logout} = useAuth();
    const navigate=useNavigate();
    const {saveditems,addSavedItems}=useMarketplaceContext();
    const [ loadedOnce,setLoadedOnce] = useState(false);
    const [ err, setErr ] = useState('');
    const [per_page, setPerpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [to_no, setTo] = useState(0);
  const [from_no, setFrom] = useState(0);

  const itemsPerPage = saveditems && per_page;
  const initialPayload = {
    currentpage: 1
  };

  const [formData, setFormData] = useState(initialPayload);

  const totalPages =
    saveditems && total > 0 && Math.ceil(total / itemsPerPage);
  const [currentPage, setCurrentPages] = useState(1);
  const handlePageChange = (newPage) => {
    setCurrentPages(newPage);
    handleParamChange("currentpage", newPage);
  };

  const handleParamChange = (name, value) => {
    setFormData((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
    loadedOnce.current=false;
  };
    useEffect(() => {
        // Make the first API call
        if(!loadedOnce){
          handlemarketplace();
        }
      }, [loadedOnce]);

    const url =process.env.REACT_APP_API_URL

  const handlemarketplace =() => {
    const additionalPath = `${url}/current_user_favorites`;
    // const path=`get_product_by_id?id=${id}`;
    // makeApiRequest("GET", null, path, token);
    const headers = {
      'Content-Type': 'application/json',
      // 'Authorization':  `Bearer ${token}`,

    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  

    axios.get(additionalPath, {params:formData,headers:headers })
      .then(response => {
        addSavedItems(response.data.favorites.data);
        setPerpage(response.data.favorites.per_page)
        setTotal(response.data.favorites.total)
        setTo(response.data.favorites.to);
        setFrom(response.data.favorites.from);
        setLoadedOnce(true);
    
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log('Unauthorized: Logging user out or performing specific action...');
          logout();
          navigate('/');
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
        setErr(err);
  
    // Rethrow the error for further handling if needed
        }
      });
  };



    
    return(
        <div className="flex flex-col gap-4">
        <p className="font-bold">Saved Items</p>
        

        {/* {saveditems&&saveditems.length >0? (saveditems.map((item, index) => (
                <div className="grid sm:grid-cols-3 grid-cols-1 gap-4">
                
                <ProductCard key={index} status={isLogged} id={item.id} item={item} productname={item.name} seller={item.created_by} saves={item.saves} price={item.price} category={item.category} saved={true}/>
                </div>
            ))):(<div>
            
            <p className="text-darkgray text-center"><div className=" inset-0 flex items-center justify-center  z-50">
            <div className="p-4 max-w-md mx-auto rounded-md">
              {/* Your spinner component *
              <Spinner
                aria-label="Default status example"
                color="warning"
                size="xl"
              />
            </div>
          </div>No saved items</p></div>)
        } */}
        {loadedOnce ? (
        saveditems && saveditems.length > 0 ? (
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-1" >
          {saveditems.map((item, index) => (
            
              <ProductCard
                status={isLogged}
                id={item.id}
                item={item}
                productname={item.name}
                seller={item.created_by}
                saves={item.saves}
                price={item.price}
                category={item.category}
                saved={true}
              />
         
          ))}
          </div>
        ) : (
          <div>
            <p className="text-darkgray text-center">No saved items</p>
          </div>
        )
      ) : (
        <div className="text-darkgray text-center">
          <div className="inset-0 flex items-center justify-center z-50">
            <div className="p-4 max-w-md mx-auto rounded-md">
              <Spinner aria-label="Default status example" color="warning" size="xl" />
            </div>
          </div>
          Loading saved items...
        </div>
      )}

<MainPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
       
    </div>
    )
}
export default SavedItems;