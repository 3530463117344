import React, { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PieChart = () => {
  const { token, logout } = useAuth();
  const [label, setLabel] = useState([]);
  const [value, setValue] = useState([]);
  const [totalRevenue, setTotal] = useState(0);
  const navigate = useNavigate();
  // get_revenue_per_event

  const orderOnce = useRef(false);
  const url =process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (orderOnce.current) return;
    orderOnce.current = true;
    handletotalorder();
  }, []);

  const handletotalorder = async () => {
    // get_dashboard_total_revenue
    const additionalPath = `${url}/get_revenue_per_event`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { headers: headers })
      .then((response) => {

        const revenueData = response.data.revenue_per_event;

      

      // Sort revenueData in descending order based on total_revenue
      const sortedData = revenueData.sort((a, b) => b.total_revenue - a.total_revenue);

      // Get only the top 10 items
      const top10Data = sortedData.slice(0, 5);

      // Calculate the total revenue for the top 10 items
      const totalRevenue = top10Data.reduce((sum, item) => sum + parseInt(item.total_revenue), 0);
      setTotal(totalRevenue);
      if (totalRevenue === 0) {
        // Handle the case where there is no revenue data
        console.log('No revenue data available.');
        return;
      }else{
        // Calculate the percentage for each revenue item in the top 10
        
        const dataWithPercentage = top10Data.map(item => ({
          name: item.name,
          percentage: totalRevenue !== 0 ? parseFloat((parseInt(item.total_revenue) / totalRevenue) * 100).toFixed(2) : 0,
      })).filter(item => parseFloat(item.percentage) !== 0);
      
      setLabel(dataWithPercentage.map(item => item.name));
      setValue(dataWithPercentage.map(item => parseFloat(item.percentage)));
      


      }

      
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/");
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
          // Rethrow the error for further handling if needed
        }
      });
  };

 
  const chartData = {
    series: value && value.length != 0 ? value : [100], // Replace with your revenue data per event
    options: {
      chart: {
        width: 400,
        type: "pie",
        height: 400,
      },
      labels: label && label.length != 0 ? label : ["No event"], // Event labels
      colors: ["#1C64F2", "#16BDCA", "#9061F9", "#FF4560", "#FFC85A"], // Customize colors
      legend: {
        position: "bottom",
      },
    },
  };

  return (
    <div className="pie-chart flex justify-center ">
      {totalRevenue===0?(<div className="h-80 flex items-center justify-center">
        <p className="text-3xl font-medium  text-darkgray text-center">No revenue data available</p>
      </div>):(<Chart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        width={chartData.options.chart.width}
        height={chartData.options.chart.height}
      />)}
      
    </div>
  );
};

export default PieChart;
