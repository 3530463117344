import React, { useState,useEffect} from "react";
import { Button,Label,TextInput } from 'flowbite-react';
import { useApi } from "../../api/APIProvider";
import { useAuth } from "../../context/AuthContext";

function ProfileForm({userinfo}){
    const[update,setUpdate]=useState(false);
    const{token}=useAuth();
    const [formData, setFormData] = useState(userinfo);
    const { makeApiRequest,data,error } = useApi();
    const [MsgE, setMsgE] = useState(false);
    
    console.log(formData.phonenumber);
    useEffect(() => {
      if (data&& data.responsemessage) {
        setMsgE("Profile Updated");
        setUpdate(true);


      }
      
      if (error) {
  
        setMsgE("Not Updated");
    
      }
  
    }, [data,error]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

    const users={
      email:formData.email,
      fullname:formData.fullname,
      phonenumber:formData.phonenumber
    }
    const usersid={
      userid:formData.id
    }
    const queryParams = new URLSearchParams(usersid).toString();
    
      const handleChanges = () => {
        const additionalPath = `update_user_details?${queryParams}`;
      makeApiRequest("PUT", users, additionalPath,token);
          


      };
    return(
        <div className=" mt-4 space-y-8">
          {MsgE&&<p>{MsgE}</p>}
        <div className="grid sm:grid-cols-2 grid-rows-1 gap-4">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="firstname" value="First name" />
              </div>
              <TextInput id="firstname"  name="firstname" value={formData.fullname.split(" ")[0]} readOnly />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="lastname" value="Last name" />
              </div>
              <TextInput id="lastname"  name="lastname" value={formData.fullname.split(" ")[1]} readOnly />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="phone" value="Phone Number" />
              </div>
              <TextInput id="phone"  name="phonenumber" value={formData.phonenumber}
            onChange={handleInputChange}  required />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email" value="Email address" />
              </div>
              <TextInput id="email"  name="email" value={formData.email}
             readonly />
            </div>
            </div>

            <div className="flex w-full justify-end">
              <Button  onClick={handleChanges} className="bg-normalblue sm:w-1/4  w-1/2 p-1">Save Changes</Button>
            </div>
     
          </div>
    )
}
export default ProfileForm;