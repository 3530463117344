import React, { useState } from "react";
import { Checkbox  } from "flowbite-react";

function LanguageMarketAccordion({ title, children, selectedCategories, handleCategoryChange }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="flex flex-col border-b-2 mb-4 p-2">
      <div className="flex flex-row justify-between">
        <div>{title}</div>
        <a href="#" onClick={toggleAccordion} className="flex text-xl">
          {isExpanded ? "-" : "+"}
        </a>
      </div>

      {isExpanded && (
        <div className="p-4">
          <ul>
          {children.map((item) => (
             <li className="flex gap-1 items-center " key={item.id}>
        <div key={item.id} className="sm:text-md text-sm">
 
            <Checkbox 
              value={item.id}
              checked={selectedCategories.includes(item.id)}
              onChange={handleCategoryChange}
           
            />
            

        
        </div>
        <p>{item.language_label}</p>
          </li>
          
      ))}
      </ul>
           </div>)}
       
           
    </div>
  );
}

export default LanguageMarketAccordion;
