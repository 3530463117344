import React, { useEffect, useState, useRef } from "react";
import { Button, Table, Badge,Spinner } from "flowbite-react";
import { useEventOrganizerContext } from "../../context/EventOrganizerContext";
import { format } from "date-fns";
import MainPagination from "../../components/Miscellanous/MainPagination";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";

function Events() {
  const { token, logout } = useAuth();
  const { eventData, addNewEvent } = useEventOrganizerContext();
  const navigate = useNavigate();
  const loadedOnce = useRef(false);
 
  const initialPayload = {
    currentpage: 1
  };

  const [formData, setFormData] = useState(initialPayload);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (loadedOnce.current) return;
    loadedOnce.current = true;
    handletotalevent();
  }, [loadedOnce,formData,currentPage]);

  const url =process.env.REACT_APP_API_URL;
  const handletotalevent = async () => {
    const additionalPath = `${url}/get_events_logged_in_user`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { params:formData,headers: headers })
      .then((response) => {
        addNewEvent(response.data.events.data);
        setPerpage(response.data.events.per_page)
        setTotal(response.data.events.total)
        setTo(response.data.events.to);
        setFrom(response.data.events.from);
        loadedOnce.current=true
        console.log(response.data.events);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
         
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  const checkDate = (dateString) => {
    // Convert the date string to a Date object
    const inputDate = new Date(dateString);

    // Get the current date
    const currentDate = new Date();

    // Compare the dates
    if (inputDate > currentDate) {
      return "Upcoming";
    } else {
      return "Completed";
    }
  };
  const handleParamChange = (name, value) => {
    setFormData((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
    loadedOnce.current=false;
  };
  const [per_page, setPerpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [to_no, setTo] = useState(0);
  const [from_no, setFrom] = useState(0);
  const itemsPerPage = eventData && per_page;



  const itemsToShow = eventData 


  const totalPages = eventData&& total > 0 &&Math.ceil(total / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    handleParamChange("currentpage", newPage);
  };

  const statustext = (status) => {
    if (status === "pending") {
      return (
        <Badge color="warning" className="flex justify-center">
          Pending
        </Badge>
      );
    } else if (status === "active") {
      return (
        <Badge color="success" className="flex justify-center">
          Active
        </Badge>
      );
    } else {
      return (
        <Badge color="failure" className="flex justify-center">
          Closed
        </Badge>
      );
    }
  };

  const datetext = (status) => {
    if (status === "passed") {
      return (
        <Badge color="failure" className="flex justify-center">
          Passed
        </Badge>
      );
    } else {
      return (
        <Badge color="purple" className="flex justify-center">
          Not Passed
        </Badge>
      );
    }
  };
  return (
    <div className="p-3">
      <div className="flex flex-row justify-between">
        <p className="text-xl font-bold text-midnightblue">Events</p>
        <a
          href="/eventorganizer/new"
          className="bg-normalblue rounded-md sm:w-1/4 p-4 text-center text-white"
        >
          New Event
        </a>
      </div>

      <div className="mt-4  xl:block hidden">
        <Table>
          <Table.Head>
            <Table.HeadCell>Event name</Table.HeadCell>
            <Table.HeadCell>Date</Table.HeadCell>
            {/* <Table.HeadCell>Location</Table.HeadCell> */}
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell>Date Status</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current==true? (itemsToShow && itemsToShow.length != 0 ? (
              itemsToShow.map((eventsData, index) => (
                <Table.Row
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  key={index}
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {eventsData.name}
                  </Table.Cell>
                  <Table.Cell>
                    {format(new Date(eventsData.date), "dd MMMM YYY")}{" "}
                    {eventsData.time}
                  </Table.Cell>
                  {/* <Table.Cell>{eventsData.location}</Table.Cell> */}
                  <Table.Cell>{statustext(eventsData.status)}</Table.Cell>
                  <Table.Cell>
                    {/* {checkDate(eventsData.date)} */}

                    {datetext(eventsData.event_date_status)}
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex ">
                     

                      <a
                        className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                        href={`/eventorganizer/event/${eventsData.event_no}`}
                      >
                        <p>View</p>
                      </a>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  No events.Create lovely memories right here
                </Table.Cell>
              </Table.Row>
            )):(
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                <div className="flex justify-center items-center h-full">
          <Spinner aria-label="Loading" color="warning" size="md" />
        </div>
                </Table.Cell>
              </Table.Row>
              
            )}
          </Table.Body>
        </Table>
      </div>

      <div className="mt-4  md:block  xl:hidden hidden ">
        <Table>
          <Table.Head>
            <Table.HeadCell>Event name</Table.HeadCell>
            {/* <Table.HeadCell>Date</Table.HeadCell> */}
            {/* <Table.HeadCell>Location</Table.HeadCell> */}
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell>Date Status</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current==true?(itemsToShow && itemsToShow.length != 0 ? (
              itemsToShow.map((eventsData, index) => (
                <Table.Row
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  key={index}
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {eventsData.name}
                  </Table.Cell>
                  {/* <Table.Cell>
                    {format(new Date(eventsData.date), "dd MMMM YYY")}{" "}
                    {eventsData.time}
                  </Table.Cell> */}
                  {/* <Table.Cell>{eventsData.location}</Table.Cell> */}
                  <Table.Cell>{statustext(eventsData.status)}</Table.Cell>
                  <Table.Cell>
                    {/* {checkDate(eventsData.date)} */}

                    {datetext(eventsData.event_date_status)}
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex ">
                     

                      <a
                        className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                        href={`/eventorganizer/event/${eventsData.event_no}`}
                      >
                        <p>View</p>
                      </a>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  No events.Create lovely memories right here
                </Table.Cell>
              </Table.Row>
            )):(
              <Table.Row>
              <Table.Cell colSpan={6} className="bg-white text-center">
              <div className="flex justify-center items-center h-full">
        <Spinner aria-label="Loading" color="warning" size="md" />
      </div>
              </Table.Cell>
            </Table.Row>

            )}
          </Table.Body>
        </Table>
      </div>

      <div className="mt-5  md:hidden lg:hidden xl:hidden block">
        <Table>
          <Table.Head>
            <Table.HeadCell>Event name</Table.HeadCell>

            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {loadedOnce.current==true? (itemsToShow && itemsToShow.length != 0 ? (
              itemsToShow.map((event, index) => (
                <Table.Row
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  key={index}
                >
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {event.name}
                  </Table.Cell>

                  <Table.Cell>
                    <div className="flex ">
                     

                      <a
                        className="font-medium text-normalblue hover:underline dark:text-cyan-500"
                        href={`/eventorganizer/event/${event.event_no}`}
                      >
                        <p>View</p>
                      </a>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} className="bg-white text-center">
                  No events.Create lovely memories right here
                </Table.Cell>
              </Table.Row>
            )):( <Table.Row>
              <Table.Cell colSpan={6} className="bg-white text-center">
              <div className="flex justify-center items-center h-full">
        <Spinner aria-label="Loading" color="warning" size="md" />
      </div>
              </Table.Cell>
            </Table.Row>)}
          </Table.Body>
        </Table>
      </div>

      <MainPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
export default Events;
