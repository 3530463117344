import React, { useEffect } from "react";
import { Navbar, Dropdown, Avatar } from "flowbite-react";
import { useAuth } from "../../context/AuthContext";
import {useNavigate } from 'react-router-dom';

function OrganizerNav() {
  const { organizer } = useAuth();
 

  return (
    <div className="flex justify-end p-2 sm:bg-white bg-midnightblue ">
      <Dropdown
        arrowIcon={false}
        inline
        label={
          <div className="flex flex-row items-center gap-2 mr-2">
            <svg
              class="w-6 h-6 sm:text-midnightblue text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 11 14H9a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 10 19Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
            <p className="sm:text-midnightblue text-white">
            {organizer?(organizer['companyname']):(<p>User</p>)}
            </p>
            <svg
              class="w-3 h-3 sm:text-midnightblue text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 8"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
              />
            </svg>
          </div>
        }
      >
        <Dropdown.Header>
          <span className="block text-sm">
            {organizer && organizer['companyname']}
          </span>
          <span className="block truncate text-sm font-medium">
            {organizer && organizer['companyemail']}
          </span>
        </Dropdown.Header>
        <Dropdown.Item>
          <a href="/eventorganizer/account">Profile</a>
        </Dropdown.Item>
        <Dropdown.Item>
          <a href="/eventorganizer/dashboard">Dashboard</a>
        </Dropdown.Item>
        <Dropdown.Item>
          <a href="/eventorganizer/events">Events</a>
        </Dropdown.Item>
        <Dropdown.Item>      <a href="/eventorganizer/finances">Finances</a></Dropdown.Item>
        <Dropdown.Item>      <a href="/eventorganizer/withdraw">Withdrawals</a></Dropdown.Item>
        <Dropdown.Item>
          <a href="/eventorganizer/analytics">Analytics</a>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item>
          <a href="/account/events" className="text-red-600">
            Leave Console
          </a>
        </Dropdown.Item>
      </Dropdown>
    </div>
  );
}
export default OrganizerNav;
