import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Spinner } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../context/AuthContext";


function CategoryModal({id, open, handleModal, tags,name }) {
  const { token, adminlogout } = useAuth();
  const [loadedOnce, setLoadedOnce] = useState(false);
  const url = process.env.REACT_APP_API_URL;
  const navigate=useNavigate();



  useEffect(() => {
    if (tags && tags.length > 0) {
      setLoadedOnce(true);
    }
  }, [tags]);

  console.log(id);


  const additionalPath = `${url}/add_tags_to_category`;
  const [tag,setTags]=useState(tags)
  const [tagInput, setTagInput] = useState('');

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  
  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter' && tagInput.trim() !== '') {
      setTags([...tags, tagInput]);
      setTagInput(''); // Clear the input field
    }
  };

  const handleRemoveTag = (index) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1);
    setTags(updatedTags);
  };

  const closeAd = () => {
    // update the ad

    // api to close and then navigaet that id
    if (!token) {
      // If token is not present in the context, try to get it from local storage
      const storedToken = localStorage.getItem("token"); // Replace 'yourTokenKey' with the actual key you used for storing the token
      if (storedToken) {
        handleedit(JSON.parse(storedToken));
        
      }
    }else{
      handleedit(token);
    }
  };

 



  const handleedit=async(tokens)=>{
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${tokens}`,
    };
    
    const statusinfo = {
      categoryid: id,
      tags:tag
    };

    const paraminfo = {
        categoryid: id
      };
   

    await axios
      .post(additionalPath,statusinfo,{params:paraminfo,headers: headers })
      .then((response) => {
        if (response.responsecode==200) {
          console.log("Updated");
        } else {
          console.log("Egbamiii");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
        
            adminlogout();
            navigate("/admin")
          console.error(err);
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });

    handleModal();
  }

  return (
    <div>
      <Modal show={open} size="xl" popup onClose={handleModal}>
        <Modal.Header>{name} Category Tags</Modal.Header>
        <Modal.Body>
          <div className="relative">
            <div>
              <strong>Tags {id}:</strong>
            </div>
            <div className="flex flex-wrap gap-2">
              {tag.map((tags, index) => (
                <div
                className="bg-lightbrown/40 text-normalbrown p-2 rounded-full flex w-auto items-center justify-center"
                key={index}
              >
                <p className="w-full">{tags}</p>
                <Button
                  onClick={() => handleRemoveTag(index)}
                  className="text-normalbrown text-center h-4 basis-1/4"
                >
                  X
                </Button>
              </div>
              ))}
            </div>
          </div>
          <div>
              <div className="mt-4 flex flex-col ">
                <input
                  type="text"
                  placeholder="Add a tag"
                  value={tagInput}
                  onChange={handleTagInputChange}
                  onKeyDown={handleInputKeyPress}
                  class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                />

                <div className="justify-end items-end flex">
                  {" "}
                  <Button className="bg-normalblue p-2 text-white  mt-4 w-1/2" onClick={()=>closeAd()}>
                    Add New Tag
                  </Button>
                </div>
              </div>
            </div>

          {!loadedOnce && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70 z-50">
              <div className="p-4 max-w-md mx-auto rounded-md">
                {/* Your spinner component */}
                <Spinner
                  aria-label="Default status example"
                  color="warning"
                  size="xl"
                />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CategoryModal;
