import React, { createContext, useContext, useState } from "react";

const EventContexts = createContext();

export const useEventContexts = () => {
  return useContext(EventContexts);
};

export const EventProvider = ({ children }) => {
  const [eventData, setEventData] = useState([]);

  const [eventorder, setOrder] = useState([]);


  const getEvent = (event) => {
    setEventData(event);
  };

  const addOrder = (event) => {
    setOrder(event);
  };

  const getOrderById = (id) => {
    return eventorder.find((event) => event.order_id === id);
  };

  
  const getEventById = (id) => {
    return eventData.find((event) => event.id === id);
  };
  const [temporaryTicketInfo, setTemporaryTicketInfo] = useState({
    event_id: null,
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    ticket_info: [],
    status: "Not Paid",
    fees:null,
    subtotal:null,
    total:null
  });


  // Function to add ticket information to the temporary state
  const addTemporaryTicketInfo = (newData) => {
    setTemporaryTicketInfo((prevData) => {
      const updatedData = {
        event_id: newData.event_id || prevData.event_id,
        firstname: newData.firstname || prevData.firstname,
        lastname: newData.lastname || prevData.lastname,
        email: newData.email || prevData.email,
        phonenumber: newData.phonenumber || prevData.phonenumber,
        ticket_info: newData.ticket_info || prevData.ticket_info,
        status: newData.status || prevData.status,
        subtotal: newData.subtotal || prevData.subtotal,
        total: newData.total || prevData.total,
        fees: newData.fees || prevData.fees,
      };

      return updatedData;
    });
  };

  // Function to clear the temporary ticket information
  const clearTemporaryTicketInfo = () => {
    setTemporaryTicketInfo([]);
  };

  //   function to get the events orders associated with the user
  const [categories,setCategories]=useState([]);
  const [eventtype,setEventType]=useState([{name:'free'},{name:'paid'}]);

  return (
    <EventContexts.Provider
      value={{
        eventData,
        getEvent,
        getEventById,
        temporaryTicketInfo,
        addTemporaryTicketInfo,
        clearTemporaryTicketInfo,
        eventorder,
        addOrder,
        getOrderById,
        categories,setCategories,eventtype
      }}
    >
      {children}
    </EventContexts.Provider>
  );
};
