import React,{useEffect, useState} from "react";
import { useParams,useLocation } from "react-router-dom";
import TopBanner from "../../components/Navigation/TopBanner";
import NavigationBar from "../../components/Navigation/NavigationBar";
import SearchBarCollapse from "../../components/SearchBars/SearchBarCollapse";
import logo from "../../assets/images/KRIFFEONLINELOGO2.png";
import ForgotPasswordForm from "../../components/Forms/ForgotPasswordForm";
import forgot from "../../assets/images/Forgot.png";
import { useAuth } from "../../context/AuthContext";
import ForgotEmail from "../../components/Forms/ForgotEmail";

function ForgotPassword(){
    const { isLogged } = useAuth();
    const { changestate } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const cstate=changestate?("Forgot"):("Change");

 


    return(
    <div>
        <section className="w-screen">
        <TopBanner/>
            <NavigationBar logo={logo} color="text-midnightblue" status={isLogged} />
            <SearchBarCollapse />
            <div className="flex sm:flex-row flex-col justify-between px-3 items-center sm:mt-0 mt-6">
                <div className="w-full basis-1/2 ">
                    <p className="sm:text-5xl text-3xl  text-midnightblue text-medium">{cstate} <span className="text-normalblue">Your</span> <span className="text-normalbrown">Password?</span> </p>
                    <p className="text-darkgray font-medium sm:mb-10 mb-8">Do not worry. We have got you covered</p>
                    <div className="basis-1/2 ">
                        {changestate?(<ForgotEmail/>):(<ForgotPasswordForm  token={token} />)}
                   

                    </div>
                  
                </div>
                <div className="basis-1/2  mt-2 w-full ">
                    <img src={forgot}/>

                </div>
                
            </div>

        </section>

    </div>
    )

}
export default ForgotPassword;