import React, { useState, useEffect } from "react";
import { Button, Label, TextInput, FileInput } from "flowbite-react";
import { useMarketplaceContext } from "../../../context/MarketplaceContexts";
import {
  fetchDataForFlexibility,
  fetchDataForStyle,
  fetchDataForLanguage,
  fetchDataForFormat,
  fetchDataForMaterial,
  fetchDataForAge,
} from "../../../api/ApiPage";

function ProductForm({ selectedProductDetails, setSelectedProductDetails,details }) {
  // const [productDetail, setProductDetail] = useState("");
  // const [selectedValue, setSelectedValue] = useState("");
  // const [formSections, setFormSections] = useState([
  //   { attribute_name: "", attribute_value: null },
  // ]);
  const {
    material,
    setMaterial,
    style,
    setStyle,
    language,
    setLanguage,
    format,
    setFormat,logout
  } = useMarketplaceContext();



  useEffect(() => {
  
    if (style.length == 0) {
      fetchDataForStyle(logout).then((data) => {
        if (data) {
          setStyle(data);
        }
      });
    }
    if (language.length == 0) {
      fetchDataForLanguage(logout).then((data) => {
        if (data) {
          setLanguage(data);
        }
      });
    }
    if (material.length == 0) {
      fetchDataForMaterial(logout).then((data) => {
        if (data) {
          setMaterial(data);
        }
      });
    }
    if (format.length == 0) {
      fetchDataForFormat(logout).then((data) => {
        if (data) {
          setFormat(data);
        }
      });
    }
  }, []);

  console.log(selectedProductDetails)
  console.log(details)

  const handleProductMaterialChange = (e) => {
    const { value } = e.target;
    if (value === "") {
      // User selected "Select," so remove the material entry
      setSelectedProductDetails((prevDetails) => [
        ...prevDetails.filter((detail) => detail.attribute_name !== "material"),
      ]);
    } else if (!isNaN(value)) {
      // User selected a valid number, update the material entry
      setSelectedProductDetails((prevDetails) => [
        ...prevDetails.filter((detail) => detail.attribute_name !== "material"),
        {
          attribute_name: "material",
          attribute_value: parseInt(value),
        },
      ]);
    }
  };

  const handleProductStyleChange = (e) => {
    const { value } = e.target;
    if (value === "") {
      // User selected "Select," so remove the material entry
      setSelectedProductDetails((prevDetails) => [
        ...prevDetails.filter((detail) => detail.attribute_name !== "style"),
      ]);
    } else if (!isNaN(value)) {
      // User selected a valid number, update the material entry
      setSelectedProductDetails((prevDetails) => [
        ...prevDetails.filter((detail) => detail.attribute_name !== "style"),
        {
          attribute_name: "style",
          attribute_value: parseInt(value),
        },
      ]);
    }
  };
  const handleProductLanguageChange = (e) => {
    const { value } = e.target;
    if (value === "") {
      // User selected "Select," so remove the material entry
      setSelectedProductDetails((prevDetails) => [
        ...prevDetails.filter((detail) => detail.attribute_name !== "language"),
      ]);
    } else if (!isNaN(value)) {
      // User selected a valid number, update the material entry
      setSelectedProductDetails((prevDetails) => [
        ...prevDetails.filter((detail) => detail.attribute_name !== "language"),
        {
          attribute_name: "language",
          attribute_value: parseInt(value),
        },
      ]);
    }
  };
  const handleProductFormatChange = (e) => {
    const { value } = e.target;
    if (value === "") {
      // User selected "Select," so remove the material entry
      setSelectedProductDetails((prevDetails) => [
        ...prevDetails.filter((detail) => detail.attribute_name !== "format"),
      ]);
    } else if (!isNaN(value)) {
      // User selected a valid number, update the material entry
      setSelectedProductDetails((prevDetails) => [
        ...prevDetails.filter((detail) => detail.attribute_name !== "format"),
        {
          attribute_name: "format",
          attribute_value: parseInt(value),
        },
      ]);
    }
  };

  const handleProductAttributeChange = (e, attributeName) => {
    const { value } = e.target;
    if (value === "") {
      // User selected "Select," so remove the attribute entry
      setSelectedProductDetails((prevDetails) => [
        ...prevDetails.filter((detail) => detail.attribute_name !== attributeName),
      ]);
    } else if (!isNaN(value)) {
      // User selected a valid number, update the attribute entry
      setSelectedProductDetails((prevDetails) => [
        ...prevDetails.filter((detail) => detail.attribute_name !== attributeName),
        {
          attribute_name: attributeName,
          attribute_value: parseInt(value),
        },
      ]);
    }
  };
  return (
    <div>
      <p className="font-bold text-xl">Additional Product Details</p>
      {details.length<1?"Loading additional details":null}
      {details.map((attribute) => (
        <div key={attribute}>
          <div className="flex flex-row justify-between">
            <div className="mb-2 block">
              <Label
                htmlFor={attribute}
                value={
                  <div>
                    <p className="font-bold text-midnightblue">
                      {attribute.charAt(0).toUpperCase() + attribute.slice(1)}
                    </p>
                    <p className="text-darkgray">
                      Choose the appropriate {attribute} for this product
                    </p>
                  </div>
                }
              />
            </div>
          </div>
          <select
            id={attribute}
            value={
              selectedProductDetails.find(
                (detail) => detail.attribute_name === attribute
              )?.attribute_value || ""
            }
            onChange={(e) => handleProductAttributeChange(e, attribute)}
            className="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
          >
            <option value="">Select</option>
            {/* Dynamically render options based on attribute data */}
            {attribute === "material" && material.map((detail) => (
              <option key={detail.id} value={detail.id}>
                {detail.material_label}
              </option>
            ))}
            {attribute === "style" && style.map((detail) => (
              <option key={detail.id} value={detail.id}>
                {detail.style_label}
              </option>
            ))}
            {attribute === "language" && language.map((detail) => (
              <option key={detail.id} value={detail.id}>
                {detail.language_label}
              </option>
            ))}
            {attribute === "format" && format.map((detail) => (
              <option key={detail.id} value={detail.id}>
                {detail.format_label}
              </option>
            ))}
          </select>
        </div>
      ))}
      {/* <div>
        <div className="flex flex-row justify-between">
          <div className="mb-2 block">
            <Label
              htmlFor="material"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Product Material
                  </p>
                  <p className="text-darkgray">
                    Choose the appropriate material for this product
                  </p>
                </div>
              }
            />
          </div>
        </div>
        <select
          id="material"
          value={
            selectedProductDetails.find(
              (detail) => detail.attribute_name === "material"
            )?.attribute_value || ""
          }
          onChange={handleProductMaterialChange}
          className="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
        >
          <option value="">Select</option>
          {material.map((detail) => (
            <option key={detail.id} value={detail.id}>
              {detail.material_label}
            </option>
          ))}
        </select>
      </div>
      

      <div>
        <div className="flex flex-row justify-between">
          <div className="mb-2 block">
            <Label
              htmlFor="style"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Product Style
                  </p>
                  <p className="text-darkgray">
                    Choose the appropriate style for this product
                  </p>
                </div>
              }
            />
          </div>
        </div>
        <select
          id="style"
          value={
            selectedProductDetails.find(
              (detail) => detail.attribute_name === "style"
            )?.attribute_value || ""
          }
          onChange={handleProductStyleChange}
          className="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
        >
          <option value="">Select</option>
          {style.map((detail) => (
            <option key={detail.id} value={detail.id}>
              {detail.style_label}
            </option>
          ))}
        </select>
      </div>



      <div>
        <div className="flex flex-row justify-between">
          <div className="mb-2 block">
            <Label
              htmlFor="language"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Product Language
                  </p>
                  <p className="text-darkgray">
                    Choose the appropriate language for this product
                  </p>
                </div>
              }
            />
          </div>
        </div>
        <select
          id="language"
          value={
            selectedProductDetails.find(
              (detail) => detail.attribute_name === "language"
            )?.attribute_value || ""
          }
          onChange={handleProductLanguageChange}
          className="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
        >
          <option value="">Select</option>
          {language.map((detail) => (
            <option key={detail.id} value={detail.id}>
              {detail.language_label}
            </option>
          ))}
        </select>
      </div>

      <div>
        <div className="flex flex-row justify-between">
          <div className="mb-2 block">
            <Label
              htmlFor="age_group"
              value={
                <div>
                  <p className="font-bold text-midnightblue">
                    Product Format
                  </p>
                  <p className="text-darkgray">
                    Choose the appropriate format for this product if it is a book
                  </p>
                </div>
              }
            />
          </div>
        </div>
        <select
          id="age_group"
          value={
            selectedProductDetails.find(
              (detail) => detail.attribute_name === "format"
            )?.attribute_value || ""
          }
          onChange={handleProductFormatChange}
          className="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
        >
          <option value="">Select</option>
          {format.map((detail) => (
            <option key={detail.id} value={detail.id}>
              {detail.format_label}
            </option>
          ))}
        </select>
      </div> */}

      

      {/* <div className="flex justify-end font-bold text-normalbrown ">
        <button onClick={addFormSection}>Add Another Detail +</button>
      </div> */}
    </div>
  );
}

export default ProductForm;
