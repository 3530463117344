import React, { useEffect, useRef, useState } from "react";
import ProductCard from "../Cards/ProductCard";
import { useAuth } from "../../context/AuthContext";
import { useMarketplaceContext } from "../../context/MarketplaceContexts";
import { fetchDataForCategory } from "../../api/ApiPage";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function SimilarProducts({ name, id }) {
  const { isLogged, token, logout } = useAuth();
  const [err, setErr] = useState("");

  const [products, setProduct] = useState([]);
  const loadedOnce = useRef(false);
  const [categoryId, setCategory] = useState(null);
  const [data, setData] = useState([]);
  const initialpayload = { category: [] };
  const [formdata, setFormData] = useState(initialpayload);
  const navigate=useNavigate();




  useEffect(() => {
    // Make the first API call

    if (data.length === 0) {
      fetchDataForCategory(logout).then((data) => {
        setData(data);
      });
    }





    if (categoryId === null && name ) {
      const foundItem = data.find((item) => item.name === name);
      if (foundItem) {
        setCategory(foundItem.id);
        handleParamChange("category", [foundItem.id]);
      }
    }
  }, [categoryId, data,name]);

  const handleParamChange = (name, value) => {
    setFormData((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
    loadedOnce.current = false;
  };



  useEffect(() => {
    // Make the first API call
    if (categoryId !== null) {
      if (loadedOnce.current) return;
      loadedOnce.current = true;
      handlemarketplace();
    }
  }, [loadedOnce, categoryId]);

  const url =process.env.REACT_APP_API_URL;

  const handlemarketplace = () => {
    console.log(id)
    const additionalPath = `${url}/list_products`;
    const headers = {
      "Content-Type": "application/json",
    };

    if(token){
      headers['Authorization']=`Bearer ${token}`
    }

    // Assuming you have an 'id' field in formdata

    axios
      .get(additionalPath, {
        params: formatPayload(formdata),
        headers: headers,
      })
      .then((response) => {
        // Exclude the current product from the list
        const filteredProducts = response.data.products.data.filter(
          (product) => product.product_no !== id
        );
        
        // Get the top 4 products
        const top4Products = filteredProducts.slice(0, 4);
        
        setProduct(top4Products);
        
        loadedOnce.current = true;
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
         
          logout();
          navigate("/")
        } else {
          console.log(err);
          setErr(err);
        }
      });
  };

  const formatPayload = (formdata) => {
    const formattedPayload = { ...formdata };

    if (formattedPayload.category.length > 0) {
      // formattedPayload.category = JSON.stringify(formattedPayload.category);
      formattedPayload.category = JSON.stringify(formattedPayload.category);
      // formattedPayload.category = `[${categoryId}]`;
    } else {
      formattedPayload.category = [];
    }

    return formattedPayload;
  };

  return (
    <div className="flex flex-col">
      <p className="font-bold text-2xl mb-2">Similar Products you may like...</p>

      <div className="flex sm:flex-row flex-col sm:gap-8 gap-4">
        {products && products.length > 0 ? (
          products.map((product, index) => (
            <ProductCard
              key={index}
              status={isLogged}
              id={product.id}
              item={product}
              productname={product.name}
              seller={product.sellername}
              saves={product.saves}
              price={product.price}
              category={product.category}
              saved={product.is_saved}
            />
          ))
        ) : (
          <p className="text-darkgray">No similar products</p>
        )}
      </div>
    </div>
  );
}

export default SimilarProducts;
