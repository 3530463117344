import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import {
  Button,
  Modal,
  Label,
  Textarea,
  Rating,
  Checkbox,
} from "flowbite-react";
import { RatingStar } from "flowbite-react/lib/esm/components/Rating/RatingStar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function RatingCard({ type, userid, eventid }) {
  const { isLogged, token, user, logout } = useAuth();
  const [isDetail, setDetail] = useState(false);
  const [rating, setRating] = useState(0); // Initialize rating with 0 (no rating)
  const [feedback, setFeedback] = useState("");
  const [anonymous, setAnonymous] = useState(false);
  const navigate=useNavigate();

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const url =process.env.REACT_APP_API_URL;

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here, you can handle the submission of the rating and feedback data, e.g., send it to a server.
    // feedback category?
    // unauthorized access
    if (type == "ad") {
      let feedbacks;

      if (anonymous) {
        feedbacks = {
          feedback_to: userid,
          feedback: feedback,
          feedback_from: null,
          rating: rating,
        };
      } else {
        feedbacks = {
          feedback_to: userid,
          feedback: feedback,
          rating: rating,
        };
      }
      console.log(feedbacks);

      const additionalPath = `${url}/rate_user`;
      // const path=`get_product_by_id?id=${id}`;
      // makeApiRequest("GET", null, path, token);
      const headers = {
        "Content-Type": "application/json",
        // 'Authorization':  `Bearer ${token}`,
      };
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      axios
        .post(additionalPath, feedbacks, { headers: headers })
        .then((response) => {
          console.log(response.data.responsemessage);
          // alert
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            logout();
            navigate("/")
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            console.log(err);
            // Rethrow the error for further handling if needed
          }
        });
    } else {
      console.log("id:", eventid);
      let feedbacks;
      if (anonymous || !isLogged) {
        feedbacks = {
          event_id: eventid,
          rating: rating,
          rating_from: null,
        };
      } else {
        feedbacks = {
          event_id: eventid,
          rating: rating,
        };
      }

      console.log(feedbacks);

      const additionalPath = `${url}/rate_event`;
      // const path=`get_product_by_id?id=${id}`;
      // makeApiRequest("GET", null, path, token);
      const headers = {
        "Content-Type": "application/json",
        // 'Authorization':  `Bearer ${token}`,
      };
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      axios
        .post(additionalPath, feedbacks, { headers: headers })
        .then((response) => {
          console.log(response.data.responsemessage);
          // alert
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            logout();
            navigate("/")
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            console.log(err);
            // Rethrow the error for further handling if needed
          }
        });

      // {
    }

    // alert that it has submitted
    setRating(0);
    setFeedback("");
    toggledetail();
  };

  const toggledetail = () => {
    setDetail(!isDetail);
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <RatingStar
          key={i}
          filled={i <= rating ? true : false}
          onClick={() => handleRatingChange(i)}
        />
      );
    }
    return stars;
  };

  const handleCheckboxChange = (event) => {
    setAnonymous(event.target.checked);
    // You can perform additional actions based on the checkbox state here
  };

  return (
    <div className="border border-normalbrown rounded-md p-3">
      {isLogged ? (
        <div className="flex flex-col ">
          <p className="font-bold ">Give Feedback </p>
          <p className="text-darkgray text-xs mb-2">
            Rate your experience from 1 (poor) to 5 (excellent)
          </p>

          <div className="flex justify-center">
            <Button
              className="bg-normalblue p-1 w-full "
              onClick={toggledetail}
            >
              Rate
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-row items-center justify-center">
          <svg
            viewBox="0 0 512 512"
            fill="normalbrown"
            height="3em"
            width="3em"
          >
            <path d="M449.07 399.08L278.64 82.58c-12.08-22.44-44.26-22.44-56.35 0L51.87 399.08A32 32 0 0080 446.25h340.89a32 32 0 0028.18-47.17zm-198.6-1.83a20 20 0 1120-20 20 20 0 01-20 20zm21.72-201.15l-5.74 122a16 16 0 01-32 0l-5.74-121.95a21.73 21.73 0 0121.5-22.69h.21a21.74 21.74 0 0121.73 22.7z" />
          </svg>
          <p className="text-normalblue font-bold">
            Please Register/Login to  rate the Seller
          </p>
        </div>
      )}

      {isDetail && (
        <Modal show={isDetail} onClose={() => setDetail(false)}>
          <Modal.Header>Feedback Form</Modal.Header>
          <Modal.Body>
            <div className={type == "ad" ? "max-w-md" : "hidden"}>
              <div className="mb-2 block">
                <Label htmlFor="comment" value="Your feedback" />
              </div>
              <Textarea
                id="comment"
                name="feedback"
                required
                rows={4}
                value={feedback}
                onChange={handleFeedbackChange}
                placeholder="Enter your feedback..."
              />
            </div>

            <div className="max-w-md">
              <div className="mb-2 block">
                <Label htmlFor="rate" value="Your Rating" />
              </div>
              <Rating size="lg">{renderStars()}</Rating>
            </div>

            <div className="flex items-center gap-2 my-4">
              <Checkbox
                id="remember"
                checked={anonymous}
                onChange={handleCheckboxChange}
              />
              <Label htmlFor="remember">Remain anonymous</Label>
            </div>

            <Button className="bg-normalblue p-3" onClick={handleSubmit}>
              Submit
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default RatingCard;
