import React,{useEffect, useRef, useState} from "react";
import { Button, Modal } from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import WithdrawMoney from "../Forms/WithdrawMoney";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";

function WithdrawalModal({handleWithdrawal,isWithdraw,id}){
    const {token}=useAuth()
    const navigate=useNavigate();
    const closeAd=()=>{
        // update the ad
        console.log("Opened");
        handleWithdrawal();

    }
    const loadedOnce=useRef(false);
    const url =process.env.REACT_APP_API_URL;
    const headers = {
      "Content-Type": "application/json",
      // 'Authorization':  `Bearer ${token}`,
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const [amount,setAmount] = useState(0.00);

    useEffect(()=>{

        if(loadedOnce.current) return;
        loadedOnce.current=true;
        handleWithdraw();

    },[]);



    const handleWithdraw=()=>{
        const additionalPath = `${url}/get_balance_to_withdraw`;
        // const path=`get_product_by_id?id=${id}`;
        // makeApiRequest("GET", null, path, token);
        const info={
          event_no:id
        }
       
      
        axios
              .get(additionalPath,{params:info,headers: headers })
              .then((response) => {
                console.log(response.data);
                setAmount(response.data.balance);
  
          
      
              })
              .catch((err) => {
                console.log(err);
              });
    }
    return(
    <div>
        <Modal show={isWithdraw} size="md" popup onClose={handleWithdrawal}>
        <Modal.Header>Withdraw Money</Modal.Header>
        <Modal.Body>
            <p className="font-bold text-midnightblue">Amount in Wallet</p>
            <div className="flex flex-row gap-2">
                <div className="border border-lightgray rounded-md p-2 basis-3/4">
                    <p>{amount}</p>
                
                </div>
                <div className="border border-lightgray rounded-md p-2 basis-1/4">
                    <p>GHS</p>
                
                </div>
                

            </div>
            
            <WithdrawMoney amount={amount}  handleWithdrawal={handleWithdrawal} id={id}/>
         
        </Modal.Body>
      </Modal>

    </div>)
}

export default WithdrawalModal;