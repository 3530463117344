import React,{useEffect, useState} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import './index.css';
import Home from "./pages/User/Home";
import AboutUs from "./pages/User/AboutUs";
import ContactUs from "./pages/User/ContactUs";
import Error404 from "./pages/User/Error404";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import AuthenticationModal from "./components/Modal/AuthenticationModal";
import Account from "./pages/User/Account";
import AdsPage from "./pages/User/SubPages/AdsPage.js";
import {useAuth } from "./context/AuthContext";
import OrderPage from "./pages/User/SubPages/OrderPage";
import EventOrganizer from "./pages/Authentication/EventOrganizer";
import Events from "./pages/User/Events";
import EventsDetails from "./pages/User/SubPages/EventDetails";
import EventPurchase from "./pages/User/SubPages/EventPurchase";
import Marketplace from "./pages/User/Marketplace";
import AdDetails from "./pages/User/SubPages/AdDetails";
import NewAd from "./pages/User/SubPages/NewAd";
import Main from "./pages/EventOrganizer/Main";
import EventPage from "./pages/EventOrganizer/SubPages/EventPage";
import SetupAccount from "./pages/Authentication/SetupAccount";
import VerifyQRCode from "./pages/User/SubPages/VerifyQRCode.js";
import SearchResults from "./pages/User/SearchResults.js";
import TestFielUpload from "./components/Forms/TestFileUpload.js";
import PrivacyPolicy from "./pages/User/PrivacyPolicy.js";
import TermsandConditions from "./pages/User/TermsandConditions.js";
import MainAdmin from "./pages/Administrator/MainAdmin.js";

function App() {
  const { isLogged,isModalVisible, toggleModal } = useAuth();

  useEffect(() => {
    if (!isLogged && !isModalVisible) {
      const modalTimeout = setTimeout(() => {
        toggleModal();
      }, 360000); 
      return () => clearTimeout(modalTimeout);
    }
  }, [isLogged, isModalVisible, toggleModal]);

  const isExcludedRoute = () => {
    const excludedPatterns = [
      '/setup-account',
      '/reset_password/',
      '/verifycode',
      '/eventspurchase/',
      '/admin',
      '/admin/'
    ];

    return !excludedPatterns.some(pattern => window.location.pathname.startsWith(pattern));
  };
  

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/aboutus" element={<AboutUs />}/>
          <Route path="/contactus" element={<ContactUs />}/>
          <Route path="/events" element={<Events/>}/>
          <Route path="/marketplace" element={<Marketplace/>}/>
          <Route path="/account/:section/:request?" element={<Account/>}/>
          <Route path="/organizerrequest" element={<EventOrganizer/>}/>
          <Route path="/addetails/:id" element={<AdDetails/>}/>
          <Route path="/postad" element={<NewAd/>}/>
          <Route path="/eventspage/:id" element={<EventsDetails/>}/>
          <Route path="/eventspurchase/:id/:restart?" element={<EventPurchase/>}/>
          <Route path="/adspage/:id/:status?" element={<AdsPage/>}/>
          <Route path="/orderpage/:id" element={<OrderPage/>}/>
          <Route path="/eventorganizer/:section/:id?/:status?/:order?" element={<Main/>}/>
          <Route path="/searchresult/:term" element={<SearchResults/>}/>
          <Route path="/reset_password/:changestate?" element={<ForgotPassword />}/>
          <Route path="/setup-account" element={<SetupAccount/>}/>
          <Route path="/testupload" element={<TestFielUpload/>}/>
          <Route path="/verifycode" element={<VerifyQRCode/>}/>
          <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
          <Route path="/termsandconditions" element={<TermsandConditions/>}/>
          <Route path="/admin/:section?/:id?" element={<MainAdmin/>}/>
          <Route path="*" element={<Error404 />}/>
        </Routes>
      </Router>
      {
       isModalVisible && isExcludedRoute() && <AuthenticationModal />
        
      }
      </div>
  );
}

export default App;
