import React, { useEffect, useState } from "react";
import { Button, Modal } from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import WithdrawMoney from "../Forms/WithdrawMoney";
import AdChoiceForm from "../Forms/Ads/AdChoiceForm";
import AdChoiceEventForm from "../Forms/AdChoiceEventForm";

function AdModal({handleWithdrawal,isWithdraw,adtype,productid,purpose}){
  const [update,setUpdated]=useState(false);

  useEffect(()=>{
    if(update){
      handleWithdrawal();
    }
  },[update])
    
    return(
    <div>
        <Modal show={isWithdraw} size="xl" popup onClose={handleWithdrawal}>
        <Modal.Header>Boost Ad</Modal.Header>
        <Modal.Body>
            <div>

              {purpose=="ad"? (<AdChoiceForm adtypes={adtype} productid={productid} setUpdated={setUpdated}/>):(<AdChoiceEventForm adtypes={adtype} productid={productid} setUpdated={setUpdated}/>)}
         
           
          
           </div>
           
            
           
        </Modal.Body>
      </Modal>

    </div>)
}

export default AdModal;