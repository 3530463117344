import React,{useEffect} from "react";
import { Button } from "flowbite-react";
import { useEventContexts } from "../../../context/EventContexts";
import { format,parse } from  'date-fns';



function OrderCard({id,event}){
    

    

    const parsedTime = event.time&&parse(event.time, 'HH:mm:ss', new Date());

              // Format the time in 12-hour clock with AM/PM indicator
    const formattedTime =parsedTime&&format(parsedTime, 'h:mm a');
   

    return(
        <div className="flex flex-row border border-lightgray rounded-md text-midnightblue justify-between  items-center ">
            <div className="flex flex-row" >
                <img className="bg-midnightblue sm:w-24 w-16 rounded-l-md rounded-bl-md"/>
                <div className="flex flex-col p-2">
                <p className="font-medium">{event.name}</p>
                <p className="text-darkgray text-xs">{format(new Date(event.date), "dd MMMM yyyy")},{formattedTime}</p>
                <p className="text-sm">{event.location}</p>
            </div>
            </div>
            

            <div className="p-2">
                <a href={`/orderpage/${id}`}><Button className="bg-normalbrown rounded-md">View</Button></a>
            </div>
        </div>
)
}

export default OrderCard;