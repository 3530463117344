import React, { useState } from "react";
import TopBanner from "../../components/Navigation/TopBanner";
import NavigationBar from "../../components/Navigation/NavigationBar";
import SearchBarCollapse from "../../components/SearchBars/SearchBarCollapse";
import logo from "../../assets/images/KRIFFEONLINELOGO2.png";
import { useParams } from "react-router-dom";
import SidebarNav from "../../components/Navigation/SidebarNav";
import Profile from "../../components/AccountPages/Profile";
import SavedItems from "../../components/AccountPages/SavedItems";
import Ads from "../../components/AccountPages/Ads";
import Orders from "../../components/AccountPages/Orders";
import Inbox from "../../components/AccountPages/Inbox";
import Events from "../../components/AccountPages/Events";
import Settings from "../../components/AccountPages/Settings";
import { useAuth } from "../../context/AuthContext";

function Account() {
  const { isLogged } = useAuth();
  const { section } = useParams();
  const [visibleDiv, setVisibleDiv] = useState(section);
  const divContentMap = {
    profile: <Profile/>,
    saved: <SavedItems />,
    ads: <Ads/>,
    orders: <Orders/>,
    inbox: <Inbox/>,
    events: <Events/>,
    settings: <Settings/>,
  };

  const content = divContentMap[visibleDiv] || "";
  const toggleDiv = (divValue) => {
    setVisibleDiv(divValue);
  };

  return (
    <section className="w-screen">
      <TopBanner />
      <NavigationBar
        logo={logo}
        color="text-midnightblue"
        status={isLogged}
      />
      <SearchBarCollapse />
      <div className="flex sm:flex-row flex-col px-3">
        <div className="basis-1/4  sm:block hidden">
          <SidebarNav toggleDiv={toggleDiv} visibleDiv={visibleDiv}/>
        </div>
        <div className=" sm:basis-[74%] w-full mt-5 rounded-lg h-auto p-4  border border-lightgray">
          {content}
        </div>
      </div>
    </section>
  );
}
export default Account;
