import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const RevenueLineChartDashboard = () => {
  const { token, logout } = useAuth();
  const navigate=useNavigate();
  const [chartData, setChartData] = useState({
    monthly: {},
  });
  const [selectedOption, setSelectedOption] = useState("monthly");
  const url =process.env.REACT_APP_API_URL;
  const [months, setMonth] = useState([]);
  const monthlyData = {
    Jan: 0,
    Feb: 0,
    Mar: 0,
    Apr: 0,
    May: 0,
    Jun: 0,
    Jul: 0,
    Aug: 0,
    Sep: 0,
    Oct: 0,
    Nov: 0,
    Dec: 0,
  };

  useEffect(() => {
    // Simulate fetching revenue data (Replace with actual API or database call)
    const fetchRevenueData = async () => {
      // get_dashboard_get_monthly_sales
      const additionalPath = `${url}/get_dashboard_get_monthly_sales`;
      const headers = {
        "Content-Type": "application/json",
      };

      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      await axios
        .get(additionalPath, { headers: headers })
        .then((response) => {
          console.log(response.data);
          setMonth(response.data.monthly);
          //  setSales(response.data.event_sales);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            
            logout();
            navigate("/")
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            console.log(err);

            // Rethrow the error for further handling if needed
          }
        });
    };

    fetchRevenueData();
  }, []);

  // useEffect(() => {
  //   if (months && months.length !== 0) {
  //     // Create a copy of the current monthly data
  //     const updatedMonthlyData = { ...monthlyData };

  //     months.forEach((item) => {
  //       const { month, revenue } = item;
  //       if (monthlyData.hasOwnProperty(month)) {
  //         monthlyData[month] = revenue; // Update value in updatedMonthlyData if the month exists
  //       }
  //     });

  //     // Update the state with the updated monthly data
  //     setChartData({
  //       monthly: updatedMonthlyData,
  //     });
  //   }
  // }, [months, monthlyData]);
  useEffect(() => {
    if (months && months.length !== 0) {
      setChartData(prevChartData => {
        // Create a copy of the current monthly data
        const updatedMonthlyData = { ...monthlyData };
  
        months.forEach((item) => {
          const { month, revenue } = item;
          if (updatedMonthlyData.hasOwnProperty(month)) {
            updatedMonthlyData[month] =  parseFloat(revenue).toFixed(2); // Update value in updatedMonthlyData if the month exists
          }
        });
  
        // Return the updated state
        return {
          monthly: updatedMonthlyData,
        };
      });
    }
  }, [months]);

  const chartOptions = {
    chart: {
      id: "revenue-line-chart",
    },
    xaxis: {
      categories: Object.keys(chartData[selectedOption]),
    },
  };

  const chartSeries = [
    {
      name: `${
        selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)
      } Revenue`,
      data: Object.values(chartData[selectedOption]),
    },
  ];

  return (
    <div className="revenue-line-chart">
      <div>
        {/* <label htmlFor="revenue-options">Select Data:</label> */}
        {/* <select id="revenue-options" onChange={handleChangeOption} value={selectedOption} className="rounded-md p-2 border border-normalblue">
          <option value="monthly">Monthly</option>
        </select> */}
      </div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="line"
        height={350}
      />
    </div>
  );
};

export default RevenueLineChartDashboard;
