import React,{useState,useEffect} from "react";
import { Button, Textarea, Label, TextInput } from "flowbite-react";
import { useApi } from "../../api/APIProvider";

function ContactForm() {
  const { makeApiRequest,data } = useApi();
  const [formData, setFormData] = useState({
    name:"",
    email:"",
    subject:"",
    message:""
  });
  const [formErrors, setFormErrors] = useState({});
  const [msg, setMsg] = useState('');


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const user={
    name:formData.name,
   email:formData.email,
   subject:formData.subject,
   message:formData.message
  }

  const validateForm = () => {
    let errors = {};

    if (!formData.name) {
      errors.name = 'Name is required';
    }

    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!isValidEmail(formData.email)) {
      errors.email = 'Invalid email address';
    }

    if (!formData.subject) {
      errors.subject = 'Subject is required';
    }

    if (!formData.message) {
      errors.message = 'Message is required';
    }

    

    return errors;
  };
  const emailRegex = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
  const isValidEmail = (email) => {
    return emailRegex.test(email);

  };


  const handlecontact=async ()=>{

    const errors = validateForm();



    if (Object.keys(errors).length === 0) {
      const additionalPath = "add_contact_form";
      makeApiRequest("POST", user, additionalPath);

      
    } else {
      // Update the state with validation errors
      setFormErrors(errors);
    }
  }

  useEffect(() => {
    if (data&&data.responsecode==200) {
      setMsg("Message sent");

    }
    

},[data]);

  return (
    <div className="flex flex-col justify-center items-center  h-full">
      <p className="text-white font-medium">{msg}</p>
      <form className="flex w-full flex-col gap-4 ">
        <div>
          
          <TextInput
            id="name"
            placeholder="Your name"
            type="text"
            name="name"
            onChange={handleInputChange}
          required
          class={formErrors.name?("block mb-2 text-sm font-medium text-red-600 border border-red-600 rounded-md w-full sm:h-16 h-10"):("block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10")}
       
          />
        </div>
        <div>
          
          <TextInput
            id="email1"
            placeholder="Your email"
            required
            type="email"
            name="email"
            onChange={handleInputChange}
            class={formErrors.email?("block mb-2 text-sm font-medium text-red-600 border border-red-600 rounded-md w-full sm:h-16 h-10"):("block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10")}
            
          />
        </div>
        <div>
          
          <TextInput
            id="text1"
            placeholder="Your subject"
            required
            name="subject"
            type="text"
            onChange={handleInputChange}
            class={formErrors.subject?("block mb-2 text-sm font-medium text-red-600 border border-red-600 rounded-md w-full sm:h-16 h-10"):("block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10")}
            
          />
        </div>
        <div id="textarea">
         
          <Textarea
            id="comment"
            placeholder="Your message..."
            required
            onChange={handleInputChange}
            name="message"
            rows={5}
            class={formErrors.subject?("block mb-2 text-sm font-medium text-red-600 border border-red-600 rounded-md w-full h-2/3"):("block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full h-2/3")}
            
          />
        </div>
        <Button type="button" className="bg-normalbrown sm:p-4 p-2 rounded-md" onClick={handlecontact}>Send message</Button>
      </form>
    </div>
  );
}

export default ContactForm;
