import React, { useEffect, useState,useRef } from "react";
import { Button, Modal,Spinner } from 'flowbite-react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../context/AuthContext";

function FAQModal({id,open,handleModal}){
    const {token}=useAuth();

    const loadedOnce = useRef(false);


  

  useEffect(() => {
    // Make the first API call
    // handleevent();

    if (id&&id.length>0){
        loadedOnce.current = true;

    }

  }, [id]);

    return(
    <div>
        <Modal show={open} size="xl" popup onClose={handleModal}>
        <Modal.Header>FAQ Details</Modal.Header>
        <Modal.Body>
            <div className="relative">
                <div>
        <strong>Question:</strong> {id.question}
      </div>
      <div>
        <strong>Category:</strong> {id.category_name}
      </div>
      <div>
        <strong>Answer:</strong> {id.answer}
      </div>
      
     
     
     

             
         
           
           </div>
           
           {/* {loadedOnce.current===false && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md">
            {/* Your spinner component 
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
          </div>
        </div>
      )} */}
           
        </Modal.Body>
      </Modal>

    </div>)
}

export default FAQModal;