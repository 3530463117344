import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useAuth } from "../../context/AuthContext";
import NavigationBar from "../../components/Navigation/NavigationBar";
import TopBanner from "../../components/Navigation/TopBanner";
import FixedFooter from "../../components/Footer/FixedFooter";
import SearchBarCollapse from "../../components/SearchBars/SearchBarCollapse";
import logo from "../../assets/images/KRIFFEONLINELOGO2.png";
import { useNavigate } from "react-router-dom";

function SearchResults() {
  const {logout,isLogged}=useAuth();
  const { term } = useParams();
  const url =process.env.REACT_APP_API_URL;
  const navigate=useNavigate()
  const additionalPath = `${url}/search`;
  const headers = {
    'Content-Type': 'application/json',
  };
  const info={
    search_term:term
  }
  const loadedOnce=useRef();

  useEffect(()=>{
    if(loadedOnce.current) return;
    loadedOnce.current=true;
    handlesearch()

  },[]);


  const handlesearch=()=>{
    axios.get(additionalPath,{params:info,headers:headers})
      .then(response => {
        // setProduct(response.data);
        console.log(response.data);
        setProduct(response.data.products);
        setEvent(response.data.events);

        
      
       
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log('Unauthorized: Logging user out or performing specific action...');
            logout();
            navigate("/")
      
          } else {
            // Handle other errors
            console.log(err);
   
          

          }
    });
  }



  const [product, setProduct] = useState([]);
  const [event, setEvent] = useState([]);
  const [selected, setSelected] = useState("product");
  const handleChange = (e) => {
    setSelected(e.target.value);
   
  };
  return (
    <div>
      <section className="w-screen">
        <TopBanner />
        <NavigationBar logo={logo} color="text-midnightblue" status={isLogged} />
        <SearchBarCollapse />
      </section>
      <section className="w-screen h-screen px-3 py-8">
      <div>
        {/* filter between products and events */}
        <p className="text-4xl text-left font-medium">
          {" "}
          Search Results for <span className="font-extrabold text-normalbrown">{term}</span>
        </p>

        <div className="flex sm:flex-row flex-col mt-2 gap-2">
          <div className="basis-1/3">
            {/* filter */}
            <p>Filter based on what you are looking for</p>
            <select onChange={handleChange} className="border border-normalblue rounded-lg w-3/4">
              <option value="product">Products</option>
              <option value="event">Events</option>
            </select>
          </div>
          <div className="basis-2/3 mt-2">
          {selected == "product" ? (
            <div>
              <p className="font-medium text-2xl text-darkgray">{product.length} results on products</p>
              <div className="flex flex-col gap-2 mt-4">
                {product&&product.length!=0?(
                  product.map((item)=>(
                  <div>
                    <a href={`/addetails/${item.product_no}`}><p className="text-normalblue font-bold text-xl">
                      {item.name}
                    </p></a>
                    
                    <p>
                      This is the product search term that was gotten based on
                      your search results
                    </p>
                  </div>))
                ):(<div>No Products for search term</div>)}
                
              </div>
            </div>
          ) : (
            <div>
              <p className="font-medium text-2xl text-darkgray">{event.length} results on products</p>
              <div className="flex flex-col gap-2 mt-4">
              {event&&event.length!=0?(
                event.map((item)=>(
                  <div>
                    <a href={`/eventspage/${item.event_no}`}><p className="text-normalblue font-bold text-xl">
                      {item.name}
                    </p></a>
                    
                    <p>
                      This is the product search term that was gotten based on
                      your search results
                    </p>
                  </div>))
                ):(<div>No events for search term</div>)}
              </div>
            </div>
          )}
        </div>
        </div>

        
      </div>
      </section>
      <section className="w-screen">
        <FixedFooter />
      </section>
    </div>
  );
}

export default SearchResults;
