import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEventOrganizerContext } from "../../../context/EventOrganizerContext";
import QRCode from "react-qr-code";
import { format } from "date-fns";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";
import { Spinner } from "flowbite-react";

function OrderReceipt() {
  const { id, order } = useParams();
  const navigate = useNavigate();
  const { token, logout } = useAuth();
  const useOnce = useRef(false);
  const [event, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const url =process.env.REACT_APP_API_URL;

  useEffect(() => {
    // get_total_order
    //
    if (useOnce.current) return;
    useOnce.current = true;
    handletotaldaily();
  }, []);

  const payload = {
    id: order,
  };

  const handletotaldaily = async () => {
    // get_dashboard_total_revenue
    const additionalPath = `${url}/get_ticket_by_id`;
    const headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { params: payload, headers: headers })
      .then((response) => {
        console.log(response.data.sale);
        setEvents(response.data.sale);
        setLoading(false);
        //  setDaily(response.data.order);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  // fucntion to get the data from id. api
  return (
    <div className="relative">
      <div className="flex flex-col p-3 gap-4 ">
        <a href="#" onClick={handleGoBack} className="text-normalblue">
          {`<`}Go Back
        </a>
        {/* div for ebvent detatils */}

        <p className="text-midnightblue font-bold">Order #K{order}</p>
        <div className=" grid grid-cols-2  w-full h-48 rounded-md p-4 text-midnightblue bg-white">
          <p className="col-span-2 text-lg font-bold">Contact Information</p>
          <p>
            First Name:
            <span className="text-darkgray text-sm">
              {event.fullname && event.fullname.split(" ")[0]}
            </span>
          </p>
          <p>
            {/* split */}
            Last Name:
            <span className="text-darkgray text-sm">
              {event.fullname && event.fullname.split(" ")[1]}
            </span>
          </p>
          <p>
            Phone Number:
            <span className="text-darkgray text-sm">
              {event && event.phonenumber}
            </span>
          </p>
          <p>
            Email Address:
            <span className="text-darkgray text-sm">
              {event && event.email}
            </span>
          </p>
        </div>

        <div className=" flex flex-col  w-full h-auto rounded-md p-4 text-midnightblue space-y-4 bg-white">
          <p className="col-span-2 text-lg font-bold">
            Order #{event && event.reference_code} Information
          </p>

          <div className="flex sm:flex-row flex-col justify-between">
            <div className="flex flex-col  basis-1/2 gap-2">
              {event &&
                event.ticketinfo &&
                event.ticketinfo.map((ticket, index) => (
                  <div
                    className="flex flex-row justify-between text-darkgray font-medium "
                    key={index}
                  >
                    <p>
                      {ticket.Quantity_purchased}x {ticket.name}
                    </p>

                    <p className="font-bold ">
                      GHC {event.ticket_total ? event.ticket_total : 0}
                    </p>
                  </div>
                ))}
              <div className="flex flex-row justify-between">
                <p>Fees</p>
                <p className="font-bold">Ghc {event && event.fees}</p>
              </div>
              <div className="flex flex-row justify-between">
                <p>Sub Fees</p>
                <p className="font-bold">Ghc {event && event.subtotal}</p>
              </div>
              <div className="flex flex-row justify-between">
                <p className="font-bold">Total</p>
                <p className="font-bold">Ghc {event && event.total}</p>
              </div>
            </div>
            <div className="flex flex-col items-center sm:mt-0 mt-4 ">
              <QRCode
                title={"Order Number"}
                value={`/verifycode?code=${event && event.reference_code}`}
                size={150}
              />
              <p>Check your email for receipt</p>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md">
            {/* Your spinner component */}
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default OrderReceipt;
