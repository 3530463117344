import React,{useState} from "react";
import { Button, Modal } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";

function EventEditModal({ id, open, handleModal, status }) {
  const { token, adminlogout } = useAuth();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/admin/update_event_status`;
  const [reason, setReason] = useState("");
  const [choice, setChoice] = useState(null);

  const closeAd = (stats) => {
    // update the ad

    // api to close and then navigaet that id
    if (!token) {
      // If token is not present in the context, try to get it from local storage
      const storedToken = localStorage.getItem("token"); // Replace 'yourTokenKey' with the actual key you used for storing the token
      if (storedToken) {
        handleedit(stats, JSON.parse(storedToken),reason);
        
      }
    }else{
      handleedit(stats, token,reason);
    }
  };

 



  const handleedit=async(stats,tokens,reason)=>{
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${tokens}`,
    };
    const bodyinfo = stats === "rejected"
    ? { approvalstatus: stats, reason_for_rejection: reason }
    : { approvalstatus: stats };

    const statusinfo = {
      event_id: id,
    };
    console.log(headers)

    await axios
      .patch(additionalPath, bodyinfo,{ params: statusinfo, headers: headers })
      .then((response) => {
        if (response.data.responsemessage) {
          console.log("Updated");
        } else {
          console.log("Egbamiii");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
        
            adminlogout();
            navigate("/admin")
          console.error(err);
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });

    handleModal();
  }
  return (
    <div>
      <Modal show={open} size="md" popup onClose={handleModal}>
        <Modal.Header>Update Event Status</Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="flex justify-center">
              <svg
                class="w-12 h-12 text-darkgray  "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
              </svg>
            </div>

            {(status === "pending" || status==="closed" || status==="deleted") && (
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Have you carefully reviewed the information on the ad? Are you
                sure you want to update this product now ?
              </h3>
            )}
            {status === "active" && (
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                The product has been approved.The user will open/close the ad
              </h3>
            )}


              {status!=="active" &&
              <div>
              <div className="mb-5">
                <select
                  value={choice}
                  onChange={(e) => setChoice(e.target.value)}
                  className="rounded-md border border-normalblue w-full"
                >
                  <option>Select</option>
                  {status === "pending" && (
                    <>
                      <option value="approved">Approve</option>
                      <option value="rejected">Decline</option>
                    </>
                  )}
                  {(status === "closed" || status === "deleted") && (
                    <>
                      <option value="approved">Approve</option>
                    </>
                  )}
                </select>
                {choice === "rejected" && (
                  <input
                    type="text"
                    placeholder="Reason for rejection"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    className="rounded-md border border-normalblue w-full mt-2 p-2"
                  />
                )}
              </div>
            

            <div className="flex justify-center gap-4">
              <Button
                className=" bg-normalblue text-white basis-1/2"
                onClick={() => closeAd(choice)}
              >
                Update
              </Button>
            </div>
            </div>
}
            {/* <div className="flex justify-center gap-4">
              {status === "pending" && (
                <div className="flex gap-2">
                  <Button
                    className="border border-normalblue text-normalblue basis-1/2"
                    onClick={() => closeAd("approved")}
                  >
                    Approve
                  </Button>
                  <Button
                    className="bg-[#AD3C3C] text-white basis-1/2"
                    onClick={() => closeAd("rejected")}
                  >
                    Decline
                  </Button>
                </div>
              )} */}

              {/* {
                    status==="active"&&<div className="flex gap-2">
            
                   
                    <Button className="border border-normalblue text-normalblue basis-1/2" >Undo Approval</Button>
                    <Button className="bg-[#AD3C3C] text-white basis-1/2">Close Event</Button>
               </div>
                } */}

              {/* {
                    status==="closed"&&<div className="flex gap-2">
            
                   
                    <Button className="bg-normalblue text-normalblue basis-1/2" >Open Event</Button>

               </div>
                } */}
            {/* </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default EventEditModal;
