import React, { useEffect, useState, useRef } from "react";
import QRCode from "react-qr-code";
import { format, parse } from "date-fns";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { Spinner, Button } from "flowbite-react";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";

function EventReceipt({ id, setEventId, setDate }) {
  // id is the order id

  const [event, setEvent] = useState();
  const navigate = useNavigate();
  const formdata = {
    id: id,
  };

  console.log(id);

  const { token, logout } = useAuth();
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [err, setErr] = useState("");
  useEffect(() => {
    // Make the first API call
    if (!loadedOnce && !event) {
      handlemarketplace();
    }
  }, [loadedOnce, event]);

  const url = process.env.REACT_APP_API_URL;

  const handlemarketplace = async () => {
    const additionalPath = `${url}/get_ticket_by_id`;

    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    await axios
      .get(additionalPath, { params: formdata, headers: headers })
      .then((response) => {
        console.log(response.data);
        setEvent(response.data.sale);
        setEventId && setEventId(response.data.sale.event_id);
        setDate && setDate(response.data.sale.date);
        setLoadedOnce(true);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/");
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
          setErr(err);

          // Rethrow the error for further handling if needed
        }
      });
  };
  const pdfContainerRef = useRef(null);

  const downloadPDF = () => {
    const image = new Image();
    image.src = event && event.images && event.images[0];

    image.onload = () => {
      // Image is loaded, now generate the PDF
      const element = document.getElementById("pdf-container");
      if (element) {
        html2pdf(element, {
          margin: 10,
          filename: `EventReceipt_${event && event.order_no}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        });
      }
    };
  };

  // const parsedTime = event.time&&parse(event.time, 'HH:mm:ss', new Date());

  //               // Format the time in 12-hour clock with AM/PM indicator
  //   const formattedTime =parsedTime&&format(parsedTime, 'h:mm a');

  // fucntion to get the data from id. api
  return (
    <div className="px-6 pt-3 relative mb-4">
      <div className="flex mb-2">
        <a
          href="#"
          className="sm:w-1/4 mt-4 text-normalblue flex gap-1"
          onClick={downloadPDF}
        >
          <svg
            class="w-6 h-6  text-normalblue dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2m-8 1V4m0 12-4-4m4 4 4-4"
            />
          </svg>
          Download Event Ticket
        </a>
      </div>
      <div className="flex flex-col space-y-10" id="pdf-container">
        <div className="relative w-full h-80 bg-midnightblue rounded-md">
          <div></div>
          <img
            src={event && event.images !== null && event.images[0]}
            alt="Event Image "
            className="w-full h-full object-contain"
          ></img>
          <div class=" absolute sm:inset-x-52 inset-x-12 -bottom-8  bg-normalbrown text-white sm:p-4 p-4 rounded-md flex flex-col items-center justify-center">
            <p className="font-bold sm:text-4xl">{event && event.name}</p>
            <p>{event && event.date}</p>
            <p>{event && event.location}</p>
          </div>
        </div>
        {/* div for ebvent detatils */}

        <div className=" grid grid-cols-2 border border-lightgray w-full h-48 rounded-md p-4 text-midnightblue ">
          <p className="col-span-2 text-lg font-bold">Contact Information</p>
          <p className="flex flex-col">
            First Name
            <span className="text-darkgray text-sm">
              {event && event.fullname.split(" ")[0]}
            </span>
          </p>
          <p className="flex flex-col">
            {/* split */}
            Last Name
            <span className="text-darkgray text-sm">
              {event && event.fullname.split(" ")[1]}
            </span>
          </p>
          <p className="flex flex-col">
            Phone Number
            <span className="text-darkgray text-sm">
              {event && event.phonenumber}
            </span>
          </p>
          <p className="flex flex-col">
            Email Address
            <span className="text-darkgray text-sm">
              {event && event.email}
            </span>
          </p>
        </div>

        <div className=" flex flex-col border border-lightgray w-full h-auto rounded-md p-4 text-midnightblue space-y-4">
          <p className="col-span-2 text-lg font-bold">Order Information</p>
          <p className="text-normalblue font-medium">
            Order #{event && event.order_no}
          </p>

          <div className="flex sm:flex-row flex-col justify-between">
            <div className="flex flex-col  basis-1/2">
              {event &&
                event.ticketinfo &&
                event.ticketinfo.map((ticket, index) => (
                  <div
                    className="flex flex-row justify-between text-darkgray font-medium "
                    key={index}
                  >
                    <p>
                      {ticket.Quantity_purchased}x {ticket.name}
                    </p>

                    <p className="font-bold ">
                      GHC {event.ticket_total ? event.ticket_total : 0}
                    </p>
                  </div>
                ))}
              <div className="flex flex-row justify-between">
                <p>Fees</p>
                <p className="font-bold">Ghc {event && event.fees}</p>
              </div>
              <div className="flex flex-row justify-between">
                <p>Sub Fees</p>
                <p className="font-bold">Ghc {event && event.subtotal}</p>
              </div>
              <div className="flex flex-row justify-between">
                <p className="font-bold">Total</p>
                <p className="font-bold">Ghc {event && event.total}</p>
              </div>
            </div>
            <div className="flex flex-col items-center sm:mt-0 mt-4 ">
              <QRCode
                title={"Order Number"}
                value={`https://kriffeonline.com/verifycode?code=${
                  event && event.reference_code
                }`}
                size={150}
              />
              <p>Check your email for receipt</p>
            </div>
          </div>
        </div>
      </div>
      {!loadedOnce && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70 z-50">
          <div className="p-4 max-w-md mx-auto rounded-md">
            {/* Your spinner component */}
            <Spinner
              aria-label="Default status example"
              color="warning"
              size="xl"
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default EventReceipt;
