import React, { useState,useEffect,useRef } from 'react';
import Chart from 'react-apexcharts';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const LineChart = () => {
  const {token,logout}=useAuth()
  const [chartData, setChartData] = useState([]);
  const [month, setMonth] = useState([]);
  const navigate=useNavigate();

  // get_monthly_signups
  const url =process.env.REACT_APP_API_URL;
  const loadedOnce=useRef(false);
  const monthlyData = {
    'Jan': 0, 'Feb': 0, 'Mar': 0,
    'Apr': 0, 'May': 0, 'Jun': 0,
    'Jul': 0, 'Aug': 0, 'Sep': 0,
    'Oct': 0, 'Nov': 0, 'Dec': 0,
  };

  useEffect(() => {
    const generateMonthlyData =async() => {
    

      const additionalPath = `${url}/get_monthly_signups`;
  const headers = {
      'Content-Type': 'application/json',
  };

  if(token){
    headers['Authorization'] = `Bearer ${token}`;
  }

  await axios.get(additionalPath, {headers: headers })
      .then(response => {
        console.log(response.data);
        setMonth(response.data.monthly);
       
        
       
          //  setSales(response.data.event_sales);
          let monthlyData = {
            'Jan': 0, 'Feb': 0, 'Mar': 0,
            'Apr': 0, 'May': 0, 'Jun': 0,
            'Jul': 0, 'Aug': 0, 'Sep': 0,
            'Oct': 0, 'Nov': 0, 'Dec': 0,
          };
          if(response && response.data &&response.data.monthly.length==0){
            
            setChartData(monthlyData);
  
  
          }else{

            response.data.monthly.forEach(item => {
              const monthName = item.month; // Assuming 'month' property contains month name
              const monthValue = item.SignUps; // Assuming 'value' property contains the value for the month
      
              if (monthlyData.hasOwnProperty(monthName)) {
                monthlyData[monthName] = monthValue;
              }
            });
      
            setChartData(monthlyData);
  
          }
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log('Unauthorized: Logging user out or performing specific action...');
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
        // Rethrow the error for further handling if needed
        }
      })
     

      

   

      
    };



   


    
    if (loadedOnce.current) return;
    loadedOnce.current = true;
    generateMonthlyData();
  }, []);

  

  
  



  const options = {
    chart: {
      id: 'line-chart',
    },
    xaxis: {
      categories: Object.keys(chartData),
    },
  };

  const series = [
    {
      name: 'Registrations',
      data: Object.values(chartData),
    },
  ];

  const handleSwitch = (data) => {
    setChartData(data);
  };

  return (
    <div className="line-chart">
     
      <Chart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default LineChart;
