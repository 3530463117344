import React, { createContext, useContext, useReducer,useState } from 'react';
import axios from 'axios';


const initialState = {
  loading: false,
  data: null,
  error: null,
};

const apiReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST':
      return { ...state, loading: true };
    case 'SUCCESS':
      return { ...state, loading: false, data: action.data };
    case 'ERROR':
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

const ApiContext = createContext();

export const useApi = () => useContext(ApiContext);

export const ApiProvider = ({ children }) => {
    
  const [state, dispatch] = useReducer(apiReducer, initialState);
  // const [data, setData] = useState(null);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  const url =process.env.REACT_APP_API_URL
  const api_url=process.env.REACT_APP_API_URL

  const makeApiRequest = async ( method, data = null, additionalPath = '',token=null) => {
    const apiUrl = `${url}/${additionalPath}`;
    // setLoading(true);

    dispatch({ type: 'REQUEST' });


    try {

        const headers = {
            'Content-Type': 'application/json',
          };
    
          if (token) {
            headers['Authorization'] = `Bearer ${token}`;
          }
      const response = await axios(apiUrl, {
        method,
        headers,
        data: data ? JSON.stringify(data) : null,
      });
      

      // if (!response.ok) {
      //   const errorMessage = await response.text();
      //   console.error('API request failed with status:', response.status, errorMessage);
      //   throw new Error('Network response was not ok');
        
      // }

      if (response.status >= 200 && response.status < 300) {
        const datas =  response.data;
        // setData(datas);
        dispatch({ type: 'SUCCESS', data: response.data });
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }

    //   const contentType = response.headers.get('content-type');
    // if (contentType && contentType.includes('application/json')) {
    //   const datas = await response.json();
    //   setData(datas);
    //   console.log('Successful API response:', datas);
    //   dispatch({ type: 'SUCCESS', datas });
    // } else {
      
    //   const textData = await response.text();
    //   dispatch({ type: 'SUCCESS', textData });

    // }

      
    } catch (error) {
        console.error('API request failed:', error); 
      dispatch({ type: 'ERROR', error });

    }
    // finally {
    //           setLoading(false);
    //         }
  };

  return (
    <ApiContext.Provider value={{ ...state, makeApiRequest}}>
      {children}
    </ApiContext.Provider>
  );
};


// // ApiContext.js

// import React, { createContext, useContext, useEffect, useState } from 'react';
// import axios from 'axios';

// const ApiContext = createContext();

// export const useApi = () => useContext(ApiContext);

// export const ApiProvider = ({ children }) => {
//   const [data, setData] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const url =process.env.REACT_APP_API_URL;

//   const fetchData = async ( method , body = null,additionalPath) => {
//     const apiUrl = `${url}/${additionalPath}`;
//     setLoading(true);
//     try {
//       const response = await axios({ method, apiUrl, data: body });
//       setData(response.data);
//     } catch (err) {
//       setError(err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     // You can add any common setup logic here if needed.
//   }, []);

//   return (
//     <ApiContext.Provider value={{ data, loading, error, fetchData }}>
//       {children}
//     </ApiContext.Provider>
//   );
// };
