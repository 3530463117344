import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";

function FAQEditCategoryModal({data,id,open, handleModal }) {
  const { token, adminlogout } = useAuth();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/update_faq_category`;
  const [formData, setFormData] = useState({
    category_name:data.category_name,
  });

  
  const loadedOnce = useRef(false);




  

  const closeAd = () => {
    // update the ad

    // api to close and then navigaet that id
    if (!token) {
      // If token is not present in the context, try to get it from local storage
      const storedToken = localStorage.getItem("token"); // Replace 'yourTokenKey' with the actual key you used for storing the token
      if (storedToken) {
        handleedit(JSON.parse(storedToken));
      }
    } else {
      handleedit(token);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleedit = async (tokens) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokens}`,
    };

    const statusinfo={
        faq_id:id
    }


    const bodyinfo = {
      category_name: formData.category_name,

    };

    console.log(bodyinfo);

    await axios
      .patch(additionalPath, bodyinfo,{params:statusinfo,headers: headers })
      .then((response) => {
        if (response.data.responsemessage) {
          console.log("Added");
        } else {
          console.log("Egbamiii");
        }

        handleModal();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs

            adminlogout();
            navigate("/admin")
          console.error(err);
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });


  };
  return (
    <div>
      <Modal show={open} size="md" popup onClose={handleModal}>
        <Modal.Header>Edit FAQ Category</Modal.Header>
        <Modal.Body>
          <div >


           
              <div className="mt-4 flex flex-col gap-2">
                <label htmlFor="question" className="font-bold">Category Name</label>
                <input
                  type="text"
                  name="category_name"
                  placeholder="Add category"
                  value={formData.category_name}
                  onChange={handleInputChange}
                  class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
                />

              

                <div className="justify-end items-end flex">
                  <Button
                    className="bg-normalblue p-2 text-white  mt-4 w-1/2"
                    onClick={() => closeAd()}
                  >
                    Edit FAQ CAtegory
                  </Button>
                </div>
              </div>
     
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default FAQEditCategoryModal;
