import React, { useState } from "react";
import { Button, TextInput } from "flowbite-react";

function Newsletter() {
  const [Subscribe, setSubscribe] = useState(false);
  const [Err, setErr] = useState(false);
  const [error, setErrors] = useState("");
  const [formData,setFormData]=useState({email:""})
  const [email, setEmail] = useState("");

  const handleemail = (e) => {
    if(Err){
      setErr(false)
    }
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const emailRegex = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
  const isValidEmail = (email) => {
    return emailRegex.test(email);
  };
  const handlesubscribe = (e) => {
    

    if (formData.email.length != 0 && isValidEmail(formData.email)) {
      // perfom function
      setSubscribe(true);
      setTimeout(() => {
        setSubscribe(false);
      }, 3000);
    } else {
      setErr(true);
      setErrors("Please enter a valid Email");
      setTimeout(() => {
        setErr(false);
        setErrors("");
      }, 3000);
    }
  };

  return (
    <div className="bg-lightblue/20 text-center py-8 w-full">
      <p className="sm:text-4xl text-xl ">
        Subscribe to our <span className="text-normalblue font-bold">News</span>
        <span className="text-normalbrown font-bold">letter</span>
      </p>
      <p className="text-darkgray text-xs sm:text-base">
        Get all the updates and marketing emails on KriffeOnline
      </p>
      <div className="flex justify-center mt-2 gap-2">
        <TextInput
          id="email2"
          placeholder="Enter a valid email address"
          required
          type="email"
          color="gray"
          className="sm:w-1/3 w-1/2 sm:text-sm text-xs"
          sizing="lg"
          name="email"
          value={formData.email}
          onChange={handleemail}
        />
        <Button
          type="button"
          onClick={handlesubscribe}
          className="bg-normalbrown rounded-md h-14 w-28"
        >
          Subscribe
        </Button>
      </div>
      {Subscribe && (
        <p className="text-green-500 font-medium">
          You have successfully subscribed to our newsletter!
        </p>
      )}
      {Err && (
        <p className="text-red-500 font-medium">
         {error}
        </p>
      )}
    </div>
  );
}

export default Newsletter;
