import React, { useState,useEffect } from "react";
import TopBanner from "../../components/Navigation/TopBanner";
import NavigationBar from "../../components/Navigation/NavigationBar";
import SearchBarCollapse from "../../components/SearchBars/SearchBarCollapse";
import logo from "../../assets/images/KRIFFEONLINELOGO2.png";
import { useAuth } from "../../context/AuthContext";
import MarketplaceNav from "../../components/Navigation/MarketplaceNav";
import MarketplaceBody from "../../components/MarketplaceBody";



function Marketplace() {
  const { isLogged,token} = useAuth();
  const [CategoryType,setCategoryType]=useState('');
  const [sortBy,setSortBy]=useState('');



  return (
    <section className="w-screen ">
      <TopBanner />
      <NavigationBar logo={logo} color="text-midnightblue" status={isLogged} />
      <SearchBarCollapse />

      <div className="sm:block hidden">
        <hr></hr>
        <MarketplaceNav setCategoryType={setCategoryType} setSortBy={setSortBy}/>
      </div>

      <div>
      <MarketplaceBody CategoryType={CategoryType} sortBy={sortBy}/>

      </div>


     

      

      
    </section>
  );
}
export default Marketplace;
