import React, { createContext, useContext, useState } from "react";

const EventOrganizerContext = createContext();

export const useEventOrganizerContext = () => {
  return useContext(EventOrganizerContext);
};

export const EventOrganizerProvider = ({ children }) => {

  // data by host
  const [eventData, setEventData] = useState([]);


  const [eventorders, setOrder] = useState([]);

  const addNewEvent = (event) => {
    setEventData(event);
  };

  const getEventById = (id) => {
    return eventData.find((event) => event.id === id);
  };

  // function that gets events per the host id
  const getOrdersById = (id) => {
    return eventorders.filter((order) => order.event_id === id);
  };

  const getOrderById = (id) => {
    return eventorders.find((event) => event.order_id === id);
  };

  // function that gets the info of one event and alsoget the orders underneath each

  const [temporaryEventInfo, setTemporaryEventInfo] = useState({
    name: "",
    date: "",
    time:"", // Date and time in ISO format
    location: "",
    host:"",
    description:"",
    summary:"",
    number: "",
    email: "",
    event_category: null,
    type_select:"",
    images:[],
    link:[],
    event_type:[],
    ticket_type:[]
  });

  




  
 
  

  // "ticket_type":[
  //     {
  //         "name":"Test ticket",
  //         "description":"loreum ipas moutem das das rasha ",
  //         "package_info":"more loreum mutila msuwq",
  //         "price":"100",
  //         "package_type":"paid",
  //         "number_available":"20"

  //     }
  // ]

  // Function to add ticket information to the temporary state
  const addTemporaryEventInfo = (newData) => {
    setTemporaryEventInfo((prevData) => {
      const updatedData = {
        name: newData.name || prevData.name,
        date: newData.date || prevData.date,
        time: newData.time || prevData.time,
        host: newData.host || prevData.host,
        location: newData.location || prevData.location,
        description: newData.description || prevData.description,
        summary: newData.summary || prevData.summary,
        event_type: newData.event_type || prevData.event_type,
        type_select: newData.type_select || prevData.type_select,
        email: newData.email || prevData.email,
        number: newData.number || prevData.number,
        event_category: newData.event_category || prevData.event_category,
        images: newData.images || prevData.images,
        ticket_type: newData.ticket_type || prevData.ticket_type,
        link: newData.link || prevData.link,
      };

      return updatedData;
    });
  };

  // Function to clear the temporary ticket information
  const clearTemporaryEventInfo = () => {
    setTemporaryEventInfo([]);
  };

  //   function to get the events orders associated with the user

  const [categories, setCategories]=useState([]);




  return (
    <EventOrganizerContext.Provider
      value={{
        eventData,
        eventorders,
        addNewEvent,
        getEventById,
        getOrdersById,
        getOrderById,
        temporaryEventInfo,
        addTemporaryEventInfo,
        clearTemporaryEventInfo,
        categories,setCategories
      }}
    >
      {children}
    </EventOrganizerContext.Provider>
  );
};
