import React, { useEffect, useState } from "react";
import ProductCard from "../Cards/ProductCard";
import { useAuth } from "../../context/AuthContext";
import { useMarketplaceContext } from "../../context/MarketplaceContexts";
import axios from "axios";
import { format, parse } from "date-fns";
import EventsCard from "../Cards/EventsCard";
import { Spinner } from "flowbite-react";
import { useNavigate } from "react-router-dom";

function PastEvents() {
  const { token, logout } = useAuth();
  const [events, setEvents] = useState([]);
  const [err, setErr] = useState("");
  const [loadedOnce, setLoadedOnce] = useState("");
  const navigate=useNavigate();
  useEffect(() => {
    // Make the first API call
    if (!loadedOnce) {
      handlemarketplace();
    }
  }, [loadedOnce]);

  const url =process.env.REACT_APP_API_URL;

  const handlemarketplace = () => {
    const additionalPath = `${url}/get_events_in_past_month`;
    // const path=`get_product_by_id?id=${id}`;
    // makeApiRequest("GET", null, path, token);
    const headers = {
      "Content-Type": "application/json",
      // 'Authorization':  `Bearer ${token}`,
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .get(additionalPath, { headers: headers })
      .then((response) => {
        setEvents(response.data.events.data);
        setLoadedOnce(true);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/")
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
          setErr(err);

          // Rethrow the error for further handling if needed
        }
      });
  };
  return (
    <div className="mb-4">
      {!loadedOnce ? (
        // Show spinner while loading
        <div className="flex flex-col justify-center p-8 items-center">
          {/* Add your spinner component here */}

          <Spinner
            aria-label="Default status example"
            color="warning"
            size="xl"
          />
          <p className="text-darkgray">Loading Past Events....</p>
        </div>
      ) : events && events.length > 0 ? (
        // Display events if data is available
        <div className="flex sm:flex-row flex-col sm:gap-8 gap-4 placeholder pt-2">
          {events.map((event, index) => (
            <EventsCard
              key={index}
              name={event.name}
              month={format(new Date(event.date), "MMM")}
              day={format(new Date(event.date), "dd")}
              location={event.location}
              id={event.event_no}
              images={event.images}
            />
          ))}
        </div>
      ) : (
        // Show "No Past Events" section if no events
        <div className="flex flex-col justify-center p-8 items-center">
          <div className="flex h-20 w-full justify-center">
            <svg viewBox="0 0 512 512" fill="darkgray" height="5em" width="5em">
              {/* Your SVG path here */}
            </svg>
          </div>
          <p className="text-darkgray text-xl text-center">No Past Events</p>
        </div>
      )}
    </div>
  );
}

export default PastEvents;
