import React from "react";

function EventFormStepper({ toggleDiv, visibleDiv }) {
  const circlstyle_active =
    "flex items-center justify-center w-10 h-10 bg-white text-lightblue border-2 border-lightblue rounded-full lg:h-12 lg:w-12  text-xl font-extrabold shrink-0";
  const circlstyle =
    "flex items-center justify-center w-10 h-10 bg-lightblue rounded-full lg:h-12 lg:w-12  shrink-0 text-white text-xl font-extrabold";
  const circlstyle_inactive =
    "flex items-center relative justify-center w-10 h-10 bg-lightgray rounded-full lg:h-12 lg:w-12 text-white text-xl font-extrabold shrink-0";
  const borders =
    "flex w-full items-center relative after:content-['']  after:w-full after:h-1 after:border-b after:border-lightgray after:border-4 after:inline-block";
  const border_active =
    "flex w-full items-center relative after:content-['']  after:w-full after:h-1 after:border-b after:border-lightblue after:border-4 after:inline-block ";
  const text_active =
    "absolute top-0 -ml-10  text-center mt-14 w-42  text-xs font-bold uppercase text-normalblue sm:block hidden";
  const text =
    "absolute top-0 -ml-10  text-center mt-14 w-42  text-xs font-bold uppercase text-lightgray sm:block hidden";
  return (
    <div className="w-full sm:mt-0 mt-5 mb-12  px-3 ">
      <ol class="flex items-center w-full mb-4 sm:mb-5 sm:pl-10 pl-5">
        <li
          class={
            visibleDiv == "event" ||
            visibleDiv == "ticket" ||
            visibleDiv == "media" ||
            visibleDiv == "review"
              ? border_active
              : borders
          }
          onClick={() => toggleDiv("basic")}
        >
          <div class={visibleDiv == "basic" ? circlstyle_active : circlstyle}>
            1
          </div>
          <div class="absolute top-0 -ml-10  text-center mt-14 w-32  text-xs font-bold uppercase text-normalblue sm:block hidden">
            Basic Details
          </div>
        </li>
        <li
          class={
            visibleDiv == "ticket" ||
            visibleDiv == "media" ||
            visibleDiv == "review"
              ? border_active
              : borders
          }
        >
          <a
            href="#"
            onClick={() => {
              if (
                visibleDiv === "ticket" ||
                visibleDiv === "media" ||
                visibleDiv === "review"
              ) {
                // Allow going back to the previous step if currently on "contact", "payment", or "receipt"
                toggleDiv("event");
              }
            }}
          >
            <div
              class={
                visibleDiv == "event"
                  ? circlstyle_active
                  : visibleDiv == "basic"
                  ? circlstyle_inactive
                  : circlstyle
              }
            >
              2
            </div>
            <div
              class={
                visibleDiv == "event" ||
                visibleDiv == "ticket" ||
                visibleDiv == "media" ||
                visibleDiv == "review"
                  ? text_active
                  : text
              }
            >
              Event Details
            </div>
          </a>
        </li>
        <li
          class={
            visibleDiv == "media" || visibleDiv == "review"
              ? border_active
              : borders
          }
        >
          <a
            href="#"
            onClick={() => {
              if (visibleDiv === "media" || visibleDiv === "review") {
                // Allow going back to the previous step if currently on "contact", "payment", or "receipt"
                toggleDiv("ticket");
              }
            }}
            disabled={visibleDiv === "event"}
          >
            <div
              class={
                visibleDiv == "ticket"
                  ? circlstyle_active
                  : visibleDiv == "basic" || visibleDiv == "event"
                  ? circlstyle_inactive
                  : circlstyle
              }
            >
              3
            </div>
            <div
              class={
                visibleDiv == "ticket" ||
                visibleDiv == "media" ||
                visibleDiv == "review"
                  ? text_active
                  : text
              }
            >
              Ticket Details
            </div>
          </a>
        </li>
        <li class={visibleDiv == "review" ? border_active : borders}>
          <a
            href="#"
            onClick={() => {
              if (visibleDiv === "review") {
                // Allow going back to the previous step if currently on "contact", "payment", or "receipt"
                toggleDiv("media");
              }
            }}
            disabled={visibleDiv === "ticket" || visibleDiv === "review"}
          >
            <div
              class={
                visibleDiv == "media"
                  ? circlstyle_active
                  : visibleDiv == "review"
                  ? circlstyle
                  : circlstyle_inactive
              }
            >
              4
            </div>
            <div class={visibleDiv == "review" ? text_active : text}>
              Media Integration
            </div>
          </a>
        </li>
        <li class="flex w-full items-center relative after:w-0 after:content-['']">
          <a
            href="#"
            onClick={() => {
              if (visibleDiv === "review") {
                // Allow going back to the previous step if currently on "contact", "payment", or "receipt"
                toggleDiv("review");
              }
            }}
            disabled={visibleDiv === "media"}
          >
            <div
              class={
                visibleDiv == "review" ? circlstyle_active : circlstyle_inactive
              }
            >
              5
            </div>
            <div class={visibleDiv == "review" ? text_active : text}>
              Review and Post
            </div>
          </a>
        </li>
      </ol>
    </div>
  );
}

export default EventFormStepper;
