import React, { useState, useEffect } from "react";
import { Dropdown, Avatar } from "flowbite-react";
import AuthenticationModal from "../Modal/AuthenticationModal";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../api/APIProvider";
import axios from "axios";

function UserSettings({ color }) {
  const {
    isLogged,
    isModalVisible,
    toggleModal,
    logout,
    user,
    token,
    userlog,
  } = useAuth();
  const url =process.env.REACT_APP_API_URL;
  const { makeApiRequest, data } = useApi();
  const navigate = useNavigate();
  const handleloggedout = () => {
    // logout
    logout();
    navigate("/");
  };

  const text = isLogged ? `Hi,${user.fullname.split(" ")[0]}` : "Account";

  // Determine the text color class based on the color parameter
  const textColorClass =
    color === "w-5 h-5 text-white" ? "text-white" : "text-midnightblue";

  // Determine the dropdown icon color class based on the color parameter
  const dropdownIconColorClass =
    color === "w-5 h-5 text-white" ? "text-white" : "text-midnightblue";

  const modals = () => {
    if (!isLogged && !isModalVisible) {
      toggleModal();
    }
  };
  return (
    <Dropdown
      dismissOnClick={false}
      renderTrigger={() => (
        <button className="inline-flex items-center text-sm font-medium text-center text-white  focus:outline-none dark:hover:text-white dark:text-gray-400">
          <svg
            class={color}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 18"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"
            />
          </svg>
          <p className={`lg:block hidden ${textColorClass}`}>{text}</p>
          <svg
            class={`ml-1 w-3 h-3 ${dropdownIconColorClass}`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 8"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
            />
          </svg>
        </button>
      )}
    >
      {isLogged ? (
        <div>
          <Dropdown.Header>
            <span className="block text-sm">{user.fullname}</span>
          </Dropdown.Header>
          <Dropdown.Item>
            <a href="/account/profile">Profile</a>
          </Dropdown.Item>
          <Dropdown.Item>
            <a href="/account/saved">Saved Items</a>
          </Dropdown.Item>
          <Dropdown.Item>
            <a href="/account/ads">Ads</a>
          </Dropdown.Item>
          <Dropdown.Item>
            <a href="/account/orders">Orders</a>
          </Dropdown.Item>
          <Dropdown.Item>
            <a href="/account/inbox">Inbox</a>
          </Dropdown.Item>
          <Dropdown.Item>
            <a href="/account/events">Event Management</a>
          </Dropdown.Item>
          <Dropdown.Item>
            <a href="/account/settings">Settings</a>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item>
            <a href="/account/settings">
              <a
                href="#"
                onClick={handleloggedout}
                className="text-red-600 flex gap-2"
              >
                <svg
                  class="w-5 h-5 text-red-600 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 15"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M1 7.5h11m0 0L8 3.786M12 7.5l-4 3.714M12 1h3c.53 0 1.04.196 1.414.544.375.348.586.82.586 1.313v9.286c0 .492-.21.965-.586 1.313A2.081 2.081 0 0 1 15 14h-3"
                  />
                </svg>
                Logout
              </a>
            </a>
          </Dropdown.Item>
        </div>
      ) : (
        <div>
          <Dropdown.Header>
            <span className="block text-sm">Account</span>
          </Dropdown.Header>
          <Dropdown.Item>
            <a href="#" onClick={modals} className="text-normalblue flex gap-2">
              <svg
                class="w-5 h-5 text-normalblue "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 15"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M1 7.5h11m0 0L8 3.786M12 7.5l-4 3.714M12 1h3c.53 0 1.04.196 1.414.544.375.348.586.82.586 1.313v9.286c0 .492-.21.965-.586 1.313A2.081 2.081 0 0 1 15 14h-3"
                />
              </svg>
              Register/Login
            </a>
          </Dropdown.Item>
        </div>
      )}
      {isModalVisible && <AuthenticationModal />}
    </Dropdown>
  );
}
export default UserSettings;
