import axios from 'axios';
const API_URL=process.env.REACT_APP_API_URL;




export const fetchDataForCategory = async (logout) => {
    const addtional_path=`${API_URL}/get_product_categories`;
    try {
      const response = await axios.get(addtional_path);
      return response.data; // Return fetched category data
    } catch (error) {

      if (error.response && error.response.status === 401) {
        // Perform actions when 401 (Unauthorized) error occurs
        console.log(
          "Unauthorized: Logging user out or performing specific action..."
        );
        logout();
        // Example: Log the user out or redirect to login page
        // You may perform the necessary actions here based on your application flow
      } else {
        // Handle other errors
        console.log(error);
        return null;


      }
    }
  };


  export const fetchDataForFlexibility = async (logout) => {
    const addtional_path=`${API_URL}/get_product_flexibility`;
    try {
      const response = await axios.get(addtional_path);
      return response.data; // Return fetched category data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Perform actions when 401 (Unauthorized) error occurs
        console.log(
          "Unauthorized: Logging user out or performing specific action..."
        );
        logout();
        // Example: Log the user out or redirect to login page
        // You may perform the necessary actions here based on your application flow
      } else {
        // Handle other errors
        console.log(error);
        return null;


      }
    }
  };


  export const fetchDataForCondition = async (logout) => {
    const addtional_path=`${API_URL}/get_product_condition`;
    try {
      const response = await axios.get(addtional_path);
      return response.data; // Return fetched category data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Perform actions when 401 (Unauthorized) error occurs
        console.log(
          "Unauthorized: Logging user out or performing specific action..."
        );
        logout();
        // Example: Log the user out or redirect to login page
        // You may perform the necessary actions here based on your application flow
      } else {
        // Handle other errors
        console.log(error);
        return null;


      }
    }
  };




// location

  export const fetchDataForStyle = async (logout) => {
    const addtional_path=`${API_URL}/get_style`;
    try {
      const response = await axios.get(addtional_path);
      return response.data; // Return fetched category data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Perform actions when 401 (Unauthorized) error occurs
        console.log(
          "Unauthorized: Logging user out or performing specific action..."
        );
        logout();
        // Example: Log the user out or redirect to login page
        // You may perform the necessary actions here based on your application flow
      } else {
        // Handle other errors
        console.log(error);
        return null;


      }
    }
  };

  export const fetchDataForLanguage = async (logout) => {
    const addtional_path=`${API_URL}/get_languages`;
    try {
      const response = await axios.get(addtional_path);
      return response.data; // Return fetched category data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Perform actions when 401 (Unauthorized) error occurs
        console.log(
          "Unauthorized: Logging user out or performing specific action..."
        );
        logout();
        // Example: Log the user out or redirect to login page
        // You may perform the necessary actions here based on your application flow
      } else {
        // Handle other errors
        console.log(error);
        return null;


      }
    }
  };

  export const fetchDataForFormat= async (logout) => {
    const addtional_path=`${API_URL}/get_formats`;
    try {
      const response = await axios.get(addtional_path);
      return response.data; // Return fetched category data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Perform actions when 401 (Unauthorized) error occurs
        console.log(
          "Unauthorized: Logging user out or performing specific action..."
        );
        logout();
        // Example: Log the user out or redirect to login page
        // You may perform the necessary actions here based on your application flow
      } else {
        // Handle other errors
        console.log(error);
        return null;

      }
      }
  };

  export const fetchDataForMaterial = async (logout) => {
    const addtional_path=`${API_URL}/get_materials`;
    try {
      const response = await axios.get(addtional_path);
      return response.data; // Return fetched category data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Perform actions when 401 (Unauthorized) error occurs
        console.log(
          "Unauthorized: Logging user out or performing specific action..."
        );
        logout();
        // Example: Log the user out or redirect to login page
        // You may perform the necessary actions here based on your application flow
      } else {
        // Handle other errors
        console.log(error);
        return null;


      }
    }
  };

  export const fetchDataForLocation = async (logout) => {
    const addtional_path=`${API_URL}/get_regions`;
    try {
      const response = await axios.get(addtional_path);
      return response.data; // Return fetched category data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Perform actions when 401 (Unauthorized) error occurs
        console.log(
          "Unauthorized: Logging user out or performing specific action..."
        );
        logout();
        // Example: Log the user out or redirect to login page
        // You may perform the necessary actions here based on your application flow
      } else {
        // Handle other errors
        console.log(error);
        return null;


      }
    }
  };

  export const fetchDataForAge = async (logout) => {
    const addtional_path=`${API_URL}/get_age_groups`;
    try {
      const response = await axios.get(addtional_path);
      return response.data; // Return fetched category data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Perform actions when 401 (Unauthorized) error occurs
        console.log(
          "Unauthorized: Logging user out or performing specific action..."
        );
        logout();
        // Example: Log the user out or redirect to login page
        // You may perform the necessary actions here based on your application flow
      } else {
        // Handle other errors
        console.log(error);
        return null;


      }
    }
  };
  

  export const fetchDataForEventCategory = async (logout) => {
    const addtional_path=`${API_URL}/get_event_categories`;
    try {
      const response = await axios.get(addtional_path);
      return response.data; // Return fetched category data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Perform actions when 401 (Unauthorized) error occurs
        console.log(
          "Unauthorized: Logging user out or performing specific action..."
        );
        logout();
        // Example: Log the user out or redirect to login page
        // You may perform the necessary actions here based on your application flow
      } else {
        // Handle other errors
        console.log(error);
        return null;


      }
    }
  };


  


  


  


