import React,{useRef,useState} from "react";
import { FileInput,Button } from "flowbite-react";
import AWS from 'aws-sdk';



function TestFielUpload(){
    const fileInputRef = useRef(null);
    const maxFileCount = 3; // Maximum number of files allowed
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif']; // Allowed file extensions
    const maxFileSize = 1024 * 1024; // Maximum file size in bytes (1MB)
    const [error,setError]=useState("");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    
    const handleDrop = async (e) => {
        const files = e.target.files;
  
    if (uploadedFiles.length + files.length > maxFileCount) {
      setError(`You can only select up to ${maxFileCount} files.`);
      fileInputRef.current.value = '';
    } else {
        try {
          AWS.config.update({
            accessKeyId: "DO00JYWNXZG6EYV2J4JT",
            secretAccessKey: "QARFr7ME28EltUtflEWNF/2OzR92JJjL1TWjQi+A5po",
            region: "blr1",
          });
    
          const spacesEndpoint = new AWS.Endpoint(
            `blr1.digitaloceanspaces.com`
          );
    
          const s3 = new AWS.S3({
            endpoint: spacesEndpoint,
          });
    
          for (const file of files) {
            const params = {
              Bucket: 'kriffeonline-assets',
              Key: `dev/products/${file.name}`,
              Body: file,
              ACL: 'public-read',
            };
    
            const uploadResult = await s3.upload(params).promise();
            setUploadedFiles((prevFiles) => [...prevFiles, uploadResult.Location]);
            console.log(uploadResult.Location);
          }
        } catch (error) {
          console.error('Error uploading files:', error);
        }
    }
      };
    return(
    <div>
        <FileInput
        helperText={error!=""?(<p className="text-red-600 font-xl font-bold">{error}</p>):("Images allowed png,jpg,jpeg")}
        id="file"
        class=" block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full "
        type="file"
        multiple
        accept=".jpg, .jpeg, .png"
        onChange={handleDrop}
        ref={fileInputRef}
        name="product_images"
     
        
      />
 

      <p>
        {uploadedFiles.length!=0&&uploadedFiles.map((file,index)=>(
            <div key={index} className="uploaded-image">
            <img src={file} alt={`Uploaded ${index}`} style={{ maxWidth: '200px' }} />
          </div>
        ))}
      </p>


    </div>)
}

export default TestFielUpload;