import React, { useState, useEffect } from "react";
import { Label, Radio, Button, Spinner } from "flowbite-react";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import { useMarketplaceContext } from "../../context/MarketplaceContexts";
import PaystackPayment from "../PayStack/PayStackPayment";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

function AdChoiceEventForm({ adtypes, productid, setUpdated }) {
  const { token, user, logout } = useAuth();
  const { adtype, setAdtype } = useMarketplaceContext();
  const [product, setProduct] = useState([]);
  const selectedSpec = product && product.find((spec) => spec.name === adtypes);
  const navigate = useNavigate();

  const [specification, setSpecification] = useState(
    selectedSpec ? selectedSpec.name : adtypes
  );
  const [selectedId, setSelectedId] = useState(
    selectedSpec ? selectedSpec.id : 1
  );

  const unchecked =
    "flex items-center justify-between border-2 border-lightgray rounded-md p-6";
  const checked =
    "flex items-center justify-between border-2 border-green-600 rounded-md p-6";
  const textcheck = "text-lg text-green-600";
  const textuncheck = "text-lg text-darkgray";
  const [err, setErr] = useState("");
  const [update, setUpdates] = useState(false);
  const [success, setPayment] = useState(false);
  const [amount, setAmount] = useState(0);
  const [adss, setAds] = useState(false);

  useEffect(() => {
    // Make the first API call
    if (adtype.length == 0) {
      handlemarketplace();
    }
  }, [adtype]);

  // get_ad_types
  const url = process.env.REACT_APP_API_URL;

  const handlemarketplace = () => {
    const additionalPath = `${url}/get_ad_types`;
    // const path=`get_product_by_id?id=${id}`;
    // makeApiRequest("GET", null, path, token);
    const headers = {
      "Content-Type": "application/json",
      // 'Authorization':  `Bearer ${token}`,
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .get(additionalPath)
      .then((response) => {
        setProduct(response.data);
        setAdtype(response.data);
        setAds(true)
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/");
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
          setErr(err);

          // Rethrow the error for further handling if needed
        }
      });
  };

  const handleStatusChange = (e) => {
    setSpecification(e.target.value);
    const selectedSpec = adtype.find((spec) => spec.name === e.target.value);

    // Store the selected spec's ID in the selectedId state
    if (selectedSpec) {
      setSelectedId(selectedSpec.id);
      setAmount(selectedSpec.price);
    }
  };

  const info = {
    ad_type: selectedId,
    event_id: productid,
  };

  const loadedOnce = useRef(false);
  const updateOnce = useRef(false);
  const [checkouturl, setCheckout] = useState(null);

  useEffect(() => {
    if (checkouturl !== null && loadedOnce.current === false) {
      window.open(checkouturl, "_self");
      loadedOnce.current = true;
    }
  }, [checkouturl, loadedOnce]);

  const handleChanges = () => {
    updateOnce.current = true;
    // add to the temp array

    // console.log(info);

    // call the paystack api and pay and after success call the update expiry api
    // update_product?product_id=PRD-20231113-GrYP

    const additionalPath = `${url}/initiate_payment_event_ad`;
    const headers = {
      "Content-Type": "application/json",
      // 'Authorization':  `Bearer ${token}`,
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (amount === 0) {
      // setUpdates(true);
      setUpdated(false);
      navigate(
        `/eventorganizer/event/${productid}/true?description=${selectedId}`
      );
    } else {
      axios
        .post(additionalPath, info, { headers: headers })
        .then((response) => {
          // setProduct(response.data);
          if (response.data !== null) {
            loadedOnce.current = false;
            setCheckout(response.data.data.links.checkout_url);

            // window.location.href= response.data.checkout_url;
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            // Perform actions when 401 (Unauthorized) error occurs
            console.log(
              "Unauthorized: Logging user out or performing specific action..."
            );
            logout();
            navigate("/");
            // Example: Log the user out or redirect to login page
            // You may perform the necessary actions here based on your application flow
          } else {
            // Handle other errors
            console.log(err);

            // Rethrow the error for further handling if needed
          }
        });
    }
    // update the product ifrst, if successful then call the paystack api an pay and if succesful call update
  };

  useEffect(() => {
    if (update) {
      console.log("ei");
      handleupdated();
    }
  }, [update]);

  const handleupdated = () => {
    // add to the temp array

    let infos = {
      event_id: productid,
    };

    // call the paystack api and pay and after success call the update expiry api
    // update_product?product_id=PRD-20231113-GrYP
    const additionalPath = `${url}/update_product_expiry_date`;
    const headers = {
      "Content-Type": "application/json",
      // 'Authorization':  `Bearer ${token}`,
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .patch(additionalPath, infos, { headers: headers })
      .then((response) => {
        // setProduct(response.data);
        console.log(response.data);
        if (response.responsecode === 200) {
          setUpdated(true);
          // set loader to false or show alert
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/");
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);

          // Rethrow the error for further handling if needed
        }
      });

    // update the product ifrst, if successful then call the paystack api an pay and if succesful call update
  };

  return (
    <div className="flex flex-col justify-center items-center  h-full">

      <form className="flex w-full flex-col gap-4 relative">
        {!adss&&adtype.length===0?(
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70 z-50">
            <div className="p-4 max-w-md mx-auto rounded-md">
              {/* Your spinner component */}
              <Spinner
                aria-label="Default status example"
                color="warning"
                size="xl"
              />
       
            </div>
          </div>
        ):(
        <fieldset className="flex w-full flex-col gap-3" id="radio">
          {adtype.map((spec, index) => (
            <p>
              <div
                key={index}
                className={specification == spec.name ? checked : unchecked}
              >
                <Label
                  htmlFor="standard"
                  className={
                    specification == spec.name ? textcheck : textuncheck
                  }
                >
                  {spec.name} Ad-GHC {spec.price}
                  <p className="text-xs text-darkgray font-normal">
                    {spec.description}
                  </p>
                </Label>

                {specification === spec.name ? (
                  <svg
                    class="w-6 h-6 text-green-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                ) : (
                  <Radio
                    id="standard"
                    name={spec.name}
                    value={spec.name}
                    checked={specification === spec.name}
                    onChange={handleStatusChange}
                  />
                )}
              </div>
            </p>
          ))}
        </fieldset>
        )}
      </form>

      <div className="flex justify-end w-full mt-2 ">
        <Button
          type="button"
          className="bg-normalblue sm:p-4 p-2 rounded-md relative"
          onClick={handleChanges}
        >
          Update Ad
          {updateOnce.current && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-70 z-50">
            <div className="p-4 max-w-md mx-auto rounded-md">
              {/* Your spinner component */}
              <Spinner
                aria-label="Default status example"
                color="warning"
                size="xl"
              />
       
            </div>
          </div>
        )}
        </Button>
        
      </div>
    </div>
  );
}
export default AdChoiceEventForm;
