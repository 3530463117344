import React,{useState,useRef,useEffect} from "react";
import { Button, Label, TextInput,Datepicker } from "flowbite-react";
import { useMarketplaceContext } from "../../../context/MarketplaceContext";
import FileUpload from "../Ads/FileUpload";
import { useEventOrganizerContext } from "../../../context/EventOrganizerContext";

function EventDetailsForm({toggleDiv,addProduct,setAdd}) {
    const [selectedCategory, setSelectedCategory] = useState('');
    // const {categories,locations,temporaryAdInfo,addTemporaryAdInfo}=useMarketplaceContext();
    const{temporaryEventInfo,addTemporaryEventInfo,categories}=useEventOrganizerContext();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [productdetails, setDetails] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [formData, setFormData] = useState({
        email:"",
        number:"",
        event_type:[],
        type_select:""


});

    const info={
        email:formData.email,
        number:formData.number,
        event_type:formData.event_type,
        type_select:formData.type_select
        
      }
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
  const handleCategoryChange = (event) => {

    const selectedValue = event.target.value;

    // Update the selected option state
    setSelectedOption(selectedValue);

    // Create a copy of the formData state
    const updatedFormData = { ...formData };

    // Update the event_type array based on the selected option
    if (selectedValue === 'Free') {
      updatedFormData.event_type = ['Free'];
      updatedFormData.type_select="Free";
    } else if (selectedValue === 'Paid') {
      updatedFormData.event_type = ['Paid'];
      updatedFormData.type_select="Paid";
    } else if (selectedValue === 'Both') {
      updatedFormData.event_type = ['Free', 'Paid'];
      updatedFormData.type_select="Both";
    } else {
      updatedFormData.event_type = []; // Reset the event_type array if none is selected
      updatedFormData.type_select="";
    }

    // Update the state with the modified formData object
    setFormData(updatedFormData);
    
    
  };

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    // Check if all required fields are filled
    const requiredFields = ["email", "number", "type_select"];

    const isFormValid = requiredFields.every((field) => formData[field] !== "");
    setIsFormValid(isFormValid);
  }, [formData]);


  const handleChanges=()=>{
    // save into the temp array
    console.log(info);
    addTemporaryEventInfo(info);
    toggleDiv("ticket")
  }

  return (
    <div className="flex justify-center items-center">
      <form className="flex w-full flex-col gap-4 ">
        <div>
        <div className="mb-2 block">
          <Label htmlFor="email" value={<div><p className="font-bold text-midnightblue">Event Email</p><p className="text-darkgray">The email address that can be used as a point of contact about the event</p></div>} />
        </div>
          
          <TextInput
            id="email"
            name="email"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            onChange={handleInputChange}
            value={temporaryEventInfo.email!=""?(temporaryEventInfo.email):(formData.email)}
          />
        </div>

        <div>
        <div className="mb-2 block">
          <Label htmlFor="phoneNumber" value={<div><p className="font-bold text-midnightblue">Event Phone Number</p><p className="text-darkgray">The Phone number associated with the event which users can contact you on </p></div>} />
        </div>
          
          <TextInput
            id="phoneNumber"
            name="number"
            required
            type="text"
            class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10"
            onChange={handleInputChange}
            value={temporaryEventInfo.number!=""?(temporaryEventInfo.number):(formData.number)}
            maxlength={10}
          />
        </div>

        <div>
            
        <div className="mb-2 block">
          <Label htmlFor="eventType" value={<div><p className="font-bold text-midnightblue">Event Type</p><p className="text-darkgray">Choose if it is an entirely free or paid event or if it is a mix of free and paid tickets choose</p></div>} />
        </div>

          
       
        
    
          
        <select  name="eventType" id="category" value={temporaryEventInfo.type_select!=""?(temporaryEventInfo.type_select):(formData.type_select)} onChange={handleCategoryChange} class="block mb-2 text-sm font-medium text-darkgray border border-lightblue rounded-md w-full sm:h-16 h-10">
        <option value="">Select a type</option>

          <option value="Free" >Free</option>
          <option value="Both" >Free&Paid</option>
          <option value="Paid" >Paid</option>
          

       
      </select>
        </div>


    <div className="flex">
    <Button type="button" className={`justify-end bg-normalblue sm:p-4 p-2 rounded-md ${
              !isFormValid ? "opacity-50 cursor-not-allowed" : ""
            }`} onClick={handleChanges}>Save Changes & Next</Button>
    </div>

        
      </form>
    </div>
  );
}

export default EventDetailsForm;
