import React,{useState,useEffect} from "react";
import { Button, Checkbox , Label, Modal, TextInput } from "flowbite-react";
import { useApi } from "../../api/APIProvider";

function ForgotEmail(){
    const [emailcheck, setEmail] = useState(false);
    const { makeApiRequest, data, error } = useApi();
    const [MsgE, setMsgE] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    }
     const handlePasswordChange = () => {
    if (formData.email.length>0) {
      const additionalPath=`send_reset_password_link?email=${formData.email}`;
      makeApiRequest("POST",null,additionalPath);
      
    } else {
      console.log("Check email syntax");
    }
  };

  useEffect(() => {
    if (data && data.confirmationcodefortest) {
      setEmail(true);
    
      
    }
   
    if(error){
      setMsgE(true)
    }
  }, [ data,error]);
   
    return( <div className="space-y-6 w-[30rem]">
    <div >
      <div className="mb-2 block">
        <Label
          htmlFor="email"
          value="Enter the email associated with your account"
        />
      </div>
       
        <TextInput
          id="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleInputChange}
          name="email"
          helperText={emailcheck?<p className="text-green-600 font-medium">Alright!Check your email for the link</p>:null}
          class={emailcheck?("block mb-2 text-sm font-medium text-green-600 border-green-600 bg-green-100 rounded-md w-full  sm:h-14 h-10 p-1"):("block mb-2 text-sm font-medium text-darkgray border-none bg-lightgray rounded-md sm:w-full sm:h-14 h-10 p-1")}
          sizing="lg"
          required
        />
    
    </div>
    <div className="flex w-full  ">
      <Button
        onClick={handlePasswordChange}
        className="bg-normalblue w-1/2 p-1"
      >
        Send email
      </Button>
    </div>
  </div>)
}

export default ForgotEmail;