import React, { useState } from "react";
import { Button,Label,TextInput } from 'flowbite-react';
import { useAuth } from "../../context/AuthContext";

function OrganizerBankForm(){
    const {organizer}=useAuth();
    const[update,setUpdate]=useState(false);
    const [formData, setFormData] = useState({
      Mobile_Money:''
    });
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
    
    return(
        <div className=" mt-4 space-y-8">
        <div className="grid sm:grid-cols-2 grid-rows-1 gap-4">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="company" value="Bank name" />
              </div>
              <TextInput id="company"  name="company" value={organizer.Bank_name}  readOnly />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="location" value="Bank Account Name" />
              </div>
              <TextInput id="location"  name="lastname" value={organizer.Bank_account_name} readOnly />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="phone" value="Bank Account Number" />
              </div>
              <TextInput id="phone"  name="phonenumber" value={organizer.Bank_account_number}
            readOnly />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email" value="Mobile Money Number" />
              </div>
              <TextInput id="email"  name="momo" value=""
             onChange={handleInputChange}  required />
            </div>
            </div>

            
     
          </div>
    )
}
export default OrganizerBankForm;