import React, { useState, useEffect, useMemo } from "react";
import TopBanner from "../../components/Navigation/TopBanner";
import NavigationBar from "../../components/Navigation/NavigationBar";
import SearchBarCollapse from "../../components/SearchBars/SearchBarCollapse";
import logo from "../../assets/images/KRIFFEONLINELOGO2.png";
import { useAuth } from "../../context/AuthContext";
import { useEventContexts } from "../../context/EventContexts";
import EventCarousel from "../../components/Carousels/EventCarousel";
import {
  format,
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import EventsCard from "../../components/Cards/EventsCard";
import axios from "axios";
import { fetchDataForEventCategory } from "../../api/ApiPage";
import MainPagination from "../../components/Miscellanous/MainPagination";
import PastEvents from "../../components/Miscellanous/PastEvents";
import {
  Spinner,
} from "flowbite-react";

const Events = () => {
  const { isLogged, token, logout } = useAuth();
  const { eventData, getEvent, categories, setCategories, eventtype } =
    useEventContexts();
  const [selectedCategories, setSelectedCategories] = useState(0);
  const [selectedEventTypes, setSelectedEventTypes] = useState(null);
  const [selectedtype, setSelectedtype] = useState(null); // Use an array for multi-select event types
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [changeCounter, setChangeCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const handleLoadingModal = () => {
    setLoading(!loading);
  };

  const url =process.env.REACT_APP_API_URL;
  const additionalPath = `${url}/list_events`;
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization':  `Bearer ${token}`,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  const initialPayload = {
    currentpage: 1,
    event_category: null,
    event_type: null,
    startdate: null,
    enddate: null,
  };

  const [formData, setFormData] = useState(initialPayload);
  const memoizedFormData = useMemo(() => formData, [formData]);
  const [perpage,setPerpage]=useState(1)
  const [total, setTotal] = useState(0);
  const [to_no, setTo] = useState(0);
  const [from_no, setFrom] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      axios
        .get(additionalPath, { params: memoizedFormData, headers: headers })
        .then((response) => {
          // Handle successful response
          console.log(response.data);

          getEvent(response.data.events.data);
          setPerpage(response.data.events.per_page)
          setTotal(response.data.events.total)
          setTo(response.data.events.to)
          setFrom(response.data.events.from)
          setLoadedOnce(true);
          setChangeCounter(0);
          setLoading(false);
        })
        .catch((error) => {
          // Handle error
          console.error("Error:", error);
        });
    };

    if (!loadedOnce) {
      fetchData();
    }
  }, [eventData, memoizedFormData, changeCounter, loadedOnce]);

  useEffect(() => {
    if (categories.length == 0) {
      fetchDataForEventCategory(logout).then((data) => {
        if (data) {
          setCategories(data);
        }
      });
    }
  }, [categories]);

  const handleParamChange = (name, value) => {
    setFormData((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
    setChangeCounter(changeCounter + 1);
    setLoadedOnce(false);
  };

  useEffect(() => {
    if (selectedCategories != null) {
      handleParamChange("event_category", parseInt(selectedCategories));
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        event_category: null,
      }));
      setLoading(false);
    }
    if (selectedEventTypes != null) {
      handleParamChange("event_type", selectedEventTypes);
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        event_type: null,
      }));
      setLoading(false);
    }

    if (selectedDateRange.length > 0) {
      handleParamChange("startdate", selectedDateRange[0].start_date);
      handleParamChange("enddate", selectedDateRange[0].end_date);
    } else {
      setFormData((prevPayload) => ({
        ...prevPayload,
        startdate: null,
        enddate: null,
      }));
    }
  }, [selectedCategories, selectedEventTypes, selectedDateRange]);

  const resetType = () => {
    setFormData(initialPayload);
  };

  const resetParam = () => {
    setFormData(initialPayload);
  };

  // Function to handle category filter change
  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
  };

  // Function to handle event type filter change (multi-select)
  const handleEventTypesChange = (selectedOptions) => {
    setSelectedtype(selectedOptions);
    const includesFree = selectedOptions.includes("free");
    const includesPaid = selectedOptions.includes("paid");

    if (includesFree && !includesPaid) {
      setSelectedEventTypes("free"); // Set 'free' if 'free' is selected and 'paid' is not selected
    } else if (!includesFree && includesPaid) {
      setSelectedEventTypes("paid"); // Set 'paid' if 'paid' is selected and 'free' is not selected
    } else {
      setSelectedEventTypes(null);
      setFormData((prevPayload) => ({
        ...prevPayload,
        event_type: null,
      }));
      // Set null if neither 'free' nor 'paid' is selected or both are selected
    }
  };

  // Function to handle date range filter change
  const handleDateRangeChange = (dateRange) => {
    setSelectedDate(dateRange);
    const today = new Date();
    let startDate = null;
    let endDate = null;

    if (dateRange === "ThisWeek") {
      startDate = startOfWeek(today);
      endDate = endOfWeek(today);
    } else if (dateRange === "ThisMonth") {
      startDate = startOfMonth(today);
      endDate = endOfMonth(today);
    } else if (dateRange === "Today") {
      startDate = today;
    }

    if (startDate !== null || endDate !== null) {
      setSelectedDateRange([
        {
          start_date: startDate ? format(startDate, "yyyy-MM-dd") : null,
          end_date: endDate ? format(endDate, "yyyy-MM-dd") : null,
        },
      ]);
    } else {
      setSelectedDateRange([]);
    }
  };

  const itemsPerPage =  perpage;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = eventData&& total>0&&Math.ceil(total / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    handleParamChange("currentpage", newPage);
  };

  return (
    <section className="w-screen ">
      <TopBanner />
      <NavigationBar logo={logo} color="text-midnightblue" status={isLogged} />
      <SearchBarCollapse />
      <EventCarousel />

      <div className="px-3 ">
        <div className="flex sm:flex-row flex-col justify-between">
          <p className="text-3xl text-normalblue font-medium">
            Upcoming <span className="text-normalbrown">Events</span>
          </p>
          <div className="flex flex-row gap-2">
            <div>
              <select
                value={selectedCategories}
                onChange={(e) =>
                  handleCategoryChange(
                    [...e.target.selectedOptions].map((option) => option.value)
                  )
                }
                className="bg-normalbrown border border-none text-white text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
              >
                {/* option to clear */}
                <option value={0} default>
                  Category
                </option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Event type filter UI (multi-select) */}
            <div>
              <select
                value={selectedtype}
                onChange={(e) =>
                  handleEventTypesChange(
                    [...e.target.selectedOptions].map((option) => option.value)
                  )
                }
                className="bg-normalbrown border border-none text-white text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
              >
                {/* option to clear */}
                <option value="Event Type">Event Type</option>
                {eventtype.map((eventType) => (
                  <option key={eventType.name} value={eventType.name}>
                    {eventType.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Date range filter UI */}
            <div>
              <select
                value={selectedDate}
                onChange={(e) => handleDateRangeChange(e.target.value)}
                className="bg-normalbrown border border-none text-white text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5"
              >
                <option value="All">Date</option>
                <option value="Today">Today</option>
                <option value="ThisWeek">This Week</option>
                <option value="ThisMonth">This Month</option>
              </select>
            </div>
          </div>
        </div>

      

        {!loadedOnce ? (
          // Show spinner while loading
          
            <div className="flex flex-col pt-48 justify-center items-center">
              {/* Add your spinner component here */}
              <Spinner
                aria-label="Default status example"
                color="warning"
                size="xl"
              />
              <p className="text-darkgray">Loading Events....</p>
          
          </div>
        ) : eventData && eventData.length > 0 ? (
          // Display events if data is available
          <div className="grid sm:grid-cols-3 xl:grid-cols-4 mt-4 sm:gap-5 gap-y-4">
            {eventData.map((event, index) => (
              <EventsCard
                key={index}
                name={event.name}
                month={format(new Date(event.date), "MMM")}
                day={format(new Date(event.date), "dd")}
                location={event.location}
                price={event.ticket_type}
                id={event.event_no}
                images={event.images}
              />
            ))}
          </div>
        ) : (
          // Show "No events to display" section if no events
          <div className="flex flex-col pt-48 justify-center items-center">
            <div className="flex h-20 w-full justify-center">
              <svg
                viewBox="0 0 512 512"
                fill="darkgray"
                height="5em"
                width="5em"
              >
                {/* Your SVG path here */}
              </svg>
            </div>
            <p className="text-darkgray text-xl text-center">
              No events to display
            </p>
          </div>
        )}

        <MainPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
        {/* pagination */}
      </div>

      <div className="px-3 pt-10 ">
        <p className="text-3xl text-normalblue font-medium">
          Past <span className="text-normalbrown">Events</span>
        </p>
        <PastEvents />
      </div>
    </section>
  );
};
export default Events;
