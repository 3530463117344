import React from "react";
import arrow from "../../assets/images/RightArrow.png";


function ReadMore({name}){
    return(
        <div className="flex flex-col">
            <p className="text-xs">{name}</p>
            <img src={arrow} className="w-20 h-6"/>
            
        </div>

    );
}

export default ReadMore;