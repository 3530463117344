import React, { useState,useEffect,useRef } from 'react';
import Chart from 'react-apexcharts';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const StackedBarChart = () => {

  // Sample data for the chart (you can replace this with your own data)
  // ratio_of_registered-to_attended
  const {token,logout}=useAuth();
  const navigate=useNavigate()
  const useOnce=useRef(false);
  const [event,setEvents]=useState([]);
  const url =process.env.REACT_APP_API_URL;

  useEffect(()=>{
    // get_total_order
    // 
    if (useOnce.current) return;
    useOnce.current = true;
    handletotaldaily();

  },[]);

  const handletotaldaily=async()=>{
    // get_dashboard_total_revenue
    const additionalPath = `${url}/ratio_of_registered-to_attended`;
  const headers = {
      'Content-Type': 'application/json',
  };

  if(token){
    headers['Authorization'] = `Bearer ${token}`;
  }

  await axios.get(additionalPath, {headers: headers })
      .then(response => {
        console.log(response.data.ratio)
        const ratio=response.data.ratio
     

// Sort events in descending order based on registration count
const sortedEvents = ratio.sort((a, b) => b.registered - a.registered);

// Get only the top 10 events
const top10Events = sortedEvents.slice(0, 10);
        setEvents(top10Events);
          //  setDaily(response.data.order);
      })
      .catch(err => {
                if (err.response && err.response.status === 401) {
                    // Perform actions when 401 (Unauthorized) error occurs
                    console.log('Unauthorized: Logging user out or performing specific action...');
                    logout();
                    navigate("/")
                    // Example: Log the user out or redirect to login page
                    // You may perform the necessary actions here based on your application flow
                  } else {
                    // Handle other errors
                    console.log(err);
                  
                     // Rethrow the error for further handling if needed
                  }
            });
  }
  
  const series = [
    {
      name: 'Registered',
      data: event&&event.length!=0?(event.map(item => item.registered)):[],
    },
    {
      name: 'Attended',
      data: event&&event.length!=0?(event.map(item => item.attended)):[],
    },
  ];

  // Categories or labels for the bars
  const categories = event&&event.length!=0?(event.map(item => item.name)):[];

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: categories,
    },
    legend: {
      position: 'top',
    },
  };

  return (
    <div className="stacked-bar-chart">
      <Chart options={options} series={series} type="bar" height="300" />
    </div>
  );
};

export default StackedBarChart;
