import React, { useState, useEffect } from "react";
import { Tabs, Rating, Spinner } from "flowbite-react";
import AdsCard from "../Cards/Account/AdsCard";
import { useMarketplaceContext } from "../../context/MarketplaceContexts";
import { RatingStar } from "flowbite-react/lib/esm/components/Rating/RatingStar";
import { useAuth } from "../../context/AuthContext";
import MainPagination from "../../components/Miscellanous/MainPagination";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Ads() {
  // logic . if it isempty let there be cards else tye text
  const { token, logout } = useAuth();
  const navigate=useNavigate();
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [err, setErr] = useState("");
  const { ads, addAds } = useMarketplaceContext();
  const [pendingItems, setPendingItems] = useState([]);
  const [openedItems, setOpenedItems] = useState([]);
  const [closedItems, setClosedItems] = useState([]);
  const [declinedItems, setDeclinedItems] = useState([]);
  const initialPayload = {
    currentpage: 1,
    status:null
  };

  const [formData, setFormData] = useState(initialPayload);
  useEffect(() => {
    // Make the first API call
    if (!loadedOnce) {
      handlemarketplace("pending");
      handlemarketplace("active");
      handlemarketplace("closed");
      handlemarketplace("declined");
    }
  }, [loadedOnce]);

  const url =process.env.REACT_APP_API_URL;

  const handlemarketplace = (stats) => {
    const additionalPath = `${url}/get_products_logged_in_user`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    // if (token) {

    // }

    const statusinfo = {
      status: stats,
    };
setFormData((prevPayload) => ({
  ...prevPayload, // or any other property you want to update
  status: stats, // or any other property you want to update
}));
    axios
      .get(additionalPath, { params: statusinfo, headers: headers })
      .then((response) => {
        console.log(response.data.products);
        // addAds(response.data);
        // setLoadedOnce(true);
        // const data=response.data.products.data
        // setPendingItems(data);
        if (response.status === 200) {
          const data = response.data.products.data;
          switch (stats) {
            case "pending":
              setPendingItems(data);
              setPerpage(response.data.products.per_page)
              setTotal(response.data.products.total)
              setTo(response.data.products.to);
              setFrom(response.data.products.from);
              break;
            case "active":
              setOpenedItems(data);
              setPer_page(response.data.products.per_page)
              set_Total(response.data.products.total)
              set_To(response.data.products.to);
              set_From(response.data.products.from);
              break;
            case "closed":
              setClosedItems(data);
              setPer_pages(response.data.products.per_page)
              set_Totals(response.data.products.total)
              break;
            case "declined":
              setDeclinedItems(data);
              setPer_pagss(response.data.products.per_page)
              set_Totls(response.data.products.total)
              break;
            default:
              break;
          }
          setLoadedOnce(true);
        } else {
          console.error("Unexpected response status:", response.status);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // Perform actions when 401 (Unauthorized) error occurs
          console.log(
            "Unauthorized: Logging user out or performing specific action..."
          );
          logout();
          navigate("/");
          // Example: Log the user out or redirect to login page
          // You may perform the necessary actions here based on your application flow
        } else {
          // Handle other errors
          console.log(err);
          setErr(err);

          return []; // Rethrow the error for further handling if needed
        }
      });
  };

 

  // pending items
  const [currentPage, setCurrentPage] = useState(1);
  const [per_page, setPerpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [to_no, setTo] = useState(0);
  const [from_no, setFrom] = useState(0);
  const itemsPerPage = 20;

  // Calculate the range of items for the current page
  const itemsToShow = pendingItems;
  const totalPages =pendingItems&&total>0&& Math.ceil(total / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  // active items
  const [currentPages, setCurrentPages] = useState(1);
  const [perpage, setPer_page] = useState(0);
  const [totals, set_Total] = useState(0);
  const [tono, set_To] = useState(0);
  const [fromno, set_From] = useState(0);
  const itemToShow = openedItems;
  const totalPage = openedItems&&totals>0&&Math.ceil(totals / itemsPerPage);

  const handlePagesChange = (newPage) => {
    setCurrentPages(newPage);
    handleParamChange("currentpage", newPage);
  };

  const handleParamChange = (name, value) => {
    setFormData((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
    loadedOnce.current=false;
  };

  // closed items

  const [currentPags, setCurrentPags] = useState(1);
  
  const [perpages, setPer_pages] = useState(0);
  const [totalss, set_Totals] = useState(0);
  const citemToShow = closedItems;
  const totalPagess =closedItems&&totals>0&& Math.ceil(totalss / itemsPerPage);

  const handlePagessChange = (newPage) => {
    setCurrentPags(newPage);
    handleParamChange("currentpage", newPage);
  };


  // declined items
  const [currentPagss, setCurrentPagss] = useState(1);
  const [perpges, setPer_pagss] = useState(0);
  const [totlss, set_Totls] = useState(0);
  const ditemToShow = declinedItems;
  const totalPagss = declinedItems&&totlss>0&&Math.ceil(totlss / itemsPerPage);

  const handlePagssChange = (newPage) => {
    setCurrentPagss(newPage);
    handleParamChange("currentpage", newPage);
  };

  return (
    <div className="flex flex-col gap-4">
      <p className="font-bold">My Ads</p>
      <div className="flex flex-row ">
        <p className="font-bold">Average Rating:</p>
        <p className="text-midnightblue">4/5</p>
        <svg
          class="w-6 h-6 text-normalbrown"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 22 20"
        >
          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
        </svg>
      </div>
      <div>
        <Tabs.Group aria-label="Tabs with underline" style="underline">
          <Tabs.Item active title="Pending">
            {loadedOnce ? (
              itemsToShow && itemsToShow.length > 0 ? (
                <ul>
                  {itemsToShow.map((item, index) => (
                    <AdsCard item={item} key={index} status={"Pending"} />
                  ))}
                </ul>
              ) : (
                <p className="text-darkgray">No pending items available</p>
              )
            ) : (
              // Loading spinner or any other loading indicator while fetching data
              <div className="text-darkgray text-center">
                <div className="inset-0 flex items-center justify-center z-50">
                  <div className="p-4 max-w-md mx-auto rounded-md">
                    <Spinner
                      aria-label="Default status example"
                      color="warning"
                      size="xl"
                    />
                  </div>
                </div>
                Loading pending items...
              </div>
            )}
            <MainPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
            {/* pagination */}
          </Tabs.Item>
          <Tabs.Item title="Opened">
            {loadedOnce ? (
              itemToShow && itemToShow.length > 0 ? (
                <ul>
                  {itemToShow.map((item, index) => (
                    <AdsCard key={index} item={item} status={"Opened"} />
                  ))}
                </ul>
              ) : (
                <p className="text-darkgray">No opened items available.</p>
              )
            ) : (
              // Loading spinner or any other loading indicator while fetching data
              <div className="text-darkgray text-center">
                <div className="inset-0 flex items-center justify-center z-50">
                  <div className="p-4 max-w-md mx-auto rounded-md">
                    <Spinner
                      aria-label="Default status example"
                      color="warning"
                      size="xl"
                    />
                  </div>
                </div>
                Loading opened items...
              </div>
            )}
            <MainPagination
              currentPage={currentPages}
              totalPages={totalPage}
              onPageChange={handlePagesChange}
            />
          </Tabs.Item>
          <Tabs.Item title="Closed">
            {loadedOnce ? (
              citemToShow && citemToShow.length > 0 ? (
                <ul>
                  {citemToShow.map((item, index) => (
                    <AdsCard key={index} item={item} status={"Closed"} />
                  ))}
                </ul>
              ) : (
                <p className="text-darkgray">No closed items available.</p>
              )
            ) : (
              // Loading spinner or any other loading indicator while fetching data
              <div className="text-darkgray text-center">
                <div className="inset-0 flex items-center justify-center z-50">
                  <div className="p-4 max-w-md mx-auto rounded-md">
                    <Spinner
                      aria-label="Default status example"
                      color="warning"
                      size="xl"
                    />
                  </div>
                </div>
                Loading closed items...
              </div>
            )}

            <MainPagination
              currentPage={currentPags}
              totalPages={totalPagess}
              onPageChange={handlePagessChange}
            />
          </Tabs.Item>
          <Tabs.Item title="Declined">
            {loadedOnce ? (
              ditemToShow && ditemToShow.length > 0 ? (
                <ul>
                  {ditemToShow.map((item, index) => (
                    <AdsCard key={index} item={item} status={"Declined"} />
                  ))}
                </ul>
              ) : (
                <p className="text-darkgray">No declined items available.</p>
              )
            ) : (
              // Loading spinner or any other loading indicator while fetching data
              <div className="text-darkgray text-center">
                <div className="inset-0 flex items-center justify-center z-50">
                  <div className="p-4 max-w-md mx-auto rounded-md">
                    <Spinner
                      aria-label="Default status example"
                      color="warning"
                      size="xl"
                    />
                  </div>
                </div>
                Loading declined items...
              </div>
            )}
            <MainPagination
              currentPage={currentPagss}
              totalPages={totalPagss}
              onPageChange={handlePagssChange}
            />
          </Tabs.Item>
        </Tabs.Group>
      </div>
    </div>
  );
}
export default Ads;
